<template>
  <div>
    <v-row class="mb-7" no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>

      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          당사는 온라인투자연계금융업 등록취소 해산결의 파산선고 등 영업중단 등
          상황 발생에 대비하여 원리금 상환 배분 업무에 관한 계획 등 이용자
          보호에 관하여 대통령령으로 정하는 사항을 외부 수탁기관인 "회계법인
          혜안"에 위탁하는 등 공정하고도 투명한 청산업무 처리절차를
          마련하였습니다.
          <br /><br />
          수탁기관이 위 업무를 수행하기 위하여 연계대출 또는 연계투자 계약 관련
          자료를 요청하는 경우 펀다는 요청받은 자료 일체를 수탁기관에
          제공합니다.
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '원리금 상환 배분 업무에 관한 계획 등에 따른 업무 처리 절차',
    },
  },
  data() {
    return {
      values: [
        '원리금 상환, 배분 업무',
        '연계대출 채권 관리',
        '투자금 및 상환금 관리',
        '연계투자계약 및 연계대출계약 관리',
        '기타 상기에 준하는 사항으로써 금융위원회가 정하여 고시하는 사항',
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding-left: 5px;
  list-style: none;
}
</style>
