import { OverlayTypes } from './types/overlay'

export default {
  name: 'overlay',
  namespaced: true,
  state: {
    overlay: null,
    properties: null,
  },
  getters: {
    [OverlayTypes.getters.IS_SHOW_OVERLAY](state) {
      return state.overlay !== null
    },
    [OverlayTypes.getters.GET_OVERLAY](state) {
      return state.overlay
    },
    [OverlayTypes.getters.GET_PROPS](state) {
      return state.properties
    },
  },
  mutations: {
    [OverlayTypes.mutations.SET_OVERLAY](state, v) {
      state.overlay = v
    },
    [OverlayTypes.mutations.SET_PROPS](state, v) {
      state.properties = v
    },
  },
  actions: {
    [OverlayTypes.actions.CLOSE_OVERLAY]({ commit }) {
      commit(OverlayTypes.mutations.SET_OVERLAY, null)
    },
    [OverlayTypes.actions.OPEN_OVERLAY]({ commit }, overlayKey) {
      commit(OverlayTypes.mutations.SET_OVERLAY, overlayKey)
    },
    [OverlayTypes.actions.PASS_PROPS]({ commit }, props) {
      commit(OverlayTypes.mutations.SET_PROPS, props)
    },
  },
}
