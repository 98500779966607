<template>
  <div class="valid-account">
    <v-row class="margin-b-10" no-gutters>
      <v-col cols="12" sm="12" md="12" class="position--relative px-0">
        <div
          class="d-flex account__select align-center justify-space-between"
          @click="onClickCodeSelectBar"
          :style="isDisabled ? 'cursor: not-allowed;' : ''"
          style="height: 46px; border-radius: 4px; border: solid 1px #e7edf6"
        >
          <template v-if="inputBankCode">
            <p class="d-flex ma-0 align-center width-100">
              <img
                class="margin-r-10 bank__image"
                :src="
                  require('@/assets/images/common/banks/' +
                    inputBankCode +
                    '.jpg')
                "
              />
              {{ inputBankName }}
            </p>
          </template>
          <template v-else>
            <p class="d-flex ma-0 align-center font-weight-bold">
              은행을 선택해 주세요.
            </p>
          </template>
          <span class="detail-arrow" :class="{ active: showAllowBanks }"></span>
        </div>
        <ul v-show="showAllowBanks" class="account__bank-list margin-t-5 px-0">
          <template v-if="allowBanks">
            <li v-for="allowBank in allowBanks" :key="allowBank.code">
              <p
                class="d-flex ma-0 align-center"
                :class="{ active: inputBankCode == allowBank.code }"
                @click="selectBank(allowBank)"
              >
                <img
                  class="margin-r-10 bank__image"
                  :src="
                    require('@/assets/images/common/banks/' +
                      allowBank.code +
                      '.jpg')
                  "
                />
                {{ allowBank.name }}
              </p>
            </li>
          </template>
          <template v-else>
            <li>
              <p class="d-flex ma-0 align-center">
                현재 은행목록을 불러올 수 없습니다.
              </p>
            </li>
          </template>
        </ul>
      </v-col>
    </v-row>

    <v-row class="margin-b-5" no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-0">
        <v-row :class="{ 'd-flex': !isMobile }" no-gutters>
          <v-col
            cols="12"
            sm="12"
            md="7"
            :class="isMobile ? 'margin-b-10' : 'padding-r-15'"
          >
            <input
              v-model="inputAccountNo"
              :disabled="isDisabled"
              class="width-100"
              placeholder="계좌번호를 입력해주세요"
              numberonly
              style="
                font-size: 15px;
                height: 46px;
                border-radius: 4px;
                border: solid 1px #e7edf6;
                box-shadow: none;
                padding: 6px 12px;
              "
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="5"
            class="px-0"
            :class="{ 'width-100': isMobile }"
          >
            <v-btn
              class="width-100 font-size--15 white--text"
              color="#333333"
              style="height: 46px; border-radius: 4px"
              tile
              :disabled="
                isDisabled ||
                  valid.validLoading ||
                  !!!this.inputBankCode ||
                  !!!this.inputAccountNo
              "
              :loading="valid.validLoading"
              @click="validAccount()"
            >
              계좌 조회하기
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" class="px-0">
            <p
              class="error--text font-size--13 line-height--16 margin-t-7 mb-0"
            >
              ※ 적금, 휴면 계좌의 경우 송금이 불가능하오니 예금 계좌로
              입력해주세요.
            </p>
            <p class="my-2 error--text font-size--13">
              ※ 등록된 계좌 외에는 예치금 입,출금이 제한됩니다.
            </p>
          </v-col>

          <v-col v-if="showAccountName" cols="12" sm="6" md="6" class="mt-3">
            <v-text-field
              v-model="accountForm.accountName"
              disabled
              readonly
              outlined
              hide-details
              label="예금주"
              placeholder="계좌 조회를 진행해주세요"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CodeTypes } from '@/store/types/code'

export default {
  props: {
    isDisabled: Boolean,
    showAccountName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAllowBanks: false,
      accountForm: {
        bankCode: null,
        accountNo: null,
        accountName: null,
      },
      inputBankCode: null,
      inputBankName: null,
      inputAccountNo: null,
      valid: {
        validLoading: false,
      },
    }
  },
  computed: {
    ...mapGetters('code', {
      allowBanks: CodeTypes.getters.GET_BANKS,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    inputBankCode: function(v) {
      if (!!this.accountForm.accountName && this.accountForm.bankCode !== v) {
        this.accountForm.accountName = null
      }
    },
    inputAccountNo: function(v) {
      if (!!this.accountForm.accountName && this.accountForm.accountNo !== v) {
        this.accountForm.accountName = null
      }
    },
    'accountForm.accountName': function() {
      this.$emit('valid-account-form', this.accountForm)
    },
  },
  async created() {
    await this.getBanks()
  },
  methods: {
    ...mapActions('code', {
      getBanks: CodeTypes.actions.GET_BANKS,
    }),
    onClickCodeSelectBar() {
      if (this.isDisabled) {
        return
      }
      this.showAllowBanks = !this.showAllowBanks
    },
    selectBank(bank) {
      this.inputBankCode = bank.code
      this.inputBankName = bank.name
      this.showAllowBanks = false
    },
    async validAccount() {
      let alert = await this.$swal.basic.confirm(
        '계좌 조회를 진행하시겠습니까?'
      )
      if (!alert.isConfirmed) {
        return
      }

      if (!!!this.inputBankCode || !!!this.inputAccountNo) {
        return
      }

      this.valid.validLoading = true

      let q = `
        data: getPaymentAccountInfo(bankCode: "${this.inputBankCode}", accountNo: "${this.inputAccountNo}"){
            realAccountName
            isSearch
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data) {
          let result = data
          if (result.isSearch) {
            this.accountForm.bankCode = this.inputBankCode
            this.accountForm.accountNo = this.inputAccountNo
            this.accountForm.accountName = result.realAccountName

            this.$swal.basic.alert({
              html: `예금주 <br /> <br /> 
              <b class="font-size--17">
                ${result.realAccountName}
              </b>
              <br />
              <br />
              위 정보가 맞는지 확인 후 진행해주세요
              `,
            })
          } else {
            this.$swal.basic.alert('올바르지 않은 계좌입니다.')
          }
        } else {
          this.$swal.basic.alert(
            '계좌조회에 실패했습니다. 문제가 지속되면 문의바랍니다.'
          )
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
      } finally {
        this.valid.validLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.valid-account .btn-funda-basic,
.valid-account .btn-funda-basic--reverse {
  padding: 10px 24px;
  border-radius: 3px;
}

.valid-account .bank__image {
  max-width: 110px;
}

.valid-account .account__select {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: solid 1px #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.valid-account .account__select .detail-arrow {
  content: '';
  margin-top: 0;
  left: unset;
  right: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #747474;
}

.valid-account .account__select .detail-arrow.active {
  border-top: unset;
  border-bottom: 6px solid #747474;
}

.valid-account .account__bank-list {
  position: absolute;
  width: 100%;
  max-height: 300px;
  background-color: white;
  border: solid 1px #ccc;
  box-shadow: none;
  list-style: none;
  overflow: scroll;
  z-index: 99;
}

.valid-account .account__bank-list li p {
  padding: 10px 12px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
}

.valid-account .account__bank-list li p:hover,
.valid-account .account__bank-list li p.active {
  background-color: #f9f9f9 !important;
  font-weight: bold;
}

.valid-account .attention {
  color: #f9356a;
  font-size: 13px;
}
</style>
