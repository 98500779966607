<template>
  <div>
    <div class="width-100 margin-t-5">
      <div class="balance-deposit width-100 padding-y-20 mb-5">
        <div
          class="my-balance-deposit-table__filter type-balance-deposit d-flex margin-b-10"
          v-if="filters != null"
        >
          <div
            v-for="(filter, index) in filters"
            :key="index"
            class="text-center cursor--pointer padding-y-10 padding-x-15"
            :class="[
              { active: filter.value == selectedFilter },
              filter.value == selectedFilter
                ? 'color--gray-3 font-weight-black'
                : 'color--gray-2 font-weight-bold',
            ]"
            @click="changeOption('filter', filter.value)"
          >
            {{ filter.name }}
          </div>
        </div>
        <MyBalanceDepositTable
          :data="tableData.transactions"
          :headers="headers"
          :is-loading="tableData.isLoading"
        >
          <template slot="col" slot-scope="slotProps">
            <div
              :style="
                isMobile ? styles.transactTime.mobile : styles.transactTime.pc
              "
              class="text-center color--gray-2 font-weight-bold"
            >
              {{ slotProps.row.transactTime | moment('YY.MM.DD HH:mm:ss') }}
            </div>
            <div
              :style="isMobile ? styles.category.mobile : styles.category.pc"
              :class="
                slotProps.row.amount < 0 ? 'color-funda--basic' : 'color--pink'
              "
              class="font-weight-black font-size--13"
            >
              {{ slotProps.row.amount &lt; 0 ? '출금' : '입금' }}
            </div>
            <div
              :style="isMobile ? styles.memo.mobile : styles.memo.pc"
              class="font-weight-bold my-balance-deposit-table__text"
            >
              <span>{{ slotProps.row.memo }}</span>
            </div>
            <div
              :style="isMobile ? styles.amount.mobile : styles.amount.pc"
              :class="
                slotProps.row.amount < 0 ? 'color-funda--basic' : 'color--pink'
              "
              class="justify-end font-weight-black font-size--14"
            >
              {{ slotProps.row.amount | commaFormat }}원
            </div>
            <div
              :style="isMobile ? styles.balance.mobile : styles.balance.pc"
              class="justify-end color--gray-2 font-weight-bold"
            >
              {{ slotProps.row.balance | commaFormat }}원
            </div>
          </template>
        </MyBalanceDepositTable>
      </div>
    </div>

    <PageMaker
      v-if="tableData.paging.totalCount > 0"
      :page="tableData.paging.page"
      :size="tableData.paging.size"
      :total-count="tableData.paging.totalCount"
      :raw-data="tableData.transactions"
      :is-white="false"
      @currentPage="changeOption('page', $event)"
    />
  </div>
</template>

<script>
import MyBalanceDepositTable from '@/components/myfunda/MyBalanceDepositTable'
import PageMaker from '@/components/PageMaker'

export default {
  components: {
    MyBalanceDepositTable,
    PageMaker,
  },
  data() {
    return {
      selectedFilter: null,
      filters: [
        { name: '전체', value: 'ALL' },
        { name: '입금', value: 'IN' },
        { name: '출금', value: 'OUT' },
        { name: '투자', value: 'INVEST' },
        { name: '지급', value: 'PAYOUT' },
      ],
      styles: {
        transactTime: {
          pc: {
            width: '19%',
            'justify-content': 'center',
          },
          mobile: {
            order: 4,
            width: '70%',
            'font-size': '13px',
          },
        },
        category: {
          pc: {
            width: '8%',
            'justify-content': 'center',
          },
          mobile: {
            order: 1,
            width: '10%',
            'min-width': '40px',
          },
        },
        memo: {
          pc: {
            width: '36%',
            'justify-content': 'center',
          },
          mobile: {
            order: 2,
            width: '90%',
            'max-width': 'calc(100% - 40px)',
            'margin-bottom': '7px',
          },
        },
        amount: {
          pc: {
            width: '18%',
            'justify-content': 'center',
          },
          mobile: {
            order: 3,
            width: '100%',
          },
        },
        balance: {
          pc: {
            width: '19%',
          },
          mobile: {
            order: 5,
            width: '30%',
          },
        },
      },
      headers: [
        {
          name: '날짜',
          width: '19%',
        },
        {
          name: '구분',
          width: '8%',
        },
        {
          name: '내역',
          width: '36%',
        },
        {
          name: '금액',
          width: '18%',
        },
        {
          name: '잔액',
          width: '19%',
        },
      ],
      tableData: {
        transactions: [],
        paging: {
          size: 20,
          page: 1,
          startNum: 1,
          totalCount: 0,
          totalPage: 1,
        },
        isLoading: false,
      },
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.getTransactions()
  },
  methods: {
    changeOption(type, value) {
      let option = {}
      if (type === 'filter') {
        if (
          !!this.$route.query.category &&
          this.$route.query.category === value
        ) {
          return
        }

        option.category = value
      } else if (type === 'page') {
        if (value === this.tableData.paging.page) {
          return
        }
        option.page = value
        option.category = this.selectedFilter
      }

      this.$router.push({
        query: option,
      })
    },
    async getTransactions() {
      this.tableData.isLoading = true
      const query = this.$route.query

      this.selectedFilter = query.category ? query.category : 'ALL'
      this.page = query.page ? query.page : 1
      this.size = query.size ? query.size : 20

      let q = `
            data: wwwBalanceDepositTransactions(req: {
                page: ${this.page},
                size: ${this.size},
                category: "${this.selectedFilter}"
            })
            {
                items: data {
                    amount
                    balance
                    memo
                    transactTime
                }
                paging {
                    size
                    page
                    startNum
                    totalCount
                    totalPage
                }
            }
        `

      try {
        let { items, paging } = await this.$fundaApi.query(gql`{${q}}`)
        if (items != null && items.length > 0) {
          this.tableData.transactions = items
          this.tableData.paging = paging
        }
      } catch (e) {
        this.$swal.basic.alert('다시 시도해주세요.')
      } finally {
        this.tableData.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-balance-deposit-table.type-balance-deposit:not(.mobile)
  .body-row
  > div:last-child {
  padding-right: 20px;
}

@media screen and (max-width: 359px) {
  .my-balance-deposit-table__filter.type-balance-deposit > div {
    width: 20%;
    padding: 10px 0 !important;
  }
}
</style>
