<template>
  <div>
    <v-row class="file-upload" no-gutters>
      <v-col cols="12">
        <SkeletonIdLoader :is-active="!!tempFile" />
      </v-col>
      <v-col cols="12">
        <p
          v-show="!!tempFile"
          class="mt-3 mb-1 error--text font-size--16 line-height--18"
        >
          ※ 개인정보 보호를 위해 미리보기가 제공되지 않습니다
        </p>
      </v-col>
      <v-col
        v-if="!!tempFile"
        cols="12"
        class="d-flex justify-center my-3 font-size--15"
      >
        <label class="mr-2 font-weight-bold" for="id-card">
          파일명:
        </label>

        <input readonly id="id-card" type="text" :value="tempFile.orgName" />
      </v-col>

      <v-col
        cols="12"
        class="file-upload__btn"
        :class="{ float: tempFile === null }"
      >
        <S3FileUploadButton
          ref="fileUploadCorporation"
          uploadOnSelect
          :bucketName="bucketName"
          :path="path"
          :tempFile.sync="tempFile"
          :savedFile.sync="savedFile"
        >
          사업자(법인) 등록증 업로드
        </S3FileUploadButton>
      </v-col>
    </v-row>

    <v-row class="mt-3" no-gutters>
      <v-col cols="12">
        <div class="label mb-3">
          <v-icon small>mdi-account</v-icon>사업자(법인)명
        </div>
        <ValidAccount
          :is-disabled="isLoading"
          :show-account-name="false"
          @valid-account-form="updateName"
        />
        <v-text-field
          outlined
          single-line
          v-model="cddForm.name"
          :loading="isLoading"
          label="계좌 조회시 자동입력됩니다"
          :error-messages="invalidMsg.name"
          :hide-details="!!!invalidMsg.name"
          :class="{ 'error--text': !!invalidMsg.name }"
          class="mt-3 mb-5"
          disabled
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="mb-5">
        <div class="label">
          <v-icon small>mdi-account-details-outline</v-icon>사업자(법인)등록번호
        </div>
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              outlined
              single-line
              v-model="cddForm.regNum1"
              name="regNum1"
              maxlength="6"
              :error-messages="invalidMsg.regNum1"
              :hide-details="!!!invalidMsg.regNum1"
              :class="{ 'error--text': !!invalidMsg.regNum1 }"
            ></v-text-field>
          </v-col>

          <v-col
            cols="1"
            class="d-flex align-center justify-center"
            style="height: 56px"
          >
            <p class="ma-0">
              ㅡ
            </p>
          </v-col>

          <v-col cols="7">
            <v-text-field
              outlined
              single-line
              v-model="cddForm.regNum2"
              :append-icon="regNum2Show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.regNum2"
              :type="regNum2Show ? 'text' : 'password'"
              :hide-details="
                cddForm.regNum2 === null ||
                  rules.regNum2[0](cddForm.regNum2) === true
              "
              name="regNum2"
              maxlength="7"
              label="뒷자리(7자리)"
              @click:append="regNum2Show = !regNum2Show"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <div class="label"><v-icon small> mdi-cellphone </v-icon>휴대폰</div>
        <v-text-field
          outlined
          single-line
          name="mobileNo"
          label="휴대폰번호를 입력해주세요('-' 제외)"
          v-model="cddForm.mobileNo"
          :disabled="isLoading"
          :error-messages="invalidMsg.mobileNo"
          :hide-details="!!!invalidMsg.mobileNo"
          class="mb-5"
          @input="formatMobileNo($event)"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <div id="policy" class="pb-5">
          <v-checkbox
            hide-details
            v-model="promotionSms.isAgree"
            :label="promotionSms.title"
            class="my-0"
          ></v-checkbox>
          <small
            class="text-plain"
            style="margin-left: 34px; font-size: 11px; display: block"
            >투자할 상품의 정보를 문자로 알려드립니다</small
          >
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="width-100 font-size--16"
          height="45px"
          :disabled="isOk !== true || isLoading"
          :loading="isLoading"
          @click="saveCddForm()"
        >
          법인투자자 등록하기
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import ValidAccount from '@/components/ValidAccount'
import SkeletonIdLoader from '@/components/common/SkeletonIdLoader'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton'

export default {
  components: {
    ValidAccount,
    S3FileUploadButton,
    SkeletonIdLoader,
  },
  data() {
    return {
      isLoading: false,
      cddForm: {
        name: null,
        mobileNo: null,
        regNum1: null,
        regNum2: null,
        cddFile: null,
      },
      regNum2Show: false,
      promotionSms: {
        title: '휴대폰으로 투자알림 받기',
        name: 'promotionSms',
        isAgree: true,
      },
      invalidMsg: {
        regNum1: null,
        regNum2: null,
        name: null,
        mobileNo: null,
      },
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/investor_type/',
      tempFile: null,
      savedFile: null,
      rules: {
        mobileNo: [
          value => !!value || '휴대전화번호를 입력해주세요',
          value =>
            /^01\d{8,9}$/.test(value) || '휴대폰전화번호가 올바르지 않습니다',
        ],
        regNum1: [value => !!value || '사업자(법인)등록번호를 입력해주세요'],
        regNum2: [
          value => !!value || '사업자(법인)등록번호를 입력해주세요',
          value =>
            /\d{7}/.test(value) || '사업자(법인)등록번호가 유효하지 않습니다',
        ],
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isValidRegNum() {
      return (
        !!this.cddForm.regNum1 &&
        !!this.cddForm.regNum2 &&
        this.invalidMsg.regNum1 === null &&
        this.invalidMsg.regNum2 === null
      )
    },
    isValidForm() {
      const f = this.cddForm
      const file = f.cddFile
      const fileIsValid = !!file?.orgName && !!file?.tempKey

      return (
        !!f.name &&
        !!f.mobileNo &&
        this.invalidMsg.mobileNo === null &&
        this.isValidRegNum === true &&
        fileIsValid
      )
    },
    isOk() {
      if (this.isValidRegNum !== true) {
        return false
      }

      return this.isValidForm === true
    },
  },
  watch: {
    'cddForm.name': {
      immediate: true,
      handler(v) {
        if (!!!v) {
          this.invalidMsg.name = '출금계좌 조회를 진행해주세요'
        } else {
          this.invalidMsg.name = null
        }
      },
    },
    'cddForm.mobileNo': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('mobileNo', newVal)
        this.invalidMsg.mobileNo = msg
      },
    },
    'cddForm.regNum1': {
      handler(newVal) {
        let msg = this.getFormErrMsg('regNum1', newVal)
        this.invalidMsg.regNum1 = msg
      },
    },
    'cddForm.regNum2': {
      handler(newVal) {
        let msg = this.getFormErrMsg('regNum2', newVal)
        this.invalidMsg.regNum2 = msg
      },
    },
    tempFile(v) {
      if (!!v) {
        this.cddForm.cddFile = v
      }
    },
    profile: {
      immediate: true,
      deep: true,
      handler(v) {
        this.cddForm.name = v?.name
      },
    },
  },
  methods: {
    ...mapMutations('auth', {
      SET_USER_VALIDATION: AuthTypes.mutations.SET_USER_VALIDATION,
    }),
    ...mapActions('auth', {
      GET_USER_VALIDATION: AuthTypes.actions.GET_USER_VALIDATION,
    }),
    updateName(accountForm) {
      this.cddForm.name = accountForm.accountName
    },
    formatMobileNo($event) {
      this.cddForm.mobileNo = $event.replace('-', '')
    },
    dateSave(date) {
      this.$refs.dialog.save(date)
    },
    getFormErrMsg(type, newVal) {
      const rules = this.rules[type]
      let msg = null
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const isValid = rule(newVal)
        if (typeof isValid !== 'boolean') {
          msg = isValid
          break
        }
      }

      return msg
    },
    async saveCddForm() {
      if (this.isOk !== true) {
        this.$swal.basic.warning('정보를 입력해주세요')
        return
      }

      let alert = await this.$swal.basic.confirm('신분증 등록을 진행합니다')
      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      let q = `
        data: registerCorporation(userIdx: ${this.userIdx}, certificate: $input) {
          userIdx
          userCddIdx
        }
      `

      try {
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: CoporationCertificate!)',
          { input: this.cddForm }
        )

        if (!!data?.userCddIdx) {
          if (this.promotionSms.isAgree === true) {
            await this.$fundaApi.mutation(gql`
              {
                wwwUpdatePromotionSms(isValid: true)
              }
            `)
          }

          const uv = await this.GET_USER_VALIDATION()
          this.SET_USER_VALIDATION(uv)
        } else {
          this.$swal.basic.error(
            '신분증 등록 중 에러가 발생하였습니다. 다시 시도해주세요.'
          )
        }
      } catch (e) {
        this.$swal.basic.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-upload {
  position: relative;
  margin-bottom: 15px;
  text-align: center;

  input {
    min-width: 200px;
    max-width: 280px;
  }

  &__btn {
    &.float {
      position: absolute;
      display: flex;
      height: 206px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
