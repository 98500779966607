<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-1 font-size--18 font-weight-bold">
          적격투자자
          <span class="font-size--16 font-weight-regular">
            (소득요건을 구비한 개인 투자자)
          </span>
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <table class="width-100">
          <thead>
            <tr>
              <th width="50%" class="font-size--15">분류기준</th>
              <th class="font-size--15">증빙자료</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-1">
                <p class="mb-2 font-size--14">
                  아래 두 가지 중 한가지를 만족하는 경우
                </p>
                <p class="mb-1 font-size--13">
                  - 이자, 배당소득
                  <span class="font-weight-bold">2천만원</span> 초과
                </p>
                <p class="mb-0 font-size--13">
                  - 사업, 근로소득
                  <span class="font-weight-bold">1억원</span> 초과
                </p>
              </td>
              <td class="px-1">
                <p class="mb-1 font-size--14 font-weight-bold">
                  - 사업소득/금융소득이 있는 경우
                </p>
                <p class="mb-1 font-size--13">
                  (1) 종합소득 과세표준 확정신고서
                </p>
                <p class="mb-3 font-size--13">(2) 종합소득세 신고서 접수증</p>

                <p class="mb-1 font-size--14 font-weight-bold">
                  - 근로소득만 있는 경우
                </p>
                <p class="mb-5 font-size--13">
                  (1) 근로소득 원천징수 영수증 (1, 2페이지)
                </p>

                <p class="mb-3 font-size--13">
                  ※ 모든 서류는
                  <span class="font-weight-bold"
                    >직전 과세기간 자료여야하며, 신고자 실명 및 주민번호</span
                  >가 모두 기재되어 있어야 합니다.
                </p>
                <p class="mb-3 font-size--13">
                  ※ 제출 서류 내 서명 또는 날인이 요구되는 란에는 반드시 신고자
                  혹은 법인의 서명 또는 날인이 있어야 합니다.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>

    <v-row class="mt-3" no-gutters>
      <v-col v-if="isAlreadyLimit2 || isWaitingStatus" cols="12" class="mt-5">
        <p class="color--pink mb-0 font-size--14 text-center">
          {{
            isAlreadyLimit2
              ? `현재 ${curMyType} 입니다.`
              : '서류 접수가 완료되어 심사가 진행중입니다.'
          }}
        </p>
      </v-col>
      <template v-else>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="isFinancialIncome"
            hide-details
            label="사업소득/금융소득 증빙"
            class="mt-0 mb-3"
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <div class="mb-4">
            <S3FileUploadButton
              ref="fileUploadLimit2_1"
              uploadOnSelect
              :bucketName="bucketName"
              :path="path"
              :tempFile.sync="tempFile"
              :savedFile.sync="savedFile"
              dark
            >
              {{ !!tempFile ? '다른파일 업로드' : '서류1 업로드' }}
            </S3FileUploadButton>
            <p v-if="!!tempFile" class="my-1 font-size--14">
              업로드된 파일: {{ tempFile.orgName }}
            </p>
          </div>
          <div v-if="isFinancialIncome">
            <S3FileUploadButton
              ref="fileUploadLimit2_2"
              uploadOnSelect
              :bucketName="bucketName"
              :path="path"
              :tempFile.sync="tempFile2"
              :savedFile.sync="savedFile2"
              dark
            >
              {{ !!tempFile2 ? '다른파일 업로드' : '서류2 업로드' }}
            </S3FileUploadButton>
            <p v-if="!!tempFile2" class="my-1 font-size--14">
              업로드된 파일: {{ tempFile2.orgName }}
            </p>
          </div>
        </v-col>

        <v-col cols="12" class="pt-5 text-center">
          <v-btn
            class="btn-funda--blue common-button"
            height="45px"
            :loading="isLoading"
            :disabled="isDisabled"
            @click="submitRequest()"
          >
            제출하기
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton.vue'

export default {
  components: {
    S3FileUploadButton,
  },
  data() {
    return {
      isLoading: false,
      requestLoading: false,
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/investor_type/',
      isFinancialIncome: true,
      requestUser: {
        investorType: 2,
        files: [],
      },
      tempFile: null,
      savedFile: null,
      tempFile2: null,
      savedFile2: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      myReq: AuthTypes.getters.GET_REQ_LIMIT,
    }),
    curMyType() {
      return this.profile?.investmentLimit.type
    },
    isAlreadyLimit2() {
      return this.profile?.investmentLimit.idx >= 2
    },
    isWaitingStatus() {
      return this.myReq?.status === 'WAITING'
    },
    fileList() {
      return [this.tempFile, this.tempFile2]
    },
    realFiles() {
      const files = this.fileList.filter((f) => {
        if (!!f) {
          return f
        }
      })

      return files
    },
    isDisabled() {
      if (this.isLoading) {
        return true
      }

      if (this.isWaitingStatus) {
        return true
      }

      let passFiles = false

      if (this.isFinancialIncome) {
        passFiles = this.realFiles.length === 2
      } else {
        passFiles = this.realFiles.length === 1
      }

      return passFiles === false
    },
  },
  watch: {
    isFinancialIncome() {
      this.tempFile = null
      this.tempFile2 = null
    },
  },
  created() {
    this.getMyReq()
  },
  methods: {
    ...mapActions('auth', {
      getMyReq: AuthTypes.actions.GET_REQ_LIMIT,
    }),
    async submitRequest() {
      if (this.isDisabled) {
        return
      }

      const alert = await this.$swal.basic.confirm(
        `[${this.curMyType}] -> [적격투자자]로 투자한도 증액을 신청합니다`
      )

      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      this.requestUser.files = this.realFiles
      const q = `
        updateInvestmentTypeFile(userIdx: ${this.userIdx}, signupUserStep1: $input) {
          idx
          userIdx
          status
        }
      `

      try {
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: SignupUserStep1)',
          { input: this.requestUser }
        )

        if (!!data) {
          this.$swal.basic.success({
            html:
              '증빙서류 접수가 완료되었습니다. <br /> 서류 검토 후 승인이 완료될 예정입니다. <br />(영업일 기준 1일 이내)',
          })
        }
      } catch (e) {
        this.$swal.basic.warning(e.message)
      } finally {
        this.isLoading = false
        this.getMyReq()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
td p {
  line-height: 1.1;
}

.common-button {
  max-width: 150px;
  width: 100%;
  font-size: 15px;
  padding: 15px 0;
}
</style>
