<template>
  <div class="d-flex align-center justify-center" style="min-height: 500px">
    <MyLoader />
  </div>
</template>

<script>
import MyLoader from '@/components/MyLoader'

export default {
  components: {
    MyLoader,
  },
}
</script>
