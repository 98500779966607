<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2
          class="mt-5 mb-2 pl-1 color--gray-3 font-size--22 font-weight-black text-center"
        >
          결산공시
        </h2>
      </v-col>
    </v-row>

    <BusinessRegistration
      :table-title="'회사의 온라인투자연계금융업 등록 내역'"
    />
    <BusinessStructure :table-title="'회사의 거래구조 및 영업방식'" />
    <CorpFinance :table-title="'회사의 재무 및 경영현황'" />
    <CorpMembers :table-title="'임직원수, 전문가 보유내역'" />
    <Consultation
      :table-title="'이용자에 대한 상담 업무시간, 방식 및 연락처'"
    />
    <LinkLoanInfo :table-title="'누적 연계대출 금액 및 연계대출 잔액'" />
    <OverdueRate :table-title="'연체율 등에 관한 사항'" />
    <LoanInterest :table-title="'대출이자에 관한 사항'" />
    <CostInfo :table-title="'수수료 등 부대비용에 관한 사항'" />

    <DebtorAssessmentSystem :table-title="'차입자의 상환능력평가 체계'" />
    <RepaymentMethod :table-title="'상환방식에 관한 사항'" />
    <CollectionMethod
      :table-title="'채무불이행 시 채권추심 등 원리금 회수 방식에 관한 사항'"
    />
    <DepositBank :table-title="'투자금 등의 예치기관에 관한 사항'" />
    <ConflictPreventionSystem :table-title="'이해상충방지체계'" />
    <RepaymentTrustInfo
      :table-title="'원리금 상환 배분 업무에 관한 계획 등 위탁 현황'"
    />
    <SettlementMethod
      :table-title="
        '원리금 상환 배분 업무에 관한 계획 등에 따른 업무 처리 절차'
      "
    />
  </div>
</template>

<script>
import BusinessRegistration from './BusinessRegistration.vue'
import BusinessStructure from './BusinessStructure.vue'
import CorpFinance from './CorpFinance.vue'
import CorpMembers from './CorpMembers.vue'
import Consultation from './Consultation.vue'
import LinkLoanInfo from './LinkLoanInfo.vue'
import OverdueRate from './OverdueRate.vue'
import LoanInterest from './LoanInterest.vue'
import CostInfo from './CostInfo.vue'
import DebtorAssessmentSystem from './DebtorAssessmentSystem.vue'
import RepaymentMethod from './RepaymentMethod.vue'
import CollectionMethod from './CollectionMethod.vue'
import DepositBank from './DepositBank.vue'
import ConflictPreventionSystem from './ConflictPreventionSystem.vue'
import RepaymentTrustInfo from './RepaymentTrustInfo.vue'
import SettlementMethod from './SettlementMethod.vue'

export default {
  components: {
    BusinessRegistration,
    BusinessStructure,
    CorpFinance,
    CorpMembers,
    Consultation,
    LinkLoanInfo,
    OverdueRate,
    LoanInterest,
    CostInfo,
    DebtorAssessmentSystem,
    RepaymentMethod,
    CollectionMethod,
    DepositBank,
    ConflictPreventionSystem,
    RepaymentTrustInfo,
    SettlementMethod,
  },
}
</script>

<style lang="scss" scoped></style>
