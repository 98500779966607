<template>
  <div
    class="balance-deposit dashboard-box--ma-0 padding-x-25 py-5 margin-t-5"
    :class="{ mobile: isMobile }"
  >
    <div class="balance-deposit__top-wrapper">
      <v-row
        class="sub-menu margin-b-15"
        :class="{ mobile: isMobile }"
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          :class="{ 'd-flex': isMobile }"
        >
          <router-link
            v-for="menu in subMenu"
            :key="menu.name"
            :to="{ name: menu.name }"
            :class="{ 'router-link-exact-active': $route.name === menu.name }"
            tag="button"
          >
            {{ menu.text }}
          </router-link>
        </v-col>
      </v-row>
    </div>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <router-view
          :key="$route.fullPath"
          :my-dashboard-data="myDashboardData"
        >
        </router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      subMenu: [
        { text: '입출금하기', name: 'myfunda-balance-deposit' },
        { text: '입출금 내역', name: 'balance-deposit-transaction' },
        { text: '포인트', name: 'balance-deposit-point' },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss">
/* start:my-balance-deposit-top */
.balance-deposit {
  color: #333333;

  button {
    transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    height: 46px;

    .loading-spinner {
      position: absolute;
      left: 0;
      right: 0;
      top: 14px; /* ie */
    }
  }

  .fadein {
    animation: fadeIn 0.5s ease-out;
  }

  .common-button {
    outline: none;
    border: none;
    font-weight: 900;
    border-radius: 6px;
    padding: 14px 8px;

    @media screen and (max-width: 768px) {
      height: 54px;
      font-size: 18px;
    }
  }

  &:not(.mobile) {
    > button {
      font-size: 15px;
      line-height: 17px;
    }
  }

  &.mobile {
    > button {
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__top {
    min-height: 88px;
  }

  &__top-notice {
    background: linear-gradient(265deg, #3a92f9, #1baaf1 72%);
    border-radius: 8px;

    @media screen and (max-width: 320px) {
      padding: 10px 5px 10px 3px !important;
    }
  }

  &__top-notice-text {
    text-align: left;
    line-height: 20px;
  }
}

.my-balance-deposit-table__text {
  position: relative;
}

.my-balance-deposit-table__text span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.my-balance-deposit-table.mobile .my-balance-deposit-table__text span {
  text-align: right;
}
/* end:my-balance-deposit-top */
</style>
