<template>
  <section id="section__secret" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row class="section-main-row secret__main" no-gutters>
        <v-col cols="12" class="mb-5">
          <p class="section-title secret-title">
            안전한 수익률의 비밀은
            <br />
            일상환과 분산투자!
          </p>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6" :class="{ 'mb-7': isMobile }">
          <div class="secret-card">
            <div>
              <p class="secret-card__icon">
                <v-icon size="56px" color="#1baaf1">
                  mdi-credit-card-refresh-outline
                </v-icon>
              </p>

              <p class="secret-card__title">
                카드매출 일상환
              </p>
            </div>

            <div class="secret-card__desc">
              <p>
                대출자의 카드매출로 매일 원리금을 자동 상환하여 안전성 UP,
                복리효과 UP!
              </p>
            </div>
          </div>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6">
          <div class="secret-card">
            <div>
              <p class="secret-card__icon">
                <v-icon size="56px" color="#1baaf1">
                  mdi-numeric-9-plus-box-multiple-outline
                </v-icon>
              </p>

              <p class="secret-card__title">
                4,000개 이상 분산투자
              </p>
            </div>

            <div class="secret-card__desc">
              <p>
                전국에 흩어져 있는 4,000개 이상의 사업자에게 리스크를 분산하여
                투자
                <br />
                (평균대출이자 연 15%)
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
#section__secret {
  background-color: rgb(0, 173, 229);

  .secret-title {
    color: white;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 5%;
  }

  .secret-card {
    position: relative;
    max-width: 95%;
    min-height: 300px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.4);
    font-weight: bold;
    text-align: center;

    margin: 0 auto;
    padding: 35px 25px 15px;

    &__title {
      color: #1baaf1;
      font-size: 25px;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 0;
    }

    &__desc {
      display: flex;
      align-items: center;
      max-width: 350px;
      width: 90%;
      height: 160px;
      max-height: 160px;

      margin: 0 auto;

      p {
        color: #333;
        font-size: 19px;
        line-height: 1.4;
      }
    }
  }

  &.mobile {
    .secret-card {
      max-width: 100%;
    }
  }
}
</style>
