import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ko from 'vuetify/src/locale/ko.ts'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  breakpoint: {
    scrollBarWidth: 6,
    mobileBreakpoint: 768
  },
  lang: {
    locales: { ko },
    current: 'ko'
  },
  theme: {
    themes: {
      light: {
        primary: '#25AEE1',
      },
    },
  },
})
