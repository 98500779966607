<template>
  <div class="debtor-details">
    <v-row no-gutters>
      <v-col cols="12">
        <p class="ma-0 text-h4 line-height--27 text-center">
          {{ store.title }}
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <p class="details__sub-title">
          상점정보
        </p>
      </v-col>
      <v-col cols="12">
        <ul class="details__info">
          <li>대출목적: {{ store.loanPurpose }}</li>
          <li>상환계획: {{ store.repaymentPlan }}</li>
          <li>
            매출현황: 월 평균
            {{ store.cardSalesMonthlyAverageAmount | commaFormat }} 원
            <br />
            (기준기간: {{ cardSalesPeriod }})
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row class="my-3" no-gutters>
      <v-col cols="12">
        <p class="details__sub-title">
          차입자정보
        </p>
      </v-col>
      <v-col cols="12">
        <ul class="details__info">
          <li>신용평점: {{ debtor.creditScore }} (KCB기준, 신용정보 기준일: {{ debtor.creditAuthenticatedDate | dateFormat }})</li>
          <li>부채현황: {{ debtor.currentDebt }}</li>
          <li>최근 1년간 연체기록: {{ debtor.latestOverdueRecordsInAYear }}</li>
          <li>온라인투자연계금융업 대출 잔액: {{ debtor.p2pLoanBalance }}</li>
          <li>
            개인파산·개인회생 등 채무불이행 기록 관련 사항:
            {{ debtor.defaultRecords }}
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row class="my-3" no-gutters>
      <v-col cols="12">
        <p class="details__sub-title">
          펀다 대출내역
        </p>
      </v-col>

      <v-col cols="12">
        <table class="details__table width-100">
          <thead>
            <tr>
              <th>실행일</th>
              <th>대출잔액</th>
              <th>대출원금</th>
              <th>채권상태</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="loan in debtor.fundaNowLoanDetails"
              :key="loan.executedAt"
            >
              <td class="text-center">
                {{ loan.executedAt | dateFormat }}
              </td>
              <td class="text-right">
                {{ loan.loanBalance | commaFormat }}
              </td>
              <td class="text-right">
                {{ loan.loanAmount | commaFormat }}
              </td>
              <td class="text-center">
                {{ loan.repaymentState }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    store: {
      type: Object,
      required: true,
      validator(v) {
        return !!v && !!v.currentCardSales
      },
    },
    debtor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardSalesPeriod() {
      let len = this.store.currentCardSales.length
      if (len < 1) {
        return '-'
      }
      let start = this.store.currentCardSales[0].date
      let end = this.store.currentCardSales[len - 1].date
      return `${start} ~ ${end}`
    },
  },
}
</script>

<style lang="scss" scoped>
.debtor-details {
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  .text-h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .details__sub-title {
    font-size: 20px;
    font-weight: bold;
    margin: 25px 0 15px;

    &::before {
      content: '■';
      margin-right: 8px;
    }

    .font-size--15 {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .details__info {
    font-size: 14px;
    list-style: decimal;
    padding-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }

  .details__table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;

    th, td {
      border: 1px solid #eee;
      padding: 12px;
      text-align: center;
    }

    th {
      font-size: 16px;
      font-weight: 600;
      background-color: #f8f8f8;
    }

    td {
      font-size: 15px;

      &.text-right {
        text-align: right;
      }
    }
  }
}
</style>
