
import qs from 'querystring'
import store from '@/store'

const getRedirectUri = () => {
  return qs.parse(location.search.substr(1)).redirectUri
}

window.nidCallback = async (token) => {
  // base64를 토큰으로 변환
  await store.dispatch('auth/signinNaver', JSON.parse(atob(token)))
  const redirectUri = getRedirectUri()
  if (redirectUri) {
    global.$vm.$router.push(redirectUri)
  }
}

export const getToken = async () => {
  let redirectUri = encodeURIComponent(getRedirectUri())
  let callback = encodeURIComponent(`${location.origin}/callback/sns/naver?redirectUri=${redirectUri}`)
  window.open(`${process.env.common.fundaApi.host}/oauth/sns/naver/access/token?callback=${callback}`, 'oauthLink', 'width=464, height=605, menubar=no, status=no, toolbar=no')
}

export default {
  getToken
}
