import { sleep } from '@/util'

export default {
  name: 'system',
  namespaced: true,
  state: {
    init: false
  },
  getters: {
    getInit(state) {
      return state.init
    }
  },
  mutations: {
    setInit(state, flag) {
      state.init = flag
    }
  },
  actions: {
    async setInit({ commit }, flag) {
      await sleep(1000)
      commit('setInit', flag)
    }
  }
}
