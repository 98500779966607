<template>
  <v-dialog v-if="fundingDataIdx" v-model="show" fullscreen transition="dialog-bottom-transition">
  <v-card >
    <v-card-title
      class="px-1 py-2 mx-auto align-center justify-space-between"
      style="max-width: 768px;"
    >
      <p class="headline ma-0 text-ellipsis" style="width: 90%"></p>
      <a @click="closeDialog()">
        <v-icon large>
          mdi-close
        </v-icon>
      </a>
    </v-card-title>
    <v-card-text class="px-0">
      <FundingDetails
        :key="fundingDataIdx"
        :f-num="fundingDataIdx"
      ></FundingDetails>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import FundingDetails from '@/components/fundings/fn/FundingDetails.vue'
export default {
  components: { FundingDetails },
  data() {
    return {
      show: false,
      fundingDataIdx: null
    }
  },
  created() {
    const { fundingDataIdx } = this.$route.params
    this.fundingDataIdx = fundingDataIdx
    this.show = true
  },
  methods: {
    async closeDialog() {
      this.show = false
      // setTimeout(async () => {
      // }, 100)
      await this.$router.push('/invest')
    }
  }
}
</script>
