export const InvestmentTypes = {
  getters: {
    GET_USER_PURE: 'GET_USER_PURE',
    GET_USER_P2P: 'GET_USER_P2P',
    GET_USER_INVESTMENT_LOADING: 'GET_USER_INVESTMENT_LOADING',
    GET_USER_INVESTMENT: 'GET_USER_INVESTMENT',
    GET_PROFIT_LOSS_LOADING: 'GET_PROFIT_LOSS_LOADING',
    GET_PROFIT_LOSS: 'GET_PROFIT_LOSS',
    GET_USER_EARNING_LOADING: 'GET_USER_EARNING_LOADING',
    GET_USER_EARNING: 'GET_USER_EARNING',
    GET_REPAYMENT_LOADING: 'GET_USER_REPAYMENT_LOADING',
    GET_INSOLVENT_AMOUNT_LOADING: 'GET_INSOLVENT_AMOUNT_LOADING',
    GET_INSOLVENT_AMOUNT: 'GET_INSOLVENT_AMOUNT',
    GET_LAST_INVESTMENT_LOADING: 'GET_LAST_INVESTMENT_LOADING',
    GET_LAST_INVESTMENT: 'GET_LAST_INVESTMENT',
    GET_USER_CNT_LOADING: 'GET_USER_CNT_LOADING',
    GET_USER_CNT: 'GET_USER_CNT',
  },
  mutations: {
    SET_USER_PURE_LOADING: 'SET_USER_PURE_LOADING',
    SET_USER_PURE: 'SET_USER_PURE',
    SET_USER_P2P_LOADING: 'SET_USER_P2P_LOADING',
    SET_USER_P2P: 'SET_USER_P2P',
    SET_USER_INVESTMENT_LOADING: 'SET_USER_INVESTMENT_LOADING',
    SET_USER_INVESTMENT: 'SET_USER_INVESTMENT',
    SET_PROFIT_LOSS_LOADING: 'SET_PROFIT_LOSS_LOADING',
    SET_PROFIT_LOSS: 'SET_PROFIT_LOSS',
    SET_USER_EARNING_LOADING: 'SET_USER_EARNING_LOADING',
    SET_USER_EARNING: 'SET_USER_EARNING',
    SET_REPAYMENT_LOADING: 'SET_USER_REPAYMENT_LOADING',
    SET_INSOLVENT_AMOUNT_LOADING: 'SET_INSOLVENT_AMOUNT_LOADING',
    SET_INSOLVENT_AMOUNT: 'SET_INSOLVENT_AMOUNT',
    SET_LAST_INVESTMENT_LOADING: 'SET_LAST_INVESTMENT_LOADING',
    SET_LAST_INVESTMENT: 'SET_LAST_INVESTMENT',
    SET_USER_CNT_LOADING: 'SET_USER_CNT_LOADING',
    SET_USER_CNT: 'SET_USER_CNT',
  },
  actions: {
    GET_USER_PURE: 'GET_USER_PURE',
    GET_USER_P2P: 'GET_USER_P2P',
    GET_USER_INVESTMENT: 'GET_USER_INVESTMENT',
    REMOVE_PROFIT_LOSS: 'REMOVE_PROFIT_LOSS',
    GET_PROFIT_LOSS: 'GET_PROFIT_LOSS',
    GET_USER_EARNING: 'GET_USER_EARNING',
    GET_INSOLVENT_AMOUNT: 'GET_INSOLVENT_AMOUNT',
    GET_LAST_INVESTMENT: 'GET_LAST_INVESTMENT',
    GET_USER_CNT: 'GET_USER_CNT',
  },
}
