<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="3">
        <p class="setting__category font-weight-black font-size--15">
          원천징수 정보
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="9">
        <v-row no-gutters>
          <v-col cols="12" class="my-0 mb-5">
            <p class="ma-0 font-size--15 line-height--23">
              펀다는 투자하신 고객님의 이자수익에 대한 세금을 대신 납부하기 위해
              원천징수 정보를 수집하고 있습니다.
              <br />
              주민등록번호와 주소정보는
              <span class="font-weight-black">
                암호화되어 원천징수 신고 이외의 용도로는 사용되지 않습니다.
              </span>
            </p>
          </v-col>

          <v-col cols="12" class="my-0 mb-5">
            <v-row class="d-flex align-center mb-5" no-gutters>
              <v-col cols="12" sm="12" md="3">
                <p class="setting__name font-size--14">이름</p>
              </v-col>

              <v-col cols="12" sm="12" md="9">
                <p class="ma-0 font-size--15 line-height--18 font-weight-black">
                  {{ myProfile.name }}
                </p>
              </v-col>
            </v-row>

            <v-row class="d-flex align-center mb-5" no-gutters>
              <v-col cols="12" sm="12" md="3">
                <p class="setting__name font-size--14">주민등록번호</p>
              </v-col>

              <v-col cols="12" sm="12" md="7">
                <p class="ma-0 font-size--15 font-weight-black">
                  <template v-if="!!myProfile.userBirthdate">
                    {{ myProfile.userBirthdate | moment('YYMMDD') }}-*******
                  </template>

                  <template v-else> 주민등록번호를 입력해주세요. </template>
                </p>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <button
                  v-if="regNumIsNull && regNumFormOpen === false"
                  class="btn-funda--black rounded common-button"
                  @click="regNumFormOpen = true"
                >
                  변경
                </button>
              </v-col>
            </v-row>

            <v-row
              v-if="regNumIsNull && regNumFormOpen"
              no-gutters
              class="d-flex align-center mb-2"
            >
              <v-col cols="12" sm="12" md="12">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="3">
                    <p class="setting__name font-size--14"></p>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <div class="reg-num">
                      <input
                        type="text"
                        maxlength="6"
                        v-model="regNumForm.regNum1"
                      />
                      <span> - </span>
                      <input
                        type="password"
                        maxlength="7"
                        v-model="regNumForm.regNum2"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" md="3"></v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="mt-2">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="3">
                    <p class="setting__name font-size--14"></p>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-row no-gutters>
                      <v-col cols="6" lg="6" md="6" sm="6" class="pr-1">
                        <button
                          type="submit"
                          class="btn-funda--black rounded common-button passwd"
                          :disabled="regNumFormLoading"
                          v-ripple
                          @click="submitRegNum()"
                        >
                          변경
                        </button>
                      </v-col>

                      <v-col cols="6" lg="6" md="6" sm="6" class="pl-1">
                        <button
                          type="button"
                          class="btn-funda--black rounded common-button passwd"
                          style="background-color: #f9356a"
                          @click="regNumFormOpen = false"
                          v-ripple
                        >
                          취소
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters class="d-flex align-center mb-5">
              <v-col cols="12" sm="12" md="3">
                <p class="setting__name font-size--14">주소지</p>
              </v-col>

              <v-col cols="12" sm="10" md="7">
                <p
                  class="ma-0 font-size--15 line-height--20 font-weight-black word-break--break-all"
                  :class="{ 'mb-5': isMobile }"
                >
                  {{ myProfile.address }}
                </p>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <button
                  v-if="isAddrFormOpen == false"
                  class="btn-funda--black rounded common-button"
                  @click="openAddrForm"
                >
                  변경
                </button>
              </v-col>
            </v-row>

            <ValidationObserver ref="validForm" v-slot="{ handleSubmit }">
              <form
                v-if="isAddrFormOpen"
                @submit.prevent="handleSubmit(submitAddrForm)"
              >
                <v-slide-y-transition hide-on-leave>
                  <v-row no-gutters class="d-flex align-center mb-2">
                    <v-col cols="12" sm="12" md="3">
                      <p class="setting__name font-size--15">새 주소지</p>
                    </v-col>

                    <v-col cols="12" sm="12" md="9" lg="7">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="address"
                        rules="required"
                        slim
                      >
                        <input
                          v-model="addrForm.addr1"
                          readonly
                          placeholder="클릭하여 주소를 검색하세요."
                          class="input-setting cursor--pointer color--gray outline-color--funda-blue"
                          @click="daumAddrOpen = !daumAddrOpen"
                        />
                        <v-slide-y-transition hide-on-leave>
                          <p
                            v-if="errors && errors.length > 0"
                            class="color--error font-size--13 mb-0 mt-1"
                          >
                            {{ errors[0] }}
                          </p>
                        </v-slide-y-transition>
                      </ValidationProvider>
                      <DaumAddr
                        v-if="!isMobile"
                        :isOpen.sync="daumAddrOpen"
                        @update:addr="oncompleteAddr"
                        class="mt-2"
                      />
                      <v-dialog
                        v-else
                        v-model="daumAddrOpen"
                        width="400"
                        max-width="400"
                        content-class="mx-0"
                      >
                        <DaumAddr
                          :isOpen.sync="daumAddrOpen"
                          @update:addr="oncompleteAddr"
                        />
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
                <v-slide-y-transition hide-on-leave>
                  <v-row no-gutters class="d-flex align-center mb-2">
                    <v-col cols="12" sm="12" md="3"> </v-col>

                    <v-col cols="12" sm="12" md="9" lg="7">
                      <input
                        v-model="addrForm.addr2"
                        ref="addr2"
                        id="addr2"
                        placeholder="상세 주소를 입력하세요."
                        class="input-setting--input color--gray font-weight-black outline-color--funda-blue"
                      />
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
                <v-slide-y-transition hide-on-leave>
                  <v-row no-gutters class="d-flex align-center">
                    <v-col cols="12" sm="12" md="3"> </v-col>

                    <v-col cols="12" sm="12" md="9" lg="7">
                      <v-row no-gutters>
                        <v-col cols="6" lg="6" md="6" sm="6" class="pr-1">
                          <button
                            type="submit"
                            class="btn-funda--black rounded common-button passwd"
                            :disabled="isAddrFormLoading"
                            v-ripple
                          >
                            변경
                          </button>
                        </v-col>

                        <v-col cols="6" lg="6" md="6" sm="6" class="pl-1">
                          <button
                            type="button"
                            class="btn-funda--black rounded common-button passwd"
                            style="background-color: #f9356a"
                            @click="closeAddrForm"
                            :disabled="isAddrFormLoading"
                            v-ripple
                          >
                            취소
                          </button>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
              </form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="my-setting__panel padding-x-25 padding-y-25" no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <p class="my-0 mb-5 font-weight-black font-size--15">
          주민등록 번호와 주소는 어디에 사용되나요?
        </p>
        <p class="my-0 mb-5 line-height--18">
          주민등록번호와 주소 정보는
          <span class="font-weight-black">
            현행 세법에 의한 원천징수 정보 전달시에만 사용됩니다.
          </span>
          <br :class="{ 'd-none': !isMobile }" />
          주민등록번호와 주소 정보 없이도 사이트 이용은 가능하나, 현행 세법상
          FUNDA 내부의 투자는 이용하실 수 없습니다. 사이트 탈퇴시 모든
          개인정보는 즉시 삭제됩니다.
        </p>
        <p class="my-0 mb-2">
          개인정보보호 책임자: 임성광(seongkwang.im@funda.kr)
        </p>
        <p class="ma-0">참고: 개인정보보호법, 소득세법</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DaumAddr from '@/components/DaumAddr'
import { AuthTypes } from '@/store/types/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    myProfile: {
      type: Object,
      default: null,
    },
  },
  components: {
    DaumAddr,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    regNumIsNull() {
      return (
        this.myProfile.investmentLimit.type === '개인투자자' &&
        !!this.myProfile.name &&
        !!!this.myProfile.userBirthdate
      )
    },
  },
  data() {
    return {
      regNumFormOpen: false,
      regNumFormLoading: false,
      regNumForm: {
        regNum1: null,
        regNum2: null,
      },
      daumAddrOpen: true,
      daumAddr: null,
      isAddrFormOpen: false,
      isAddrFormLoading: false,
      addrForm: {
        addr1: null,
        addr2: null,
      },
    }
  },
  methods: {
    ...mapGetters('auth', {
      getSession: 'getSession',
    }),
    ...mapActions('auth', {
      reloadProfile: AuthTypes.actions.GET_PROFILE,
    }),
    oncompleteAddr(addrObj) {
      this.addrForm.addr1 = addrObj.address
      this.$nextTick(_ => {
        this.$refs.addr2.focus()
      })
      setTimeout(_ => {
        this.$vuetify.goTo('#addr2', { offset: 150 })
      }, 50)
    },
    openAddrForm() {
      this.isAddrFormOpen = true
      this.daumAddrOpen = true
    },
    closeAddrForm() {
      this.addrForm.addr1 = null
      this.addrForm.addr2 = null
      this.isAddrFormOpen = false
    },
    async submitRegNum() {
      if (!!!this.regNumForm.regNum1 || !!!this.regNumForm.regNum2) {
        this.$swal.basic.alert('주민등록번호를 입력해주세요.')
        return
      }

      if (this.regNumFormLoading === true) {
        return
      }

      let btnResult = await this.$swal.basic.confirm({
        title: '주민등록번호 변경',
        text: '주민등록번호를 변경하시겠습니까?',
      })

      if (btnResult.isConfirmed !== true) {
        return
      }

      let q = `
        data: wwwUpdateUserRegNum(regNum1: "${this.regNumForm.regNum1}", regNum2: "${this.regNumForm.regNum2}") {
          userBirthdate
        }
      `

      this.regNumFormLoading = true

      try {
        await this.$fundaApi.mutation(gql`{${q}}`)

        await this.reloadProfile()

        this.regNumFormOpen = false

        this.$swal.basic.alert('주민등록번호가 변경되었습니다.')
      } catch (e) {
        this.$swal.basic.alert(e.errors[0].message)
      } finally {
        this.regNumFormLoading = false
      }
    },
    async submitAddrForm() {
      if (this.isAddrFormLoading === true) {
        return
      }

      try {
        this.isAddrFormLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '주소 변경',
          text: '주소를 변경하시겠습니까?',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        const addr = [this.addrForm.addr1, this.addrForm.addr2].join(',')
        await this.$fundaApi.mutation(gql`{
          result: wwwUpdateUserAddress(address: "${addr}") {
            idx
          }
        }`)

        await this.reloadProfile()

        this.closeAddrForm()

        const msg = '주소지 변경이 완료되었습니다.'
        if (this.isMobile === true) {
          this.$swal.basic.alert(msg)
        } else {
          this.$swal.basic.alert(msg)
        }
      } catch (e) {
        this.$swal.basic.alert('주소 저장에 실패했습니다.')
        throw e
      } finally {
        this.isAddrFormLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reg-num {
  display: table;

  input {
    display: table-cell;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: solid 1px #565a5c;
    padding-left: 6px;
    font: initial !important;
    font-size: 13px;
  }

  span {
    display: table-cell;
    width: 1%;
  }
}

.my-setting__panel {
  border-radius: 4px;
  border: 1px solid #e7edf6;
  background-color: #f6faff;
  color: #565a5c;
}
button {
  transition: all 0.3s;
}
.my-setting .common-button {
  max-width: 100%;
}
</style>
