export const AlarmTypes = {
  getters: {
    GET_HAS_UNREAD: 'GET_HAS_UNREAD',
  },
  mutations: {
    SET_UNREAD_LOADING: 'SET_UNREAD_LOADING',
    SET_HAS_UNREAD: 'SET_HAS_UNREAD',
  },
  actions: {
    GET_HAS_UNREAD: 'GET_HAS_UNREAD',
  },
}
