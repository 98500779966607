<template>
  <v-dialog
    v-model="leaveForm.show"
    persistent
    no-click-animation
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        class="btn-funda--black rounded common-button"
        @click="leaveForm.show = true"
        v-bind="attrs"
        v-on="on"
        v-ripple
      >
        회원 탈퇴
      </button>
    </template>

    <div
      class="custom-popup color--gray-3 font-nanumsquare"
      :class="{ show: leaveForm.show }"
    >
      <ValidationObserver
        tag="div"
        class="custom-popup__contents"
        ref="validForm"
      >
        <form @submit.prevent="submitLeaveForm">
          <div class="popup-body py-7 px-5">
            <div class="notice-wrapper">
              <h3 class="text-header mt-0">펀다 회원탈퇴</h3>
              <p class="margin-y-10 font-size--16">
                회원탈퇴에 앞서
                <span class="color--red">아래의 사항을 반드시 확인</span
                >해주세요.
              </p>
              <div class="notice-responsibility">
                1. 다음의 조건이 남아있을 시 회원탈퇴가 불가합니다.<br />
                <span class="li-text">예치금 잔고</span><br />
                <span class="li-text">잔여 투자 원금</span>
                <br /><br />
                2. 탈퇴 시 보유하고 있는 포인트는 소멸됩니다.<br />
                <span class="li-text">
                  님의 잔여 포인트:
                  <span class="color--red">{{ point | commaFormat }}</span> 원
                </span>
                <br /><br />
                3. 다음의 정보는 일정 기간 보관됩니다.<br />
                <span class="li-text">원천징수를 위한 정보(필요시까지)</span
                ><br />
                <span class="li-text">재가입방지를 위한 정보(30일)</span>
              </div>
              <div>
                <div class="notice-text">
                  <p class="ma-0 padding-b-10">
                    {{ name }}님의
                    <span class="color--red"
                      >잔여 포인트 {{ point | commaFormat }}원은 소멸</span
                    >됩니다.
                  </p>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="leave_agree"
                    :rules="{ required: { allowFalse: false } }"
                    slim
                  >
                    <div class="d-flex align-center">
                      <input
                        v-model="leaveForm.isAgree"
                        type="checkbox"
                        id="is-agree"
                        :disabled="isLoading"
                      />
                      <label for="is-agree" class="agree-text">
                        위 내용에 동의합니다.
                      </label>
                    </div>
                    <v-slide-y-transition hide-on-leave>
                      <p
                        v-if="errors.length > 0"
                        class="color--error font-size--13 mb-0 mt-2"
                      >
                        {{ errors[0] }}
                      </p>
                    </v-slide-y-transition>
                  </ValidationProvider>
                </div>
                <div class="reason-wrapper pt-5">
                  <p class="margin-b-10 font-size--15">
                    아래에 탈퇴사유를 적어주세요.
                  </p>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="leave_reason"
                    rules="required|min:10"
                    slim
                  >
                    <textarea
                      v-model="leaveForm.reason"
                      class="rounded--4 mb-0"
                      rows="3"
                      :disabled="isLoading"
                    ></textarea>
                    <v-slide-y-transition hide-on-leave>
                      <p
                        v-if="errors.length > 0"
                        class="color--error font-size--13 mb-0"
                      >
                        {{ errors[0] }}
                      </p>
                    </v-slide-y-transition>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div>
            <v-fade-transition>
              <div v-show="leaveForm.isAgree" class="leave-check-password">
                <p class="mb-2">
                  회원탈퇴를 위해 비밀번호를 다시 한 번 입력해주세요.
                </p>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                  slim
                >
                  <input
                    v-model="leaveForm.passwd"
                    type="password"
                    class="rounded--4 px-3 py-2 font-nanumsquare"
                    placeholder="비밀번호를 입력해주세요"
                    autocomplete="off"
                    :disabled="isLoading"
                  />
                  <v-slide-y-transition hide-on-leave>
                    <p
                      v-if="errors.length > 0"
                      class="color--error font-size--13 mb-0 text-center mt-2"
                    >
                      {{ errors[0] }}
                    </p>
                  </v-slide-y-transition>
                </ValidationProvider>
              </div>
            </v-fade-transition>
            <v-fade-transition>
              <div class="px-5">
                <ul
                  v-if="errorDetails.length > 0"
                  class="error-list color--error"
                >
                  <li
                    v-for="(errorDetail, index) in errorDetails"
                    :key="index"
                    class="margin-l-10"
                  >
                    {{ errorDetail.text }}
                  </li>
                </ul>
              </div>
            </v-fade-transition>
            <div>
              <button
                type="submit"
                v-ripple
                class="button--left ma-0 btn-funda--warning common-button font-size--20 font-weight-black"
                :disabled="isLoading"
              >
                회원 탈퇴
              </button>
              <button
                type="button"
                v-ripple
                class="button--right ma-0 btn-funda--black common-button font-size--20 font-weight-black"
                @click="$emit('close')"
                :disabled="isLoading"
              >
                닫기
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </v-dialog>
</template>
<script>
import { AuthTypes } from '@/store/types/auth'
import { mapActions } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      leaveForm: {
        show: false,
        isAgree: false,
        passwd: null,
        reason: null,
      },
      errorDetails: [],
      isLoading: false,
      point: null,
    }
  },
  watch: {
    'leaveForm.show': async function(v) {
      if (v === true) {
        // 포인트 잔액 조회
        try {
          this.isLoading = true
          const { point } = await this.$fundaApi.query(gql`
            {
              point: wwwPointDeposit
            }
          `)
          this.point = point
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  methods: {
    ...mapActions('auth', {
      signout: AuthTypes.actions.SIGN_OUT,
    }),
    async submitLeaveForm() {
      if (this.isLoading === true) {
        return
      }

      try {
        this.isLoading = true

        // form 유효성 검사
        const form = this.$refs.validForm
        const isFormValid = await form.validate()
        if (isFormValid !== true) {
          // const fieldNames = Object.getOwnPropertyNames(form.errors)
          const fieldNames = ['leave_agree', 'leave_reason', 'password']
          // 첫번째 에러 메세지 alert
          fieldNames.some((field) => {
            const errors = form.errors[field]
            if (errors.length > 0) {
              this.$swal.basic.alert({
                allowOutsideClick: false,
                text: errors[0],
              })
              return true
            }
          })
          return
        }

        // 확인
        let btnResult = await this.$swal.basic.confirm({
          title: '회원 탈퇴',
          text: `정말 탈퇴하시겠습니까?\n(잔여 포인트 ${this.$options.filters.commaFormat(
            this.point
          )}원은 소멸됩니다.)`,
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        // 입력받은 비밀번호가 일치하는지 검사
        const { isPasswordMatched } = await this.$fundaApi.query(gql`{
          isPasswordMatched: checkUserPassword(password: "${this.leaveForm.passwd}")
        }`)
        if (isPasswordMatched !== true) {
          const msg = '비밀번호가 일치하지 않습니다.'
          this.$swal.basic.alert({
            allowOutsideClick: false,
            text: msg,
          })
          this.$refs.validForm.setErrors({
            password: [msg],
          })
          return
        }
        // 탈퇴 실행
        const { result } = await this.$fundaApi.mutation(gql`{
          result: wwwDeleteUser(reason: "${this.leaveForm.reason}")
        }`)

        // 결과가 success면 탈퇴 성공
        const resultObj = JSON.parse(result)
        // 실패시 result = 'fail' 성공시 result = 'ok'
        if (resultObj.result !== 'ok') {
          if (resultObj.error_msg != null && resultObj.error_msg.length > 0) {
            this.errorDetails.splice(0, this.errorDetails.length)
            resultObj.error_msg.forEach((text) => {
              this.errorDetails.push({ text })
            })
          }
          this.$swal.basic.alert({
            title: '탈퇴 실패',
            text: '회원 탈퇴에 실패했습니다.',
          })
          return
        }

        this.$swal.basic.alert({
          text:
            '회원탈퇴가 완료되었습니다.\n그동안 펀다를 이용해 주셔서 감사합니다.',
        })

        // 로그아웃
        this.signout()
      } catch (e) {
        const msg = e?.message
        if (!!msg) {
          this.$swal.basic.alert(msg)
        }
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.button--left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 60%;
  height: 60px;
}
.button--right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 40%;
  height: 60px;
}
.custom-popup {
  color: #565a5c;

  .text-header {
    margin: 20px 0;
    font-size: 22px;
  }

  .notice-responsibility {
    margin: 10px 0;
    padding: 10px;
    font-weight: bold;
    background-color: #f6faff;
    border: 1px solid #e7edf6;

    > span.li-text {
      font-weight: normal;

      &:before {
        content: ' - ';
        padding-left: 15px;
      }
    }
  }

  .notice-text {
    padding: 5px 0;
    font-size: 15px;

    input[type='checkbox'] {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    .agree-text {
      margin-left: -3px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .reason-wrapper {
    textarea {
      width: 100%;
      border: solid 1px #d7e1ea;
      resize: none;
      padding: 15px;
    }
  }

  .error-list {
    margin-bottom: 5px;
    padding-top: 5px;
    border-top: 1px solid #ddd;
  }

  .leave-check-password {
    margin-bottom: 20px;
    text-align: center;

    input {
      max-width: 220px;
      width: 100%;
      padding-left: 3px;
      border: solid 1px #d7e1ea;
      font-size: 16px !important;
      line-height: 2 !important;
    }
    input::-webkit-input-placeholder {
      color: #727d8c;
      font-family: NanumSquare, sans-serif !important;
    }
    input:-ms-input-placeholder {
      color: #727d8c;
      font-family: NanumSquare, sans-serif !important;
    }
  }
}
</style>
