<template>
  <CatchphraseSection sectionId="intro-A" @go-register="$emit('go-register')" />
</template>

<script>
import CatchphraseSection from './section/CatchphraseSection.vue'

export default {
  components: {
    CatchphraseSection,
  },
}
</script>
