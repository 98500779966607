<template>
  <div class="id">
    <section id="hero_2" class="invest-pased-wrap">
      <div class="intro_title animated fadeInDown">
        <h1>아이디 찾기</h1>
      </div>
    </section>
    <v-container class="fill-height" fluid>
      <v-row
        class="align-center justify-center px-5 py-9"
        style="min-height: 400px;"
      >
        <v-col cols="12" sm="8" md="4">
          <p class="mb-5">
            <a
              class="font-size--14 color-funda--basic"
              @click="$router.push({ name: 'password' })"
            >
              (* 비밀번호를 잊어버리셨나요? 비밀번호 찾기 ->)
            </a>
          </p>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-content step="1">
                <h2>아이디를 잊어버리셨나요?</h2>
                <h3>아래의 방법으로 아이디를 찾아보세요</h3>
              </v-stepper-content>

              <v-stepper-content step="2">
                <h2>{{ user.phone }}으로 찾은 아이디입니다</h2>
                <h3>
                  개인정보 보호를 위해 아이디는 일부는 *로 표기됩니다.<br />아이디를
                  선택하고, 인증할 휴대폰번호를 입력해주세요.
                </h3>
              </v-stepper-content>

              <v-stepper-content step="3">
                <h2>인증번호가 발송되었습니다.</h2>
              </v-stepper-content>

              <v-stepper-content step="4">
                <h2>아이디 찾기가 완료되었습니다.</h2>
              </v-stepper-content>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form @submit.prevent="step1(user)">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="user.phone"
                        :rules="[rules.requiredPhone]"
                        label="휴대폰 번호('-'제외)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-5">
                      <v-btn
                        :disabled="stepLoading || isPhoneValid"
                        :loading="stepLoading"
                        tile
                        depressed
                        large
                        color="primary"
                        type="submit"
                      >
                        다음 단계
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form @submit.prevent="step2(findUser, user)">
                  <v-card elevation="0">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-checkbox
                          v-for="item in findUsers"
                          :key="item.idx"
                          v-model="findUser"
                          :label="
                            `${item.id} (가입일: ${$moment(
                              item.registerDate
                            ).format('YYYY.MM.DD')})`
                          "
                          :value="item"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                      <template v-if="findUser">
                        <v-col cols="12">
                          <v-text-field
                            v-model="user.phone"
                            :rules="[rules.requiredPhone]"
                            label="휴대폰 번호('-'제외)"
                            :disabled="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-5">
                          <v-btn
                            :disabled="stepLoading || isPhoneValid"
                            :loading="stepLoading"
                            tile
                            depressed
                            large
                            color="primary"
                            type="submit"
                          >
                            다음 단계
                          </v-btn>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form v-if="findUser" @submit.prevent="step3(findUser)">
                  <v-card>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="findUser.verifyWord"
                          label="인증번호"
                          :rules="[rules.requiredVerifyWord]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="mb-5">
                        <v-btn
                          :disabled="stepLoading || isVerifyValid"
                          :loading="stepLoading"
                          tile
                          depressed
                          large
                          color="primary"
                          type="submit"
                        >
                          다음 단계
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card v-if="findUserSuccess.id" elevation="0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      {{ findUserSuccess.id }} (가입일:
                      {{ findUserSuccess.registerDate | moment('YYYY.MM.DD') }})
                    </v-col>
                    <v-card-actions>
                      <v-btn
                        :to="{ name: 'login' }"
                        tile
                        depressed
                        large
                        color="primary"
                      >
                        로그인 하러가기
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  layout: 'legacy',
  data() {
    return {
      e1: 1,
      findUser: {},
      findUserSuccess: {},
      user: {
        id: '',
        phone: '',
      },
      stepLoading: false,
      verifyWord: '',
      findUsers: [],
      rules: {
        requiredPhone: (value) => !!value || '휴대폰 번호를 입력해주세요',
        requiredVerifyWord: (value) => !!value || '인증번호를 입력해주세요',
      },
    }
  },
  computed: {
    isPhoneValid() {
      return this.user.phone.length < 10 || this.user.phone.length > 11
    },
    isVerifyValid() {
      return !this.findUser.verifyWord || this.findUser.verifyWord.length > 6
    },
  },
  methods: {
    errorMessage(e) {
      if (e.status >= 400 && e.status < 500) {
        const text = `${e.errors[0].message}`
        this.$swal.basic.error({ text })
      } else {
        throw e
      }
    },
    async step1(user) {
      try {
        this.stepLoading = true
        let { findUsers } = await this.$fundaApi.query(gql`{
          findUsers: getUserSummariesByPhone(phone: "${user.phone}"){
            id
            registerDate
            idx
          }
        }`)
        if (findUsers.length === 0) {
          this.$swal.basic.error({
            text: '등록되지 않은 휴대폰 번호 입니다.',
          })
          return
        }
        this.findUsers = findUsers
        this.e1 = 2
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
    async step2(findUser, user) {
      try {
        this.stepLoading = true
        let { userAuthIdx } = await this.$fundaApi.mutation(gql`{
          userAuthIdx: requestFindIdAuth(userIdx: ${findUser.idx}, phone: "${user.phone}")
        }`)
        findUser.userAuthIdx = userAuthIdx
        this.e1 = 3
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },

    async step3(findUser) {
      try {
        this.stepLoading = true
        let { findUserSuccess } = await this.$fundaApi.mutation(gql`{
          findUserSuccess: confirmFindIdAuth(userAuthIdx: ${findUser.userAuthIdx}, userIdx: ${findUser.idx}, verifyWord: "${findUser.verifyWord}"){
            id
            registerDate
            idx
          }
        }`)
        this.findUserSuccess = findUserSuccess
        this.e1 = 4
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#hero_2 {
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;
  position: relative;
  height: 236px;
  background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
    center;
  background-size: cover;

  & ~ .container {
    background-color: #ffffff;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;

    h1 {
      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }
  }
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

h3 {
  font-size: 14px;
  color: #b6b6b6;
  margin-bottom: 4px;
}

.v-stepper {
  box-shadow: none;

  &__header {
    height: auto;
    box-shadow: none;

    .v-stepper__content {
      padding: 0;
    }
  }

  &__items {
    .v-stepper__content {
      border: 1px solid #e7edf6;

      .v-card__actions {
        padding-left: 0;

        .v-btn {
          top: unset;
          left: unset;
        }
      }
    }
  }
}

.v-card {
  &.stepper {
    .v-card__title {
      font-size: 17px;
      font-weight: 500;
      padding: 0;
    }
  }
}

.v-btn {
  height: 40px !important;
  color: #ffffff;
  background-color: #25aae1 !important;
  border-radius: 3px;
  font-size: 13px;
}
</style>
