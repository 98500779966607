import Vue from 'vue'
import Router from 'vue-router'
import _interceptor from './_interceptor'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    // if (err === undefined || err === false) {
    //   // next('/other') next(false) 고려..
    //   // console.log(err, 'location')
    //   Vue.config.errorHandler(err)
    // } else {
    //   // console.error(err, 'location')
    //   Vue.config.errorHandler(err)
    // }
    Vue.config.errorHandler(err)
  })
}

const routers = [
  require('./main'),
  require('./error'),
  require('./invest'),
  require('./intro'),
  require('./news'),
  require('./notice'),
  require('./terms'),
  require('./login'),
  require('./logout'),
  require('./signup'),
  require('./id'),
  require('./password'),
  require('./myfunda'),
  require('./test'),
  require('./v2'),
  require('./landing'),
  require('./shareholders-notice'),
  require('./callback'),
  require('./oauth'),
  require('./funding'),
]

Vue.use(Router)

let mergeRouters = []
routers.forEach((route) => {
  mergeRouters = mergeRouters.concat(route.default)
})

const vueRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: mergeRouters,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

_interceptor.forEach((f) => {
  vueRouter.beforeEach(f.default)
})

export default vueRouter
