<template>
  <div>
    <v-row class="mb-5" no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>

      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          당사는 차입자의 채무불이행 발생 시 내부 채권관리팀을 통한 채무상환
          독촉을 1차적으로 진행함과 아울러 장기연체채권에 대하여 외부
          채권추심전문기관에의 채권추심 위임 채권매각 등 채권회수 절차를
          진행하고 있습니다.
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '채무불이행 시 채권추심 등 원리금 회수 방식에 관한 사항',
    },
  },
}
</script>

<style lang="scss" scoped></style>
