<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          당사는 연계대출 심사 가이드라인을 제정하여 차입자의 상환능력을
          심사하고 있으며, 차입자의 신용평가모형을 자체 개발하여 운영 중입니다.
          또한, 연계대출 심사를 위하여 차입자의 신용상태, 채무수준, 상환능력,
          금융거래 이력 뿐만 아니라, 머신러닝 알고리즘을 기반으로 차입자의
          신용카드 매출데이터 분석, 미래매출 예측, 개인신용등급 확인 등을 통해
          차입자의 상환능력을 평가하고 있으며, 주요 확인사항은 다음과 같습니다
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th class="text-center">주요 확인사항</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">개인, 개인사업자</td>
                <td>
                  <p class="mb-1">
                    매출정보(여신금융협회, 배달의민족, 요기요, 쿠팡이츠)
                  </p>
                  <p class="mb-1">
                    신용정보(KCB점수)
                  </p>
                  <p class="mb-0">
                    부채정보(금융기관 대출잔액, 펀다 대출잔액)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '차입자의 상환능력평가 체계',
    },
  },
}
</script>

<style lang="scss" scoped></style>
