import Vue from 'vue'

const pluginName = '$eventBus'
const pluginValue = new Vue()

const plugin = {
  install(Vue) {
    Vue.prototype[pluginName] = pluginValue
  },
}

Vue.use(plugin)

export default plugin
