export default {
  name: 'funding',
  namespaced: true,
  state: {
    investFundingsRawDatas: [],
    investFundings: [],
    announcements: []
  },
  mutations: {
    setInvestFundingsRawDatas(state, investFundingsRawDatas) {
      state.investFundingsRawDatas = investFundingsRawDatas
    },
    setInvestFundings(state, investFundings) {
      state.investFundings = investFundings
    },
    setAnnouncements(state, announcements) {
      state.announcements = announcements
    }
  },
  actions: {
    async getFundingAnnouncements({ commit }, fundingDataIdxes) {
      const results = await global.$fundaApi.getFundingAnnouncements(fundingDataIdxes)
      commit('setAnnouncements', results)
      return results
    }
  },
}
