<template>
  <v-card>
    <v-card-title>
      주주 공지사항 리스트
      <v-spacer></v-spacer>
    </v-card-title>
    <v-skeleton-loader
      v-if="getNoticesLoading"
      :loading="!getNoticesLoading"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-show="!getNoticesLoading"
      :headers="headers"
      :items="notices"
      :search="search"
      item-key="name"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <tr v-for="item in items" :key="item.id">
            <td>
              <v-layout justify-center>
                <router-link
                  :to="{
                    name: 'shareholders-notice-detail',
                    params: { id: item.name, notice: item },
                  }"
                >
                  {{ item.title }}
                </router-link>
              </v-layout>
            </td>
            <td>
              <v-layout justify-center>
                {{ item.created }}
              </v-layout>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="headers.length" style="text-align: center">
              게시된 공지사항이 없습니다
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>
<style scoped>
.v-card {
  margin-top: 60px;
  margin-bottom: 160px;
}
</style>
<script>
// import * as firebase from '@/plugins/firebase'

export default {
  name: 'shareholdersNotice',
  data() {
    return {
      search: '',
      notices: [],
      getNoticesLoading: false,
      headers: [
        {
          text: '제목',
          align: 'center',
          sortable: false,
          value: 'title',
          width: '60%',
        },
        {
          text: '게시일',
          align: 'center',
          sortable: false,
          value: 'created',
          width: '40%',
        },
      ],
    }
  },
  async created() {
    await this.setNotices()
  },
  methods: {
    formatCreated(time) {
      return new Date(time.toDate()).toISOString().split('T')[0]
    },
    async setNotices() {
      // const collection = 'notices'
      this.getNoticesLoading = true
      try {
        // const querySnapshot = await firebase.db
        //   .collection(`${collection}`)
        //   .orderBy('created', 'desc')
        //   .get()
        // querySnapshot.forEach((doc) => {
        //   docs.push({ ...doc.data(), id: doc.id })
        // })

        const docs = [
          {
            created: '2022-12-12',
            name: '2022.12.12 신주발행공고.pdf',
            title: '신주발행공고',
          },
          {
            created: '2022-09-19',
            name: '2022.09.19 신주발행공고.pdf',
            title: '신주발행공고',
          },
          {
            created: '2022-05-26',
            name: '2022.05.26 주식명의개서 정지 공고.pdf',
            title: '주식명의개서 정지 공고 (임시주주총회)',
          },
          {
            created: '2021-10-29',
            name: '2021.10.29 합병(보고)공고.pdf',
            title: '합병(보고)공고',
          },
          {
            created: '2021-09-27',
            name: '2021.09.27 합병에 따른 채권자 이의제출 및 주권제출 공고.pdf',
            title: '합병에 따른 채권자 이의제출 및 주권제출 공고',
          },
          {
            created: '2021-09-08',
            name: '2021.09.08 소규모합병 공고.pdf',
            title: '소규모합병 공고',
          },
        ]

        this.notices = docs
      } catch (error) {
        if (error) {
          this.$swal.fire('실패', '공지사항을 가져오는데 실패했습니다', 'error')
        }
      } finally {
        this.getNoticesLoading = false
      }
    },
  },
}
</script>
