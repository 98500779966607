<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center" rowspan="2">구분</th>
                <th class="text-center" rowspan="2">가중평균금리</th>
                <th class="text-center" colspan="3">현행금리</th>
              </tr>
              <tr>
                <th class="text-center">최고금리</th>
                <th class="text-center">최저금리</th>
                <th class="text-center">연체금리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in keyList(info)" :key="k">
                <td class="text-center">
                  {{ k }}
                </td>
                <td class="text-right">{{ info[k]['가중평균금리'] }} %</td>
                <td class="text-right">{{ info[k]['최고금리'] }} %</td>
                <td class="text-right">{{ info[k]['최저금리'] }} %</td>
                <td class="text-right">{{ info[k]['연체금리'] }} %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '대출이자에 관한 사항',
    },
  },
  data() {
    return {
      info: null,
    }
  },
  created() {
    this.info = {
      '부동산 PF': {
        가중평균금리: 0,
        최고금리: 0,
        최저금리: 0,
        연체금리: 0,
      },
      부동산담보: {
        가중평균금리: 0,
        최고금리: 10,
        최저금리: 6,
        연체금리: 13,
      },
      '기타담보 (어음, 매출채권 담보 제외)': {
        가중평균금리: 16.45,
        최고금리: 18.0,
        최저금리: 10.0,
        연체금리: 19.15,
      },
      '어음, 매출채권 담보': {
        가중평균금리: 0,
        최고금리: 0,
        최저금리: 0,
        연체금리: 0,
      },
      '개인사업자 신용': {
        가중평균금리: 11.13,
        최고금리: 19.0,
        최저금리: 4.0,
        연체금리: 19.0,
      },
      '법인 신용': {
        가중평균금리: 0.0,
        최고금리: 10.7,
        최저금리: 4.0,
        연체금리: 13.06,
      },
      합계: {
        가중평균금리: 13.17,
        최고금리: 0,
        최저금리: 0,
        연체금리: 0,
      },
    }
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
