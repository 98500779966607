<template>
  <div>
    <v-alert text color="info">
      <div class="alert-text">
        <p class="mb-5 color--black font-weight-bold">
          Q. 본인인증 절차가 꼭 필요한가요?
        </p>

        <p class="mb-3 font-size--14">
          펀다는 온투업 라이센스를 정식 획득한
          <span class="font-weight-black">제도권 금융사</span>로서 온투금융법,
          금융소비자보호법, 금융실명법 신용정보법 등 제도권 금융에 적용되는
          엄격한 규제를 모두 준수해야 하는
          <span class="font-weight-black">의무사항</span>이 있습니다.
        </p>

        <p class="ma-0 font-size--14">
          <span class="font-weight-black">본인인증 절차</span>는 투명한 금융거래
          환경과 자금세탁, 불법행위를 방지하여
          <span class="font-weight-black">투자자 보호를 강화</span>하고
          <span class="font-weight-black">투자자의 자산을 안전</span>하게 지키기
          위한 절차입니다.
        </p>
      </div>
    </v-alert>
    <CorporationDoc v-if="isCorp" />
    <v-stepper v-model="e1" vertical>
      <template v-for="(step, index) in guideSteps">
        <v-stepper-step
          :step="index + 1"
          :key="step.title"
          :complete="step.isValid"
        >
          <p class="ma-0 font-size--13 font-weight-bold">
            <template v-if="step.name === 'isValidIdentification'">
              {{ isCorp ? `사업자(법인) 등록증 업로드` : step.title }}
            </template>
            <template v-else>
              {{ step.title }}
            </template>

            <template v-if="step.isValid">
              (승인)
            </template>
          </p>
          <span
            v-if="step.name === 'isValidIdentification'"
            class="mt-1 font-size--12"
          >
            {{ isCorp ? '' : '(신분증은 암호화되어 안전하게 보관됩니다)' }}
          </span>
        </v-stepper-step>
        <v-stepper-content :step="index + 1" :key="index + 1">
        </v-stepper-content>
      </template>
    </v-stepper>

    <div class="pa-0">
      <v-btn
        class="btn-funda-basic"
        color="primary"
        @click="$emit('next-step')"
        elevation="0"
      >
        본인인증 시작하기
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import CorporationDoc from '@/components/signup/CorporationDoc.vue'

export default {
  components: {
    CorporationDoc,
  },
  data() {
    return {
      e1: 0,
      steps: [
        {
          title: '휴대폰 본인인증',
          name: 'isValidPhone',
          isValid: false,
        },
        {
          title: '신분증 등록',
          name: 'isValidIdentification',
          isValid: false,
        },
        {
          title: '계좌 인증',
          name: 'isValidAccount',
          isValid: false,
        },
        {
          title: '투자자 추가 정보 등록',
          name: 'isValidEdd',
          isValid: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    isCorp() {
      return this.profile?.investmentLimit?.idx === 3
    },
    guideSteps() {
      let result = []
      this.steps.forEach((s) => {
        if (s.name === 'isValidPhone') {
          if (this.isCorp === false) {
            result.push(s)
          }
        } else {
          result.push(s)
        }
      })

      return result
    },
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!!v) {
          this.steps.forEach((s) => {
            s.isValid = v[s.name]
          })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.v-stepper {
  box-shadow: none;
  &__label {
    font-weight: 600;
  }
}

.alert-text {
  p {
    line-height: 1.5;
    word-break: break-all;
  }
}
</style>
