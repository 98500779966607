<template>
  <div>
    <CatchphraseSection
      sectionId="intro-C"
      @go-register="$emit('go-register')"
    />
    <FundingStructureSection />
    <EarningsRateChartSection />
    <BusinessSecretSection />
    <VideoSection />
    <MediaSection />
    <AffiliateSection />
  </div>
</template>

<script>
import CatchphraseSection from './section/CatchphraseSection.vue'
import FundingStructureSection from '@/pages/main/section/FundingStructureSection.vue'
import EarningsRateChartSection from '@/pages/main/section/EarningsRateChartSection.vue'
import BusinessSecretSection from '@/pages/main/section/BusinessSecretSection.vue'
import VideoSection from '@/pages/main/section/VideoSection.vue'
import MediaSection from '@/pages/main/section/MediaSection.vue'
import AffiliateSection from '@/pages/main/section/AffiliateSection.vue'

export default {
  components: {
    CatchphraseSection,
    FundingStructureSection,
    EarningsRateChartSection,
    BusinessSecretSection,
    VideoSection,
    MediaSection,
    AffiliateSection,
  },
}
</script>
