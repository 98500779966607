<template>
  <v-dialog
    v-if="overlay"
    v-model="isShow"
    max-width="768"
    persistent
    content-class="ovelay-dialog"
    :fullscreen="isFullscreen"
    :transition="transition"
  >
    <component :is="overlay.component" v-bind="componentProps"></component>
  </v-dialog>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { OverlayTypes } from '@/store/types/overlay'
import AlarmBoard from '@/components/alarm/AlarmBoard.vue'
import FundingDetailsDisplay from '@/components/invest/FundingDetailsDisplay.vue'
import InvestOrderDisplay from '@/components/invest/InvestOrderDisplay.vue'
import qs from 'querystring'

let OVERLAYS

export default {
  components: {
    AlarmBoard,
    FundingDetailsDisplay,
    InvestOrderDisplay,
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const isMobile = internalInstance.$vuetify.breakpoint.mobile

    // 일종의 Overlay 라우터 선언이라고 볼 수 있다.
    // 더 좋은 방법이 있으면 수정요망,,
    OVERLAYS = {
      '#alarm': {
        hash: '#alarm',
        name: 'alarmDialog',
        component: 'AlarmBoard',
        transition: 'slide-x-transition',
        isFullscreen: isMobile,
        props: false,
      },
      '#fd': {
        hash: '#fd',
        name: 'fundingDetailsDialog',
        component: 'FundingDetailsDisplay',
        transition: 'dialog-bottom-transition',
        isFullscreen: true,
        props: true,
      },
      '#order': {
        hash: '#order',
        name: 'investOrder',
        component: 'InvestOrderDisplay',
        transition: 'dialog-bottom-transition',
        isFullscreen: isMobile,
        props: true,
      },
    }

    return {
      OVERLAYS,
    }
  },
  data() {
    return {
      isShow: false,
      overlay: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
    }),
    ...mapGetters('overlay', {
      showDialog: OverlayTypes.getters.IS_SHOW_OVERLAY,
      overlayKey: OverlayTypes.getters.GET_OVERLAY,
      getProps: OverlayTypes.getters.GET_PROPS,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    componentProps() {
      if (this.overlay.props === true) {
        return this.getProps
      }
      return null
    },
    hash() {
      return this.overlay?.hash
    },
    isFullscreen() {
      return this.overlay?.isFullscreen
    },
    transition() {
      return this.overlay?.transition
    },
  },
  watch: {
    overlayKey(v) {
      if (!!v) {
        // overlayKey 가 null 만 아니라면 overlay 유지를 하고자 이렇게 만듬
        this.overlay = this.OVERLAYS[v]
      }
    },
    showDialog: {
      immediate: true,
      handler(v) {
        this.isShow = v
      },
    },
    isShow(v) {
      const html = document.getElementsByTagName('html')[0]
      if (v) {
        html.classList.add('overflow-y-hidden')
        if (this.searchHash(this.hash) < 0) {
          history.pushState({ dialog: this.overlay?.name }, '', `${this.hash}?${qs.stringify(this.getProps)}`)
        }
      } else {
        html.classList.remove('overflow-y-hidden')
        if (this.searchHash(this.hash) > -1) {
          history.back()
        } else {
          this.closeDialog()
        }
      }
    },
  },
  mounted() {
    window.onpopstate = (_) => {
      this.closeDialog()
    }
  },
  beforeDestroy() {
    window.onpopstate = null
  },
  created() {
    const [hash, query] = this.$route.hash.split('?')

    if (hash === '#fd' && query) {
      const { fundingDataIdx } = qs.parse(query)
      this.passProps({
        fundingDataIdx: Number(fundingDataIdx)
      })
      this.openDialog(hash)
      // this.isShow = true
    }
  },
  methods: {
    ...mapActions('overlay', {
      closeDialog: OverlayTypes.actions.CLOSE_OVERLAY,
      openDialog: OverlayTypes.actions.OPEN_OVERLAY,
      passProps: OverlayTypes.actions.PASS_PROPS,
    }),
    hasOverlayKey(name) {
      const keys = Object.keys(this.OVERLAYS)
      return keys.indexOf(name) > -1
    },
    searchHash(text) {
      const hash = location?.hash
      return hash.indexOf(text)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog__content--active {
  z-index: 10000001 !important;
}

::v-deep .ovelay-dialog {
  max-height: 100%;
  height: 100%;
}
</style>
