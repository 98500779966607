<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center">업무시간</th>
                <th class="text-center">상담방식</th>
                <th class="text-center">연락처</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" rowspan="3">10 : 00 ~ 17 : 30</td>
                <td class="text-center">전화</td>
                <td class="text-center">02-6925-1788</td>
              </tr>

              <tr>
                <td class="text-center">이메일</td>
                <td class="text-center">contact@funda.kr</td>
              </tr>

              <tr>
                <td class="text-center">온라인 채널톡</td>
                <td class="text-center">https://funda.channel.io/</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '상담업무 안내',
    },
  },
}
</script>

<style lang="scss" scoped></style>
