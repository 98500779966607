<template>
  <div class="media">
    <section id="hero_2" class="invest-pased-wrap main-300">
      <div class="intro_title animated fadeInDown">
        <h1>언론보도</h1>
        <p>새로운 펀다 소식을 만나보세요</p>
      </div>
    </section>
    <div class="col-lg-12 col-md-12 container__inner tab-content">
      <div class="review_strip">
        <div class="row margin-bottom-15 text-center">
          <span class="h3">언론보도</span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-list table-hover table-news">
                <thead>
                  <tr>
                    <th>제목</th>
                    <th>작성일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="news in newses" :key="news.idx">
                    <td class="single-line">
                      <a
                        :href="news.link1"
                        target="_blank"
                        v-html="news.title"
                      ></a>
                    </td>
                    <td>{{ news.datetime | moment('YYYY-MM-DD') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <ul class="pagination">
          <li
            v-for="index in paging.totalPage"
            :class="{
              active:
                index === $route.query.page ||
                (!$route.query.page && index === 1),
            }"
            :key="index"
          >
            <router-link
              :to="{ name: 'news', query: { page: index } }"
              :key="index"
              >{{ index }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'legacy',
  data() {
    return {
      newses: [],
      paging: {
        totalPage: 0,
      },
    }
  },
  created() {
    _seo({
      titles: ['언론 속의 펀다'],
    })
    let page = this.$route.query.page
    page = page || 1
    this.getNewses({
      page,
    })
  },
  methods: {
    async getNewses(param) {
      let a = gql`{
        data: getNewses(newsesSearch: {
          category: "언론보도"
          valid: true,
          paging:{
            page: ${param.page},            
            size: 15
          }
        }){
          newses: data{
            idx
            title
            link1
            datetime
          }
          paging{
            size
            page
            totalCount
            totalPage
          }
        }
      }`
      const { newses, paging } = await this.$fundaApi.query(a)
      this.newses = newses
      this.paging = paging
    },
  },
}
</script>
<style lang="scss" scoped>
.media {
  .container {
    background-color: #ffffff;
  }
}

#hero_2 {
  position: relative;
  height: 470px;
  background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
    center;
  background-size: cover;
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;
}

#hero_2.main-300 {
  height: 300px;

  @media (max-width: 767px) {
    height: 150px;
  }
}

#hero_2 .intro_title h1 {
  font-size: 38px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
  padding-top: 30px;

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

#hero_2 .intro_title h1:before {
  margin-right: 10px;
}

#hero_2 .intro_title h1:after {
  margin-left: 10px;
}

#hero_2 .intro_title h1:before,
#hero_2 .intro_title h1:after {
  display: inline-block;
  width: 60px;
  height: 1px;
  content: '';
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.5);
}

.intro_title {
  display: table-cell;
  vertical-align: middle;
}

.tab-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 80px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.review_strip {
  margin-top: 30px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #e7edf6;
  border-radius: 3px;
  position: relative;
  padding: 30px 25px 20px 25px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  color: #333333;
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  cursor: pointer;
  background-color: #555;
  border-color: #555;
  color: white !important;
  z-index: 2;
}

.pagination > li > a,
.pagination > li > span {
  color: #333333;
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid #ddd;
}

a {
  transition: all 0.2s ease;
  outline: none;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.h3 {
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  width: 100%;
  color: inherit;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

.table.table-list {
  table-layout: initial;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.table.table-list td:nth-child(1) {
  text-align: left;
}

.table.table-list td:nth-child(1),
.table.table-list th:nth-child(1) {
  width: 70%;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 0px;
  line-height: 1.42857143;
  vertical-align: top;
}

.single-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-news a {
  color: #565a5c;
}

.table.table-list td:nth-child(2),
.table.table-list th:nth-child(2) {
  width: 30%;
}

.table.table-list td {
  text-align: center;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
</style>
