<template>
  <v-tooltip
    :max-width="maxWidth"
    :top="alignment === 'top'"
    :right="alignment === 'right'"
    :bottom="alignment === 'bottom'"
    :left="alignment === 'left'"
  >
    <template v-slot:activator="{ attrs, on }">
      <span
        v-bind="attrs"
        v-on="on"
        class="cursor--pointer"
        :class="icon[type]"
        :style="{ 'font-size': size }"
        open-on-click
      ></span>
    </template>
    <span>
      {{ text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'question',
    },
    alignment: {
      type: String,
      default: 'top',
    },
    text: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '15px',
    },
    maxWidth: {
      type: String,
      default: '220px',
    },
  },
  data() {
    return {
      icon: {
        question: 'icon-question',
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
