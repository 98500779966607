<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (1) 연체율
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center" colspan="2">구분</th>
                <th
                  v-for="year in years"
                  :key="year + '년'"
                  class="text-center"
                >
                  {{ year }} 년
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="gubun in gubunKeys">
                <template v-for="(kidsKey, index) in gubun.kidsKeys">
                  <tr :key="gubun.text + '_' + kidsKey + '_' + index">
                    <td
                      v-if="index === 0"
                      :rowspan="gubun.kidsKeys.length"
                      class="text-center"
                    >
                      {{ gubun.text }}
                    </td>
                    <td class="text-center">
                      {{ kidsKey }}
                    </td>
                    <td
                      v-for="val in info"
                      :key="val.year + '_' + gubun.text + '_' + kidsKey"
                      class="text-right"
                    >
                      <template v-if="kidsKey === '연체율'">
                        {{ val.구분[gubun.text][kidsKey] }} %
                      </template>
                      <template v-else>
                        {{ val.구분[gubun.text][kidsKey] | commaFormat }}
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (2) 손실률
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!loss">
            <thead>
              <tr>
                <th class="text-center" colspan="2">구분</th>
                <th
                  v-for="year in years"
                  :key="year + '년'"
                  class="text-center"
                >
                  {{ year }} 년
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="gubun in lossGubunKeys">
                <template v-for="(kidsKey, index) in gubun.kidsKeys">
                  <tr :key="gubun.text + '_' + kidsKey + '_' + index">
                    <td
                      v-if="index === 0"
                      :rowspan="gubun.kidsKeys.length"
                      class="text-center"
                    >
                      {{ gubun.text }}
                    </td>
                    <td class="text-center">
                      {{ kidsKey }}
                    </td>
                    <td
                      v-for="val in loss"
                      :key="val.year + '_' + gubun.text + '_' + kidsKey"
                      class="text-right"
                    >
                      <template v-if="['연체율', '손실률'].includes(kidsKey)">
                        {{ val.구분[gubun.text][kidsKey] }} %
                      </template>
                      <template v-else>
                        {{ val.구분[gubun.text][kidsKey] | commaFormat }}
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '연체율 등 연체에 관한 사항',
    },
  },
  data() {
    return {
      info: null,
      loss: null,
      years: [],
      gubunKeys: [],
      lossGubunKeys: []
    }
  },
  watch: {
    info: {
      deep: true,
      handler(v) {
        if (!!v) {
          let years = []
          this.info.forEach((val) => {
            years.push(val.year)

            let gubunKeys = []
            this.keyList(val.구분).forEach((v) => {
              gubunKeys.push({
                text: v,
                kidsKeys: this.keyList(val.구분[v]),
              })
            })
            this.gubunKeys = gubunKeys
          })
          this.years = years
        }
      },
    },
    loss: {
      deep: true,
      handler(v) {
        if (!!v) {
          v.forEach((val) => {
            let gubunKeys = []
            this.keyList(val.구분).forEach((v) => {
              gubunKeys.push({
                text: v,
                kidsKeys: this.keyList(val.구분[v]),
              })
            })
            this.lossGubunKeys = gubunKeys
          })
        }
      },
    },
  },
  created() {
    this.info = [
      {
        year: 2023,
        구분: {
          '부동산 PF': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          부동산담보: {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            '총 연계대출잔액': 2063430794,
            '총 연체채권잔액': 2063430794,
            연체율: 100,
          },
          '어음, 매출채권 담보': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '개인사업자 신용': {
            '총 연계대출잔액': 3303585844,
            '총 연체채권잔액': 318501731,
            연체율: 9.64,
          },
          '법인 신용': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          합계: {
            '총 연계대출잔액': 5367016638,
            '총 연체채권잔액': 2381932525,
            연체율: 44.38,
          },
        },
      },
      {
        year: 2022,
        구분: {
          '부동산 PF': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          부동산담보: {
            '총 연계대출잔액': 20000000,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            '총 연계대출잔액': 2150230794,
            '총 연체채권잔액': 2150230794,
            연체율: 100,
          },
          '어음, 매출채권 담보': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '개인사업자 신용': {
            '총 연계대출잔액': 3841851605,
            '총 연체채권잔액': 119893237,
            연체율: 3.12,
          },
          '법인 신용': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          합계: {
            '총 연계대출잔액': 6012082399,
            '총 연체채권잔액': 2270124031,
            연체율: 37.76,
          },
        },
      },
      {
        year: 2021,
        구분: {
          '부동산 PF': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          부동산담보: {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            '총 연계대출잔액': 2269910794,
            '총 연체채권잔액': 2269910794,
            연체율: 100,
          },
          '어음, 매출채권 담보': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '개인사업자 신용': {
            '총 연계대출잔액': 4101808945,
            '총 연체채권잔액': 117619412,
            연체율: 2.87,
          },
          '법인 신용': {
            '총 연계대출잔액': 16923644,
            '총 연체채권잔액': 16923644,
            연체율: 100,
          },
          합계: {
            '총 연계대출잔액': 6388643383,
            '총 연체채권잔액': 2404453850,
            연체율: 37.64,
          },
        },
      },
      {
        year: 2020,
        구분: {
          '부동산 PF': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          부동산담보: {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            '총 연계대출잔액': 2779493979,
            '총 연체채권잔액': 2754857301,
            연체율: 99.11,
          },
          '어음, 매출채권 담보': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '개인사업자 신용': {
            '총 연계대출잔액': 5374103622,
            '총 연체채권잔액': 1104470399,
            연체율: 20.55,
          },
          '법인 신용': {
            '총 연계대출잔액': 347532388,
            '총 연체채권잔액': 113916826,
            연체율: 32.78,
          },
          합계: {
            '총 연계대출잔액': 8501129989,
            '총 연체채권잔액': 3973244526,
            연체율: 46.74,
          },
        },
      },
      {
        year: 2019,
        구분: {
          '부동산 PF': {
            '총 연계대출잔액': 0,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          부동산담보: {
            '총 연계대출잔액': 150000000,
            '총 연체채권잔액': 150000000,
            연체율: 100,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            '총 연계대출잔액': 5521696732,
            '총 연체채권잔액': 0,
            연체율: 0,
          },
          '어음, 매출채권 담보': {
            '총 연계대출잔액': 22008163,
            '총 연체채권잔액': 22008163,
            연체율: 100,
          },
          '개인사업자 신용': {
            '총 연계대출잔액': 17096707364,
            '총 연체채권잔액': 480132103,
            연체율: 2.81,
          },
          '법인 신용': {
            '총 연계대출잔액': 1688995828,
            '총 연체채권잔액': 40470806,
            연체율: 2.4,
          },
          합계: {
            '총 연계대출잔액': 24479408087,
            '총 연체채권잔액': 692611072,
            연체율: 2.83,
          },
        },
      },
      // {
      //   year: 2018,
      //   구분: {
      //     '부동산 PF': {
      //       '총 연계대출잔액': 0,
      //       '총 연체채권잔액': 0,
      //       연체율: 0,
      //     },
      //     부동산담보: {
      //       '총 연계대출잔액': 150000000,
      //       '총 연체채권잔액': 150000000,
      //       연체율: 100,
      //     },
      //     '기타담보 (어음, 매출채권 담보 제외)': {
      //       '총 연계대출잔액': 523219285,
      //       '총 연체채권잔액': 0,
      //       연체율: 0,
      //     },
      //     '어음, 매출채권 담보': {
      //       '총 연계대출잔액': 22008163,
      //       '총 연체채권잔액': 22008163,
      //       연체율: 100,
      //     },
      //     '개인사업자 신용': {
      //       '총 연계대출잔액': 9060798663,
      //       '총 연체채권잔액': 345231506,
      //       연체율: 3.81,
      //     },
      //     '법인 신용': {
      //       '총 연계대출잔액': 3959403840,
      //       '총 연체채권잔액': 75940622,
      //       연체율: 1.92,
      //     },
      //     합계: {
      //       '총 연계대출잔액': 13715429951,
      //       '총 연체채권잔액': 593180291,
      //       연체율: 4.32,
      //     },
      //   },
      // },
    ]
    this.loss = [
      {
        year: 2022,
        구분: {
          '부동산 PF': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          부동산담보: {
            대출취급액: 20000000,
            손실확정금액: 0,
            손실률: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '어음, 매출채권 담보': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '개인사업자 신용': {
            대출취급액: 32611975651,
            손실확정금액: 307677637,
            손실률: 0.94,
          },
          '법인 신용': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          합계: {
            대출취급액: 32631975651,
            손실확정금액: 307677637,
            손실률: 0.94,
          },
        },
      },
      {
        year: 2021,
        구분: {
          '부동산 PF': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          부동산담보: {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '어음, 매출채권 담보': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '개인사업자 신용': {
            대출취급액: 79374329681,
            손실확정금액: 341396747,
            손실률: 0.43,
          },
          '법인 신용': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          합계: {
            대출취급액: 79374329681,
            손실확정금액: 341396747,
            손실률: 0.43,
          },
        },
      },
      {
        year: 2020,
        구분: {
          '부동산 PF': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          부동산담보: {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            대출취급액: 602000000,
            손실확정금액: 0,
            손실률: 0,
          },
          '어음, 매출채권 담보': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '개인사업자 신용': {
            대출취급액: 82521919224,
            손실확정금액: 40909179,
            손실률: 0.05,
          },
          '법인 신용': {
            대출취급액: 1470560000,
            손실확정금액: 0,
            손실률: 0,
          },
          합계: {
            대출취급액: 84594479224,
            손실확정금액: 40909179,
            손실률: 0.05,
          },
        },
      },
      {
        year: 2019,
        구분: {
          '부동산 PF': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          부동산담보: {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            대출취급액: 10274000000,
            손실확정금액: 0,
            손실률: 0,
          },
          '어음, 매출채권 담보': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '개인사업자 신용': {
            대출취급액: 42387145844,
            손실확정금액: 0,
            손실률: 0,
          },
          '법인 신용': {
            대출취급액: 6891950000,
            손실확정금액: 0,
            손실률: 0,
          },
          합계: {
            대출취급액: 59553095844,
            손실확정금액: 0,
            손실률: 0,
          },
        },
      },
      {
        year: 2018,
        구분: {
          '부동산 PF': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          부동산담보: {
            대출취급액: 150000000,
            손실확정금액: 0,
            손실률: 0,
          },
          '기타담보 (어음, 매출채권 담보 제외)': {
            대출취급액: 570000000,
            손실확정금액: 0,
            손실률: 0,
          },
          '어음, 매출채권 담보': {
            대출취급액: 0,
            손실확정금액: 0,
            손실률: 0,
          },
          '개인사업자 신용': {
            대출취급액: 20016809530,
            손실확정금액: 0,
            손실률: 0,
          },
          '법인 신용': {
            대출취급액: 25485040000,
            손실확정금액: 0,
            손실률: 0,
          },
          합계: {
            대출취급액: 46221849530,
            손실확정금액: 0,
            손실률: 0,
          },
        },
      },
    ]
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
