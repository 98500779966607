<template>
  <div style="background-color: white">
    <section id="hero_2" class="invest-pased-wrap main-300">
      <div class="intro_title animated fadeInDown">
        <h1>마이펀다</h1>
      </div>
    </section>

    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-bold line-height--27">
              채권 매각
            </p>
          </v-col>

          <v-col cols="12" class="text-right pt-2">
            <VoteForWriteOffPopup />
          </v-col>
        </v-row>
        <hr />
        <v-row class="pt-4" no-gutters>
          <v-col v-if="isLoading" cols="12">
            <div class="text-center">
              <MyLoader />
            </div>
          </v-col>
          <template v-else>
            <v-col cols="12">
              <p
                class="mt-4 mb-1 font-size--14 text-right"
                style="line-height: 1.4"
              >
                ◎
                <span v-if="waitingFunds.length > 0">
                  매각 투표 : {{ waitingFunds.length | commaFormat }} 건 /
                </span>

                <span v-if="rejectedFunds.length > 0">
                  매각 제외 : {{ rejectedFunds.length | commaFormat }} 건 /

                  <br />
                </span>

                <span v-if="approvalFunds.length > 0">
                  매각 확정 : {{ approvalFunds.length | commaFormat }} 건 /
                </span>
                매각 완료 : {{ doneFunds.length | commaFormat }} 건
              </p>
            </v-col>
            <v-col class="table-col" cols="12">
              <div class="data-table-wrapper">
                <table class="width-100">
                  <thead>
                    <tr>
                      <th width="5%" class="sticky-v-col">번호</th>
                      <th style="min-width: 80px">호수</th>
                      <th width="17%" style="min-width: 120px">상품명</th>
                      <th width="17%" style="min-width: 120px">상점명</th>
                      <th style="min-width: 100px">투자액</th>
                      <th style="min-width: 100px">대출자<br />상환액</th>
                      <th style="min-width: 100px">매각 후<br />회수액</th>
                      <th style="min-width: 100px">총<br />상환액</th>
                      <th style="min-width: 120px">상환일</th>
                      <th style="min-width: 80px">공지</th>
                    </tr>
                  </thead>
                  <template v-if="!!disposals && disposals.length > 0">
                    <tbody>
                      <tr
                        v-for="(dis, index) in disposals"
                        :key="`${dis.fNum}_${dis.storeName}`"
                      >
                        <td class="sticky-v-col">{{ index + 1 }}</td>
                        <td>{{ dis.fNum }} 호</td>
                        <td>{{ dis.fundingDataTitle }}</td>
                        <td>{{ dis.storeName }}</td>
                        <td>{{ dis.fInvestmentAmount | commaFormat }} 원</td>
                        <td>
                          <template v-if="dis.status === 'WAITING'">
                            {{ dis.rpyAmount | commaFormat }} 원
                          </template>
                          <template v-else>
                            <LoadingText :isLoading="repaymentLoading">
                              {{ getRepaymentPrincipalFromStore(dis) }}
                            </LoadingText>
                          </template>
                        </td>
                        <td>
                          <template v-if="dis.status === 'DONE'">
                            <LoadingText :isLoading="repaymentLoading">
                              {{ getRepaymentPrincipal(dis) | commaFormat }}
                            </LoadingText>
                            원
                          </template>
                          <template v-else>
                            -
                          </template>
                        </td>
                        <td class="total-amt">
                          {{ dis.rpyAmount | commaFormat }}
                          원
                        </td>
                        <td>
                          {{ dis.lastRepaymentDate }}
                        </td>
                        <td :class="getStatusColorClass(dis.status)">
                          <span
                            class="cursor--pointer text-decoration--underline"
                            @click="showNoicePopup(dis)"
                          >
                            {{ getStatusTxt(dis.status) }}
                            <i class="icon-doc-text-2"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="sticky-v-col">합계</td>
                        <td colspan="3"></td>
                        <td>{{ totalInvestmentAmount | commaFormat }} 원</td>
                        <td colspan="2"></td>
                        <td>{{ totalRepaymentAmount | commaFormat }} 원</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                  <template v-else>
                    <tbody>
                      <tr>
                        <td colspan="10">
                          투자중인 상품 중 채권 매각 대상이 없습니다.
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
            </v-col>
          </template>
        </v-row>
      </div>
    </div>

    <keep-alive>
      <OverdueNotice2
        ref="overdueNotice"
        :fn-num="selectedFnNum"
      ></OverdueNotice2>
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import MyLoader from '@/components/MyLoader.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import OverdueNotice2 from '@/components/popups/OverdueNotice2.vue'
import VoteForWriteOffPopup from '@/components/popups/VoteForWriteOffPopup.vue'

export default {
  components: {
    MyLoader,
    LoadingText,
    OverdueNotice2,
    VoteForWriteOffPopup,
  },
  data() {
    return {
      isLoading: false,
      repaymentLoading: false,
      disposals: [],
      repayments: {},
      selectedFnNum: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    totalInvestmentAmount() {
      let total = 0
      this.disposals.forEach((dis) => {
        total += dis.fInvestmentAmount
      })

      return total
    },
    totalRepaymentAmount() {
      let total = 0
      this.disposals.forEach((dis) => {
        total += dis.rpyAmount
      })

      return total
    },
    doneFunds() {
      const result = this.disposals.filter((v) => {
        return v.status === 'DONE'
      })

      return result
    },
    voteFunds() {
      const result = this.disposals.filter((v) => {
        return v.status !== 'DONE'
      })

      return result || []
    },
    waitingFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'WAITING'
      })

      return result
    },
    approvalFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'APPROVAL'
      })

      return result
    },
    rejectedFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'REJECTION'
      })

      return result
    },
  },
  created() {
    this.getDisposals()
    this.getRepayments()
  },
  methods: {
    getStatusTxt(status) {
      let result = '매각 완료'
      if (status === 'WAITING') {
        result = '매각 투표'
      } else if (status === 'REJECTION') {
        result = '매각 제외'
      } else if (status === 'APPROVAL') {
        result = '매각 확정'
      }
      return result
    },
    getStatusColorClass(status) {
      let result = 'done'
      if (status === 'WAITING' || status === 'APPROVAL') {
        result = 'color-funda--basic'
      }
      return result
    },
    async showNoicePopup(d) {
      this.selectedFnNum = d.fnNum
      this.$refs.overdueNotice.showToggle()
    },
    async getDisposals() {
      const q = `
        data: getWriteOffListByUserIdx(userIdx: ${this.userIdx}) {
          fNum
          fundingDataTitle
          sNum
          fnNum
          storeName
          status
          repaymentStatus
          loanAmount
          interestRate
          fInvestmentAmount
          rpyAmount
          investmentDatetime
          useDays
          dueDate
          lastRepaymentDate
          
          balance
        }
      `

      this.isLoading = true

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.disposals = data
      } finally {
        this.isLoading = false
      }
    },
    async getRepayments() {
      const q = `
        data: getWriteOffRepayments(userIdx: ${this.userIdx})
      `

      this.repaymentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        data = JSON.parse(data)

        data.forEach((r) => {
          const { principal, fNum, sNum } = r
          const key = `${fNum}_${sNum}`
          this.repayments[key] = principal
        })
      } finally {
        this.repaymentLoading = false
      }
    },
    getRepaymentPrincipal(obj) {
      const key = `${obj?.fNum}_${obj?.sNum}`
      const result = this.repayments[key]
      return !!result ? result : 0
    },
    getRepaymentPrincipalFromStore(obj) {
      const rpy = obj.rpyAmount
      const saleAmt = this.getRepaymentPrincipal(obj)
      const fromStore = rpy - saleAmt
      if (fromStore > 0) {
        return this.$options.filters.commaFormat(fromStore) + ' 원'
      } else {
        return '-'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#hero_2 {
  position: relative;
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;

  &.main-300 {
    height: 300px;

    @media (max-width: 991px) {
      height: 150px;
    }

    h1 {
      font-size: 38px;
      color: #fff;
      font-weight: bold;

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }

      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  &.invest-pased-wrap {
    background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
      center;
    background-size: cover;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;
  }
}

.btn__same-store-auto {
  max-width: 110px;
  width: 110px;
  box-shadow: unset;
  padding: 25px 0px !important;
  font-size: 15px;
  font-weight: bold;

  &.agree {
    color: white;
    background-color: #1baaf1 !important;
  }
}

.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 24px;
          width: 15px;
          height: 15px;
          right: 10px;
          background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg')
            no-repeat center;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.rpy {
            min-width: 75px;
          }
        }

        .total-amt {
          background-color: rgb(238, 238, 238, 0.3);
        }

        thead {
          background-color: #f9fcff;

          &.rpy {
            background-color: white;
            border-bottom: 1px solid #e7edf6;

            th {
              padding-top: 15px !important;
              padding-bottom: 15px !important;
            }
          }

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            &:not(.rpy).sticky-v-col {
              background-color: #f9fcff !important;
            }
          }
        }

        tbody {
          tr {
            &.active {
              background-color: #f5f5f5;

              .sticky-v-col {
                &.rpy {
                  background-color: #f5f5f5;
                }
              }
            }
          }

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            font-weight: 700;
            text-align: center;

            &.done {
              color: #727d8c;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
