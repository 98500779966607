const meta = {
  layout: 'LegacyLayout'
}

export default [
  {
    path: '/shareholders-notice',
    name: 'shareholders-notice',
    component: require('@/pages/shareholders-notice/List.vue').default,
    meta,
  },
  {
    path: '/shareholders-notice/:id',
    name: 'shareholders-notice-detail',
    component: require('@/pages/shareholders-notice/Detail.vue').default,
    meta,
    props: true,
  },
]
