<template>
  <div>
    <v-fade-transition hide-on-leave>
      <!-- 비밀번호 변경 버튼 시작 -->
      <div v-if="!passwordForm.show" key="show">
        <v-row class="d-flex align-center" no-gutters>
          <v-col cols="12" sm="12" md="2">
            <p class="setting__name font-size--14">
              비밀번호
            </p>
          </v-col>

          <v-col cols="9" sm="9" md="6" lg="7">
            <form>
              <input
                value="*********"
                class="input-setting passwd"
                type="password"
                autocomplete="off"
                disabled
                readonly
              />
            </form>
          </v-col>

          <v-col cols="3" sm="3" md="4" lg="3" class="pl-2">
            <button
              class="btn-funda--black rounded common-button"
              style="max-width: 100%;"
              :disabled="passwordForm.show"
              @click="passwordForm.show = !passwordForm.show"
              v-ripple
            >
              변경
            </button>
          </v-col>

          <v-col cols="3" sm="3" md="4"></v-col>
        </v-row>
      </div>
      <!-- 비밀번호 변경 버튼 끝 -->

      <!-- 비밀번호 변경 폼 시작 -->
      <div v-else key="hide">
        <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(updatePassword)">
            <!-- 현재 비밀번호 입력 시작 -->
            <ChangeFormInput
              label="현재 비밀번호"
              name="oldpassword"
              type="password"
              rules="required"
              autofocus
              :inputValue.sync="passwordForm.oldPassword"
              :disabled="passwordForm.loading"
            />
            <!-- 현재 비밀번호 입력 끝 -->

            <!-- 새 비밀번호 입력 시작 -->
            <ChangeFormInput
              @focus="passwordFocus"
              label="새 비밀번호"
              name="password"
              type="password"
              rules="required|password"
              :inputValue.sync="passwordForm.passwd"
              :disabled="passwordForm.loading"
            />
            <!-- 새 비밀번호 입력 끝 -->

            <!-- 비밀번호 확인 입력 시작 -->
            <ChangeFormInput
              label="비밀번호 확인"
              name="repassword"
              type="password"
              :rules="`required|repassword:${passwordForm.passwd}`"
              :inputValue.sync="passwordForm.confirm"
              :disabled="passwordForm.loading"
            />
            <!-- 비밀번호 확인 입력 끝 -->

            <!-- 폼 액션 시작 -->
            <v-row class="d-flex align-center mb-5" no-gutters>
              <v-col cols="12" lg="2" sm="12" md="2"> </v-col>

              <v-col cols="12" lg="10" md="10" sm="9">
                <v-row no-gutters>
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                    class="mb-0 mb-md-2 mb-lg-0 pr-1"
                  >
                    <button
                      type="submit"
                      class="btn-funda--black rounded common-button passwd"
                      :disabled="invalid || passwordForm.loading"
                      v-ripple
                    >
                      변경
                    </button>
                  </v-col>

                  <v-col cols="6" lg="6" md="6" sm="6" class="pl-1">
                    <button
                      type="button"
                      class="btn-funda--black rounded common-button passwd"
                      style="background-color: #f9356a"
                      @click="close"
                      :disabled="passwordForm.loading"
                      v-ripple
                    >
                      취소
                    </button>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4"> </v-col>
            </v-row>
            <!-- 폼 액션 끝 -->
          </form>
        </ValidationObserver>
      </div>
      <!-- 비밀번호 변경 폼 끝 -->
    </v-fade-transition>
  </div>
</template>
<script>
import ChangeFormInput from './ChangeFormInput.vue'
import Regex from '../../../../const/Regex'
export default {
  components: { ChangeFormInput },
  data() {
    return {
      passwordForm: {
        show: false,
        oldPassword: '',
        passwd: '',
        confirm: '',
        loading: false,
      },
      passwordFormCopy: null,
    }
  },
  methods: {
    passwordFocus() {
      if (this.$refs.validForm.fields.password.invalid === true) {
        this.$refs.validForm.setErrors({
          password: [Regex.PASSWORD.MSG],
        })
      }
    },
    async updatePassword() {
      if (this.passwordForm.loading === true) {
        return
      }

      try {
        this.passwordForm.loading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '비밀번호 변경',
          text: '비밀번호를 변경하시겠습니까?',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        const { result } = await this.$fundaApi.mutation(gql`{
          result: wwwUserPasswordChange(oldPassword: "${this.passwordForm.oldPassword}", password: "${this.passwordForm.passwd}", rePassword: "${this.passwordForm.confirm}")
        }`)

        if (result === true) {
          this.close()

          this.$swal.basic.alert('비밀번호 변경이 완료되었습니다.')
        }
      } catch (e) {
        if (e.status === 400 && e.code === 'PW-100') {
          this.$refs.validForm.setErrors({
            oldpassword: [e.errors[0].message],
          })
        } else if (e.status === 400) {
          this.$refs.validForm.setErrors({
            repassword: [e.errors[0].message],
          })
        }

        console.error(e)
      } finally {
        this.passwordForm.loading = false
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
button {
  transition: all 0.3s;
}
.my-setting .common-button.passwd {
  max-width: 100%;
}
</style>
