<template>
  <v-row id="signup-intro" no-gutters>
    <v-col cols="12" v-if="pageLoading"> <PageLoading /> </v-col>
    <v-col cols="12" v-else>
      <component :is="selectedDisplay" @go-register="goRegister()"></component>
    </v-col>
  </v-row>
</template>

<script>
import PageLoading from '@/components/common/PageLoading.vue'
import FundaIntroA from './FundaIntroA.vue'
import FundaIntroB from './FundaIntroB.vue'
import FundaIntroC from './FundaIntroC.vue'
import FundaIntroD from './FundaIntroD.vue'
import FundaIntroE from './FundaIntroE.vue'

export default {
  components: {
    PageLoading,
    FundaIntroA,
    FundaIntroB,
    FundaIntroC,
    FundaIntroD,
    FundaIntroE,
  },
  data() {
    return {
      selectedDisplay: 'FundaIntroA',
      pageLoading: false,
    }
  },
  async created() {
    let introType = this.$route?.query?.intro

    if (introType) {
      this.selectedDisplay = `FundaIntro${introType}`
    }

    this.pageLoading = true
    setTimeout(() => {
      this.pageLoading = false
    }, 500)
  },
  methods: {
    goRegister() {
      const params = this.$route.query
      this.$router.push({ name: 'signupChannelRegister', query: params })
    },
  },
}
</script>

<style lang="scss">
#signup-intro .section-container.container {
  @media (min-width: 1904px) {
    max-width: 1185px !important;
  }
}

#signup-intro .section-container--narrow.container {
  @media (min-width: 1264px) {
    max-width: 1024px !important;
  }
  @media (min-width: 1904px) {
    max-width: 1024px !important;
  }
}

#signup-intro section {
  padding-top: 50px;
  padding-bottom: 50px;
}

#signup-intro .section-title {
  margin-bottom: 5%;
  color: #333333;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 23px;
    margin-bottom: 7%;
  }
}
</style>
