<template>
  <div class="login-hisotry-table">
    <v-data-table
      :items="items"
      :headers="headers"
      mobile-breakpoint="0"
      hide-default-footer
      calculate-widths
    >
      <template v-slot:no-data>
        <div class="d-flex justify-center align-center" style="height: 313px">
          <v-fade-transition mode="out-in">
            <MyLoader v-if="isLoading == true" key="loader" />
            <div
              v-else
              key="empty"
              class="text-center font-weight-bold mx-auto width-100"
            >
              <img src="@/assets/images/myfunda/ic-ic-my-list-empty.svg" />
              <p class="color--gray-2 margin-t-10 font-size--16">
                로그인 내역이 아직 없습니다.
              </p>
            </div>
          </v-fade-transition>
        </div>
      </template>
      <template v-slot:[`item.loginDatetime`]="{ item }">
        <template v-if="isMobile">
          <div>
            {{ item.loginDatetime | moment('YYYY-MM-DD') }}
          </div>
          <div>
            {{ item.loginDatetime | moment('HH:mm:ss') }}
          </div>
        </template>
        <template v-else>
          {{ item.loginDatetime | moment('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </template>
      <template v-slot:footer>
        <hr class="hr-solid mt-0" :class="{ 'mb-0': isMobile }" />
        <PageMaker
          v-if="totalCount > 0"
          :page="page"
          :size="size"
          :total-count="totalCount"
          :raw-data="items"
          :is-white="false"
          @currentPage="changeOption({ page: $event })"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import MyLoader from '../../../../components/MyLoader'
import PageMaker from '../../../../components/PageMaker.vue'

export default {
  components: { PageMaker, MyLoader },
  props: {
    query: {
      type: Object,
      default: _ => {
        return {}
      },
    },
  },
  data() {
    return {
      page: this.query.page ? Number(this.query.page) : 1,
      size: this.query.size ? Number(this.query.size) : 6,
      isLoading: false,
      items: [],
      headers: [
        {
          text: '일시',
          value: 'loginDatetime',
          sortable: false,
          align: 'center',
        },
        {
          text: '로그인',
          value: 'loginSuccess',
          sortable: false,
          align: 'center',
        },
        { text: 'IP', value: 'userIp', sortable: false, align: 'center' },
      ],
      totalCount: 0,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true

        const q = `
          data: wwwLoginLog(page: ${this.page}, size: ${this.size})
            {
              items: data {
                userIp
                loginSuccess
                loginDatetime
              }
              paging {
                totalCount
              }
            }
        `

        const {
          paging: { totalCount },
          items,
        } = await this.$fundaApi.query(gql`{${q}}`)
        this.items = items
        this.totalCount = totalCount
      } finally {
        this.isLoading = false
      }
    },
    changeOption(option) {
      if (option.page != null && option.page === this.page) {
        return
      }
      this.$router.push({
        query: Object.assign({}, this.query, option),
      })
    },
  },
}
</script>
