import { numberFormat } from '@/util'
import Vue from 'vue'

export function commaFormat(value) {
  return numberFormat(value)
}
Vue.filter('commaFormat', commaFormat)

export function rateFormat(value) {
  let v = parseFloat(value) * 100
  return parseFloat(v.toFixed(2))
}

Vue.filter('rateFormat', rateFormat)

Vue.filter('won', function(value) {
  if (!value) return '0'
  return value.toString() + '원'
})
