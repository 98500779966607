export const AuthTypes = {
  getters: {
    IS_AUTHENTICATED: 'IS_AUTHENTICATED',
    GET_LOGIN_DIALOG: 'GET_LOGIN_DIALOG',
    GET_INCREASE_DIALOG: 'GET_INCREASE_DIALOG',
    GET_USER_IDX: 'GET_USER_IDX',
    GET_BALANCE_LOADING: 'GET_BALANCE_LOADING',
    GET_BALANCE: 'GET_BALANCE',
    GET_PROFILE: 'GET_PROFILE',
    IS_FN_TESTER: 'IS_FN_TESTER',
    IS_RUBY: 'IS_RUBY',
    GET_SIGN_UP_SOCIAL: 'GET_SIGN_UP_SOCIAL',
    GET_USER_VALIDATION: 'GET_USER_VALIDATION',
    GET_REQ_LIMIT: 'GET_REQ_LIMIT',
  },
  mutations: {
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    SET_PROFILE: 'SET_PROFILE',
    SET_BALANCE: 'SET_BALANCE',
    SET_IS_FN_TESTER: 'SET_FN_TESTER',
    SET_PAYMENT_USER: 'SET_PAYMENT_USER',
    SET_USER_VALIDATION: 'SET_USER_VALIDATION',
    SET_REQ_LIMIT: 'SET_REQ_LIMIT',
    SET_INCREASE_DIALOG: 'SET_INCREASE_DIALOG',
  },
  actions: {
    ON_LOGIN_DIALOG: 'ON_LOGIN_DIALOG',
    ON_INCREASE_DIALOG: 'ON_INCREASE_DIALOG',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    GET_PROFILE: 'GET_PROFILE',
    GET_BALANCE: 'GET_BALANCE',
    GET_IS_FN_TESTER: 'GET_IS_FN_TESTER',
    GET_PAYMENT_USER: 'GET_PAYMENT_USER',
    GET_USER_VALIDATION: 'GET_USER_VALIDATION',
    GET_REQ_LIMIT: 'GET_REQ_LIMIT',
  },
}
