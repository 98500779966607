<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <MyLoader />
      <p class="mt-1 mb-5 font-size--14">
        매출그래프를 불러오는 중입니다.
      </p>
    </div>
    <div :id="id" class="mx-auto" style="max-width: 550px; width: 100%;"></div>
  </div>
</template>
<script>
import { jsLoad } from '@/util'
import MyLoader from '@/components/MyLoader'
// 중복 문제 해결 필요
const scriptId = 'scGoogleChart2'

/**
 * 기존 공시페이지 매출분석 섹션
 *
 * props id
 * 차트 그릴때 사용
 * new window.google.visualization.ColumnChart(document.getElementById(this.id))
 *
 * props salesData
 * typeof Array
 * [
 *   {
 *     date: '2021-01-21',
 *     count: 7,
 *     amount: 360000,
 *   },
 *   {
 *     date: '2021-01-22',
 *     count: 10,
 *     amount: 569800,
 *   },
 *   {
 *     date: '2021-01-23',
 *     count: 14,
 *     amount: 918700,
 *   },
 * ]
 *
 */
/* eslint-disable */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
    rawData: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },
  components: {
    MyLoader,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  async created() {
    await jsLoad({
      id: scriptId,
      src: '//www.gstatic.com/charts/loader.js',
    })
    this.drawChart()
  },
  methods: {
    async drawChart() {
      window.google.charts.load('current', { packages: ['corechart'] })
      this.isLoading = true
      await window.google.charts.setOnLoadCallback(this.drawStacked)
      this.isLoading = false
    },
    drawStacked() {
      let initDataTable = [
        this.settings,
        ...this.rawData
      ]

      let data = window.google.visualization.arrayToDataTable(initDataTable)

      let chart = new window.google.visualization.ColumnChart(
        document.getElementById(this.id)
      )
      chart.draw(data, this.options)
    },
  },
}
</script>
