<template>
  <PageLoading v-if="pageLoading" />
  <div v-else>
    <SwitchComponent switch-name="SIGN_UP" :switching.sync="registerSwitch" />
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-1 font-size--16 font-weight-bold">
          ㅁ 필수항목
        </p>
        <hr class="hr-solid mt-0 mb-3" />
      </v-col>
      <v-col cols="12" class="mt-2 mb-5">
        <v-row no-gutters>
          <v-col cols="6">
            <p
              class="btn-type-selector"
              :class="{ selected: !isCorp }"
              @click="clickIsCorp(false)"
            >
              개인투자자입니다
            </p>
          </v-col>

          <v-col cols="6">
            <p
              class="btn-type-selector"
              :class="{ selected: isCorp }"
              @click="clickIsCorp(true)"
            >
              법인투자자입니다
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="isCorp" cols="12" class="mb-5">
        <CorporationDoc />

        <v-radio-group v-model="isLoan" row class="mt-4" hide-details>
          <v-radio label="대부업 투자자입니다." :value="true"></v-radio>
          <v-radio
            label="대부업 투자자가 아닙니다."
            :value="false"
            :class="{ 'mt-2': isMobile }"
          >
          </v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <div class="label"><v-icon small>mdi-account</v-icon>아이디/이메일</div>
        <v-text-field
          outlined
          single-line
          v-model="registerUser.email"
          name="id"
          label="이메일을 입력해주세요"
          :disabled="isSocial"
          :error-messages="invalidMsg.email"
          :hide-details="!!!invalidMsg.email"
          class="mb-5"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <template v-if="isSocial === false">
          <div class="label">
            <v-icon small>mdi-key-variant</v-icon>비밀번호
          </div>
          <v-text-field
            outlined
            single-line
            v-model="registerUser.password"
            name="password"
            type="password"
            label="비밀번호를 입력해주세요(6~15자)"
            :error-messages="invalidMsg.password"
            :hide-details="!!!invalidMsg.password"
            class="mb-5"
          ></v-text-field>

          <div class="label">
            <v-icon small>mdi-key-variant</v-icon>비밀번호 재입력
          </div>
          <v-text-field
            outlined
            single-line
            v-model="registerUser.rePassword"
            :rules="rules.rePassword"
            :hide-details="
              rules.rePassword[0](registerUser.rePassword) === true
            "
            name="rePassword"
            type="password"
            label="비밀번호를 다시 입력해주세요"
            class="mb-2"
          ></v-text-field>
        </template>
      </v-col>

      <v-col cols="12" class="mt-5">
        <p class="mb-1 font-size--16 font-weight-bold">
          ㅁ 선택항목
        </p>
        <hr class="hr-solid mt-0 mb-3" />
      </v-col>

      <v-col cols="12">
        <div class="label">추천인 코드</div>
        <v-text-field
          outlined
          single-line
          v-model="registerUser.code"
          name="code"
          label="공유받은 추천인 코드를 입력해주세요"
          :loading="isCodeLoading"
          :error-messages="invalidMsg.code"
          :hide-details="!!!invalidMsg.code"
          class="mb-5"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="label" :class="{ 'color-funda--basic': isChannel }">
          프로모션(파트너사) 코드
        </div>
        <v-text-field
          v-if="isChannel"
          outlined
          disabled
          single-line
          label="(OK캐시백)프로모션 자동 적용"
        ></v-text-field>
        <v-text-field
          v-else
          outlined
          single-line
          v-model="registerUser.affiliateCode"
          :error-messages="invalidMsg.affiliateCode"
          :loading="isAffiliateCodeLoading"
          name="affiliate-code"
          label="공유받은 프로모션(파트너사) 코드를 입력해주세요"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div id="policy" class="pb-5">
          <v-checkbox
            hide-details
            label="약관에 모두 동의합니다."
            class="my-0"
            :value="isAgreeAll"
            @click="agreeAll"
          ></v-checkbox>
          <v-checkbox
            v-for="required in agreement.required"
            v-model="required.isAgree"
            :key="required.name"
            :rules="rules[required.rule]"
            class="mt-0 pl-3"
            hide-details
          >
            <template v-slot:label>
              <div>
                (필수)
                <span class="font-weight-bold"> {{ required.title }}</span
                >에 동의합니다.
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      :href="`/terms?mode=${required.mode}`"
                      @click.stop
                      v-on="on"
                    >
                      [보기]
                    </a>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox
            v-for="optional in agreement.optional"
            v-model="optional.isAgree"
            :key="optional.name"
            class="mt-0 pl-3"
            hide-details
          >
            <template v-slot:label>
              <div>
                (선택)
                <span class="font-weight-bold"> {{ optional.title }}</span
                >에 동의합니다.
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      :href="`/terms?mode=${optional.mode}`"
                      @click.stop
                      v-on="on"
                    >
                      [보기]
                    </a>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn
          v-if="cannotRegister === false"
          class="btn-funda-basic reverse"
          color="primary"
          :loading="isLoading"
          :disabled="isLoading || !isOk"
          @click="signup()"
          elevation="0"
        >
          가입하기
        </v-btn>

        <p v-else class="ma-0 color--red text-center font-size--15">
          현재 회원가입이 불가합니다.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Regex from '@/const/Regex'
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import AuthService from '@/service/auth/AuthService'
import { v4 as uuidv4 } from 'uuid'
import PageLoading from '@/components/common/PageLoading.vue'
import CorporationDoc from '@/components/signup/CorporationDoc.vue'
import SwitchComponent from '@/components/SwitchComponent.vue'

export default {
  props: {
    recommenderCode: {
      default: null,
    },
    promotionCode: {
      default: null,
    },
    isChannel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PageLoading,
    CorporationDoc,
    SwitchComponent,
  },
  data() {
    return {
      pageLoading: false,
      isLoading: false,
      isSocial: false,
      isCorp: false,
      isLoan: false,
      cddType: 'residentCard',
      registerUser: {
        email: '',
        password: '',
        rePassword: '',
        code: null,
        affiliateCode: null,
        investmentLimitType: 1,
        requiredAgree: [],
        optionalAgree: [],
      },
      agreement: {
        required: [
          {
            title: '서비스이용약관',
            name: 'serviceAgree',
            isAgree: false,
            mode: 'SERVICE',
            rule: 'serviceAgree',
          },
          {
            title: '온라인투자연계이용약관',
            name: 'onlineInvestmentAgree',
            isAgree: false,
            mode: 'ONLINE_INVESTMENT',
            rule: 'onlineInvestmentAgree',
          },
          {
            title: '개인(신용)정보 수집 및 이용',
            name: 'infoGather',
            isAgree: false,
            mode: 'INFO_GATHER',
            rule: 'infoGather',
          },
          {
            title: '개인(신용)정보 제3자 제공',
            name: 'thirdParty',
            isAgree: false,
            mode: 'THIRD_PARTY',
            rule: 'thirdParty',
          },
          {
            title: '전자금융거래약관',
            name: 'eFinance',
            isAgree: false,
            mode: 'E_FINANCE',
            rule: 'eFinance',
          },
        ],
        optional: [
          {
            title: '마케팅 정보 선택적 수집 및 이용',
            name: 'optionalInfoGather',
            isAgree: false,
            mode: 'OPTIONAL_INFO_GATHER',
            rule: 'optionalInfoGather',
          },
        ],
      },
      rules: {
        id: [
          (value) => !!value || '아이디를 입력해주세요',
          (value) =>
            /^[a-zA-Z0-9]{4,}$/.test(value) ||
            '아이디는 영문, 숫자 4자리이상 입력해주세요',
        ],
        email: [
          (value) => !!value || '이메일을 입력해주세요',
          (value) =>
            /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
            '이메일 주소가 유효하지 않습니다',
        ],
        password: [
          (value) => !!value || '비밀번호를 입력해주세요',
          (value) =>
            Regex.PASSWORD.REGEX.test(value) ||
            '비밀번호는 영문, 숫자, 특수문자(!@#$%^*+=-) 조합 6~15자로 입력해주세요',
        ],
        rePassword: [
          (value) =>
            value === this.registerUser.password ||
            '비밀번호가 일치하지 않습니다',
        ],
        serviceAgree: [(value) => value || '서비스 이용약관에 동의해주세요'],
        onlineInvestmentAgree: [
          (value) => value || '온라인투자연계 이용약관에 동의해주세요',
        ],
        infoAgree: [(value) => value || '개인정보 취급방침에 동의해주세요'],
        infoGather: [
          (value) => value || '개인(신용)정보 수집 및 이용에 동의해주세요',
        ],
        thirdParty: [
          (value) => value || '개인(신용)정보 제3자 제공에 동의해주세요',
        ],
        eFinance: [(value) => value || '전자금융거래약관에 동의해주세요'],
      },
      isCodeLoading: false,
      isAffiliateCodeLoading: false,
      validation: {
        email: false,
        code: true,
        affiliateCode: true,
      },
      invalidMsg: {
        email: null,
        password: null,
        code: null,
        affiliateCode: null,
      },
      partnerCode: null,
      registerSwitch: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      signupSocial: AuthTypes.getters.GET_SIGN_UP_SOCIAL,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    cannotRegister() {
      return this.registerSwitch?.isOff === true
    },
    isAgreeRequired() {
      const required = this.agreement.required.every((a) => {
        return a.isAgree === true
      })
      return required
    },
    isAgreeAll() {
      const optional = this.agreement.optional.every((a) => {
        return a.isAgree === true
      })
      if (optional === false) {
        this.$emit('clear-optional')
      }
      return this.isAgreeRequired && optional
    },
    isValidForm() {
      const allValid = Object.keys(this.validation).every((k) => {
        return this.validation[k] === true
      })

      return allValid
    },
    isOk() {
      return (
        this.cannotRegister === false &&
        this.isAuthenticated === false &&
        !!this.registerUser.email &&
        !!this.registerUser.password &&
        !!this.registerUser.rePassword &&
        this.isValidForm === true &&
        this.isAgreeRequired === true
      )
    },
  },
  watch: {
    isChannel: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.agreement.optional.forEach((o) => {
            if (o.name === 'optionalInfoGather') {
              o.isAgree = true
            }
          })
        }
      },
    },
    recommenderCode: {
      immediate: true,
      handler(v) {
        if (v) {
          this.registerUser.code = v
        }
      },
    },
    promotionCode: {
      immediate: true,
      handler(v) {
        if (v) {
          this.registerUser.affiliateCode = v
        }
      },
    },
    'registerUser.email': {
      immediate: false,
      async handler(newVal) {
        const msg = this.getFormErrMsg('email', newVal)

        if (msg === null) {
          await this.emailDuplicate()
          if (this.validation.email !== true) {
            this.invalidMsg.email = '이미 가입된 이메일 주소입니다.'
          } else {
            this.invalidMsg.email = null
          }
        } else {
          this.invalidMsg.email = msg
        }
      },
    },
    'registerUser.password'(newVal) {
      const msg = this.getFormErrMsg('password', newVal)
      this.invalidMsg.password = msg
    },
    'registerUser.code': {
      immediate: true,
      async handler(newVal) {
        if (!!newVal) {
          await this.confirmCodeValid(newVal)
          !this.validation.code
            ? (this.invalidMsg.code = '추천인 코드가 유효하지 않습니다')
            : (this.invalidMsg.code = null)
        } else {
          this.invalidMsg.code = null
        }
      },
    },
    'registerUser.affiliateCode': {
      immediate: true,
      async handler(newVal) {
        await this.confirmAffiliateCodeValid(newVal)
        if (this.validation.affiliateCode === true) {
          this.invalidMsg.affiliateCode = null
        } else {
          this.invalidMsg.affiliateCode = '프로모션 코드가 유효하지 않습니다'
          this.$emit('invalid-affiliate')
        }
      },
    },
    signupSocial: {
      immediate: true,
      handler() {
        if (!!this.signupSocial) {
          this.isSocial = true
          this.registerUser.email = this.signupSocial.profile.email

          const password =
            uuidv4()
              .replace(/-/g, '')
              .substring(0, 13) + '!'

          this.registerUser.password = password
          this.registerUser.rePassword = password
        }
      },
    },
    isCorp: {
      immediate: true,
      handler(v) {
        this.pageLoading = true
        setTimeout(() => {
          this.pageLoading = false
        }, 500)

        if (v === true) {
          this.registerUser.investmentLimitType = 3
        } else {
          this.registerUser.investmentLimitType = 1
        }
      },
    },
  },
  created() {
    const partnerCode = this.$cookies.get('partner') ?? null
    this.partnerCode = partnerCode
  },
  methods: {
    ...mapActions('auth', ['signinKakao', 'signinSns']),
    clickIsCorp(v) {
      if (this.pageLoading) {
        return
      }

      this.isCorp = v
    },
    agreeAll() {
      let v = !this.isAgreeAll

      this.agreement.required.forEach((r) => {
        r.isAgree = v
      })

      this.agreement.optional.forEach((o) => {
        o.isAgree = v
      })
    },
    getFormErrMsg(type, newVal) {
      const rules = this.rules[type]
      let msg = null
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const isValid = rule(newVal)
        if (typeof isValid !== 'boolean') {
          msg = isValid
          break
        }
      }

      return msg
    },
    async emailDuplicate() {
      if (this.registerUser.email.length < 3) {
        return
      }
      try {
        let { isValidEmail } = await this.$fundaApi.query(gql`{
          isValidEmail : getSignupUserEmailValid(id: "${this.registerUser.email}")
        }`)
        this.validation.email = isValidEmail
      } catch (e) {
        this.validation.email = false
      }
    },
    async confirmCodeValid(code) {
      if (!!!code) {
        this.validation.code = true
        return
      }

      this.isCodeLoading = true

      try {
        let isValid = await this.$fundaApi.query(gql`{
          data: searchRecommenderCode(
            code: "${code}"
            ) {
              code,
              shortenUrl
            }
        }`)
        this.validation.code = !!isValid.code
      } catch (e) {
        this.validation.code = false
      } finally {
        this.isCodeLoading = false
      }
    },
    async confirmAffiliateCodeValid(affiliateCode) {
      if (!affiliateCode) {
        this.validation.affiliateCode = true
        return
      }

      this.isAffiliateCodeLoading = true
      try {
        const isValid = await this.$fundaApi.query(gql`{
          data: getPromotionCode(
            promotionCode: "${affiliateCode}"
            ) {
              idx,
              title
            }
        }`)
        this.validation.affiliateCode = !!isValid.idx
      } catch (e) {
        this.validation.affiliateCode = false
      } finally {
        this.isAffiliateCodeLoading = false
      }
    },
    async signup() {
      if (this.isOk !== true) {
        return
      }

      const alert = await this.$swal.basic.confirm(
        '회원등록을 진행하시겠습니까?'
      )
      if (!alert.isConfirmed) {
        return
      }

      Object.keys(this.agreement).forEach((ag) => {
        this.registerUser[`${ag}Agree`] = []
        for (const { mode, isAgree } of this.agreement[ag]) {
          if (isAgree === true) {
            this.registerUser[`${ag}Agree`].push(mode)
          }
        }
      })

      const signupUser = this.registerUser
      if (this.isCorp === true && this.isLoan === true) {
        signupUser.isLoan = true
      }

      try {
        this.isLoading = true

        let q = `
          user: signupUser(signupUser: $signupUser) {
            id
            idx
          }
        `

        if (this.partnerCode !== null) {
          q = `
            user: signupUserForAffiliate(signupUser: $signupUser, code: "${this.partnerCode}") {
              id
              idx
            }
          `
        }

        const { user } = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'signupUser($signupUser: SignupUser!)',
          {
            signupUser,
          }
        )

        await AuthService.signin({
          username: user.id,
          password: signupUser.password,
        })

        if (this.isSocial === true) {
          this.signinSns(this.signupSocial)
        }
      } catch (e) {
        this.$swal.basic.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div.label {
  font-weight: 600;
}

input[type='checkbox'].sort__checkbox {
  width: 19px !important;
  height: 19px;
  margin: 0;
  padding: 0 !important;
  border: 1px solid rgb(38, 139, 239, 0.3);
  border-radius: 100%;
  background: url('~@/assets/images/common/icons/etc-safe-checkbox.svg')
    transparent no-repeat;
  background-size: contain;
  opacity: 1 !important;
  visibility: visible !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input[type='checkbox'].sort__checkbox.checked {
  background: url('~@/assets/images/common/icons/etc-checkbox-selected.svg')
    transparent no-repeat;
  background-size: contain;
}

.btn-type-selector {
  width: 100%;
  border-bottom: 2px solid #1baaf1;
  color: rgb(0, 0, 0, 0.31);
  cursor: pointer;
  font-size: 15px;
  margin: 0;
  padding: 17px 0;
  text-align: center;

  &.selected {
    background-color: white !important;
    border: 2px solid #1baaf1;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #1baaf1 !important;
    font-weight: 900;
  }

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
}
</style>
