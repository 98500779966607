<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    await this.$swal.basic.custom({
      confirmButtonText: '확인',
      customClass: {
        container: 'custom-alert__container icon-container',
        icon: 'custom-alert__icon',
        popup: 'custom-alert__popup signup-complete__popup',
        content: 'custom-alert__content',
        confirmButton: 'custom-alert__ok-btn',
      },
      html: `
        <span style="color: #1baaf1; font-size: 20px; font-weight: 900">본인인증 접수 완료!</span>
        <br /><br />
        심사 승인까지는 
        <br />
        <span style="color: #1baaf1; text-decoration: underline;">평균 12분 내외</span> 소요됩니다.
        <br />
        <br />
        (평일 영업일 기준 / 주말 및 공휴일의 경우 다음 영업일 승인)

        <br />
        <br />
        투자 서비스 이용을 위해 
        <br />
        빠른 승인을 도와드리겠습니다.
        <span style="vertical-align: middle;">
          <img style="width: 20px; height: 20px;" src="//cdn.funda.kr/funding/common/image/img-smile.png" />
        </span>
        `,
    })
    this.$router.push({ name: 'main' })
  },
}
</script>

<style>
.custom-alert__popup.signup-complete__popup {
  max-width: 400px;
  width: 95% !important;
}

.custom-alert__popup.signup-complete__popup .custom-alert__ok-btn {
  background-color: #1baaf1 !important;
}
</style>
