<template>
  <div v-if="isAuth" class="alarm__bell">
    <v-menu offset-y close-on-content-click left ref="alarmMenu">
      <template v-slot:activator="{ on, attrs }">
        <span class="cursor--pointer" v-bind="attrs" v-on="on">
          <v-badge v-if="hasUnread" color="pink" dot overlap>
            <v-icon :class="{ 'ani-ringring': isRingRing }" color="pink">
              mdi-bell-outline
            </v-icon>
          </v-badge>
          <v-icon v-else>
            mdi-bell-outline
          </v-icon>
        </span>
      </template>

      <div class="dropdown__alarm">
        <AlarmBoard ref="dropdownAlarm" />
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { AlarmTypes } from '@/store/types/alarm'
import AlarmBoard from '@/components/alarm/AlarmBoard.vue'

export default {
  components: {
    AlarmBoard,
  },
  data() {
    return {
      isRingRing: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
    }),
    ...mapGetters('alarm', {
      hasUnread: AlarmTypes.getters.GET_HAS_UNREAD,
    }),
  },
  watch: {
    hasUnread(v) {
      if (v === true) {
        this.isRingRing = true
        setTimeout(() => {
          this.isRingRing = false
        }, 2250)
      }
    },
  },
  created() {
    this.getHasUnread()
  },
  methods: {
    ...mapActions('alarm', {
      getHasUnread: AlarmTypes.actions.GET_HAS_UNREAD,
    }),
  },
}
</script>

<style lang="scss" scoped>
.alarm__bell {
  margin-left: 5px;
}

.ani-ringring {
  animation: ringring 0.7s infinite;
}

@keyframes ringring {
  0% {
    transform: rotate(-20deg);
  }

  25% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-20deg);
  }
}

.dropdown__alarm {
  min-width: 400px;
  max-width: 400px;
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
