<template>
  <div
    class="d-flex align-center flex-column justify-center"
    style="min-height: 400px"
  >
    <MyLoader />
    <p class="mt-3 mb-0 font-size--17 font-weight-bold">
      휴대폰 본인 인증이 진행중입니다...
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import MyLoader from '@/components/MyLoader'

export default {
  components: {
    MyLoader,
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: [AuthTypes.getters.GET_USER_IDX],
      userValidation: [AuthTypes.getters.GET_USER_VALIDATION],
    }),
  },
  created() {
    setTimeout(() => {
      this.getValidMobileFromPayment()
    }, 2000)
  },
  methods: {
    ...mapActions('auth', {
      GET_USER_VALIDATION: AuthTypes.actions.GET_USER_VALIDATION,
    }),
    async getValidMobileFromPayment() {
      let q = `
        data: getValidMobileFromPayment(userIdx: ${this.userIdx}) {
          isValidPhone
          phoneValidTime
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (data.isValidPhone === true) {
          let userValidation = await this.GET_USER_VALIDATION()
          userValidation.isCallback = true
          window.opener.phonePaymentCallback(userValidation)
        }
      } catch (e) {
        await this.$swal.basic.alert(e.message)
      }
      window.close()
    },
  },
}
</script>

<style lang="scss" scoped></style>
