<template>
  <div style="background-color: white">
    <section id="hero_2" class="invest-pased-wrap main-300">
      <div class="intro_title animated fadeInDown">
        <h1>마이펀다</h1>
      </div>
    </section>

    <v-dialog v-model="showSameStoreAutoAgreeAlert" max-width="700px">
      <SameStoreAutoAgreement />
      <v-spacer></v-spacer>
      <div class="py-3 text-center" style="background: white">
        <v-btn
          class="btn__same-store-auto mr-2"
          @click="showSameStoreAutoAgreeAlert = false"
        >
          다음에
        </v-btn>
        <v-btn
          class="btn__same-store-auto ml-2 agree"
          @click="updateAgree(true)"
        >
          동의하기
        </v-btn>
      </div>
    </v-dialog>

    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-bold line-height--27">
              {{ fNumInt }}호
              {{ !!fundingDataTitle ? fundingDataTitle : '' }} 리포트
            </p>
          </v-col>
          <v-col v-if="isOldFN === false" class="mt-3" cols="12">
            <p class="d-flex align-center justify-end ma-0 font-size--15">
              동채권 재투자
              <label class="funda-switch ml-2" @click="switchAgree()">
                <input
                  :value="isAutoInvestStore"
                  :checked="isAutoInvestStore"
                  type="checkbox"
                  disabled="disabled"
                />
                <span class="slider round"></span>
              </label>
            </p>
          </v-col>
        </v-row>
        <v-row class="my-5" no-gutters>
          <v-col v-if="hasFNum" cols="12">
            <SameFnStoreInvDetails :key="fNumInt" :fNum="fNumInt" />
          </v-col>
        </v-row>
        <v-row class="pt-2" no-gutters>
          <v-col cols="12">
            <p class="font-size--18 font-weight-bold">
              투자 내역<span v-if="fNumInt > 2000000" class="font-size--15"
                >(투자일: {{ investmentDate }})</span
              >
            </p>
          </v-col>
          <v-col class="table-col" cols="12">
            <div v-if="isInvestmentLoading" class="text-center">
              <MyLoader />
            </div>
            <div v-else class="data-table-wrapper">
              <table class="width-100">
                <thead>
                  <tr>
                    <th width="5%" class="sticky-v-col">번호</th>
                    <th width="35%" style="min-width: 120px">상점명</th>
                    <th style="min-width: 70px">수익률</th>
                    <th style="min-width: 100px">투자액</th>
                    <th style="min-width: 110px">누적<br />상환원금</th>
                    <th style="min-width: 110px">누적<br />상환이자</th>
                    <th style="min-width: 60px">상환<br />상태</th>
                  </tr>
                </thead>
                <template v-if="!!investInfo && investInfo.length > 0">
                  <tbody>
                    <tr v-for="(inv, index) in investInfo" :key="inv.storeName">
                      <td class="sticky-v-col">{{ index + 1 }}</td>
                      <td>
                        {{ isOldFN ? inv.storeName : fundingDataTitle }}
                      </td>
                      <td>{{ inv.interestRate | rateFormat }} %</td>
                      <td>{{ inv.investmentAmount | commaFormat }} 원</td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ inv.principal | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ inv.interest | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <template v-if="isFNTester">
                        <template v-if="inv.investmentAmount === inv.principal">
                          <td class="color-funda--basic">연체</td>
                        </template>
                        <template v-else>
                          <td class="color--green">상환완료</td>
                        </template>
                      </template>
                      <template v-else>
                        <td
                          :class="
                            repaymentComplete(inv) && isRepaying(inv)
                              ? 'color--green'
                              : 'color-funda--basic'
                          "
                        >
                          <template v-if="isOverdueResolve(inv)">
                            <span
                              class="cursor--pointer text-decoration--underline"
                              @click="showNoicePopup(inv)"
                            >
                              연체해소
                              <i class="icon-doc-text-2"></i>
                            </span>
                          </template>

                          <template v-else>
                            <template v-if="repaymentComplete(inv)">
                              상환완료
                            </template>
                            <template v-else>
                              <template
                                v-if="isInsolvent(inv) || isOverdue(inv)"
                              >
                                <span
                                  class="cursor--pointer"
                                  @click="showNoicePopup(inv)"
                                >
                                  {{ isInsolvent(inv) ? '부실' : '연체' }}
                                  <i class="icon-doc-text-2"></i>
                                </span>
                              </template>
                              <template v-else>
                                {{ !!executeDate ? '상환중' : fStatus }}
                              </template>
                            </template>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="sticky-v-col">합계</td>
                      <td colspan="2"></td>
                      <td>{{ total.investmentAmount | commaFormat }} 원</td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ total.rpyPrincipal | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ total.rpyInterest | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="7">투자내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-10" no-gutters>
          <v-col cols="12">
            <p class="font-size--18 font-weight-bold">상환 내역</p>
          </v-col>
          <v-col class="table-col" cols="12">
            <div v-if="isRepaymentLoading" class="text-center">
              <MyLoader />
            </div>
            <div v-else class="data-table-wrapper">
              <table class="width-100">
                <thead class="rpy">
                  <tr>
                    <th width="9%" class="sticky-v-col rpy">회차</th>
                    <th width="25%" style="min-width: 85px">
                      상환일<br />(상점명)
                    </th>
                    <th style="min-width: 120px">총액</th>
                    <th style="min-width: 120px">원금</th>
                    <th style="min-width: 80px">이자<br />(세전)</th>
                    <th style="min-width: 80px">세금</th>
                    <th style="min-width: 80px">수수료</th>
                    <th v-if="hasPressFee" style="min-width: 80px">
                      추심<br />수수료
                    </th>
                  </tr>
                </thead>
                <template v-if="!!rpyInfo && rpyInfo.length > 0">
                  <tbody>
                    <template v-for="(rpy, index) in rpyInfo">
                      <tr
                        :key="index"
                        class="cursor--pointer"
                        :class="{ active: rpy.showChild }"
                        @click="rpy.showChild = !rpy.showChild"
                      >
                        <td class="sticky-v-col rpy">
                          <span
                            class="detail-arrow"
                            :class="{ active: rpy.showChild }"
                          ></span>
                          {{ index + 1 }}
                        </td>
                        <td class="font-weight-bold">
                          {{ rpy.repaymentDate }}
                        </td>
                        <td>
                          {{ rpy.totalRepaymentAfterCost | commaFormat }} 원
                        </td>
                        <td>{{ rpy.principal | commaFormat }} 원</td>
                        <td>{{ rpy.interest | commaFormat }} 원</td>
                        <td>{{ rpy.tax | commaFormat }} 원</td>
                        <td>{{ rpy.fee | commaFormat }} 원</td>
                        <td v-if="hasPressFee">
                          {{ rpy.pressFee | commaFormat }} 원
                        </td>
                      </tr>

                      <tr
                        v-show="rpy.showChild"
                        v-for="(child, index2) in rpy.details"
                        :key="`${index}-${index2}_${child.storeName}`"
                        class="active"
                      >
                        <td class="sticky-v-col rpy active">-</td>
                        <td>{{ child.storeName }}</td>
                        <td>
                          {{ child.totalRepaymentAfterCost | commaFormat }} 원
                        </td>
                        <td>{{ child.principal | commaFormat }} 원</td>
                        <td>{{ child.interest | commaFormat }} 원</td>
                        <td>{{ child.tax | commaFormat }} 원</td>
                        <td>{{ child.fee | commaFormat }} 원</td>
                        <td v-if="hasPressFee">
                          {{ child.pressFee | commaFormat }} 원
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="sticky-v-col rpy">합계</td>
                      <td></td>
                      <td>{{ total.rpyTotalAfterCost | commaFormat }} 원</td>
                      <td>{{ total.rpyPrincipal | commaFormat }} 원</td>
                      <td>{{ total.rpyInterest | commaFormat }} 원</td>
                      <td>{{ total.rpyTax | commaFormat }} 원</td>
                      <td>{{ total.rpyFee | commaFormat }} 원</td>
                      <td v-if="hasPressFee">
                        {{ total.rpyPressFee | commaFormat }} 원
                      </td>
                    </tr>
                  </tfoot>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="7">상환내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <keep-alive>
      <OverdueNotice2
        ref="overdueNotice"
        :fn-num="overdueFnNum"
      ></OverdueNotice2>
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import MyLoader from '@/components/MyLoader'
import SameStoreAutoAgreement from '@/components/common/SameStoreAutoAgreement.vue'
import OverdueNotice2 from '@/components/popups/OverdueNotice2.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import SameFnStoreInvDetails from '@/components/invest/SameFnStoreInvDetails.vue'

export default {
  props: {
    fNum: {
      type: String,
      default: null,
    },
  },
  components: {
    MyLoader,
    OverdueNotice2,
    SameStoreAutoAgreement,
    LoadingText,
    SameFnStoreInvDetails,
  },
  data() {
    return {
      isInvestmentLoading: true,
      isRepaymentLoading: true,
      noticeLoading: true,
      autoInvestStores: [],
      fundingData: null,
      fundingDataTitle: null,
      fStatus: null,
      executeDate: null,
      investmentDate: null,
      total: {
        investAmount: 0,
        rpyTotalAfterCost: 0,
        rpyPrincipal: 0,
        rpyInterest: 0,
        rpyTax: 0,
        rpyFee: 0,
        rpyPressFee: 0,
      },
      investInfo: null,
      rpyInfo: null,
      overdueFnNum: null,
      updateLoading: false,
      showSameStoreAutoAgreeAlert: false,
      hasPressFee: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isFNTester: [AuthTypes.getters.IS_FN_TESTER],
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    fNumInt() {
      return parseInt(this.fNum)
    },
    hasFNum() {
      return !isNaN(this.fNumInt)
    },
    isOldFN() {
      return this.fNumInt < 20000
    },
    sNum() {
      if (!!this.investInfo) {
        return parseInt(this.investInfo[0].sNum)
      }
      return null
    },
    isAutoInvestStore() {
      let include = this.autoInvestStores.some((s) => {
        return s.fnStoreIdx === this.sNum
      })
      return include
    },
    repaymentComplete() {
      return (detail) => {
        // 상환완료여부
        return parseInt(detail.repaymentStatus) === 1
      }
    },
    isRepaying(detail) {
      return (detail) => {
        // 상환중
        return parseInt(detail.overdueStatus) < 1
      }
    },
    isOverdueResolve(detail) {
      return (detail) => {
        // 연체해소여부
        return parseInt(detail.overdueStatus) === 1
      }
    },
    isInsolvent(detail) {
      return (detail) => {
        // 부도
        return parseInt(detail.maxOverdueStatus) > 3
      }
    },
    isOverdue(detail) {
      return (detail) => {
        // 연체
        return parseInt(detail.overdueStatus) > 1
      }
    },
  },
  async created() {
    if (this.hasFNum) {
      await this.getFnInvestment()
      this.getFnRepayment()

      this.getMyAutoInvestStores()
    }
  },
  methods: {
    async getFnInvestment() {
      let q = `
        data: getFnInvestment(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNumInt})
      `

      this.isInvestmentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        let d = JSON.parse(data)
        let result = d.data

        if (result.length < 1) {
          return
        }

        result.forEach((info) => {
          info.principal = 0
          info.interest = 0
          info.pressFee = 0
          info.overdueNotice = null

          this.total.investAmount += info.investAmount
        })

        this.fundingDataTitle = result[0].fundingDataTitle
        this.fStatus = result[0].fStatus
        this.executeDate = result[0].executeDate
        this.investmentDate = result[0].investmentDate
        this.useDays = result[0].useDays

        this.investInfo = result
      } finally {
        this.isInvestmentLoading = false
      }
    },
    async getFnRepayment() {
      if (this.investInfo === null) {
        this.isRepaymentLoading = false
        return
      }

      let q = `
        data: getFnRepayment(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNumInt})
      `

      this.isRepaymentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        let d = JSON.parse(data)
        let result = d.data

        if (result.summary.length < 1) {
          return
        }

        this.investInfo.forEach((inv) => {
          const sNum = inv.sNum
          inv.principal = result.summary[sNum].principal
          inv.interest = result.summary[sNum].interest
          inv.pressFee = result.summary[sNum].pressFee

          if (inv.pressFee > 0) {
            this.hasPressFee = true
          }
        })

        result.details.forEach((rpy) => {
          rpy.showChild = false

          this.total.rpyTotalAfterCost += rpy.totalRepaymentAfterCost
          this.total.rpyPrincipal += rpy.principal
          this.total.rpyInterest += rpy.interest
          this.total.rpyTax += rpy.tax
          this.total.rpyFee += rpy.fee
          this.total.rpyPressFee += rpy.pressFee
        })

        this.rpyInfo = result.details
      } finally {
        this.isRepaymentLoading = false
      }
    },
    async getMyAutoInvestStores() {
      if (this.isOldFN) {
        return
      }
      let q = `
        data: getFnAutoInvestStores(userIdx: ${this.userIdx}){
          fnStoreIdx
        }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.autoInvestStores = data
    },
    async showNoicePopup(detail) {
      this.overdueFnNum = detail.fnNum
      this.$refs.overdueNotice.showToggle()
    },
    switchAgree() {
      if (this.updateLoading === true) {
        return
      }

      if (this.isAutoInvestStore === true) {
        // ON -> OFF
        this.updateAgree(false)
      } else {
        // OFF -> ON
        this.showSameStoreAutoAgreeAlert = true
      }
    },
    async updateAgree(v) {
      if (typeof v === 'boolean') {
        let q = `data: addFnAutoInvestStore(userIdx:${this.userIdx}, fnStoreIdx: ${this.sNum})`

        if (v === false) {
          let yes = await this.$swal.basic.confirm(
            '동조건 재투자 시 상환금의 빠른 재투자로 투자 효율이 증가합니다. 그래도 중단하시겠습니까?'
          )

          if (yes.isConfirmed === false) {
            return
          }

          q = `
            data: deleteFnAutoInvestStore(userIdx:${this.userIdx}, fnStoreIdx: ${this.sNum})
          `
        }

        this.updateLoading = true

        try {
          await this.$fundaApi.mutation(gql`{${q}}`)

          this.getMyAutoInvestStores()
          this.showSameStoreAutoAgreeAlert = false
        } finally {
          this.updateLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#hero_2 {
  position: relative;
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;

  &.main-300 {
    height: 300px;

    @media (max-width: 991px) {
      height: 150px;
    }

    h1 {
      font-size: 38px;
      color: #fff;
      font-weight: bold;

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }

      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  &.invest-pased-wrap {
    background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
      center;
    background-size: cover;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;
  }
}

.btn__same-store-auto {
  max-width: 110px;
  width: 110px;
  box-shadow: unset;
  padding: 25px 0px !important;
  font-size: 15px;
  font-weight: bold;

  &.agree {
    color: white;
    background-color: #1baaf1 !important;
  }
}

.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 33px;
          width: 15px;
          height: 15px;
          right: 10px;
          background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg')
            no-repeat center;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.rpy {
            min-width: 75px;
          }
        }

        thead {
          background-color: #f9fcff;

          &.rpy {
            background-color: white;
            border-bottom: 1px solid #e7edf6;

            th {
              padding-top: 15px !important;
              padding-bottom: 15px !important;
            }
          }

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            &:not(.rpy).sticky-v-col {
              background-color: #f9fcff !important;
            }
          }
        }

        tbody {
          tr {
            &.active {
              background-color: #f5f5f5;

              .sticky-v-col {
                &.rpy {
                  background-color: #f5f5f5;
                }
              }
            }
          }

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
