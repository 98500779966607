<template>
  <div>
    <template v-if="isOff === false">
      <v-row class="file-upload" no-gutters>
        <v-col cols="12">
          <SkeletonIdLoader :is-active="!!tempFile" />
        </v-col>
        <v-col cols="12">
          <p
            v-show="!!tempFile"
            class="mt-3 mb-1 error--text font-size--16 line-height--18"
          >
            ※ 개인정보 보호를 위해 미리보기가 제공되지 않습니다
          </p>
        </v-col>
        <v-col
          v-if="!!tempFile"
          cols="12"
          class="d-flex justify-center my-3 font-size--15"
        >
          <label class="mr-2 font-weight-bold" for="id-card">
            파일명:
          </label>

          <input readonly id="id-card" type="text" :value="tempFile.orgName" />
        </v-col>

        <v-col
          cols="12"
          class="file-upload__btn"
          :class="{ float: tempFile === null }"
        >
          <S3FileUploadButton
            ref="fileUploadDriverLicense"
            uploadOnSelect
            :bucketName="bucketName"
            :path="path"
            :tempFile.sync="tempFile"
            :savedFile.sync="savedFile"
            :isCamera="true"
          >
            {{ !!tempFile ? '다른파일 업로드' : '운전면허증 업로드' }}
          </S3FileUploadButton>
        </v-col>
      </v-row>

      <v-row class="mt-3" no-gutters>
        <v-col cols="12">
          <div class="label"><v-icon small>mdi-account</v-icon>이름</div>
          <v-text-field
            outlined
            single-line
            v-model="cddForm.name"
            :rules="rules.name"
            label="성명을 입력해주세요"
            readonly
            disabled
            hide-details
            class="mb-3"
            :loading="isLoading"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="label"><v-icon small>mdi-calendar</v-icon>생년월일</div>
          <v-text-field
            outlined
            single-line
            v-model="cddForm.birthday"
            readonly
            disabled
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="mb-3">
          <div class="label">
            <v-icon small>mdi-account-details-outline</v-icon>주민등록번호
          </div>

          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field
                outlined
                single-line
                v-model="jumin.jumin1"
                name="jumin1"
                maxlength="6"
                :error-messages="jumin.jumin1ErrorMsg"
                :hide-details="!!!jumin.jumin1ErrorMsg"
                :class="{ 'error--text': !!jumin.jumin1ErrorMsg }"
                readonly
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="height: 56px"
            >
              <p class="ma-0">
                ㅡ
              </p>
            </v-col>

            <v-col cols="7">
              <v-text-field
                outlined
                single-line
                v-model="jumin.jumin2"
                :append-icon="jumin.jumin2Show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="jumin.jumin2Show ? 'text' : 'password'"
                :error-messages="jumin.jumin2ErrorMsg"
                :hide-details="!!!jumin.jumin2ErrorMsg"
                name="jumin2"
                maxlength="7"
                label="뒷자리(7자리)"
                @click:append="jumin.jumin2Show = !jumin.jumin2Show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div class="label">
            <v-icon small>mdi-smart-card-outline</v-icon>면허정보
          </div>

          <v-row class="serial-form mb-3" no-gutters>
            <v-col
              v-for="(sf, index) in serialForm"
              :key="sf.name"
              :cols="sf.length"
              class="serial-area"
              :class="{ active: sf.name === focusingFieldName }"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <p>
                    {{ sf.text }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12">
                  <v-subheader
                    class="example-serial"
                    :style="{ letterSpacing: sf.length - 2 + 'px' }"
                  >
                    {{ focusingFieldText(sf) }}
                  </v-subheader>
                  <v-subheader
                    v-if="index < serialForm.length - 1"
                    class="example-serial serial-hyphen"
                  >
                    -
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    single-line
                    v-model="cddForm[sf.name]"
                    hide-details
                    :rules="rules[sf.name]"
                    :maxlength="sf.length"
                    :name="sf.name"
                    @blur="focusingFieldName = null"
                    @focus="focusField($event)"
                  ></v-text-field>
                  <v-subheader
                    v-if="index < serialForm.length - 1"
                    class="example-serial serial-hyphen"
                  >
                    -
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-show="!!serialErrorMsg" cols="12">
              <p class="my-1 error--text font-size--12 text-left">
                {{ serialErrorMsg }}
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div class="label">
            <span>암호일련번호</span>
            <v-btn
              icon
              width="18px"
              height="18px"
              class="ml-1"
              @click="showSerialImg = !showSerialImg"
            >
              <v-icon color="grey lighten-1" class="font-size--18">
                mdi-help-circle
              </v-icon>
            </v-btn>

            <div v-show="showSerialImg">
              <hr class="width-100 my-2" />
              <img
                src="@/assets/images/signup/serial_number.png"
                style="max-width: 330px; width: 100%"
              />
            </div>
          </div>
          <v-text-field
            outlined
            single-line
            v-model="cddForm.passwordSerialNumber"
            :rules="rules.passwordSerialNumber"
            placeholder="영문 및 숫자로 된 6자리"
            maxlength="6"
            class="pwd-serial-number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="width-100 font-size--16"
            height="45px"
            :disabled="isOk !== true || isLoading"
            :loading="isLoading"
            @click="saveForm()"
          >
            운전면허증 등록하기
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="d-flex align-center justify-center"
          style="min-height: 300px"
        >
          <div v-html="driverLicenseSwitch.contents" class="text-center"></div>
        </v-col>
      </v-row>
    </template>

    <SwitchComponent
      switch-name="DRIVERLICENSE"
      :switching.sync="driverLicenseSwitch"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import SkeletonIdLoader from '@/components/common/SkeletonIdLoader.vue'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton.vue'
import SwitchComponent from '@/components/SwitchComponent.vue'

export default {
  components: {
    S3FileUploadButton,
    SkeletonIdLoader,
    SwitchComponent,
  },
  data() {
    return {
      isLoading: false,
      rules: {
        name: [value => !!value || '휴대폰 본인인증 후 자동입력됩니다.'],
        jumin2: [
          value => !!value || '주민등록번호를 입력해주세요.',
          value => /[1-4]\d{6}/.test(value) || '주민번호가 올바르지 않습니다',
        ],
        areaNumber: [
          value => !!value || '면허정보를 입력해주세요',
          value => value?.length === 2 || '면허정보를 입력해주세요',
        ],
        yearNumber: [
          value => !!value || '면허정보를 입력해주세요',
          value => value?.length === 2 || '면허정보를 입력해주세요',
        ],
        serialNumber: [
          value => !!value || '면허정보를 입력해주세요',
          value => value?.length === 6 || '면허정보를 입력해주세요',
        ],
        endingNumber: [
          value => !!value || '면허정보를 입력해주세요',
          value => value?.length === 2 || '면허정보를 입력해주세요',
        ],
        passwordSerialNumber: [
          value => !!value || '암호일련번호를 입력해주세요.',
          value => value?.length === 6 || '암호일련번호가 올바르지 않습니다',
        ],
      },
      jumin: {
        jumin1: null,
        jumin1ErrorMsg: null,
        jumin2: null,
        jumin2Show: false,
        jumin2ErrorMsg: null,
      },
      serialForm: [
        {
          name: 'areaNumber',
          text: '지역',
          length: 2,
        },
        {
          name: 'yearNumber',
          text: '년도',
          length: 2,
        },
        {
          name: 'serialNumber',
          text: '일련번호',
          length: 6,
        },
        {
          name: 'endingNumber',
          text: '맨뒤',
          length: 2,
        },
      ],
      cddForm: {
        name: null,
        birthday: null,
        areaNumber: null,
        yearNumber: null,
        serialNumber: null,
        endingNumber: null,
        passwordSerialNumber: null,
        cddFile: null,
      },
      serialErrorMsg: null,
      showSerialImg: true,
      focusingFieldName: null,
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/id-cards/',
      tempFile: null,
      savedFile: null,
      driverLicenseSwitch: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isOff() {
      return this.driverLicenseSwitch?.isOff === true
    },
    focusingFieldText() {
      return sf => {
        const txt = sf.name === this.focusingFieldName ? 'O' : 'X'
        let temp = []
        for (let index = 0; index < sf.length; index++) {
          temp.push(txt)
        }
        return temp.join('')
      }
    },
    isValidJumin() {
      return (
        !!this.jumin.jumin1 &&
        !!this.jumin.jumin2 &&
        this.jumin.jumin1ErrorMsg === null &&
        this.jumin.jumin2ErrorMsg === null
      )
    },
    isValidForm() {
      const all = this.serialForm.every(f => {
        return !!this.cddForm[f.name]
      })
      const f = this.cddForm
      const file = f.cddFile
      const fileIsValid = !!file?.orgName && !!file?.tempKey

      return all === true && fileIsValid
    },
    isOk() {
      if (this.isValidJumin !== true) {
        return false
      }

      return this.isValidForm === true
    },
  },
  watch: {
    'jumin.jumin1': {
      immediate: true,
      handler(v) {
        if (v?.length !== 6) {
          this.jumin.jumin1ErrorMsg = '휴대폰 본인인증 후 자동입력됩니다.'
        } else {
          this.jumin.jumin1ErrorMsg = null
        }
      },
    },
    'jumin.jumin2': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('jumin2', newVal)
        this.jumin.jumin2ErrorMsg = msg
      },
    },
    'cddForm.areaNumber': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('areaNumber', newVal)
        this.serialErrorMsg = msg
      },
    },
    'cddForm.yearNumber': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('yearNumber', newVal)
        this.serialErrorMsg = msg
      },
    },
    'cddForm.serialNumber': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('serialNumber', newVal)
        this.serialErrorMsg = msg
      },
    },
    'cddForm.endingNumber': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('endingNumber', newVal)
        this.serialErrorMsg = msg
      },
    },
    profile: {
      immediate: true,
      deep: true,
      handler(v) {
        this.cddForm.name = v?.name
        this.cddForm.birthday = v?.userBirthdate

        if (!!v?.userBirthdate) {
          let repl = v.userBirthdate.replace(/-/g, '')
          this.jumin.jumin1 = repl.slice(-6)
        }
      },
    },
    tempFile(v) {
      if (!!v) {
        this.cddForm.cddFile = v
      }
    },
  },
  methods: {
    ...mapMutations('auth', {
      SET_USER_VALIDATION: AuthTypes.mutations.SET_USER_VALIDATION,
    }),
    ...mapActions('auth', {
      GET_USER_VALIDATION: AuthTypes.actions.GET_USER_VALIDATION,
    }),
    getFormErrMsg(type, newVal) {
      const rules = this.rules[type]
      let msg = null
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const isValid = rule(newVal)
        if (typeof isValid !== 'boolean') {
          msg = isValid
          break
        }
      }

      return msg
    },
    focusField(e) {
      this.focusingFieldName = e.target.name
    },
    async saveForm() {
      if (this.isOk !== true) {
        this.$swal.basic.warning('정보를 입력해주세요')
        return
      }

      let alert = await this.$swal.basic.confirm('신분증 등록을 진행합니다')
      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      let q = `
        data: registerDriverLicense(userIdx: ${this.userIdx}, 
          regNum1: "${this.jumin.jumin1}",
          regNum2: "${this.jumin.jumin2}",
          driverLicense: $input) {
            userIdx
            userCddIdx
          }
        `

      try {
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: DriverLicense!)',
          { input: this.cddForm }
        )

        if (!!data?.userCddIdx) {
          const uv = await this.GET_USER_VALIDATION()
          this.SET_USER_VALIDATION(uv)
        } else {
          this.$swal.basic.error(
            '신분증 등록 중 에러가 발생하였습니다. 다시 시도해주세요.'
          )
        }
      } catch (e) {
        this.$swal.basic.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-upload {
  position: relative;
  margin-bottom: 15px;
  text-align: center;

  input {
    min-width: 200px;
    max-width: 280px;
  }

  &__btn {
    &.float {
      position: absolute;
      display: flex;
      height: 206px;
      align-items: center;
      justify-content: center;
    }
  }
}

.example-serial {
  color: #727d8c;
  font-size: 20px;
  font-weight: 900;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
}

.serial-hyphen {
  min-width: 20px;
}

.serial-form {
  .serial-area {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    &.active {
      p,
      .example-serial {
        color: #1baaf1;
      }
    }
  }

  p {
    font-size: 13px;
    line-height: 1.5;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }

  input {
    text-align: center;
  }

  .col {
    position: relative;
  }

  .v-input {
    width: 85%;
    margin: 0 auto;
    padding-top: 0;

    &::v-deep input {
      text-align: center;
    }
  }

  .serial-hyphen {
    position: absolute;
    top: 0;
    right: -10px;
  }
}

.pwd-serial-number {
  ::v-deep input {
    text-transform: uppercase;
  }
}
</style>
