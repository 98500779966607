<template>
  <div class="skeleton" :class="{ active: isActive }">
    <template v-if="isActive">
      <div class="skeleton-text">
        <h2 class="skeleton-title"></h2>
        <p class="skeleton-description">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </p>
        <a href="#" class="skeleton-link"></a>
      </div>
      <div class="skeleton-img"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  max-width: 360px;
  height: 206px;
  margin: 0 auto;
  background-color: #eeeeee;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  box-shadow: 0 9px 33px rgba(0, 0, 0, 0.07);

  &.active {
    background-color: #e2e8f0;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    margin-right: 10px;
  }

  &-img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #94a3b8;
    animation: pulse 2s infinite ease-in-out;
  }

  &-description {
    margin: 10px 0;

    span {
      &:nth-child(1) {
        display: block;
        width: 60px;
        height: 10px;
        background-color: #94a3b8;
      }

      &:nth-child(2) {
        display: block;
        width: 100%;
        height: 10px;
        background-color: #94a3b8;
        margin-top: 5px;
      }

      &:nth-child(3) {
        display: block;
        width: 100px;
        height: 10px;
        background-color: #94a3b8;
        margin-top: 5px;
      }

      &:nth-child(4) {
        display: block;
        width: 50px;
        height: 10px;
        background-color: #94a3b8;
        margin-top: 5px;
      }
    }
  }

  &-link {
    width: 75px;
    height: 12px;
    background-color: #94a3b8;
    margin-top: 40px;
  }

  &-skills {
    display: flex;
    justify-content: space-between;

    .skill {
      width: 20%;
      height: 12px;
      background-color: #94a3b8;
      list-style: none;
    }
  }
}
</style>
