<template>
  <v-dialog width="350px" v-model="modal">
    <template v-slot:activator="{ on, attrs }">
      <span>
        <a
          class="pa-0 text-decoration--underline"
          type="button"
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ userCount | commaFormat }} </a
        >&nbsp;명
      </span>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="px-4">
        <div class="users-text-wrapper px-1 font-size--13 color--body">
          <p
            v-for="(user, index) in users"
            :key="user + index"
            :class="{ 'mb-0': users.length == index + 1 }"
          >
            {{ user }}
          </p>
        </div>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-btn
          @click="modal = false"
          height="46px"
          color="#333333"
          class="modal-ok-button color--white font-size--18 font-weight-black"
          block
        >
          확인
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    userCount: {
      type: Number,
      required: true,
    },
    usersText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    users() {
      return this.usersText == null ? [] : this.usersText.split(',')
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  font-family: NanumSquare, sans-serif !important;
}

.users-text-wrapper {
  max-height: 200px;
  overflow-y: scroll;
  line-height: 22px;
  letter-spacing: 0.7px;
  word-break: break-all;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #727d8c;
  }
}

.modal-ok-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
