<template>
  <div :id="id"></div>
</template>
<script>
import { jsLoad } from '@/util'
// 중복 문제 해결 필요
const scriptId = 'scGoogleChart'

/**
 * 기존 공시페이지 일상환 분석 섹션
 *
 * props id
 * 차트 그릴때 사용
 * new window.google.visualization.BarChart(document.getElementById(this.id))
 *
 * props rawData
 * {
 *    repaymentPerDay: 150902,
 *    bcSalesPerDay: 253383,
 *    repaymentRatio: 59.6,
 * }
 *
 */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    rawData: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      reqired: true,
    },
  },
  data() {
    return {
      cardSales: 'BC',
    }
  },
  watch: {
    isMobile() {
      this.drawChart()
    },
  },
  async created() {
    await jsLoad({
      id: scriptId,
      src: '//www.gstatic.com/charts/loader.js',
    })
    this.drawChart()
  },
  methods: {
    addComma(number) {
      return this.$options.filters.commaFormat(number)
    },
    drawChart() {
      window.google.charts.load('current', { packages: ['corechart'] })
      window.google.charts.setOnLoadCallback(this.drawBarChart)
    },
    drawBarChart() {
      // props
      let repaymentPerDay = this.rawData.repaymentPerDay
      // props
      let bcSalesPerDay = this.rawData.bcSalesPerDay
      // props
      let repaymentRatio = this.rawData.repaymentRatio

      // 기존 코드에서 변경 isMobile = window.matchMedia('only screen and (max-width: 768px)')

      // props
      let cardSales = this.cardSales

      let data = window.google.visualization.arrayToDataTable([
        [
          'Year',
          '일환산 펀다 상환금',
          { role: 'annotation' },
          { role: 'tooltip' },
          '전체(일평균 ' + cardSales + '카드 매출)',
          { role: 'annotation' },
          { role: 'tooltip' },
        ],
        [
          '',
          repaymentPerDay,
          this.addComma(repaymentPerDay) + '원(' + repaymentRatio + '%)',
          '일환산 펀다 상환금:\n' + this.addComma(repaymentPerDay) + '원',
          {
            v: bcSalesPerDay - repaymentPerDay,
            f: this.addComma(bcSalesPerDay),
          },
          this.addComma(bcSalesPerDay) + '원',
          '전체(일평균 ' +
            cardSales +
            '카드 매출):\n' +
            this.addComma(bcSalesPerDay) +
            '원',
        ],
      ])

      // 기존 코드에서 변경 isMobile props로 받음
      let fontSize = 15
      let charWidth = 89
      let chart
      if (this.isMobile === true) {
        fontSize = 14
        charWidth = 70
        chart = new window.google.visualization.ColumnChart(
          document.getElementById(this.id)
        )
      } else {
        chart = new window.google.visualization.BarChart(
          document.getElementById(this.id)
        )
      }

      let options = {
        title: '(단위:원)',
        titlePosition: 'in',
        colors: ['#25AAE1', '#f2f2f2'],
        isStacked: true,
        legend: {
          position: 'top',
          maxLines: 2,
          alignment: 'end',
        },
        chartArea: {
          width: charWidth + '%',
        },
        annotations: {
          textStyle: {
            fontSize: fontSize,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return tooltipItems.yLabel + '원'
            },
          },
        },
        series: {
          1: {
            annotations: {
              textStyle: {
                color: '#333333',
              },
            },
          },
        },
      }

      chart.draw(data, options)
    },
  },
}
</script>
