import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import store from '@/store'
let environment = store.state.env.mode

if (environment === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://abfdc4b22d6c4e5ebf66616ddfa50e21@o140193.ingest.sentry.io/5577971',
    autoSessionTracking: true,
    environment,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}
