<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  data() {
    return {
      validationAlarm: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isNotCloseAlarms() {
      let result = this.validationAlarm.filter((d) => {
        return d.isClose === false
      })

      return result
    },
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      async handler(v) {
        if (!!!v) {
          return
        }

        if (!!this.profile?.userForcedWithdraw) {
          return
        }

        await this.getValidationAlarm()

        const routeName = this.$route.name
        if (routeName === 'signup' || routeName === 'signupSteps') {
          return
        }

        if (v?.isValid === true) {
          return
        }

        if (this.isNotCloseAlarms.length > 0) {
          return
        }

        const isPhone = v?.isValidPhone === false
        const isCddWaiting =
          v?.isValidIdentification === false &&
          v?.userCdds?.some((cdd) => cdd.status === 'WAITING')
        const isAccount = v?.isValidAccount === false
        const isEdd = v?.userEdds?.length === 0

        if (isPhone || isCddWaiting === false || isAccount || isEdd) {
          if (!!this.profile?.name && !!this.profile.userPhone) {
            const alert = await this.$swal.basic.confirm({
              html: `<span style="font-weight: 600">${this.profile.name}</span> 투자자님! <br /> 
              투자자 본인인증이 필요합니다. <br /><br /> 
              강화된 고객확인제도에 따라 <br />
              투자 전 1회 본인인증을 진행합니다.`,
            })
            if (alert.isConfirmed) {
              this.$router.push({ name: 'signupSteps' })
            }
          }
        }
      },
    },
  },
  methods: {
    async getValidationAlarm() {
      if (!!!this.userIdx) {
        return
      }

      const q = `
          data: getUserValidationAlarm(userIdx: ${this.userIdx}) {
            idx
            userIdx
            type
            memo
            isClose
            createTime
          }
        `
      const data = await this.$fundaApi.query(gql`{${q}}`)

      const isNotCloseAlarms = data.filter((d) => {
        return d.isClose === false
      })

      if (isNotCloseAlarms.length > 0) {
        const alarm = isNotCloseAlarms[0]

        if (alarm.type === 'APPROVAL') {
          let text =
            '투자자 등록이 승인되었습니다.<br />투자 서비스를 이용하실 수 있습니다.'
          if (!!alarm.memo) {
            text = alarm.memo
          }

          await this.$swal.basic.success({
            html: text,
          })
        } else {
          await this.$swal.basic.warning({
            title: '본인 인증 재요청',
            text: '사유: ' + alarm.memo,
          })
        }
        this.updateValidationAlarm(alarm)
      }

      this.validationAlarm = data
    },
    async updateValidationAlarm(alarm) {
      const q = `
        data: updateUserValidationAlarm(userIdx: ${this.userIdx}, inputUserValidationAlarm: {
          idx: ${alarm.idx}
          isClose: true
        }) {
          idx
          isClose
        }
      `

      await this.$fundaApi.mutation(gql`{${q}}`)
    },
  },
}
</script>

<style lang="scss" scoped></style>
