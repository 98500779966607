<template>
  <v-row class="login" align="center" justify="center" no-gutters>
    <v-col cols="12" class="text-center">
      <button class="btn-funda-basic reverse" @click="ON_LOGIN_DIALOG()">
        FUNDA 로그인
      </button>
    </v-col>
  </v-row>
</template>
<script>
import { AuthTypes } from '@/store/types/auth'
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    _seo({
      titles: ['로그인'],
    })

    this.ON_LOGIN_DIALOG()
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
    }),
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.redirect()
        }
      },
    },
  },
  methods: {
    ...mapActions('auth', [AuthTypes.actions.ON_LOGIN_DIALOG]),
    redirect() {
      let redirectUri = this.$route.query.redirectUri
      if (redirectUri == null || redirectUri === '/signup') {
        redirectUri = '/'
      }
      this.$router.push({ path: redirectUri })
    },
  },
}
</script>
<style lang="scss" scoped>
.login {
  min-height: calc(100vh - 56px);
  background: url('~@/assets/images/login_background.jpg') no-repeat center;
  background-size: cover;
}

button.btn-funda-basic {
  width: 200px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}
</style>
