<template>
  <div>
    <v-row v-if="showStoreBalance && isOldFN === false" no-gutters>
      <v-col cols="12">
        <p class="mt-3 mb-0 color--black font-size--15">
          <v-icon class="icon__already" small>
            mdi-flag-variant
          </v-icon>
          현재 이 상점에 남은 투자금액:
          <span class="font-weight-bold">
            {{ storeBalance | commaFormat }}
          </span>
          원
        </p>
      </v-col>
    </v-row>

    <v-row v-if="showInvestList.length > 0" class="mt-1" no-gutters>
      <v-col cols="12">
        <p
          class="my-2 color--black cursor--pointer font-size--15"
          @click="showSameStore = !showSameStore"
        >
          <v-icon class="icon__already" small>
            mdi-flag-variant
          </v-icon>
          나의 동일 차주 투자 목록
          <v-icon small>
            {{ showSameStore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </p>
      </v-col>
      <v-col v-show="showSameStore" cols="12">
        <p
          v-for="inv in showInvestList"
          :key="inv.idx"
          class="mb-2 pl-4 font-size--13"
        >
          <a @click="toLink(inv)">
            {{ getText(inv) }}
          </a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  props: {
    fNum: {
      type: Number,
      required: true,
      default: null,
    },
    hideZeroBalance: {
      type: Boolean,
      default: false,
    },
    hideDoneRepay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storeBalanceLoading: false,
      storeBalance: 0,
      showSameStore: true,
      investListLoading: false,
      investList: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    isOldFN() {
      return this.fNum < 20000
    },
    showStoreBalance() {
      if (this.hideZeroBalance) {
        return this.storeBalance > 0
      }
      return true
    },
    showInvestList() {
      let result = []
      if (this.hideDoneRepay) {
        result = this.investList.filter((inv) => {
          return inv.status === '상품상환중'
        })
      } else {
        result = this.investList.filter((inv) => {
          return this.fNum !== inv.idx
        })
      }
      return result
    },
  },
  watch: {
    isAuth: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.getFnStoreBalance()
          this.getSameStoreInvestList()
        }
      },
    },
  },
  methods: {
    toLink(inv) {
      if (this.fNum === inv.idx) {
        return
      }

      const r = this.$router.resolve({
        name: 'myfunda-fundanow-detail',
        params: { fNum: inv.idx },
      })

      window.open(r.href, '_blank')
    },
    getText(inv) {
      let statusTxt = `(${inv.showStatus})`
      if (inv.showStatus === '정상마감' || inv.showStatus === '중도상환마감') {
        statusTxt = '(상환완료)'
      }

      return `${inv.idx}호 ${inv.title} ${statusTxt}`
    },
    async getFnStoreBalance() {
      if (this.isAuth === false) {
        return
      }

      if (this.isOldFN) {
        return
      }

      if (this.storeBalanceLoading === true) {
        return
      }

      const q = `data: getInvestmentBalanceByFundingDataIdx(
          userIdx: ${this.userIdx}
          fundingDataIdx: ${this.fNum}
        )
      `

      this.storeBalanceLoading = true

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        if (!!data) {
          this.storeBalance = data
        }
      } catch (e) {
        this.storeBalance = 0
      } finally {
        this.storeBalanceLoading = false
      }
    },
    async getSameStoreInvestList() {
      if (this.isOldFN) {
        return
      }

      if (this.investListLoading === true) {
        return
      }

      const q = `
        data: getSameFnStoreInvList(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNum}) {
          idx
          title
          status
          repaymentStatus
          fnRepaymentStatus
          investmentAmount
          showStatus
        }
      `

      this.investListLoading = true

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.investList = data
      } finally {
        this.investListLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
