<template>
  <div v-show="isOpen" style="position: relative">
    <div :id="daumAddrWrap" class="daum-addr-wrap"></div>
  </div>
</template>
<script>
/**
 <daum-addr
    :isOpen.sync="isOpen"
    :addr.sync="addr"
    @result="addrResult"
  />
 */
export default {}
</script>
<script>
import { jsLoad } from '@/util'
const scriptId = 'scDaumMap'
const daumAddrWrap = 'daumAddrWrap'
export default {
  async created() {
    await jsLoad({
      id: scriptId,
      src: '//ssl.daumcdn.net/dmaps/map_js_init/postcode.v2.js?autoload=false',
    })
    if (this.isOpen === true) {
      this.open()
    }
  },
  watch: {
    isOpen(value) {
      if (value === true) {
        this.open()
      }
    },
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
    addr: {
      required: false,
    },
  },
  data() {
    return {
      daumAddrWrap,
      show: true,
    }
  },
  methods: {
    close() {
      this.$emit('update:is-open', false)
    },
    open() {
      let $this = this
      if (typeof daum === 'undefined') {
        setTimeout(() => {
          $this.show()
        }, 50)
      } else {
        let elementWrap = document.getElementById(daumAddrWrap)
        window.daum.postcode.load(() => {
          new window.daum.Postcode({
            oncomplete(data) {
              // let obj = {
              //   zoncode: data.zonecode,
              //   sido: data.sido,
              //   sigungu: data.sigungu,
              //   bname: data.bname,
              // }
              let obj = data
              // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
              var fullAddr = '' // 최종 주소 변수
              var extraAddr = '' // 조합형 주소 변수

              // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
              if (data.userSelectedType === 'R') {
                // 사용자가 도로명 주소를 선택했을 경우
                fullAddr = data.roadAddress
              } else {
                // 사용자가 지번 주소를 선택했을 경우(J)
                fullAddr = data.jibunAddress
              }

              // 사용자가 선택한 주소가 도로명 타입일때 조합한다.
              if (data.userSelectedType === 'R') {
                // 법정동명이 있을 경우 추가한다.
                if (data.bname !== '') {
                  extraAddr += data.bname
                }
                // 건물명이 있을 경우 추가한다.
                if (data.buildingName !== '') {
                  extraAddr +=
                    extraAddr !== ''
                      ? ', ' + data.buildingName
                      : data.buildingName
                }
                // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
                fullAddr += extraAddr !== '' ? ' (' + extraAddr + ')' : ''
              }
              obj.address = fullAddr

              // 부모에 데이터 전달
              $this.$emit('result', obj)
              $this.$emit('update:addr', obj)
              $this.close()
            },
            onresize(size) {
              elementWrap.style.height = size.height + 'px'
            },
            width: '100%',
            height: '100%',
          }).embed(elementWrap)
          this.show = true
        })
      }
    },
  },
}
</script>
<style lang="css">
#__daum__layer_1 {
  overflow: scroll !important;
  min-width: unset !important;
}
</style>
