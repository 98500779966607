<template>
  <div>
    <RepaymentSummary
      :dateRange.sync="searchDateRange"
      :rpyCount.sync="totalCount"
    />

    <RepaymentTable :dateRange="searchDateRange" :totalCount="totalCount" />
  </div>
</template>

<script>
import RepaymentSummary from '@/components/myfunda/repayment/RepaymentSummary.vue'
import RepaymentTable from '@/components/myfunda/repayment/RepaymentTable.vue'

export default {
  components: {
    RepaymentSummary,
    RepaymentTable,
  },
  data() {
    return {
      searchDateRange: [],
      totalCount: 0,
    }
  },
}
</script>

<style lang="scss" scoped></style>
