<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (1) 임직원수
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th colspan="3">구분</th>
                <th>국내</th>
                <th>국외</th>
                <th>합계</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="4" class="text-center">임원</td>
                <td colspan="2" class="text-center">비등기임원</td>
                <td class="text-right">
                  {{ members.임원.비등기임원.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.임원.비등기임원.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.임원.비등기임원.국내 +
                      members.임원.비등기임원.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="text-center">등기 이사</td>
                <td class="text-center">사내이사</td>
                <td class="text-right">
                  {{ members.임원.등기이사.사내이사.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.임원.등기이사.사내이사.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.임원.등기이사.사내이사.국내 +
                      members.임원.등기이사.사내이사.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center">사외이사</td>
                <td class="text-right">
                  {{ members.임원.등기이사.사외이사.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.임원.등기이사.사외이사.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.임원.등기이사.사외이사.국내 +
                      members.임원.등기이사.사외이사.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center">
                  감사 또는 상근감사위원
                </td>
                <td class="text-right">
                  {{
                    members.임원['감사 또는 상근감사위원'].국내 | commaFormat
                  }}
                </td>
                <td class="text-right">
                  {{
                    members.임원['감사 또는 상근감사위원'].국외 | commaFormat
                  }}
                </td>
                <td class="text-right">
                  {{
                    (members.임원['감사 또는 상근감사위원'].국내 +
                      members.임원['감사 또는 상근감사위원'].국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td rowspan="7" class="text-center">직원</td>
                <td rowspan="4" class="text-center">정규직</td>
              </tr>
              <tr>
                <td class="text-center">일반직</td>
                <td class="text-right">
                  {{ members.직원.정규직.일반직.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.정규직.일반직.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.정규직.일반직.국내 +
                      members.직원.정규직.일반직.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center">전문인력</td>
                <td class="text-right">
                  {{ members.직원.정규직.전문인력.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.정규직.전문인력.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.정규직.전문인력.국내 +
                      members.직원.정규직.전문인력.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center">무기계약직</td>
                <td class="text-right">
                  {{ members.직원.정규직.무기계약직.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.정규직.무기계약직.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.정규직.무기계약직.국내 +
                      members.직원.정규직.무기계약직.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td rowspan="3" class="text-center">비정규직</td>
                <td class="text-center">전체</td>
                <td class="text-right">
                  {{ members.직원.비정규직.전체.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.비정규직.전체.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.비정규직.전체.국내 +
                      members.직원.비정규직.전체.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center">전문인력</td>
                <td class="text-right">
                  {{ members.직원.비정규직.전문인력.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.비정규직.전문인력.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.비정규직.전문인력.국내 +
                      members.직원.비정규직.전문인력.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center">그 외</td>
                <td class="text-right">
                  {{ members.직원.비정규직.그외.국내 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ members.직원.비정규직.그외.국외 | commaFormat }}
                </td>
                <td class="text-right">
                  {{
                    (members.직원.비정규직.그외.국내 +
                      members.직원.비정규직.그외.국외)
                      | commaFormat
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">계</td>
                <td class="text-right">{{ members.계.국내 | commaFormat }}</td>
                <td class="text-right">{{ members.계.국외 | commaFormat }}</td>
                <td class="text-right">
                  {{ (members.계.국내 + members.계.국외) | commaFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (2) 전문가 보유내역
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th rowspan="2">
                  구 분
                </th>
                <th colspan="2">국내</th>
                <th colspan="2">국외</th>
                <th rowspan="2">합계</th>
              </tr>
              <tr>
                <th>정규직</th>
                <th>비정규직</th>
                <th>정규직</th>
                <th>비정규직</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="eKey in keyList(expert)" :key="eKey">
                <td class="text-center">{{ eKey }}</td>
                <td class="text-right">
                  {{ expert[eKey].국내.정규직 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ expert[eKey].국내.비정규직 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ expert[eKey].국외.정규직 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ expert[eKey].국외.비정규직 | commaFormat }}
                </td>
                <td class="text-right">
                  {{ expert[eKey].합계 | commaFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '임직원수, 전문가 보유내역',
    },
  },
  data() {
    return {
      members: {
        임원: {
          비등기임원: {
            국내: 0,
            국외: 0,
          },
          등기이사: {
            사내이사: {
              국내: 2,
              국외: 0,
            },
            사외이사: {
              국내: 0,
              국외: 0,
            },
          },
          '감사 또는 상근감사위원': {
            국내: 1,
            국외: 0,
          },
        },
        직원: {
          정규직: {
            일반직: {
              국내: 8,
              국외: 0,
            },
            전문인력: {
              국내: 0,
              국외: 0,
            },
            무기계약직: {
              국내: 0,
              국외: 0,
            },
          },
          비정규직: {
            전체: {
              국내: 0,
              국외: 0,
            },
            전문인력: {
              국내: 0,
              국외: 0,
            },
            그외: {
              국내: 0,
              국외: 0,
            },
          },
        },
        계: {
          국내: 11,
          국외: 0,
        },
      },
      expert: {
        회계사: {
          국내: {
            정규직: 0,
            비정규직: 1,
          },
          국외: {
            정규직: 0,
            비정규직: 0,
          },
          합계: 1,
        },
        변호사: {
          국내: {
            정규직: 0,
            비정규직: 0,
          },
          국외: {
            정규직: 0,
            비정규직: 0,
          },
          합계: 0,
        },
        여신심사역: {
          국내: {
            정규직: 0,
            비정규직: 0,
          },
          국외: {
            정규직: 0,
            비정규직: 0,
          },
          합계: 0,
        },
        '그 외': {
          국내: {
            정규직: 0,
            비정규직: 0,
          },
          국외: {
            정규직: 0,
            비정규직: 0,
          },
          합계: 0,
        },
      },
    }
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
