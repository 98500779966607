export const CodeTypes = {
  getters: {
    GET_BANKS: 'GET_BANKS'
  },
  mutations: {
    SET_LOADING: 'SET_LOADING',
    SET_BANKS: 'SET_BANKS'
  },
  actions: {
    GET_BANKS: 'GET_BANKS'
  }
}
