<template>
  <div>
    <OverdueNotice1 ref="noticePopup" :content="popup.popupContent">
    </OverdueNotice1>

    <v-row class="margin-t-15" no-gutters>
      <v-col
        v-if="false"
        cols="4"
        sm="4"
        md="4"
        lg="4"
        @click="pagingSizeOpened = !pagingSizeOpened"
      >
        <p class="ma-0 pl-1 cursor--pointer">
          <span class="color--gray-2 font-size--16 font-weight-bold">
            {{ paging.size }}개씩 보기
          </span>
        </p>
        <div
          class="invest-list__dropdown paging-size"
          v-show="pagingSizeOpened"
        >
          <p
            v-for="pagingSize in pagingSizeOptions"
            :key="'size-' + pagingSize"
            :class="{ active: paging.size === pagingSize }"
          >
            {{ pagingSize }}개씩 보기
          </p>
        </div>
      </v-col>
      <v-col cols="8" sm="8" md="8" lg="8">
        <span class="opacity-0">
          -
        </span>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="4"
        @click="filterOpened = !filterOpened"
      >
        <p class="invest-list__filter ma-0 cursor--pointer text-right">
          <span class="color--gray-2 font-size--16 font-weight-bold">
            {{ selectedFilter === '전체' ? '필터' : selectedFilter }}
          </span>
          <picture class="vertical-align--middle">
            <img src="@/assets/images/common/icons/ic-my-filter.svg" />
          </picture>
        </p>
        <div class="invest-list__dropdown filter-box" v-show="filterOpened">
          <p
            v-for="filter in myFilterOptions"
            :key="filter"
            @click="sortingData(filter)"
          >
            {{ filter }}
          </p>
        </div>
      </v-col>
    </v-row>

    <div
      v-if="sortedList.length == 0"
      class="dashboard-box--pa-0 d-flex justify-center align-center"
      style="height: 495px"
    >
      <transition name="fade1" mode="out-in">
        <MyLoader v-if="getDataLoading" key="loader" />
        <div
          v-else
          key="empty"
          class="text-center font-weight-bold mx-auto width-100"
        >
          <img src="@/assets/images/myfunda/ic-ic-my-list-empty.svg" />
          <p class="color--gray-2 margin-t-10 font-size--16">
            투자내역이 아직 없습니다.
          </p>
        </div>
      </transition>
    </div>

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="12"
        md="6"
        v-for="(myInvestment, index) in sortedList"
        :key="myInvestment.fundingDataIdx"
        :class="isMobile ? '' : index % 2 === 0 ? 'pr-2' : 'pl-2'"
      >
        <div
          class="dashboard-box--pa-0 list-card"
          :class="{ active: sortedList[index].moreAction }"
        >
          <div
            class="list-card__front-ground"
            v-show="myInvestment.moreAction"
            @click="sortedList[index].moreAction = false"
          >
            <div class="front-ground__background"></div>

            <v-col cols="12" sm="12" md="12" style="z-index: 1">
              <p
                class="front-ground__close ma-0 padding-t-15 padding-r-15 text-right"
              >
                <picture>
                  <img
                    src="@/assets/images/myfunda/btn-my-list-close.svg"
                    style="padding-bottom: 2px"
                  />
                </picture>
              </p>
            </v-col>

            <v-row
              class="d-flex height-100 align-start justify-center"
              no-gutters
            >
              <v-col cols="1" sm="1" md="1" lg="1"></v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
                lg="10"
                class="d-flex width-50 margin-t-10 justify-center text-center"
              >
                <button
                  v-if="myInvestment.isOldFundaNow === false"
                  class="front-ground__gongsi margin-r-5 padding-y-10 font-weight-bold"
                  @click.stop="openGongsi(myInvestment)"
                >
                  공시
                </button>

                <button
                  v-if="myInvestment.isFundaNow"
                  class="front-ground__gongsi margin-r-5 padding-y-10 font-weight-bold"
                  @click.stop="openFundaNowReport(myInvestment.fundingDataIdx)"
                >
                  투자상세
                </button>

                <button
                  v-if="
                    myInvestment.isFundaNow === false &&
                      myInvestment.fStatus != '투자중' &&
                      myInvestment.fStatus != '투자마감'
                  "
                  class="front-ground__gongsi margin-r-5 padding-y-10 font-weight-bold"
                  @click.stop="
                    openSchedule(
                      myInvestment.isMajungmul,
                      myInvestment.fundingDataIdx
                    )
                  "
                >
                  상환일정
                </button>

                <button
                  v-if="
                    myInvestment.isNewFundaNow &&
                      myInvestment.fStatus === '투자중'
                  "
                  class="front-ground__gongsi padding-y-10 font-weight-bold"
                  :disabled="cancelLoading || balanceLoading"
                  @click.stop="cancelInvest(myInvestment.fundingDataIdx)"
                >
                  <template v-if="cancelLoading || balanceLoading">
                    <span class="loading-spinner d-inline-block"></span>
                  </template>
                  <template v-else>
                    투자취소
                  </template>
                </button>

                <button
                  v-if="
                    myInvestment.isFundaNow === false &&
                      (myInvestment.fRepaymentStatus === '연체' ||
                        myInvestment.fRepaymentStatus === '부도')
                  "
                  class="front-ground__gongsi font-weight-bold"
                  @click="
                    showNoticePopup(
                      myInvestment.fundingDataIdx,
                      myInvestment.bankruptcyNoticeForCreditors
                    )
                  "
                >
                  연체/부실 안내
                </button>
              </v-col>
              <v-col
                cols="1"
                sm="1"
                md="1"
                lg="1"
                class="text-center"
                style="z-index: 1"
              >
              </v-col>
            </v-row>
          </div>

          <v-row
            class="padding-r-30 pl-5 py-5"
            @click.stop="sortedList[index].moreAction = true"
            no-gutters
          >
            <v-col
              cols="12"
              class="position--relative d-flex pr-0 pl-0 margin-b-10"
            >
              <MyInvestStatus
                :key="myInvestment.key"
                :my-investment="myInvestment"
                :filter="selectedFilter"
                :is-loading="getDataLoading"
              />
              <span
                class="color--gray-2 margin-x-10 font-weight-light font-size--14"
              >
                &#124;
              </span>
              <p class="list-card__title color--gray-2 font-weight-bold ma-0">
                {{ getFundingTitleText(myInvestment) }}
              </p>
              <span class="list-card__more-action margin-l-10"></span>
            </v-col>
            <v-col cols="12" class="mb-2">
              <p
                class="list-card__feature"
                :class="{ 'opacity-0': myInvestment.dueDate === null }"
              >
                {{ getFundingPeriodText(myInvestment) }}
              </p>
            </v-col>
            <v-col cols="12" class="d-flex">
              <p
                class="list-card__amt ma-0 color--black font-weight-black position--relative"
                style="line-height: 1.5;"
              >
                {{ myInvestment.investmentAmount | commaFormat }}원
                <span
                  v-if="myInvestment.pointAmount > 0"
                  class="font-size--13 color-funda--basic font-weight-bold"
                  style="right: 0; bottom: 17px"
                >
                  &#40;P{{ myInvestment.pointAmount | commaFormat }}&#41;
                </span>

                <span
                  class="color--gray-2 margin-x-5 font-weight-light font-size--14"
                >
                  &#124;
                </span>
                {{
                  myInvestment.isOldFundaNow
                    ? '5~19.9'
                    : (myInvestment.interestRate * 100).toFixed(2)
                }}
                %
                <img
                  v-if="isAutoInvestStore(myInvestment)"
                  src="@/assets/images/invest/icon-same-store-auto.svg"
                  style="width: 16px; heigth: 16px; vertical-align: text-top;"
                />
                <span
                  v-if="myInvestment.additionalRate > 0"
                  class="font-size--13 color-funda--basic font-weight-bold"
                  style="right: 0; bottom: 16px"
                >
                  <template
                    v-if="isFNTester === true && myInvestment.isFundaNow"
                  >
                  </template>
                  <template v-else>
                    &#43;{{ (myInvestment.additionalRate * 100).toFixed(2) }}%
                  </template>
                </span>
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div v-if="isMobile !== true" class="my-5"></div>

    <PageMaker
      :key="paging.page * paging.totalCount"
      :page="paging.page"
      :size="paging.size"
      :total-count="paging.totalCount"
      :raw-data="sortedList"
      :data-loading="getDataLoading"
      @currentPage="changePage"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import OverdueNotice1 from '@/components/popups/OverdueNotice1'
import MyLoader from '@/components/MyLoader'
import MyInvestStatus from '@/components/myfunda/MyInvestStatus'
import PageMaker from '@/components/PageMaker'

export default {
  components: {
    OverdueNotice1,
    MyLoader,
    MyInvestStatus,
    PageMaker,
  },
  data: function() {
    return {
      selectedFilter: '전체',
      filterOptions: ['전체', '상환중', '상환완료', '중도상환', '연체', '부실'],
      filterOpened: false,
      getDataLoading: true,
      sortedList: [],
      autoInvestStores: [],
      paging: {
        page: 1,
        size: 12,
        totalCount: 0,
      },
      pagingSizeOptions: [6, 12, 18],
      pagingSizeOpened: false,
      popup: {
        fundingDataIdx: null,
        popupContent: null,
      },
      cancelLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: [AuthTypes.getters.GET_USER_IDX],
      isFNTester: [AuthTypes.getters.IS_FN_TESTER],
      balanceLoading: [AuthTypes.getters.GET_BALANCE_LOADING],
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    myFilterOptions() {
      return this.filterOptions.filter(filter => filter !== this.selectedFilter)
    },
  },
  created() {
    let q = this.$route.query
    if (!!q.filter) {
      this.selectedFilter = q.filter
    }
    if (this.isMobile !== true) {
      this.paging.size = 18
    }
    this.getMyAutoInvestStores()
  },
  methods: {
    ...mapActions('auth', [
      AuthTypes.actions.GET_PROFILE,
      AuthTypes.actions.GET_BALANCE,
    ]),
    changePage(page) {
      if (page === this.paging.page && this.paging.totalCount > 0) {
        return
      }

      if (!!!page) {
        page = this.paging.page
      }

      this.paging.page = page
      this.fetchData()
    },
    sortingData(filter) {
      this.selectedFilter = filter

      this.paging.page = 1
      this.fetchData()
    },
    getFundingTitleText(f) {
      if (f.isOldFundaNow) {
        return f.title
      }

      return `${this.$options.filters.dateFormat(f.datetime)} ${f.title}`
    },
    getFundingPeriodText(f) {
      let periodText = `상환기간: ${f.loanPeriod}개월`

      if (f.isNewFundaNow) {
        periodText = `만기일 ${!!f.dueDate ? f.dueDate : ''}`
      } else if (f.isOldFundaNow) {
        periodText = `상환기간: ${
          f.fUseDays != null ? f.fUseDays / 30 : 1.5
        }개월`
      }

      return periodText
    },
    async fetchData() {
      this.getDataLoading = true

      let status = this.selectedFilter !== '전체' ? this.selectedFilter : ''
      switch (this.selectedFilter) {
        case '상환중':
          status = '상품상환중'
          break
        case '상환완료':
          status = '정상마감'
          break
        case '중도상환':
          status = '중도상환마감'
          break
        case '부실':
          status = '부도'
          break
        default:
          break
      }

      let q = `
        data: wwwInvestmentListByStatus(page: ${this.paging.page}, size: ${this.paging.size}, status: "${status}") {
          items: data {
              fundingDataIdx
              title
              fStatus
              fRepaymentStatus
              loanPeriod
              interestRate
              bankruptcyNoticeForCreditors
              fInvestmentType
              pointAmount
              investmentAmount
              additionalRate
              investmentState
              datetime
              isMajungmul
              isSafeplan
              fUseDays
              dueDate

              isFundaNow
              isNewFundaNow
              isOldFundaNow

              fnStore {
                idx
                storeName
              }
            }
            paging {
              size
              page
              startNum
              totalCount
              totalPage
            }
          }
        `

      try {
        let { items, paging } = await this.$fundaApi.query(gql`{${q}}`)

        if (this.sortedList.length > 0) {
          this.sortedList.splice(0, this.sortedList.length)
        }
        for (let i = 0; i < items.length; i++) {
          const invest = items[i]
          invest.moreAction = false
          invest.key = this.paging.page * invest.fundingDataIdx
          this.sortedList.push(invest)
        }
        // if (this.sortedList.length > 0) {
        //     this.sortedList.splice(0, this.sortedList.length)
        //     for (let i = 0 ; i < data.data.length ; i++) {
        //         data.data[i].moreAction = false
        //         this.sortedList.push(data.data[i])
        //     }
        // } else {
        //     this.sortedList = data.data
        // }
        this.paging = paging
      } catch (e) {
      } finally {
        this.getDataLoading = false
      }
    },
    async getMyAutoInvestStores() {
      let q = `
        data: getFnAutoInvestStores(userIdx: ${this.userIdx}){
          fnStoreIdx
        }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.autoInvestStores = data
    },
    isAutoInvestStore(item) {
      if (!!item.fnStore) {
        let include = this.autoInvestStores.some(s => {
          return s.fnStoreIdx === item.fnStore.idx
        })
        return include
      }

      return false
    },
    async cancelInvest(fundingDataIdx) {
      let yes = await this.$swal.basic.confirm('투자취소를 하시겠습니까?')
      if (yes.isConfirmed === false) {
        return
      }

      let q = `
        data: cancelInvest(inputCancelInvestment: {
          fundingDataIdx: ${fundingDataIdx}
          userIdx: ${this.userIdx}
          isAutoInvest: false
        })
      `

      this.cancelLoading = true

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data === true) {
          await this.GET_PROFILE()
          await this.GET_BALANCE()
          this.fetchData()
          this.$swal.basic.alert('투자취소가 완료되었습니다')
        }
      } catch (e) {
        let msg = e.message
        this.$swal.basic.warning(
          !!msg ? msg : '에러가 발생했습니다. 문제가 지속되면 문의바랍니다'
        )
      } finally {
        this.cancelLoading = false
      }
    },
    openGongsi(myInvestment) {
      let page = myInvestment.isNewFundaNow ? 'fundingFn' : 'funding'

      let r = this.$router.resolve({
        name: page,
        params: { fNum: myInvestment.fundingDataIdx },
      })

      window.open(r.href, '_blank')
    },
    openFundaNowReport(idx) {
      let r = this.$router.resolve({
        name: 'myfunda-fundanow-detail',
        params: { fNum: idx },
      })

      window.open(r.href, '_blank')
    },
    openSchedule(isMajungmul, idx) {
      let r = this.$router.resolve({
        name: isMajungmul
          ? 'myfunda-majungmul-detail'
          : 'myfunda-schedule-detail',
        params: { fNum: idx },
      })

      window.open(r.href, '_blank')
    },
    showNoticePopup(fundingDataIdx, content) {
      this.popup = {
        fundingDataIdx,
        popupContent: content.replace(/(?:\r\n|\r|\n)/g, '<br />'),
      }

      this.$refs.noticePopup.showToggle()
    },
  },
}
</script>

<style lang="scss" scoped>
.invest-list__dropdown {
  position: absolute;
  max-width: 110px;
  width: 50%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: 5px;
  z-index: 7;

  &.paging-size {
    left: 0;
  }

  &.filter-box {
    right: 0;
  }
}
.invest-list__dropdown p {
  margin: 0;
  padding: 10px;
  text-align: center;
  color: #727d8c;
  line-height: 1.5;
}

.invest-list__dropdown p:hover,
.invest-list__dropdown p.active {
  background-color: #f2f9ff;
  color: black;
}
.list-card {
  position: relative;
  min-height: 110px;
  max-height: 110px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &:not(.active):hover {
    border: 1px solid #1baaf1;
  }

  &__front-ground {
    position: absolute;
    width: 100%;
    // height: 70px;
    height: 110px;
    z-index: 5;

    // @media screen and (max-width: 767px) {
    //   height: 110px;
    // }

    button {
      max-height: 45px;
      // width: 130px;
      width: 100%;
      border: none;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
      font-size: 15px;

      // @media screen and (max-width: 767px) {
      //   width: 100%;
      // }
    }
  }

  &__feature,
  &__amt {
    font-size: 15px;
  }

  &__feature {
    position: relative;
    margin: 0;
    color: black;
    font-weight: bold;
  }

  &__more-action {
    position: absolute;
    right: 0;

    &::after {
      content: '';
      position: absolute;
      width: 3px;
      height: 15px;
      margin-top: 2px;
      background: url('~@/assets/images/common/icons/group-15.svg') no-repeat
        center;
      background-size: contain;
    }
  }

  .front-ground {
    &__background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000;
      border-radius: 8px;
      opacity: 0.5;
    }

    &__gongsi {
      background-color: white;
      color: #333333;
      z-index: 1;
    }
  }
}
</style>
