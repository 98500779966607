<template>
  <div id="main">
    <CatchphraseSection />
    <FundingStructureSection />
    <EarningsRateChartSection />
    <BusinessSecretSection />
    <VideoSection />
    <MediaSection />
    <ManagerIntroduction />
    <InterviewSection />
    <AffiliateSection />
  </div>
</template>

<script>
import CatchphraseSection from './section/CatchphraseSection.vue'
import FundingStructureSection from './section/FundingStructureSection.vue'
import EarningsRateChartSection from './section/EarningsRateChartSection.vue'
import BusinessSecretSection from './section/BusinessSecretSection.vue'
import VideoSection from './section/VideoSection.vue'
import MediaSection from './section/MediaSection.vue'
import ManagerIntroduction from './section/ManagerIntroduction.vue'
import InterviewSection from './section/InterviewSection.vue'
import AffiliateSection from './section/AffiliateSection.vue'

export default {
  components: {
    CatchphraseSection,
    FundingStructureSection,
    EarningsRateChartSection,
    BusinessSecretSection,
    VideoSection,
    MediaSection,
    AffiliateSection,
    ManagerIntroduction,
    InterviewSection,
  },
  created() {
    _seo({
      titles: ['소상공인 전문 P2P 금융'],
    })
  },
}
</script>

<style lang="scss">
#main section {
  padding-top: 50px;
  padding-bottom: 50px;
}

#main .section-container.container {
  @media (min-width: 1904px) {
    max-width: 1185px !important;
  }
}

#main .section-container--narrow.container {
  @media (min-width: 1264px) {
    max-width: 1024px !important;
  }
  @media (min-width: 1904px) {
    max-width: 1024px !important;
  }
}

#main .section-title {
  margin-bottom: 5%;
  color: #333333;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 23px;
    margin-bottom: 7%;
  }
}

/* start: auto-invest-float-button */
.auto-invest-float-button-wrapper {
  position: fixed;
  width: 100%;
  bottom: 40px;
  z-index: 19;
  max-width: 400px;
  margin: 0 auto;
  left: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    bottom: 90px;
  }
}

.auto-invest-float-button-wrapper a {
  display: block;
  margin: 0 auto;
  max-width: 400px;
  border-radius: 35px;
  box-shadow: 0 8px 20px 0 rgba(51, 51, 51, 0.16);
  background-color: #1baaf1;
  color: white !important;
  outline: none;
  border: 0;
  font-family: NanumSquare;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.auto-invest-float-button-wrapper a:hover {
  color: white;
  /* transition: background-color 0.3s; */
}

@media screen and (min-width: 992px) {
  .auto-invest-float-button-wrapper a {
    padding: 19px 0;
  }
}

@media screen and (max-width: 991px) {
  .auto-invest-float-button-wrapper {
    padding: 0 20px;
  }
  .auto-invest-float-button-wrapper a {
    padding: 19px 0;
  }
}
/* end: auto-invest-float-button */
@media screen and (max-width: 767px) {
  .main-affiliate-section h1 {
    margin: 50px 0;
  }
  .main-affiliate-section .mas-img-m {
    display: block;
    width: 100%;
    padding: 0 30px;
    max-width: 500px;
  }
  .main-affiliate-section .mas-img-pc {
    display: none;
  }
}
</style>
