<template>
  <section id="section__catchphrase" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row class="cp__main" no-gutters>
        <v-col :cols="isMobile ? 12 : 6" class="cp__textarea">
          <div class="cp__text">
            <p class="cp__text-sub">
              우리동네 잘 나가는 상점에 투자하자
            </p>

            <div class="cp__text-main">
              <p>
                연 10.67%
                <span
                  class="position--absolute font-size--22"
                  style="margin-left: -3px"
                >
                  *
                </span>
              </p>

              <p style="white-space: nowrap;">
                일복리 재테크 플랫폼
              </p>

              <p>
                펀다
              </p>
            </div>

            <p class="font-size--13 color--body">
              (* 최근 3년간 평균 세전 수익률)
            </p>
          </div>

          <v-btn
            :id="sectionId"
            class="cp__btn ani-twinkle btn-funda--blue"
            height="45px"
            @click="$emit('go-register')"
          >
            회원가입 하러가기
          </v-btn>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6" class="cp__imgarea">
          <picture>
            <img src="@/assets/images/main/catchphrase-myfunda.png" />
          </picture>
        </v-col>

        <v-col cols="12" class="position--relative">
          <div class="legally-text">
            <p>
              펀다는 [온라인투자연계금융업 및 이용자 보호에 관한 법률]에 따라
              금융위원회에 정식 등록, 철저히 관리되고 있는 온라인투자연계금융
              사업자입니다.
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: String,
      default: 'intro-',
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
#section__catchphrase {
  background-color: white;

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cp__main {
    position: relative;
    min-height: 72vh;
    display: flex;
    align-items: center;

    margin: 0 auto;

    .cp__text {
      margin-bottom: 32px;

      &-sub {
        margin-bottom: 12px;
        font-size: 25px;
      }

      &-main {
        color: #1baaf1;
        font-size: 37px;
        font-weight: 900;
        letter-spacing: 2px;

        p {
          margin: 0;
          line-height: 1.2;
        }
      }
    }

    .cp__btn {
      border-radius: 25px;
      font-size: 17px;
      font-weight: bold;
      padding: 0 28px;
    }

    .cp__imgarea {
      position: relative;
      text-align: right;

      img {
        max-width: 430px;
        width: 90%;
      }
    }
  }

  .legally-text {
    position: absolute;
    max-width: 550px;
    bottom: 20px;

    p {
      color: #565a5c !important;
      line-height: 1.4;
      margin: 0;
    }
  }

  &.mobile {
    .cp__main {
      align-items: unset;

      .cp__textarea {
        text-align: center;
      }

      .cp__text {
        &-sub {
          font-size: 20px;
        }

        &-main {
          font-size: 30px;
        }
      }

      .cp__imgarea {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        img {
          width: 100%;
        }
      }
    }

    .legally-text {
      position: static;
      margin-top: 25px;
      padding-left: 15px;
    }
  }
}
</style>
