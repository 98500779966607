<template>
  <div class="funding__majungmul">
    <div v-html="contents"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    fNum: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contents: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      if (this.fNum === null) {
        return
      }

      let { data } = await axios.get(
        `https://s3.ap-northeast-2.amazonaws.com/cdn.funda.kr/funding/custom/${this.fNum}/index.html`
      )

      this.contents = data
    },
  },
}
</script>

<style lang="scss">
.funding__majungmul {
  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid rgb(221, 221, 221);
    }
  }
}
</style>
