<template>
  <div>
    <SignupInput
      class="mb-1"
      name="phone"
      ref="phoneInput"
      rules="required|phone"
      icon="mdi-cellphone"
      label="휴대폰번호"
      placeholder="휴대폰번호를 입력해주세요('-' 제외)"
      addon
      addonBtnText="인증"
      :inputValue="inputValue"
      :addonLoading="isRequestLoading || isConfirmLoading"
      :addonDisabled="isRequestLoading || isConfirmLoading || phoneValid"
      :disabled="isRequestLoading || isConfirmLoading || phoneValid"
      @update:inputValue="$emit('update:inputValue', $event)"
      @click="requestPhoneValid"
    />
    <SignupInput
      v-show="authIdx != null"
      name="code"
      ref="codeInput"
      rules="required|numeric|digits:6"
      icon="mdi-cellphone"
      placeholder="인증번호를 입력해주세요"
      addon
      addonBtnText="인증확인"
      :inputValue="verifyWord"
      :addonLoading="isRequestLoading || isConfirmLoading"
      :addonDisabled="isRequestLoading || isConfirmLoading || phoneValid"
      :disabled="isRequestLoading || isConfirmLoading || phoneValid"
      @update:inputValue="$emit('update:verifyWord', $event)"
      @click="confirmPhoneValid"
    />
  </div>
</template>
<script>
import SignupInput from './SignupInput.vue'

export default {
  components: { SignupInput },
  props: {
    inputValue: {
      type: String,
      default: null,
    },
    verifyWord: {
      type: String,
      default: null,
    },
    authIdx: {
      type: Number,
      default: null,
    },
    phoneValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRequestLoading: false,
      isConfirmLoading: false,
    }
  },
  watch: {
    inputValue() {
      // 핸드폰 번호 변경되면 인증 번호 초기화
      if (this.authIdx != null) {
        this.$emit('update:authIdx', null)
        this.$emit('update:verifyWord', null)
        this.$emit('update:phoneValid', false)
      }
    },
  },
  methods: {
    async requestPhoneValid() {
      if (this.isRequestLoading === true) {
        return
      }
      try {
        this.isRequestLoading = true
        let { phoneUserAuthIdx } = await this.$fundaApi.mutation(gql`{
          phoneUserAuthIdx: requestSignupPhoneAuth(phone: "${this.inputValue}")
        }`)
        this.$emit('update:authIdx', phoneUserAuthIdx)
        this.$emit('update:phoneValid', false)
        this.$nextTick(_ => {
          this.$refs.codeInput.$refs.input.focus()
        })
      } catch (e) {
        if (e.code) {
          const msg = e.errors[0].message
          this.$swal.basic.error(msg)
          this.setErrors('phoneInput', msg)
        } else {
          throw e
        }
      } finally {
        this.isRequestLoading = false
      }
    },
    async confirmPhoneValid() {
      try {
        this.isConfirmLoading = true
        await this.$fundaApi.mutation(gql`{
          confirmSignupPhoneAuth(
            userAuthIdx: ${this.authIdx}, 
            phone: "${this.inputValue}", 
            verifyWord: "${this.verifyWord}")
        }`)
        this.$emit('update:phoneValid', true)
      } catch (e) {
        if (e.code) {
          const msg = e.errors[0].message
          this.$swal.basic.error(msg)
          this.setErrors('codeInput', msg)
        } else {
          throw e
        }
      } finally {
        this.isConfirmLoading = false
      }
    },
    setErrors(field, msg) {
      this.$refs[field].$refs.validationProvider.setErrors([msg])
    },
  },
}
</script>
