import { fundaApi } from '@/plugins/api/fundaApi'
import store from '@/store'
import { AlarmTypes } from './types/alarm'
import { AuthTypes } from './types/auth'

export default {
  name: 'alarm',
  namespaced: true,
  state: {
    isUnreadLoading: false,
    hasUnread: false,
  },
  getters: {
    [AlarmTypes.getters.GET_HAS_UNREAD](state) {
      return state.hasUnread
    },
  },
  mutations: {
    [AlarmTypes.mutations.SET_UNREAD_LOADING](state, v) {
      state.isUnreadLoading = v
    },
    [AlarmTypes.mutations.SET_HAS_UNREAD](state, v) {
      state.hasUnread = v
    },
  },
  actions: {
    async [AlarmTypes.actions.GET_HAS_UNREAD]({ commit, state }) {
      if (state.isUnreadLoading) {
        return
      }

      const userIdx = store.getters['auth/' + AuthTypes.getters.GET_USER_IDX]
      if (!userIdx) {
        return
      }

      commit(AlarmTypes.mutations.SET_UNREAD_LOADING, true)

      const q = `data: hasUnreadUserAlarm(userIdx: ${userIdx})`

      try {
        let data = await fundaApi.query(gql`{${q}}`)
        commit(AlarmTypes.mutations.SET_HAS_UNREAD, data)
      } finally {
        commit(AlarmTypes.mutations.SET_UNREAD_LOADING, false)
      }
    },
  },
}
