<template>
  <div
    class="my-account d-flex flex-column width-100 height-100 justify-space-between cursor-pointer"
    :class="{
      'dashboard-box--ma-0 mt-2': linkedSocial === false,
      'dashboard-box': linkedSocial === true,
      'margin-b-15': isMobile === false && linkedSocial === false,
    }"
    @click="$router.push({ name: 'myfunda-balance-deposit' })"
  >
    <div :class="isMobile ? 'mb-6' : 'mb-2'">
      <p class="mb-1 color--white font-size--16">예치금</p>
      <p class="color--white mb-0">
        <span class="font-weight-bold">
          <BalanceText loading-size="24" />원
        </span>
      </p>
    </div>

    <div style="line-height: 19px">
      <transition name="fade5" mode="out-in">
        <div v-if="profile && profile.vaccount" class="mb-2">
          <p
            class="ma-0 color--white"
            :class="isMobile ? 'mb-1 font-size--15' : 'font-size--13'"
          >
            펀다 계좌:
            {{ profile.vaccount.bankCode | bankName }}
          </p>
          <p
            class="ma-0 py-1 color--white"
            :class="isMobile ? 'font-size--18' : 'font-size--16'"
          >
            {{ profile.vaccount.accountNo }}
          </p>
        </div>
      </transition>
      <transition name="fade5" mode="out-in">
        <div v-if="!!waccount">
          <p
            class="ma-0 color--white"
            :class="isMobile ? 'mb-1 font-size--15' : 'font-size--13'"
          >
            등록 계좌: {{ waccount.bankCode | bankName }}
          </p>
          <p
            class="ma-0 py-1 color--white"
            :class="isMobile ? 'font-size--18' : 'font-size--16'"
          >
            {{ waccount.accountNo }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import BalanceText from '@/components/common/text/BalanceText.vue'

export default {
  components: {
    BalanceText,
  },
  data() {
    return {
      waccount: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    linkedSocial() {
      return this.profile?.socials.length > 0
    },
  },
  async created() {
    await this.getWaccount()
  },
  methods: {
    ...mapActions('auth', {
      GET_PAYMENT_USER: AuthTypes.actions.GET_PAYMENT_USER,
    }),
    async getWaccount() {
      let data = await this.GET_PAYMENT_USER()
      if (!!data) {
        this.waccount = data.withdrawAccount
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-account {
  background-color: #1baaf1 !important;

  div:first-child p:last-child {
    font-size: 24px;

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      font-size: 20px;
    }
  }
}
</style>
