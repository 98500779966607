<template>
  <div
    v-show="linkedSocial === false"
    class="dashboard-box--ma-0 width-100 mt-4 mb-2 cursor-pointer"
  >
    <v-row class="d-flex align-center margin-b-10" no-gutters>
      <v-col cols="3" sm="3" md="3">
        <img
          src="@/assets/images/social/kakao/kakaolink_btn_small.png"
          style="width: 37px"
        />
      </v-col>
      <v-col cols="9" sm="9" md="9">
        <p
          class="mb-0 font-weight-black font-size--15 color--default-gray"
          style="line-height: 1.3"
        >
          카카오톡 계정을 연동하고
          <br />
          간편하게 로그인하세요!
        </p>
      </v-col>
    </v-row>
    <button
      class="width-100 btn-funda--black rounded py-2 font-size--15"
      depressed
      @click="$router.push({ name: 'myfunda-setting' })"
    >
      카카오톡 연동하기
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    linkedSocial() {
      return this.profile?.socials.length > 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
