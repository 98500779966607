<template>
  <div class="dashboard-box--ma-0 padding-x-25 py-5 margin-t-5">
    <v-row
      class="sub-menu margin-b-15"
      :class="{ mobile: isMobile }"
      no-gutters
    >
      <v-col cols="12" sm="12" md="12" lg="12" :class="{ 'd-flex': isMobile }">
        <router-link
          v-for="menu in subMenu"
          :key="menu.name"
          :to="{ name: menu.name }"
          :class="{ 'router-link-exact-active': $route.name === menu.name }"
          tag="button"
        >
          {{ menu.text }}
        </router-link>
      </v-col>
    </v-row>

    <hr
      v-if="$route.name !== 'myfunda-setting-login-history'"
      class="hr-solid"
      style="border-top: 2px solid #333333"
    />

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
        <div v-if="!!myProfile" class="my-setting">
          <router-view :key="$route.fullPath" :my-profile="myProfile">
          </router-view>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AuthTypes } from '@/store/types/auth'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      subMenu: [
        { text: '회원 정보', name: 'myfunda-setting' },
        { text: '투자자 정보', name: 'myfunda-setting-investor' },
        { text: '로그인내역', name: 'myfunda-setting-login-history' },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', { myProfile: AuthTypes.getters.GET_PROFILE }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss">
.my-setting {
  .setting {
    &__category {
      margin: 0;
      font-weight: 900;

      @media screen and (max-width: 768px) {
        margin: 0 0 20px 0;
      }
    }

    &__name {
      margin: 0px;
      font-weight: 700;

      @media screen and (max-width: 768px) {
        margin: 0 0 10px 0;
      }
    }
  }

  .input-setting {
    width: 100%;
    padding: 5px 12px;
    background-color: #f2f9ff;
    border-radius: 4px;
    font-size: 15px !important;
    line-height: 2.4 !important;
  }

  .input-setting--input {
    width: 100%;
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 15px !important;
    line-height: 2.4 !important;
    border: 1px solid #d7e1ea;
    @mixin input-setting--plceholder {
      color: #727d8c;
      font-family: NanumSquare, sans-serif !important;
      font-weight: 500;
    }
    &::-webkit-input-placeholder {
      @include input-setting--plceholder;
    }
    &:-ms-input-placeholder {
      @include input-setting--plceholder;
    }
  }

  .common-button {
    max-width: 100px;
    width: 100%;
    padding: 5px;
    font-size: 15px;
    line-height: 2.4;

    &.passwd {
      min-width: 109px;
      padding: 3px 12px;
      line-height: 2 !important;
    }
  }
}
</style>
