<template>
  <div id="notice-band" v-if="!!fundaNotice" v-show="isShowNotice">
    <div class="notice__content">
      <p class="text-ellipsis">
        <span class="notice__heading">
          {{ fundaNotice.category }}
        </span>

        <a class="color--white" :href="fundaNotice.url" target="_blank">
          {{ fundaNotice.content }}
        </a>
      </p>
      <div
        class="color--white cursor--pointer text-right"
        style="min-width: 17px;"
        @click="switchNotice(false)"
      >
        X
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters('notice', {
      isShowNotice: 'isShow',
      fundaNotice: 'getNotice',
    }),
  },
  watch: {
    isShowNotice(v) {
      if (v === true) {
        document.getElementById('funda-main').classList.add('show-notice')
      } else {
        document.getElementById('funda-main').classList.remove('show-notice')
      }
    },
  },
  created() {
    this.getNotice()
  },
  methods: {
    ...mapActions('notice', ['switchNotice', 'getNotice']),
  },
}
</script>

<style>
#funda-main.show-notice {
  padding-top: 20px;
}
</style>

<style lang="scss" scoped>
#notice-band {
  position: fixed;
  width: 100%;
  top: 64px;
  padding: 5px 12px 3px;
  background-color: #8d8c8c;
  z-index: 10;

  @media screen and (max-width: 768px) {
    top: 56px;
  }

  .notice__heading {
    color: #f8f807;
  }

  .notice__content {
    display: flex;
    max-width: 768px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    > p {
      max-width: calc(100% - 22px);
      margin: 0;
      color: white;
    }
  }
}
</style>
