<template>
  <div class="my-verify-form">
    <div class="my-verify-form__content rounded--6 pa-5">
      <p class="mb-2 font-size--14 font-weight-bold">
        인증번호는 5분 이내로 입력해 주세요.
      </p>
      <ValidationObserver ref="validForm" v-slot="{ invalid }">
        <ValidationProvider
          v-slot="{ errors }"
          name="code"
          rules="required|numeric|min:3|max:6"
          slim
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="8"
              md="8"
              sm="12"
              class="position--relative d-flex align-center"
            >
              <input
                v-model="code"
                type="tel"
                class="rounded--4 width-100 font-weight-black"
                :class="{ 'color--error': errors.length > 0 }"
                @input="inputCode()"
              />
              <span
                v-if="expireTime != null"
                class="expire-in color-funda--basic mr-2"
              >
                {{ minutes | twoDigits }}:{{ seconds | twoDigits }}
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="12"
              class="pl-sm-0 pl-md-2 mt-2 mt-sm-2 mt-md-0"
            >
              <button
                v-if="expireTime != null && minutes == 0 && seconds == 0"
                @click="requestResend"
                type="button"
                class="rounded--6 btn-funda--blue width-100 font-weight-black"
                :class="isMobile == true ? 'font-size--16' : 'font-size--15'"
                :disabled="isLoading"
                v-ripple
              >
                <template v-if="isLoading">
                  <span class="loading-spinner"></span>
                </template>
                <template v-else> 재전송 </template>
              </button>
              <button
                v-else
                @click="requestConfirm"
                type="button"
                class="rounded--6 btn-funda--blue width-100 font-weight-black"
                :class="isMobile == true ? 'font-size--16' : 'font-size--15'"
                :disabled="invalid || isLoading"
                v-ripple
              >
                <template v-if="isLoading">
                  <span class="loading-spinner"></span>
                </template>
                <template v-else> 인증번호 확인 </template>
              </button>
            </v-col>
          </v-row>
          <v-slide-y-transition hide-on-leave>
            <div
              v-if="errors.length > 0"
              class="mt-2 mb-0 color--error font-size--13"
            >
              <div v-html="errors[0]"></div>
            </div>
          </v-slide-y-transition>
        </ValidationProvider>
      </ValidationObserver>
      <v-row v-if="isRegisterAccount === true" no-gutters>
        <hr class="width-100 my-3" />
        <v-col class="mb-3" cols="12">
          <img
            src="@/assets/images/signup/withdraw_auth.png"
            style="max-width: 330px; width: 100%"
          />
        </v-col>

        <v-col cols="12">
          <p class="mb-0 font-size--15 font-weight-bold line-height--20">
            ※ 해당 은행앱을 실행하여 웰컴에서 입금한 1원의 입금자명 3자리 혹은
            4자리 숫자를 확인 후 입력해주세요.
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMsg: {
      type: String,
      default: null,
    },
    expireTime: {
      type: Date,
      default: null,
      // required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isRegisterAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expireInterval: null,
      minutes: null,
      seconds: null,
      code: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    errorMsg(v) {
      if (v != null) {
        this.$refs.validForm.setErrors({
          code: [v],
        })
      }
    },
    expireTime(v) {
      this.setExpireTime()
    },
  },
  created() {
    this.setExpireTime()
  },
  methods: {
    setExpireTime() {
      if (this.expireTime == null) {
        return
      }

      if (this.expireInterval != null) {
        clearInterval(this.expireInterval)
      }
      this.setMinutesSeconds()
      this.expireInterval = setInterval((_) => {
        this.setMinutesSeconds()
      }, 1000)
    },
    setMinutesSeconds() {
      let now = new Date().getTime()
      let expireIn = Math.floor((this.expireTime - now) / 1000)

      if (expireIn < 1) {
        clearInterval(this.expireInterval)
        this.minutes = 0
        this.seconds = 0
        return
      }

      this.minutes = Math.floor(expireIn / 60)
      this.seconds = expireIn % 60
    },
    inputCode() {
      if (!!this.code && this.code.length > 0) {
        let validCode = this.code.replace(/[^\d]/g, '').substring(0, 6)
        this.code = validCode
      }
    },
    requestResend() {
      this.code = null
      this.$emit('request-resend')
    },
    requestConfirm() {
      this.$emit('request-confirm', this.code)
    },
  },
  filters: {
    twoDigits(value) {
      let num = Number(value)
      return num < 10 ? '0' + num : num
    },
  },
}
</script>

<style lang="scss" scoped>
.my-verify-form {
  p {
    letter-spacing: -0.2px;
  }
  &__content {
    background-color: #f2f9ff;
    input {
      border: solid 1px #d7e1ea;
      background-color: white;
      padding: 6px 45px 6px 12px;
      font-size: 18px;
      height: 46px;
    }
    .expire-in {
      position: absolute;
      right: 0;
      letter-spacing: -0.2px;
      font-size: 14px;
      font-weight: 700;
    }
    button {
      height: 46px;
    }
  }
}
</style>
