<template>
  <div>
    <div class="font-weight-black font-size--15 mb-3">
      로그인 내역
    </div>
    <LoginHistoryTable :query="query" />
  </div>
</template>
<script>
import LoginHistoryTable from '../../../components/myfunda/setting/loginhistory/LoginHistoryTable.vue'
export default {
  components: { LoginHistoryTable },
  props: {
    query: {
      type: Object,
      default: _ => {
        return null
      },
    },
  },
}
</script>
