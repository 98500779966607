import Vue from 'vue'
import store from '@/store'

Vue.filter('bankName', value => {
  let bank = null
  let banks = store.getters['code/GET_BANKS']

  if (Array.isArray(banks)) {
    bank = banks.find(bank => parseInt(bank.code) === parseInt(value))
    return !!bank ? bank.name : null
  } else {
    banks = store.dispatch('code/GET_BANKS')
    return null
  }
})
