export default [
  {
    name: 'main',
    path: '',
    component: require('@/pages/main/Main.vue').default,
    meta: {
      layout: 'LegacyLayoutFluid',
    },
  },
]
