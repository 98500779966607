<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" slim>
    <v-checkbox
      class="my-0"
      :class="{ 'multi-line': description != null }"
      :input-value="inputValue"
      :hide-details="errors.length == 0"
      :error-messages="errors"
      :disabled="disabled"
      @change="$emit('update:inputValue', $event)"
    >
      <template v-slot:label>
        <div :class="labelClass" class="font-weight-black color--gray-3">
          {{ label }}
          <v-tooltip v-if="link != null">
            <template v-slot:activator="{ on }">
              <a target="_blank" :href="link" @click.stop v-on="on">
                [보기]
              </a>
            </template>
          </v-tooltip>
        </div>
        <p class="font-size--11">{{ description }}</p>
      </template>
    </v-checkbox>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    inputValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: _ => {
        return {}
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.v-input ::v-deep {
  & .v-label {
    font-size: 12px;
  }
}

.multi-line ::v-deep {
  padding-bottom: 20px;

  & .v-label {
    flex-wrap: wrap;
    p {
      margin-bottom: 0;
      position: absolute;
      top: 20px;
    }
  }
}
</style>
