import AuthService from '@/service/auth/AuthService'

/**
 * 토큰 갱신용 인터셉터
 */
export default async (to, from, next) => {
  if (AuthService.isTokenExpire()) {
    await AuthService.sync()
  }
  next()
}
