<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="header color-black">
          출금계좌등록
        </p>
        <div class="label">
          <v-icon small>mdi-playlist-check</v-icon>은행선택
        </div>
        <ValidAccount
          :is-disabled="!!updateAccountConfirm.tid || updateAccountReq.loading"
          @valid-account-form="updateAccountForm"
        />
      </v-col>
    </v-row>

    <v-row v-show="!!updateAccountReq.accountForm" class="mt-2 mb-5" no-gutters>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="width-100 font-size--15"
          height="45px"
          tile
          :loading="updateAccountReq.loading"
          :disabled="!isUpdateAccountRequestable || updateAccountReq.loading"
          @click="requestModifyAccount"
        >
          {{
            !!updateAccountConfirm.tid ? '인증 진행중입니다' : '1원 인증하기'
          }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <div class="balance-deposit__content-waccount-confirm">
          <MyVerifyForm
            v-if="!!updateAccountConfirm.tid"
            :expire-time="updateAccountConfirm.expireTime"
            :error-msg="updateAccountConfirm.errorMsg"
            :is-loading="
              updateAccountReq.loading || updateAccountConfirm.loading
            "
            :is-register-account="true"
            @request-confirm="confirmModifyAccount"
            @request-resend="requestModifyAccount"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import ValidAccount from '@/components/ValidAccount'
import MyVerifyForm from '@/components/myfunda/MyVerifyForm'

export default {
  components: {
    ValidAccount,
    MyVerifyForm,
  },
  data() {
    return {
      pageLoading: false,
      updateAccountReq: {
        show: false,
        loading: false,
        accountForm: null,
      },
      updateAccountConfirm: {
        loading: false,
        tid: null,
        verifyWord: null,
        expireTime: null,
        errorMsg: null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isUpdateAccountRequestable: function() {
      return (
        !!this.updateAccountReq.accountForm &&
        !!this.updateAccountReq.accountForm.accountName &&
        this.updateAccountReq.loading === false &&
        !!!this.updateAccountConfirm.tid
      )
    },
  },
  methods: {
    ...mapMutations('auth', {
      SET_USER_VALIDATION: AuthTypes.mutations.SET_USER_VALIDATION,
    }),
    ...mapActions('auth', {
      GET_PAYMENT_USER: AuthTypes.actions.GET_PAYMENT_USER,
      GET_USER_VALIDATION: AuthTypes.actions.GET_USER_VALIDATION,
    }),
    updateAccountForm(accountForm) {
      this.updateAccountReq.accountForm = accountForm
    },
    async getPaymentUser() {
      let data = await this.GET_PAYMENT_USER()
      this.paymentUser = data
      if (!!data) {
        this.vaccount = data.virtualAccounts.find(vaccount => {
          return vaccount.type === 'USER' && vaccount.isClosed === false
        })
      }
    },
    async requestModifyAccount() {
      if (
        this.updateAccountReq.loading === true ||
        this.isUpdateAccountRequestable === false
      ) {
        return
      }
      const btnResult = await this.$swal.basic.confirm(
        '등록할 계좌로 1원이 입금됩니다.'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.updateAccountReq.loading = true

      let form = this.updateAccountReq.accountForm

      let q = `
        data: wwwUpdateWithdrawAccount(bankCode: "${form.bankCode}", accountNo: "${form.accountNo}", accountName: "${form.accountName}"){
          idx
          paymentUserIdx
          paymentUserWithdrawAccountIdx
          bankCode
          accountNo
          accountName
          tid
          isVerify
          updateTime
        }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '1원 입금 인증을 실패하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          this.updateAccountConfirm.tid = data.tid
          this.updateAccountConfirm.expireTime = this.$moment()
            .add(5, 'minutes')
            .toDate()
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
      } finally {
        this.updateAccountReq.loading = false
      }
    },
    async confirmModifyAccount(code) {
      if (this.updateAccountConfirm.loading === true) {
        return
      }
      const btnResult = await this.$swal.basic.confirm(
        '출금계좌 등록을 진행합니다.'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.updateAccountConfirm.loading = true

      let q = `
        data: wwwConfirmWithdrawAccount(tid: "${this.updateAccountConfirm.tid}", verifyWord: "${code}"){
          tid
          bankCode
          accountNo
          accountName
          isVerify
          updateTime
        }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (!!data) {
          await this.getPaymentUser()
          this.clearForm('updateAccountReq')
          this.clearForm('updateAccountConfirm')
          await this.$swal.basic.alert('출금계좌가 정상적으로 등록되었습니다.')
          const uv = await this.GET_USER_VALIDATION()
          this.SET_USER_VALIDATION(uv)
        } else {
          this.$swal.basic.alert(
            '계좌 변경을 실패하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
        this.updateAccountConfirm.errorMsg = e.message
      } finally {
        this.updateAccountConfirm.loading = false
      }
    },
    clearForm(dataName) {
      Object.keys(this[dataName]).forEach(k => {
        if (typeof this[dataName][k] === 'boolean') {
          this[dataName][k] = !this[dataName][k]
        } else {
          this[dataName][k] = null
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
