<template>
  <div v-if="isAuth" class="alarm__bell">
    <span @click="openDialog()">
      <v-badge v-if="hasUnread" color="pink" dot overlap>
        <v-icon :class="{ 'ani-ringring': isRingRing }" color="pink">
          mdi-bell-outline
        </v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-bell-outline
      </v-icon>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { AlarmTypes } from '@/store/types/alarm'
import { OverlayTypes } from '@/store/types/overlay'

export default {
  data() {
    return {
      overlayName: '#alarm',
      isRingRing: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
    }),
    ...mapGetters('alarm', {
      hasUnread: AlarmTypes.getters.GET_HAS_UNREAD,
    }),
  },
  watch: {
    hasUnread: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.isRingRing = true
          setTimeout(() => {
            this.isRingRing = false
          }, 2250)
        }
      },
    },
  },
  created() {
    this.getHasUnread()
  },
  methods: {
    ...mapActions('alarm', {
      getHasUnread: AlarmTypes.actions.GET_HAS_UNREAD,
    }),
    ...mapActions('overlay', {
      setOverlay: OverlayTypes.actions.OPEN_OVERLAY,
    }),
    openDialog() {
      this.setOverlay(this.overlayName)
    },
  },
}
</script>

<style lang="scss" scoped>
.alarm__bell {
  position: absolute;
  left: 48px;
  margin: 0;
}

.ani-ringring {
  animation: ringring 0.7s infinite;
}

@keyframes ringring {
  0% {
    transform: rotate(-20deg);
  }

  25% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-20deg);
  }
}
</style>
