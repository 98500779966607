import { render, staticRenderFns } from "./EarningsRateText.vue?vue&type=template&id=594de0aa&scoped=true&"
import script from "./EarningsRateText.vue?vue&type=script&lang=js&"
export * from "./EarningsRateText.vue?vue&type=script&lang=js&"
import style0 from "./EarningsRateText.vue?vue&type=style&index=0&id=594de0aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594de0aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
