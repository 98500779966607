import lib from './lib'
import proxy from './proxy'
import s3 from './s3'
import signin from './signin'
import funding from './funding'

const fundaApi = {
  ...lib,
  ...signin,
  ...proxy,
  ...s3,
  ...funding
}

export default fundaApi
