import store from '@/store'
import { AuthTypes } from '@/store/types/auth'
import { sleep } from '@/util'

const meta = {
  layout: 'LegacyLayoutFluid',
}

async function fundingBeforeEnter(to, from, next) {
  if (!!to.params.fNum) {
    let tryCount = 0
    while (store.getters['auth/getProfileLoading']) {
      tryCount++
      await sleep(500)
      if (tryCount > 100) {
        break
      }
    }

    let ok = false
    let isAdmin = false
    let fNum = to.params.fNum
    let q = `
      data: wwwFundingDetail(fNum: ${fNum}) {
        visible
        registerDate
        status
        investmentType
        investmentStartDate
        investmentClosureDate
        isNewFundaNow
        
        majungmulType {
          name
        }
      }
    `

    let data = await global.$fundaApi.query(gql`{${q}}`)
    let auth = await store.dispatch(`auth/${AuthTypes.actions.GET_PROFILE}`)

    ok = data.visible
    isAdmin = !!auth && auth.isAdmin

    if (data.status !== '투자중' && !!!auth) {
      ok = false
    }

    // 마중물 상품이면...
    if (!!data.majungmulType) {
      ok = false

      if (!!auth && isAdmin === false) {
        // VIP
        if (data.majungmulType.name === 'VIP') {
          let isVIP = auth.userFundaClasses.some((c) => {
            return !!c.fundaClass && c.fundaClass.className === 'VIP'
          })

          ok = isVIP
        }

        // 루비스톤
        if (data.majungmulType.name === 'RUBYSTONE') {
          let rubyRegexp = /(ruby|루비)/gi
          let isRuby = rubyRegexp.test(auth.affiliateCode)

          ok = isRuby
        }
      }
    }

    if (isAdmin === true || ok === true) {
      if (data.isNewFundaNow === true && to.name !== 'fundingFn') {
        next({ name: 'fundingFn', params: to.params })
        return
      }
      next()
    } else {
      alert('제한된 상품입니다.')
      next('/')
    }
  } else {
    alert('상품이 없습니다.')
    next('/')
  }
}

async function getProfileBeforeEnter(to, from, next) {
  let tryCount = 0
  while (store.getters['auth/getProfileLoading']) {
    tryCount++
    await sleep(500)
    if (tryCount > 100) {
      break
    }
  }

  const profile = store.getters[`auth/${AuthTypes.getters.GET_PROFILE}`]
  return profile
}

const getXaiGroup = async () => {
  const profile = await getProfileBeforeEnter()
  const classes = profile?.userFundaClasses || []
  const xai = classes.filter(c => c.fundaClass.classCode >= 10 && c.fundaClass.classCode <= 14)[0]
  const result = !!xai ? xai.fundaClass.className : 't0'
  return result
}

export default [
  {
    name: 'invest',
    path: '/invest',
    meta,
    beforeEnter: async (to, from, next) => {
      const group = await getXaiGroup()
      next({ name: 'investXAI', params: { group } })
    },
  },
  {
    name: 'investXAI',
    path: '/invest/:group?',
    meta,
    component: () => import('@/pages/invest/InvestVer2.vue'),
    beforeEnter: async (to, from, next) => {
      const group = await getXaiGroup()
      const { params } = to
      if (group !== params?.group) {
        next({ name: 'invest' })
      } else {
        next()
      }
    },
  },
  {
    name: 'funding',
    path: '/invest/funding/:fNum',
    meta,
    component: require('@/pages/invest/Funding.vue').default,
    props: true,
    beforeEnter: async (to, from, next) => {
      await fundingBeforeEnter(to, from, next)
    },
  },
  {
    name: 'fundingFn',
    path: '/invest/fundanow/:fNum',
    meta,
    component: require('@/components/fundings/fn/FundingDetails.vue').default,
    props: true,
    beforeEnter: async (to, from, next) => {
      await fundingBeforeEnter(to, from, next)
    },
  },
]
