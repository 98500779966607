<template>
  <v-app light>
    <RootLayout>
      <router-view :key="$route.fullPath + '&' + $store.state.time.current" />
    </RootLayout>
    <Errors />
      <!--<ChannelPlugin :plugin-id="'f87291b1-e8d9-4937-88a4-d096bcc8a33b'" />-->
      <ChannelPlugin :plugin-id="'02e8bc32-c02e-4e6d-a181-057653789c8b'" />

  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import RootLayout from '@/layouts/RootLayout.vue'

export default {
  metaInfo() {
    return this.metaInfo
  },
  name: 'App',
  components: {
    RootLayout,
    Errors: () => import('@/components/error/FooterErrors.vue'),
    ChannelPlugin: () => import('@/components/channel/ChannelPlugin.vue'),
  },
  computed: {
    ...mapGetters('system', {
      initStatus: 'getInit',
    }),
    ...mapGetters('seo', {
      getSeo: 'get',
    }),
  },
  watch: {
    getSeo(value) {
      this.metaInfo = value
      this.$meta().refresh()
    },
  },
  data: function() {
    return {
      metaInfo: {},
    }
  },
}
</script>
