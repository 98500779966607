<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          (1) 자기의 계산으로 한 연계투자 상품 현황
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th v-for="h in infoHeader" :key="'header_' + h.text">
                  {{ h.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in keys" :key="k.text">
                <td class="text-center">{{ k.text }}</td>
                <td
                  v-for="h in infoHeader"
                  :key="k.text + '_' + h.text"
                  class="text-right"
                >
                  <template v-if="h.value === '연체율'">
                    {{ info[k.value][h.value] | rateFormat }} %
                  </template>
                  <template v-else>
                    {{ info[k.value][h.value] | commaFormat }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          (2) 그 밖에 연계투자 상품 현황
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!etc">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th v-for="h in etcHeader" :key="'header_' + h.text">
                  {{ h.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in keys" :key="k.text">
                <td class="text-center">{{ k.text }}</td>
                <td
                  v-for="h in etcHeader"
                  :key="k.text + '_' + h.text"
                  class="text-right"
                >
                  <template
                    v-if="h.value === '평균금리' || h.value === '연체율'"
                  >
                    {{ etc[k.value][h.value] | rateFormat }} %
                  </template>
                  <template v-else>
                    {{ etc[k.value][h.value] | commaFormat }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { equityInvestInfoData } from './data/equityInvestInfo.js'
import { equityInvestEtcData } from './data/equityInvestEtc.js'

export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 자기계산으로 한 연계투자에 관한 사항',
    },
    year: {
      required: true,
    },
    month: {
      required: true,
    },
  },
  data() {
    return {
      keys: [
        { text: '부동산 PF', value: '부동산PF' },
        { text: '부동산담보', value: '부동산담보' },
        {
          text: '기타담보 (어음, 매출채권 담보 제외)',
          value: '기타담보(어음.매출채권담보제외)',
        },
        { text: '어음, 매출채권 담보', value: '어음.매출채권담보' },
        { text: '개인사업자 신용', value: '개인사업자신용대출' },
        { text: '법인 신용', value: '법인사업자신용대출' },
        { text: '합계', value: '합계' },
      ],
      infoHeader: [
        { text: '누적연계 투자금액', value: '누적연계투자금액' },
        { text: '연계투자 잔액', value: '연계투자잔액' },
        {
          text: '누적 자기계산 연계투자 금액',
          value: '누적자기계산연계투자금액',
        },
        {
          text: '자기자본 대비 자기계산 연계투자 금액 비율',
          value: '자기자본대비자기계산연계투자금액비율',
        },
        { text: '연체율', value: '연체율' },
      ],
      etcHeader: [
        { text: '누적연계 투자금액', value: '누적연계투자금액' },
        { text: '연계투자 잔액', value: '연계투자잔액' },
        { text: '평균금리', value: '평균금리' },
        { text: '연체율', value: '연체율' },
      ],
      info: null,
      etc: null,
    }
  },
  computed: {
    yearHyphenMonth() {
      let tempMonth = `${this.month}`
      tempMonth = tempMonth.padStart(2, '0')
      return `${this.year}-${tempMonth}`
    },
  },
  watch: {
    yearHyphenMonth: {
      immediate: true,
      handler(v) {
        if (!!equityInvestInfoData[v]) {
          this.info = equityInvestInfoData[v]
        } else {
          let obj = {}
          this.keys.forEach(k => {
            obj[k.value] = {}
            this.infoHeader.forEach(h => {
              obj[k.value][h.value] = 0
            })
          })
          this.info = obj
        }

        if (!!equityInvestEtcData[v]) {
          this.etc = equityInvestEtcData[v]
        } else {
          let obj = {}
          this.keys.forEach(k => {
            obj[k.value] = {}
            this.etcHeader.forEach(h => {
              obj[k.value][h.value] = 0
            })
          })
          this.etc = obj
        }
      },
    },
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
