<template>
  <v-row class="signup" no-gutters>
    <v-col cols="12">
      <div class="signup-board my-5">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="header color-black my-0">
              회원등록
            </p>
          </v-col>
        </v-row>
        <v-card elevation="0" class="py-0" min-height="700px">
          <v-card-text v-if="isAuth === false" class="px-0">
            <UserRegister
              :recommenderCode="recommender"
              :promotionCode="promotion"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import UserRegister from '@/components/signup/UserRegister.vue'

export default {
  components: {
    UserRegister,
  },
  data() {
    return {
      recommender: null,
      promotion: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
  },
  watch: {
    userValidation: {
      immediate: true,
      handler(v) {
        if (v?.isValid === true) {
          this.$router.push({ name: 'invest' })
        } else if (v?.isValid === false) {
          this.$router.push({ name: 'signupSteps' })
        }
      },
    },
  },
  created() {
    _seo({
      titles: ['회원가입'],
    })

    const recommender = this.$cookies.get('recommender') ?? null
    const promotion = this.$cookies.get('promotion') ?? null
    this.recommender = recommender
    this.promotion = promotion
  },
}
</script>

<style lang="scss">
.signup {
  min-height: calc(100vh - 56px);
  background-color: #ffffff;

  .signup-board {
    max-width: 500px;
    width: 95%;
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  p.header {
    font-size: 20px;
    font-weight: 600;
  }

  div.label {
    font-size: 14px;
    margin-bottom: 4px;

    i {
      margin-right: 4px;
    }
  }

  button.btn-funda-basic {
    width: 100%;
    height: 50px !important;
    font-size: 17px !important;
    letter-spacing: -0.01rem;
    font-weight: 600;
  }

  .v-text-field__details {
    padding: 0 !important;

    .v-messages__message {
      line-height: 1.2;
    }
  }
}
</style>
