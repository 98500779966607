<template>
  <div
    v-if="isFirstInvest"
    class="custom-popup welcome-popup"
    :class="{ show: showPopup }"
    @click="showPopup = false"
  >
    <div
      class="custom-popup__contents color--body"
      @click.stop="showPopup = true"
    >
      <div class="position--relative">
        <div class="popup-body">
          <div>
            <span class="cursor--pointer" @click.stop="showPopup = false">
              <v-icon large>
                mdi-close
              </v-icon>
            </span>
          </div>
          <div class="body__img">
            <img src="@/assets/images/popup/ok-newbie.png" />
          </div>
        </div>
        <div class="popup-footer">
          <div
            id="go-to-invest"
            class="popup-close-btn"
            @click.stop="moveTo('invest')"
          >
            투자하러 가기
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  data() {
    return {
      affiliateImg: {
        OK시럽: 'ok-newbie.png',
      },
      showPopup: true,
      isFirstInvest: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isOK() {
      const affiliate = this.profile?.affiliateCode
      return affiliate === 'OK시럽'
    },
  },
  watch: {
    isOK: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.getLastInvest()
        }
      },
    },
  },
  methods: {
    moveTo(name) {
      this.showPopup = false
      this.$router.push({ name: name })
    },
    async getLastInvest() {
      if (this.isOK === false) {
        return
      }

      const q = `
        data: getLastInvestment(userIdx: ${this.userIdx}) {
          fundingDataIdx
          investmentAmount
          pointAmount
          datetime
        }
      `
      const data = await this.$fundaApi.query(gql`{${q}}`)
      this.isFirstInvest = data === null
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup.show > .custom-popup__contents {
  position: fixed;
  top: 100px;
  animation: moveDown 0.7s;

  overflow-y: hidden;
}

.custom-popup > .custom-popup__contents {
  max-width: 90%;
  width: fit-content !important;
}

.custom-popup .popup-body {
  background-color: #eaeff4;
  overflow-y: auto;
}

.custom-popup .body__img {
  max-height: 554px;

  img {
    height: 100%;
  }

  @media screen and (max-height: 568px) {
    max-height: 350px;
  }
}

.custom-popup .popup-footer {
  background-color: #eaeff4;
  padding: 15px 0;
  border-top: 1px solid rgb(0, 0, 0, 0.3);
}

.custom-popup .popup-footer .popup-close-btn {
  width: 60%;
  padding: 10px 0;
  background-color: #1baaf1;
  border-radius: 4px;
}

@keyframes moveDown {
  0% {
    top: 30px;
  }
  100% {
    top: 100px;
  }
}

@media screen and (max-height: 800px) {
  .custom-popup.show > .custom-popup__contents {
    top: 50px;
  }

  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 50px;
    }
  }
}
</style>
