<template>
  <div class="balance-deposit__content-vaccount" :class="{ mobile: isMobile }">
    <template v-if="isUserForcedWithdraw === false">
      <p class="margin-b-7 color-funda--basic font-weight-black font-size--20">
        펀다 계좌
      </p>
      <p class="attention color--gray">
        ※ <b class="font-weight-black">내 등록 계좌</b>에서만 예치금 입금이
        가능합니다.
      </p>
      <p class="margin-b-7 font-weight-bold">예금주</p>
      <p
        v-if="!!vaccount"
        class="margin-b-15 fadein font-size--19 font-weight-black"
      >
        {{ vaccount.accountName }}
      </p>
      <p v-if="!!vaccount" class="margin-b-7 font-weight-bold fadein">
        {{ vaccount.bankCode | bankName }}
      </p>
      <p
        v-if="!!vaccount"
        class="font-weight-black fadein font-size--19"
        :class="isMobile ? 'margin-b-25' : 'mb-0'"
      >
        {{ vaccount.accountNo }}
      </p>
    </template>
    <template v-else> </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  props: {
    vaccount: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isUserForcedWithdraw() {
      return !!this.profile?.userForcedWithdraw
    },
  },
}
</script>

<style lang="scss" scoped>
.attention {
  width: fit-content;
  background-color: #f6faff;
  border: solid 1px #e7edf6;
  border-radius: 20px;
  line-height: 1.3;

  margin-bottom: 20px;
  padding: 10px;
}

.balance-deposit__content-vaccount:not(.mobile) {
  min-height: 180px;
}

.balance-deposit__content-vaccount p:nth-child(1) {
  font-size: 16px;
}

.balance-deposit__content-vaccount p:nth-child(3),
.balance-deposit__content-vaccount p:nth-child(5) {
  line-height: 16px;
}
</style>
