<template>
  <div id="invest-board">
    <div
      v-if="fetchLoading || isLoading"
      class="dashboard-box--pa-0 margin-y-5 d-flex justify-center align-center"
      style="height: 528px"
    >
      <MyLoader />
    </div>
    <div v-else class="dashboard-box--pa-0 margin-t-5">
      <InvestCountBoard />

      <div
        class="invest-details px-6 py-6"
        :style="isMobile == false ? 'margin-top: 18px;' : ''"
      >
        <v-row
          v-if="isMobile"
          class="invest-details__mobile-menu text-center"
          no-gutters
        >
          <v-col cols="6">
            <button
              class="padding-x-15 padding-y-10 color--gray-2 border--none"
              :class="{ active: activeMobileMenu === 'total' }"
              @click="changeMobileMenu('total')"
            >
              총 투자
            </button>
          </v-col>
          <v-col cols="6">
            <button
              class="padding-x-15 padding-y-10 color--gray-2 border--none"
              :class="{ active: activeMobileMenu === 'done' }"
              @click="changeMobileMenu('done')"
            >
              상환완료
            </button>
          </v-col>
          <v-col cols="6">
            <button
              class="padding-x-15 padding-y-10 color--gray-2 border--none"
              :class="{ active: activeMobileMenu === 'expect' }"
              @click="changeMobileMenu('expect')"
            >
              상환예정
            </button>
          </v-col>
          <v-col cols="6">
            <button
              class="padding-x-15 padding-y-10 color--gray-2 border--none"
              :class="{ active: activeMobileMenu === 'overdue' }"
              @click="changeMobileMenu('overdue')"
            >
              연체/부실
            </button>
          </v-col>
        </v-row>

        <template v-if="isMobile === false">
          <div v-for="key in boardKey" :key="key">
            <v-row class="invest-details__row" no-gutters>
              <v-col md="3" lg="2">
                <p
                  class="invest-details__sub-title ma-0 font-weight-bold font-size--15 color--black"
                >
                  {{ boards[key]['title'] }}
                </p>
              </v-col>

              <v-col md="5" lg="5">
                <v-row class="margin-b-5" no-gutters>
                  <v-col cols="4" sm="5" md="4" lg="5">
                    <p class="invest-details__key ma-0 color--gray-2">
                      원금
                      <Tooltip
                        v-if="key === 'expect'"
                        text="연체채권이 존재하는 경우, 연체원금이 포함되어 있습니다."
                        size="16px"
                        alignment="bottom"
                      />
                    </p>
                  </v-col>
                  <v-col cols="8" sm="7" md="8" lg="7" class="text-right">
                    <p class="invest-details__val ma-0 color--black">
                      <template v-if="key === 'done'">
                        <span class="font-weight-bold color--black">
                          {{ boards[key]['principal'] | commaFormat }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold color--black">
                          <LoadingText :is-loading="userBalanceLoading">
                            {{ balance[key] | commaFormat }}
                          </LoadingText>
                        </span>
                      </template>
                      원
                    </p>
                  </v-col>
                </v-row>

                <v-row class="margin-b-5" no-gutters>
                  <v-col cols="4" sm="4" md="4" lg="4">
                    <p class="invest-details__key ma-0 color--gray-2">
                      이자
                      <span class="font-size--11" style="margin-left: -3px">
                        (세전)
                      </span>
                    </p>
                  </v-col>
                  <v-col cols="8" sm="8" md="8" class="text-right">
                    <p class="invest-details__val ma-0 color--black">
                      <span class="font-weight-bold color--black">
                        {{ boards[key]['interest'] | commaFormat }}
                      </span>
                      원
                    </p>
                  </v-col>
                </v-row>

                <v-row v-if="key !== 'expect'" no-gutters>
                  <v-col cols="4" sm="4" md="4" lg="4">
                    <p class="invest-details__key ma-0 color--gray-2">
                      연 수익률
                    </p>
                  </v-col>
                  <v-col cols="8" sm="8" md="8" lg="8" class="text-right">
                    <p class="invest-details__val ma-0 color--black">
                      <span class="font-weight-bold color--black">
                        {{ boards[key]['earningsRate'] }}
                      </span>
                      %
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col md="4" lg="5">
                <v-row class="margin-b-5" no-gutters>
                  <v-col cols="4" sm="4" md="4" lg="4">
                    <p class="invest-details__key ma-0 color--gray-2">세금</p>
                  </v-col>
                  <v-col cols="8" sm="8" md="8" class="text-right">
                    <p class="invest-details__val ma-0 color--black">
                      <span class="font-weight-bold color--black">
                        {{ boards[key]['tax'] | commaFormat }}
                      </span>
                      원
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="4" sm="4" md="4" lg="4">
                    <p class="invest-details__key ma-0 color--gray-2">
                      플랫폼 이용료{{
                        key === 'expect' && feeFreeText ? '*' : ''
                      }}
                    </p>
                  </v-col>
                  <v-col cols="8" sm="8" md="8" class="text-right">
                    <p class="invest-details__val ma-0 color--black">
                      <span class="font-weight-bold color--black">
                        {{ boards[key]['fee'] | commaFormat }}
                      </span>
                      원
                    </p>
                  </v-col>
                </v-row>

                <v-row v-if="key === 'expect'" class="margin-t-10" no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <template v-if="feeFreeText">
                      <p
                        class="mx-0 margin-b-5 color--light-gray font-size--13"
                      >
                        * 수수료 면제 혜택이 적용중입니다.
                      </p>
                      <p
                        v-html="feeFreeText"
                        class="ma-0 color--gray-2 font-size--12"
                        style="line-height: 1.5"
                      ></p>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <hr class="hr-solid" />
          </div>
          <keep-alive>
            <OverdueAndInsolvent />
          </keep-alive>
        </template>

        <template v-else>
          <keep-alive>
            <OverdueAndInsolvent v-if="activeMobileMenu === 'overdue'" />
          </keep-alive>
          <v-row
            v-if="activeMobileMenu !== 'overdue'"
            class="invest-details__row mobile padding-t-15"
            no-gutters
          >
            <v-col cols="12" class="mb-5">
              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="5" md="5" lg="5">
                  <p class="invest-details__key ma-0 color--gray-2">
                    원금
                    <Tooltip
                      v-if="activeMobileMenu === 'expect'"
                      text="연체채권이 존재하는 경우, 연체원금이 포함되어 있습니다."
                      size="16px"
                      alignment="bottom"
                    />
                  </p>
                </v-col>
                <v-col cols="8" sm="7" md="7" lg="7" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <template v-if="activeMobileMenu === 'done'">
                      <span class="font-weight-bold color--black">
                        {{
                          boards[activeMobileMenu]['principal'] | commaFormat
                        }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="font-weight-bold color--black">
                        <LoadingText :is-loading="userBalanceLoading">
                          {{ balance[activeMobileMenu] | commaFormat }}
                        </LoadingText>
                      </span>
                    </template>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    이자
                    <span class="font-size--11" style="margin-left: -3px">
                      (세전)
                    </span>
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[activeMobileMenu]['interest'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="activeMobileMenu !== 'expect'" no-gutters>
                <v-col cols="4" sm="4" md="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    연 수익률
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[activeMobileMenu]['earningsRate'] }}
                    </span>
                    %
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">세금</p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[activeMobileMenu]['tax'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    플랫폼 이용료{{
                      activeMobileMenu === 'expect' && feeFreeText ? '*' : ''
                    }}
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[activeMobileMenu]['fee'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-if="activeMobileMenu === 'expect' && feeFreeText"
                class="mt-5"
                no-gutters
              >
                <v-col cols="12" sm="12" md="12" lg="12">
                  <p class="mx-0 margin-b-5 color--light-gray font-size--13">
                    * 수수료 면제 혜택이 적용중입니다.
                  </p>
                  <p
                    v-html="feeFreeText"
                    class="ma-0 color--gray-2 font-size--12"
                    style="line-height: 1.5"
                  ></p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import MyLoader from '@/components/MyLoader.vue'
import Tooltip from '@/components/common/Tooltip.vue'
import InvestCountBoard from '@/components/myfunda/invest/InvestCountBoard.vue'
import OverdueAndInsolvent from '@/components/myfunda/invest/OverdueAndInsolvent.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'

export default {
  components: {
    Tooltip,
    MyLoader,
    InvestCountBoard,
    OverdueAndInsolvent,
    LoadingText,
  },
  data: function() {
    return {
      activeMobileMenu: 'total',
      boardKey: ['total', 'done', 'expect'],
      isLoading: true,
      eventInvests: [],
      feeFreeText: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    ...mapGetters('investment', {
      userBalanceLoading: InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userBalance: InvestmentTypes.getters.GET_USER_INVESTMENT,
      userEarningLoading: InvestmentTypes.getters.GET_USER_EARNING_LOADING,
      userEarning: InvestmentTypes.getters.GET_USER_EARNING,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    fetchLoading() {
      // eslint-disable-next-line
      return this.userEarningLoading
    },
    boards() {
      if (this.fetchLoading === true) {
        return null
      }

      const data = {
        done: {
          title: '상환완료',
          principal: this.userEarning.donePrincipal,
          interest: this.userEarning.doneInterest,
          earningsRate: this.userEarning.earningsRate,
          tax: this.userEarning.doneTax,
          fee: this.userEarning.doneFee,
        },
        expect: {
          title: '상환예정',
          principal: 0,
          interest: this.userEarning.scheduleInterest,
          earningsRate: 0,
          tax: this.userEarning.scheduleTax,
          fee: this.userEarning.scheduleFee,
        },
        overdue: {
          title: '연체현황',
        },
      }

      data.total = {
        title: '총 투자',
        principal: 0,
        interest: data.done.interest + data.expect.interest,
        earningsRate: data.done.earningsRate,
        tax: data.done.tax + data.expect.tax,
        fee: data.done.fee + data.expect.fee,
      }

      let tempBoards = {}
      this.boardKey.forEach((v) => {
        tempBoards[v] = data[v]
      })
      return tempBoards
    },
    balance() {
      const pure = this.userBalance?.pure ?? 0
      const donePrincipal = this.boards?.done.principal ?? 0
      return {
        expect: pure,
        total: pure + donePrincipal,
      }
    },
  },
  async created() {
    this.getMyEvents()
    await this.getUserEarning()
  },
  methods: {
    ...mapActions('investment', {
      getUserEarning: InvestmentTypes.actions.GET_USER_EARNING,
    }),
    summaryCountClick(status) {
      this.$router.push({
        name: 'myfunda-invest-list',
        query: { filter: status },
      })
    },
    changeMobileMenu(v) {
      this.activeMobileMenu = v
    },
    async getMyEvents() {
      this.isLoading = true

      const q = `
        data: wwwMyEventInvest(categoryIdx: 9, onTime: true) {
          categoryName
          imgSrc
          eventName
          startDatetime
          endDatetime
          createdTime
          onEventTime
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data.sort((a, b) => {
          return new Date(b.endDatetime) - new Date(a.endDatetime)
        })

        this.eventInvests = data

        if (this.eventInvests.length > 0) {
          this.feeFreeText = `
            혜택이 적용되는 상품에 한하여 상환 시 수수료가 면제됩니다.
            (~${this.$options.filters.dateFormat(
              this.eventInvests[0].endDatetime
            )} 투자 상품까지)`
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.invest-details {
  @media screen and (max-width: 767px) {
    margin: 0;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    &__sub-title {
      margin-bottom: 5px !important;
    }
  }
  &__mobile-menu {
    button {
      font-size: 16px;
      background-color: transparent;

      &.active {
        background-color: #f2f9ff;
        border-radius: 22px;
        color: #1baaf1;
        font-weight: bold;
      }
    }
  }

  &__row {
    .invest-details {
      &__key,
      &__val {
        line-height: 1.3;
      }
    }
    @media screen and (min-width: 1200px) {
      > div:nth-child(2) {
        padding-right: 40px;
      }
      > div:nth-child(3) {
        padding-left: 40px;
      }
    }
    &.mobile {
      .invest-details {
        &__key {
          font-size: 15px;
          white-space: nowrap;
        }

        &__val {
          font-size: 17px;
        }
      }
    }

    &:not(.mobile) {
      .invest-details {
        &__key {
          font-size: 14px;
          white-space: nowrap;
        }

        &__val {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
