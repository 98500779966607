import { fundaApi } from '@/plugins/api/fundaApi'
import { XaiTypes } from './types/xai'

export default {
  name: 'xai',
  namespaced: true,
  state: {
    msgMap: {}
  },
  getters: {
    [XaiTypes.getters.GET_MSG]: (state) => ({ fundingDataIdx, group }) => {
      const msg = state.msgMap?.[fundingDataIdx] || null
      if (group) {
        return msg === null ? msg : (msg?.[group] || {})
      } else {
        return msg
      }
    },
  },
  mutations: {
    [XaiTypes.mutations.SET_MSG](state, { fundingDataIdx, msg }) {
      state.msgMap[fundingDataIdx] = msg
    },
  },
  actions: {
    async [XaiTypes.actions.GET_MSG]({ commit, getters }, { fundingDataIdx, group, userIdx }) {
      const msg = getters[XaiTypes.getters.GET_MSG]({ fundingDataIdx, group })
      if (msg === null) {
        let data = {}
        try {
          const resp = await fundaApi.call({
            url: `/now/experiments/xai-messages/${fundingDataIdx}?user=${userIdx}&group=${group}`,
            method: 'GET',
          })

          data = resp.messages
        } catch (e) {}

        commit(XaiTypes.mutations.SET_MSG, { fundingDataIdx, msg: data })
      }
    }
  },
}
