<template>
  <v-card>
    <v-card-title
      class="px-1 py-2 mx-auto align-center justify-space-between"
      style="max-width: 768px;"
    >
      <p class="headline ma-0 text-ellipsis" style="width: 90%"></p>
      <a @click="closeDialog()">
        <v-icon large>
          mdi-close
        </v-icon>
      </a>
    </v-card-title>
    <v-card-text class="px-0">
      <FundingDetails
        :key="fundingDataIdx"
        :f-num="fundingDataIdx"
      ></FundingDetails>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import { OverlayTypes } from '@/store/types/overlay'
import FundingDetails from "@/components/fundings/fn/FundingDetails.vue";

export default {
  props: {
    fundingDataIdx: {
      type: Number,
      default: null
    },
  },
  components: {
    FundingDetails,
  },
  methods: {
    ...mapActions('overlay', {
      storeCloseDialog: OverlayTypes.actions.CLOSE_OVERLAY,
    }),
    async closeDialog(){
      await this.storeCloseDialog()
      // await this.$router.replace('/invest')
    }
  },
}
</script>

<style lang="scss" scoped></style>
