export default {
  name: 'event',
  namespaced: true,
  state: {
    recommender: {
      pointAmount: 2000,
      blogUrl: 'http://bit.ly/2AdTdto',
      // pointAmount: 4000,
      // blogUrl: 'https://bit.ly/3AA9Eim',
    },
  },
  getters: {
    getRecommender(state) {
      return state.recommender
    },
  },
}
