<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (1) 요약재무상태표
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th rowspan="2" colspan="2">
                  구 분
                </th>
                <th v-for="y in years" :key="y + '년'" colspan="2">
                  {{ y }}년
                </th>
              </tr>
              <tr>
                <template v-for="y in years">
                  <th :key="y + '_금액'">금액</th>
                  <th :key="y + '_구성비'">구성비</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="fKey in keyList(info[years[0]].finance)">
                <template
                  v-for="(ff, fIdex) in keyList(info[years[0]].finance[fKey])"
                >
                  <tr :key="years[0] + ff + '_header'">
                    <td
                      v-if="fIdex === 0"
                      :rowspan="keyList(info[years[0]].finance[fKey]).length"
                      class="text-center"
                    >
                      {{ fKey }}
                    </td>
                    <td class="text-center">{{ ff }}</td>
                    <td class="text-right">
                      {{ info[years[0]].finance[fKey][ff][0] | commaFormat }}
                    </td>
                    <td class="text-right">
                      {{ info[years[0]].finance[fKey][ff][1] }} %
                    </td>
                    <td class="text-right">
                      {{ info[years[1]].finance[fKey][ff][0] | commaFormat }}
                    </td>
                    <td class="text-right">
                      {{ info[years[1]].finance[fKey][ff][1] }} %
                    </td>
                  </tr>
                </template>
              </template>
              <tr>
                <td colspan="2" class="text-center">
                  부채 및 자본총계(총자산)
                </td>
                <td class="text-right">
                  {{ total[years[0]].finance[0] | commaFormat }}
                </td>
                <td class="text-right">{{ total[years[0]].finance[1] }} %</td>
                <td class="text-right">
                  {{ total[years[1]].finance[0] | commaFormat }}
                </td>
                <td class="text-right">{{ total[years[1]].finance[1] }} %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (2) 요약손익계산서
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>
                  구 분
                </th>
                <th v-for="y in years" :key="y + '년'">{{ y }}년</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="pKey in keyList(info[years[0]].profitLoss)">
                <tr :key="pKey">
                  <td class="text-center">{{ pKey }}</td>
                  <td class="text-right">
                    {{ info[years[0]].profitLoss[pKey] | commaFormat }}
                  </td>
                  <td class="text-right">
                    {{ info[years[1]].profitLoss[pKey] | commaFormat }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (3) 취급업무별 영업실적 (연간 취급액 기준)
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th rowspan="2" colspan="2">
                  구 분
                </th>
                <th v-for="y in years" :key="y + '년'" colspan="2">
                  {{ y }}년
                </th>
              </tr>
              <tr>
                <template v-for="y in years">
                  <th :key="y + '_금액'">금액</th>
                  <th :key="y + '_구성비'">구성비</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="bKey in keyList(info[years[0]].businessResults)">
                <template
                  v-for="(bb, bIdex) in keyList(
                    info[years[0]].businessResults[bKey]
                  )"
                >
                  <tr :key="years[0] + bb + bIdex + '_header'">
                    <td
                      v-if="bIdex === 0"
                      :rowspan="
                        keyList(info[years[0]].businessResults[bKey]).length
                      "
                      class="text-center"
                    >
                      {{ bKey }}
                    </td>
                    <td class="text-center">{{ bb }}</td>
                    <td class="text-right">
                      {{
                        info[years[0]].businessResults[bKey][bb][0]
                          | commaFormat
                      }}
                    </td>
                    <td class="text-right">
                      {{ info[years[0]].businessResults[bKey][bb][1] }} %
                    </td>
                    <td class="text-right">
                      {{
                        info[years[1]].businessResults[bKey][bb][0]
                          | commaFormat
                      }}
                    </td>
                    <td class="text-right">
                      {{ info[years[1]].businessResults[bKey][bb][1] }} %
                    </td>
                  </tr>
                </template>
              </template>
              <tr>
                <td colspan="2" class="text-center">
                  합계
                </td>
                <td class="text-right">
                  {{ total[years[0]].businessResults[0] | commaFormat }}
                </td>
                <td class="text-right">
                  {{ total[years[0]].businessResults[1] }} %
                </td>
                <td class="text-right">
                  {{ total[years[1]].businessResults[0] | commaFormat }}
                </td>
                <td class="text-right">
                  {{ total[years[1]].businessResults[1] }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 재무 및 경영현황',
    },
  },
  data() {
    return {
      total: {
        // 2019: {
        //   finance: [3796618463, 100],
        //   businessResults: [59546610000, 100],
        // },
        // 2020: {
        //   finance: [2252514183, 100],
        //   businessResults: [84594479224, 100],
        // },
        // 2021: {
        //   finance: [6968096661, 100],
        //   businessResults: [84594479224, 100],
        // },
        2022: {
          finance: [6884910942, 100],
          businessResults: [32631975651, 100],
        },
        2023: {
          finance: [6741833662, 100],
          businessResults: [73052480212, 100],
        },
      },
      info: {
        // 2019: {
        //   finance: {
        //     자산: {
        //       '현금 및 예치금': [2124211602, 55.96],
        //       유가증권: [0, 0],
        //       '단기대여금 및 수취채권': [1174672093, 30.94],
        //       유형자산: [69580099, 1.83],
        //       기타자산: [428154669, 11.27],
        //       자산총계: [3796618463, 100],
        //     },
        //     부채: {
        //       차입부채: [108708955, 84.46],
        //       기타부채: [20000000, 15.54],
        //       부채총계: [128708955, 100],
        //     },
        //     자본: {
        //       납입자본금: [512890500, 13.98],
        //       자본잉여금: [10005736185, 272.79],
        //       이익잉여금: [-6850717177, -186.77],
        //       자본조정: [0, 0],
        //       '기타 포괄순익누계액': [0, 0],
        //       자본총계: [3667909508, 100],
        //     },
        //   },
        //   profitLoss: {
        //     영업수익: 2367047494,
        //     영업비용: 3883128317,
        //     영업이익: -1516080823,
        //     영업외수익: 615367727,
        //     영업외비용: 20895607,
        //     법인세차감전순이익: -921608703,
        //     법인세비용: 0,
        //     당기순이익: -921608703,
        //   },
        //   businessResults: {
        //     '연계 대출 업무': {
        //       '부동산 PF': [0, 0],
        //       '부동산 담보': [0, 0],
        //       '기타 담보(어음, 매출채권제외)': [10274000000, 17.25],
        //       '어음, 매출채권 담보': [0, 0],
        //       '개인사업자 신용': [42380660000, 71.17],
        //       '법인 신용': [6891950000, 11.57],
        //       소계: [59546610000, 100],
        //     },
        //     '기타 업무': {
        //       경영업무: [0, 0],
        //       부수업무: [0, 0],
        //       소계: [0, 0],
        //     },
        //   },
        // },
        // 2020: {
        //   finance: {
        //     자산: {
        //       '현금 및 예치금': [371363403, 16.48],
        //       // 유가증권: [0, 0],
        //       // '단기대여금 및 수취채권': [1510114863, 67.04],
        //       연계대출채권: [1510114863, 67.04],
        //       유형자산: [52960853, 2.35],
        //       기타자산: [318075064, 14.13],
        //       자산총계: [2252514183, 100],
        //     },
        //     부채: {
        //       // 차입부채: [92496439, 82.22],
        //       연계대출채무: [92496439, 82.22],
        //       기타부채: [20000000, 17.78],
        //       부채총계: [112496439, 100],
        //     },
        //     자본: {
        //       납입자본금: [544219500, 25.43],
        //       자본잉여금: [12271821089, 573.44],
        //       이익잉여금: [-10676022845, -498.87],
        //       // 자본조정: [0, 0],
        //       // '기타 포괄순익누계액': [0, 0],
        //       자본총계: [2140017744, 100],
        //     },
        //   },
        //   profitLoss: {
        //     영업수익: 975718595,
        //     영업비용: 4765477117,
        //     영업이익: -3789758522,
        //     영업외수익: 138651292,
        //     영업외비용: 174198438,
        //     // 법인세차감전순이익: -3825305668,
        //     // 법인세비용: 0,
        //     당기순이익: -3825305668,
        //   },
        //   businessResults: {
        //     '연계 대출 업무': {
        //       '부동산 PF': [0, 0],
        //       '부동산 담보': [0, 0],
        //       '기타 담보(어음, 매출채권제외)': [602000000, 0.71],
        //       '어음, 매출채권 담보': [0, 0],
        //       '개인사업자 신용': [82521919224, 97.55],
        //       '법인 신용': [1470560000, 1.74],
        //       소계: [84594479224, 100],
        //     },
        //     '기타 업무': {
        //       경영업무: [0, 0],
        //       부수업무: [0, 0],
        //       소계: [0, 0],
        //     },
        //   },
        // },
        // 2021: {
        //   finance: {
        //     자산: {
        //       '현금 및 예치금': [257812174, 3.7],
        //       연계대출채권: [6388643383, 91.7],
        //       유형자산: [25859691, 0.4],
        //       기타자산: [295781413, 4.2],
        //       자산총계: [6968096661, 100],
        //     },
        //     부채: {
        //       연계대출채무: [6258415403, 94.0],
        //       기타부채: [401728083, 6.0],
        //       부채총계: [6660143486, 100],
        //     },
        //     자본: {
        //       납입자본금: [553754000, 179.8],
        //       자본잉여금: [12960609881, 4208.6],
        //       이익잉여금: [13206410706, -4288.4],
        //       자본총계: [307953175, 100],
        //     },
        //   },
        //   profitLoss: {
        //     영업수익: 431706654,
        //     영업비용: 2943970485,
        //     영업이익: -2512263831,
        //     영업외수익: 255741189,
        //     영업외비용: 273865219,
        //     당기순이익: -2530387861,
        //   },
        //   businessResults: {
        //     '연계 대출 업무': {
        //       '부동산 PF': [0, 0],
        //       '부동산 담보': [0, 0.23],
        //       '기타 담보(어음, 매출채권제외)': [0, 0],
        //       '어음, 매출채권 담보': [0, 0],
        //       '개인사업자 신용': [79374329681, 100],
        //       '법인 신용': [0, 0],
        //       소계: [79374329681, 100],
        //     },
        //     '기타 업무': {
        //       경영업무: [0, 0],
        //       부수업무: [0, 0],
        //       소계: [0, 0],
        //     },
        //   },
        // },
        2022: {
          finance: {
            자산: {
              '현금 및 예치금': [486099178, 7.06],
              연계대출채권: [6012082399, 87.32],
              유형자산: [361863628, 5.26],
              기타자산: [24865737, 0.36],
              자산총계: [6884910942, 100],
            },
            부채: {
              연계대출채무: [5887760417, 91.12],
              기타부채: [574090745, 8.88],
              부채총계: [6461851162, 100],
            },
            자본: {
              납입자본금: [564582000, 133.46],
              자본잉여금: [13743117089, 3248.50],
              이익잉여금: [-13884639309, -3281.96],
              자본총계: [423059780, 100],
            },
          },
          profitLoss: {
            영업수익: 1235213063,
            영업비용: 1986284403,
            영업이익: -751071340,
            영업외수익: 713540477,
            영업외비용: 640697740,
            당기순이익: -678228603,
          },
          businessResults: {
            '연계 대출 업무': {
              '부동산 PF': [0, 0],
              '부동산 담보': [20000000, 0.0613],
              '기타 담보(어음, 매출채권제외)': [0, 0],
              '어음, 매출채권 담보': [0, 0],
              '개인사업자 신용': [32611975651, 99.9387],
              '법인 신용': [0, 0],
              소계: [32631975651, 100],
            },
            '기타 업무': {
              경영업무: [0, 0],
              부수업무: [0, 0],
              소계: [0, 0],
            },
          },
        },
        2023: {
          finance: {
            자산: {
              '현금 및 예치금': [109127990, 1.62],
              연계대출채권: [5367016638, 79.61],
              유형자산: [9671388, 0.14],
              기타자산: [1256017646, 18.63],
              자산총계: [6741833662, 100],
            },
            부채: {
              연계대출채무: [5271764913, 89.60],
              기타부채: [612021928, 10.40],
              부채총계: [5883786841, 100],
            },
            자본: {
              납입자본금: [564582000, 65.80],
              자본잉여금: [13743117089, 1601.67],
              이익잉여금: [-13449652268, -1567.47],
              자본총계: [858046821, 100],
            },
          },
          profitLoss: {
            영업수익: 836094399,
            영업비용: 1346206132,
            영업이익: -510111733,
            영업외수익: 1410573978,
            영업외비용: 465475204,
            당기순이익: -434987041,
          },
          businessResults: {
            '연계 대출 업무': {
              '부동산 PF': [0, 0],
              '부동산 담보': [0, 0],
              '기타 담보(어음, 매출채권제외)': [0, 0],
              '어음, 매출채권 담보': [0, 0],
              '개인사업자 신용': [73052480212, 100],
              '법인 신용': [0, 0],
              소계: [73052480212, 100],
            },
            '기타 업무': {
              경영업무: [0, 0],
              부수업무: [0, 0],
              소계: [0, 0],
            },
          },
        },
      },
    }
  },
  computed: {
    years() {
      let temp = this.keyList(this.info)
      // temp = temp.sort().reverse()
      temp = temp.sort((a, b) => (a > b ? -1 : 1))
      return temp
    },
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
