import $store from '@/store'
import { simpleClone } from '@/util'
import { AuthTypes } from '@/store/types/auth'
import qs from 'query-string'

export default async (to, from, next) => {
  const accessToken = 'accessToken'
  const refreshToken = 'refreshToken'
  const q = simpleClone(to.query)
  if (q[accessToken] && q[refreshToken]) {
    await $store.dispatch(`auth/${AuthTypes.actions.SIGN_IN}`, {
      access_token: q[accessToken],
      refresh_token: q[refreshToken],
    })
    delete q[accessToken]
    delete q[refreshToken]
    location.replace(`${to.path}?${qs.stringify(q)}`)
    return
  }
  next()
}
