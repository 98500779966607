<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    window.opener.nidCallback(this.$route.query.token)
    window.close()
  },
}
</script>
