<template>
  <v-progress-circular
    v-if="isLoading === true"
    class="mx-2"
    :size="loadingSize"
    :width="loadingWidth"
    indeterminate
  >
  </v-progress-circular>
  <span v-else>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingSize: {
      default: 16,
    },
    loadingWidth: {
      default: 2,
    },
  },
}
</script>
