import { numberFormat, removeComma } from '@/util'
import Vue from 'vue'

const InputInvest = {
  common: {
    getObj(el, binding, vnode) {
      let obj = vnode.context
      let index = el.dataset.index
      let arg = binding.arg

      return obj[arg][index]
    },
    setObjValue(el, binding, vnode, value) {
      let obj = InputInvest.common.getObj(el, binding, vnode)
      let path = binding.expression
      let arr = path.split('.')

      if (arr.length > 1) {
        arr = arr.slice(1)
      }

      let column = arr[0]

      obj[column] = value
    },
    getObjValue(el, binding, vnode) {
      let obj = InputInvest.common.getObj(el, binding, vnode)
      let path = binding.expression
      let arr = path.split('.')

      if (arr.length > 1) {
        arr = arr.slice(1)
      }

      let column = arr[0]

      // arr.forEach((v, i) => {
      //   if (Object.prototype.hasOwnProperty.call(obj, v)) {
      //     obj = InputInvest.common.getObjValue(obj[v], arr.slice(i + 1))
      //   }
      // })

      return obj[column]
    },
    getMaxValue(el) {
      return parseInt(el.getAttribute('max'), 10)
    },
  },
  bind(el, binding, vnode) {
    el.value = InputInvest.common.getObjValue(el, binding, vnode)
    el.addEventListener('input', (e) => {
      e.target.value = removeComma(e.target.value)
      InputInvest.inputHandler(el, binding, vnode, e.target.value)
    })
  },
  update(el, binding, vnode) {
    let obj = InputInvest.common.getObj(el, binding, vnode)
    let maxValue = InputInvest.common.getMaxValue(el, binding, vnode)
    let v = InputInvest.common.getObjValue(el, binding, vnode)

    if (!isNaN(maxValue) && maxValue < v) {
      obj.isErr = true

      // global.$vm.$swal.basic.alert(
      //   `최대 ${commaFormat(maxValue)}원까지 입력할 수 있습니다`
      // )
      // eslint-disable-next-line
      // InputInvest.common.setObjValue(el, binding, vnode, parseInt(maxValue, 10))
      // InputInvest.update(el, binding, vnode)
      // return
    } else {
      obj.isErr = false
    }

    v = v === null ? NaN : v
    el.value = isNaN(v) ? null : numberFormat(v)
  },
  inputHandler(el, binding, vnode, eventValue) {
    // eslint-disable-next-line
    if (InputInvest.common.getObjValue(el, binding, vnode) === eventValue) {
      InputInvest.update(el, binding, vnode)
      return
    }

    InputInvest.common.setObjValue(el, binding, vnode, eventValue)
  },
}

Vue.directive('input-invest', InputInvest)
