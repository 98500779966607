<template>
  <div style="background-color: white">
    <section id="hero_2" class="invest-pased-wrap main-300">
      <div class="intro_title animated fadeInDown">
        <h1>마이펀다</h1>
      </div>
    </section>

    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-black line-height--27">
              {{ fNum }} 호 {{ fundingDataTitle }} 리포트
            </p>
          </v-col>
        </v-row>
        <hr />

        <v-row class="pt-8" no-gutters>
          <v-col cols="12">
            <p class="font-size--18 font-weight-bold">상환 내역</p>
          </v-col>

          <v-col class="table-col" cols="12">
            <div class="data-table-wrapper">
              <table class="width-100">
                <thead class="rpy">
                  <tr>
                    <th width="8%" class="sticky-v-col">회차</th>
                    <th style="min-width: 110px">상환일</th>
                    <th width="20%" style="min-width: 100px">원금</th>
                    <th style="min-width: 100px">이자<br />(세전)</th>
                    <th style="min-width: 90px">세금</th>
                    <th style="min-width: 100px">이자<br />(세후)</th>
                    <th style="min-width: 90px">수수료</th>
                  </tr>
                </thead>
                <template v-if="!!rpyInfo">
                  <tbody>
                    <tr v-for="(rpy, index) in rpyInfo" :key="rpy.date">
                      <td class="sticky-v-col">{{ index + 1 }} 차</td>
                      <td>{{ rpy.date }}</td>
                      <td>{{ rpy.principal | commaFormat }} 원</td>
                      <td>{{ rpy.interest | commaFormat }} 원</td>
                      <td>{{ rpy.tax | commaFormat }} 원</td>
                      <td>{{ (rpy.interest - rpy.tax) | commaFormat }} 원</td>
                      <td>{{ rpy.fee | commaFormat }} 원</td>
                    </tr>
                  </tbody>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="7">상환 내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row class="pt-8" no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="6">
                <p class="font-size--18 font-weight-bold">투자 내역</p>
              </v-col>
              <v-col cols="6" class="text-right">
                <template v-if="majungmulName === 'RUBYSTONE'">
                  <a
                    class="btn-funda-basic font-weight-black"
                    target="_blank"
                    href="//cdn.funda.kr/funding/custom/5838/20200901_funding_list.pdf"
                  >
                    투자상품보기
                  </a>
                </template>
              </v-col>
            </v-row>

            <v-row class="mb-4" no-gutters>
              <v-col cols="12" sm="12" md="4" class="summary__col">
                <v-row class="d-flex align-center" no-gutters>
                  <v-col cols="6">
                    <p class="summary__title">투자원금</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="summary__amt">
                      {{ (investmentAmount / 10000) | commaFormat }}
                      만원
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4" class="summary__col">
                <v-row class="d-flex align-center" no-gutters>
                  <v-col cols="6">
                    <p class="summary__title">세전 이자(예상)</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="summary__amt">
                      {{ parseInt(totalInterest) | commaFormat }}
                      원
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4" class="summary__col">
                <v-row class="d-flex align-center" no-gutters>
                  <v-col cols="6">
                    <p class="summary__title white-space--nowrap">
                      평균 수익률
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="summary__amt">
                      <span>연 </span>
                      {{ showTotalRate }} %
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="table-col" cols="12">
                <div class="data-table-wrapper">
                  <table class="width-100">
                    <thead>
                      <tr>
                        <th
                          width="15%"
                          class="sticky-v-col"
                          style="min-width: 110px"
                        >
                          투자일
                        </th>
                        <th style="min-width: 50px">채권수</th>
                        <th style="min-width: 100px">일별<br />투자금</th>
                        <th style="min-width: 100px">이자<br />(세전)</th>
                        <th style="min-width: 90px">세금<br />(예상)</th>
                        <th style="min-width: 50px">일별<br />수익률</th>
                      </tr>
                    </thead>
                    <template v-if="!!investInfo">
                      <tbody>
                        <tr v-for="inv in investInfo" :key="inv.date">
                          <td class="sticky-v-col">{{ inv.date }}</td>
                          <td>{{ inv.num_of_invest | commaFormat }}</td>
                          <td>
                            {{ parseInt(inv.total_invest) | commaFormat }} 원
                          </td>
                          <td>
                            {{
                              parseInt(inv.calculated_interest) | commaFormat
                            }}
                            원
                          </td>
                          <td>{{ parseInt(inv.tax) | commaFormat }} 원</td>
                          <td>{{ parseFloat(inv.rate * 100).toFixed(2) }} %</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="sticky-v-col">합계/평균</td>
                          <td colspan="2"></td>
                          <td>
                            {{ parseInt(totalInterest) | commaFormat }} 원
                          </td>
                          <td>{{ parseInt(totalTax) | commaFormat }} 원</td>
                          <td>{{ showTotalRate }} %</td>
                        </tr>
                      </tfoot>
                    </template>
                    <template v-else>
                      <tbody>
                        <tr>
                          <td colspan="7">상환 내역이 없습니다.</td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                  <p class="sub-text">
                    * 수익률은 세전 예상 이자 기준의 연환산 수익률 입니다.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fNum: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      fundingDataTitle: null,
      majungmulName: 'VIP',
      investmentAmount: 0,
      totalInterest: 0,
      totalTax: 0,
      totalRate: 0,
      rpyInfo: null,
      investInfo: null,
    }
  },
  computed: {
    showTotalRate() {
      return parseFloat(this.totalRate * 100).toFixed(2)
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true

      let q = `data: wwwMajungmulReport(fNum: ${this.fNum})`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        let d = JSON.parse(data)

        this.fundingDataTitle = d.title
        this.majungmulName = d.majungmulName
        this.investmentAmount = d.prime_fund_my_amount
        this.totalInterest = d.total_interest
        this.totalTax = d.total_tax
        this.totalRate = d.total_rate
        this.rpyInfo = d.repayment
        this.investInfo = d.daily
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#hero_2 {
  position: relative;
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;

  &.main-300 {
    height: 300px;

    @media (max-width: 991px) {
      height: 150px;
    }

    h1 {
      font-size: 38px;
      color: #fff;
      font-weight: bold;

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }

      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  &.invest-pased-wrap {
    background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
      center;
    background-size: cover;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;
  }
}

.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .btn-funda-basic {
      padding: 5px 10px;
      border: 1px solid #1baaf1;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.1;
      border-radius: 17px;

      &:hover {
        color: black;
      }
    }

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 33px;
          width: 15px;
          height: 15px;
          right: 10px;
          background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg')
            no-repeat center;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.rpy {
            min-width: 75px;
          }
        }

        thead {
          background-color: #f9fcff;

          .sticky-v-col {
            background-color: #f9fcff !important;
          }

          &.rpy {
            background-color: unset;
            border-bottom: 1px solid #e7edf6;

            .sticky-v-col {
              background-color: white !important;
            }
          }

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;
          }
        }

        tbody {
          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #333333;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #333333;
            font-weight: 700;
            text-align: center;
          }
        }
      }

      .sub-text {
        margin: 0;
        margin-top: 10px;
        color: #8a94a2;
        font-size: 12px;
        text-align: right;
      }
    }
  }
}

.summary {
  &__col {
    &:not(:last-child) {
      padding-right: 40px;

      @media screen and (max-width: 1024px) {
        padding-right: 20px;
      }

      @media screen and (max-width: 959px) {
        padding-right: 0;
      }
    }
  }

  &__title {
    margin: 10px 0;
    color: #8a94a2;
    font-size: 16px;
  }

  &__amt {
    margin: 10px 0;
    color: #333333;
    font-size: 18px;
    font-weight: 900;
    text-align: right;

    span {
      color: #8a94a2;
      font-size: 15px;
    }
  }
}
</style>
