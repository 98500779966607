<template>
  <div>
    <p v-if="false" class="ma-0">
      <v-icon small>mdi-information-outline</v-icon>
      투자 튜토리얼
    </p>
    <div
      class="custom-popup welcome-popup"
      :class="{ show: showPopup }"
      @click="showPopup = false"
    >
      <div
        class="custom-popup__contents color--body"
        @click.stop="showPopup = true"
      >
        <div class="popup-body">
          <picture>
            <img :src="`${cdnPath}${headerImg}`" />
            <img :src="imgSrc" />
          </picture>
        </div>
        <div class="popup-footer">
          <v-row no-gutters>
            <v-col cols="12" class="footer-btn-area">
              <v-btn
                class="btn-funda-basic footer-btn"
                elevation="0"
                height="55px"
                color="primary"
                @click.stop="prevStep()"
                text
              >
                {{ carousel === 0 ? '닫기' : '&lt; 이전' }}
              </v-btn>

              <v-btn
                class="btn-funda-basic footer-btn"
                :class="{ 'tutorial-end': isMaxItem }"
                elevation="0"
                height="55px"
                color="primary"
                @click.stop="nextStep()"
                text
              >
                {{ isMaxItem ? '투자 시작하기' : '다음 >' }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  data() {
    return {
      cdnPath: '//cdn.funda.kr/funding/common/image/_tutorials/',
      isFirstInvest: false,
      showPopup: false,
      carousel: 0,
      headerImg: 'invest/invest-tutorial-header.png',
      items: [
        'invest-tutorial-1.png',
        'invest-tutorial-2.png',
        'invest-tutorial-3.png',
        'invest-tutorial-4.png',
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    imgSrc() {
      return `${this.cdnPath}invest/${this.items[this.carousel]}`
    },
    isMaxItem() {
      return this.carousel === this.items.length - 1
    },
    isOK() {
      const affiliate = this.profile?.affiliateCode
      return affiliate === 'OK시럽'
    },
  },
  watch: {
    isAuth: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.getLastInvest()
        }
      },
    },
    isFirstInvest: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.showPopup = true
        }
      },
    },
  },
  methods: {
    prevStep() {
      if (this.carousel === 0) {
        this.showPopup = false
      }
      const min = 0
      let temp = this.carousel
      temp--
      this.carousel = Math.max(min, temp)
    },
    nextStep() {
      if (this.isMaxItem) {
        this.showPopup = false
      }
      const max = this.items.length - 1
      let temp = this.carousel
      temp++
      this.carousel = Math.min(max, temp)
    },
    async getLastInvest() {
      if (this.isOK === false) {
        this.isFirstInvest = false
        return
      }

      const q = `
        data: getLastInvestment(userIdx: ${this.userIdx}) {
          fundingDataIdx
          investmentAmount
          pointAmount
          datetime
        }
      `
      const data = await this.$fundaApi.query(gql`{${q}}`)
      this.isFirstInvest = data === null
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  height: 100%;
}

.custom-popup.show > .custom-popup__contents {
  position: fixed;
  top: 80px;
  animation: moveDown 0.7s;

  overflow-y: hidden;
}

@keyframes moveDown {
  0% {
    top: 30px;
  }
  100% {
    top: 80px;
  }
}

.custom-popup > .custom-popup__contents {
  max-width: 90%;
  width: 390px !important;
}

.custom-popup .popup-body {
  max-height: 75vh;
  overflow-y: auto;
}

.custom-popup .popup-footer {
  display: flex;
  justify-content: space-between;

  .footer-btn-area {
    display: flex;
    justify-content: space-between;

    .footer-btn {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.custom-popup .popup-footer .popup-close-btn {
  width: 60%;
  padding: 10px 0;
  background-color: #1baaf1;
  border-radius: 4px;
}

@media screen and (max-height: 800px) {
  .custom-popup.show > .custom-popup__contents {
    top: 40px;
  }

  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 40px;
    }
  }
}
</style>
