/* eslint-disable no-async-promise-executor */
import store from '@/store'
import { fundaApi } from '@/plugins/api/fundaApi'
import { AuthTypes } from '../../store/types/auth'
import { SignUpFailedManyTimesException } from '@/error'

const TOKEN_NAME = process.env.common.token.name
const REFRESH_TOKEN_NAME = process.env.common.token.refreshTokenName
const SIGN_UP_FAILED_COUNT_NAME = 'sign-up-failed-count'
const SIGN_UP_FAILED_AT_NAME = 'sign-up-failed-at'
const MAXIMUM_ALLOWED_SIGN_UP_FAILED_COUNT = 5
const FAILED_TIME_MINUTES = 30
const REST_SIGN_UP_FAILED_COUNT_AFTER = FAILED_TIME_MINUTES * 60 * 1000 // 30 minutes

function getRedirectUri(to) {
  let uri
  if (to) {
    uri = to.fullPath
  } else {
    uri = location.pathname + location.search
  }
  return uri
}

const getToken = () => {
  if (localStorage.getItem(TOKEN_NAME) === 'undefined') {
    localStorage.clear()
  }

  return {
    access_token: localStorage.getItem(TOKEN_NAME),
    refresh_token: localStorage.getItem(REFRESH_TOKEN_NAME),
  }
}

function getItemName(userId) {
  return 'sign-up-failed-' + userId
}

function getSignUpObj(userId) {
  const itemName = getItemName(userId)
  let obj = localStorage.getItem(itemName)
  // eslint-disable-next-line
  return !!obj ? JSON.parse(obj) : { [SIGN_UP_FAILED_AT_NAME]: new Date(), [SIGN_UP_FAILED_COUNT_NAME]: 0, }
}

function setSignUpObj(userId, signUpObj) {
  const itemName = getItemName(userId)
  localStorage.setItem(itemName, JSON.stringify(signUpObj))
}

function getSignUpFailedCount(userId) {
  let signUpObj = getSignUpObj(userId)
  let count = signUpObj[SIGN_UP_FAILED_COUNT_NAME]

  if (count >= MAXIMUM_ALLOWED_SIGN_UP_FAILED_COUNT) {
    let failedAt = new Date(signUpObj[SIGN_UP_FAILED_AT_NAME])
    let elapsed = new Date() - failedAt
    if (elapsed > REST_SIGN_UP_FAILED_COUNT_AFTER) {
      count = resetSignUpFailedCount(userId)
    }
  }

  return count
}

function increaseSignUpFailedCount(userId) {
  let signUpObj = getSignUpObj(userId)
  let count = getSignUpFailedCount(userId)
  count++

  signUpObj[SIGN_UP_FAILED_COUNT_NAME] = count
  signUpObj[SIGN_UP_FAILED_AT_NAME] = new Date()

  setSignUpObj(userId, signUpObj)

  return count
}

function resetSignUpFailedCount(userId) {
  let signUpObj = getSignUpObj(userId)
  signUpObj[SIGN_UP_FAILED_COUNT_NAME] = 0
  setSignUpObj(userId, signUpObj)
  return signUpObj[SIGN_UP_FAILED_COUNT_NAME]
}

function signout() {
  store.dispatch(`auth/${AuthTypes.mutations.SIGN_OUT}`)
}

// 성공시에 호출
async function signinSuccess(token) {
  await store.dispatch(`auth/${AuthTypes.actions.SIGN_IN}`, token)
  // expireTokenProcess()
  return token
}

function sync() {
  return new Promise(async (resolve, reject) => {
    if (refreshLoading) {
      resolves.push(resolve)
    } else if (
      refreshLoading === false &&
      store.state.auth.info != null &&
      authService.isTokenExpire()
    ) {
      refreshLoading = true
      resolves.push(resolve)
      try {
        if (authService.isRefreshTokenExpire()) {
          signout()
        } else {
          await authService.refresh()
        }
      } catch (e) {
        signout()
        throw e
      } finally {
        while (resolves.length > 0) {
          const r = resolves.pop()
          r(getToken())
        }
        refreshLoading = false
      }
    } else {
      resolve(getToken().token)
    }
  })
}

// 자동 로그아웃
// let autoSignoutTimeout = null
// function expireTokenProcess() {
//   clearTimeout(autoSignoutTimeout)
//   // 자동 타임아웃 시간
//   const autoExpTime =
//     store.getters['auth/getRefreshTokenExpireTime'] - Date.now()
//   autoSignoutTimeout = setTimeout(_ => {
//     authService.signout()
//     global.$vm._router.push({
//       path: '/signout/auto',
//       query: { redirectUri: getRedirectUri() }
//     })
//   }, autoExpTime)
// }

// function getHttpBasicAuth() {
//   return btoa(`${client.id}:${client.secret}`)
// }

let refreshLoading = false
const resolves = [] // 토큰 갱신시 블럭걸린 resolve
const authService = {
  isSignin() {
    return store.getters['auth/isSignin']
  },
  isTokenExpire() {
    return store.getters['auth/getTokenExpireTime'] < Date.now()
  },
  isRefreshTokenExpire() {
    return store.getters['auth/getRefreshTokenExpireTime'] < Date.now()
  },
  getToken,
  hasAnyRole(roles) {
    return store.getters['auth/hasAnyRole'](roles)
  },
  signin(user) {
    return new Promise(async (resolve, reject) => {
      let signUpFailedCount = getSignUpFailedCount(user.username)
      if (signUpFailedCount >= MAXIMUM_ALLOWED_SIGN_UP_FAILED_COUNT) {
        let error = new SignUpFailedManyTimesException(
          `로그인 실패 ${MAXIMUM_ALLOWED_SIGN_UP_FAILED_COUNT}회 이상 (${FAILED_TIME_MINUTES}분간 로그인이 제한됩니다)`
        )
        reject(error)
        throw error
      }
      try {
        const data = await fundaApi.signin(user)
        resetSignUpFailedCount(user.username)
        await signinSuccess(data)
        resolve(data)
      } catch (e) {
        if (e.status >= 400 && e.status < 500) {
          increaseSignUpFailedCount(user.username)
          signUpFailedCount = getSignUpFailedCount(user.username)
          e.signUpFailedCount = signUpFailedCount
          e.maximumAllowedCount = MAXIMUM_ALLOWED_SIGN_UP_FAILED_COUNT
          e.failedTime = FAILED_TIME_MINUTES
        }
        reject(e)
        throw e
      }
    })
  },
  async refresh() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await fundaApi.signin({
          scope: 'default',
          grant_type: 'refresh_token',
          refresh_token: getToken().refresh_token,
        })
        signinSuccess(data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  signout,
  sync,
  getRedirectUri,
  async init() {
    // 초기값 세팅
    if (getToken().refresh_token != null) {
      await store.dispatch(`auth/${AuthTypes.mutations.SIGN_IN}`, getToken())
      if (authService.isRefreshTokenExpire()) {
        signout()
      } else {
        // expireTokenProcess()
        await sync()
      }
    }
  },
}

export default authService
