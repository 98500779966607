import Vue from 'vue'
import pluginValue from './api'
const pluginName = '$fundaApi'

const plugin = {
  install(Vue) {
    window[pluginName] = pluginValue
    Vue.prototype[pluginName] = pluginValue
  }
}

Vue.use(plugin)

export default plugin

export const fundaApi = pluginValue
