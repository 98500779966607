<template>
  <div>
    <v-row class="mb-7" no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>

      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          펀다는 차입자의 카드매출로 발생한 매출금액을 연계된 BNK경남은행 펌뱅킹
          서비스를 통해 매 영업일마다 상환 받아, 투자자에게 다음 영업일에 상환을
          진행합니다.
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '상환방식에 관한 사항',
    },
  },
}
</script>

<style lang="scss" scoped></style>
