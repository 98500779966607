<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th class="text-center">내용</th>
                <th class="text-center">수수료율 (정액일 경우 금액)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">연계투자관련</td>
                <td class="text-center">연계투자 중개 수수료</td>
                <td class="text-center">2.5 % 이내</td>
              </tr>

              <tr>
                <td class="text-center">연계투자관련</td>
                <td class="text-center">채권추심 수수료</td>
                <td class="text-center">5 % ~ 30 % 기간에 따라 차등 부과</td>
              </tr>

              <tr>
                <td class="text-center">연계대출관련</td>
                <td class="text-center">연계대출 중개 수수료</td>
                <td class="text-center">2.1 % ~ 6.4 %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '수수료 등 부대비용에 관한 사항',
    },
  },
}
</script>

<style lang="scss" scoped></style>
