export const TimeTypes = {
  getters: {
    GET_KR_TIME_DIFF: 'GET_KR_TIME_DIFF',
  },
  actions: {
    GET_KR_DATE: 'GET_KR_DATE',
    GET_IS_INSPECT_TIME: 'GET_IS_INSPECT_TIME',
    GET_CANNOT_INVEST_TIME: 'GET_CANNOT_INVEST_TIME',
  },
}
