import store from '@/store'
import authService from './auth/AuthService'

const init = async () => {
  // 최초 계정상태 체크
  try {
    await authService.init()
  } finally {
    await store.dispatch('system/setInit', true)
  }
}

init()
