import AuthService from '@/service/auth/AuthService'

export default [
  {
    name: 'signup',
    path: '/signup',
    meta: { layout: 'LegacyLayoutFluid' },
    component: require('@/pages/signup/SignupRegister.vue').default,
  },
  {
    name: 'signupChannel',
    path: '/signup/channel',
    meta: { layout: 'LegacyLayoutFluid' },
    component: require('@/pages/signup/channel/Index.vue').default,
  },
  {
    name: 'signupChannelRegister',
    path: '/signup/channel/register',
    meta: { layout: 'LegacyLayoutFluid' },
    component: require('@/pages/signup/channel/Register.vue').default,
  },
  {
    name: 'signupSteps',
    path: '/signup/step',
    meta: { layout: 'LegacyLayoutFluid' },
    component: require('@/pages/signup/SignupSteps.vue').default,
    beforeEnter(to, from, next) {
      if (AuthService.isSignin() === false) {
        next({ name: 'signup' })
        return
      }
      next()
    },
  },
]
