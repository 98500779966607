<template>
  <div
    v-if="isLoading"
    class="d-flex justify-center align-center"
    style="min-height: 100px"
  >
    <MyLoader />
  </div>
  <div
    v-else
    style="min-height: 100px"
    :class="{ 'd-flex justify-center align-center': overdueDetails === null }"
  >
    <template v-if="!!overdueDetails">
      <template v-if="isMobile">
        <v-row class="margin-b-5" no-gutters>
          <v-col cols="4" sm="5" md="5" lg="5">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.principal }}
              <Tooltip :text="overdueTooltip" size="16px" alignment="bottom" />
            </p>
          </v-col>
          <v-col cols="8" sm="7" md="7" lg="7" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueDetails.overdueAmount | commaFormat }}
              </span>
              원
            </p>
          </v-col>
        </v-row>

        <v-row class="margin-b-5" no-gutters>
          <v-col cols="4" sm="4" md="4" lg="4">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.rate }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueRate | rateFormat }}
              </span>
              %
            </p>
          </v-col>
        </v-row>

        <v-row no-gutters style="margin-bottom: 2px;">
          <v-col cols="4" sm="4" md="4">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.countFunding }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueDetails.countOverdueFunding | commaFormat }}
              </span>
              건
            </p>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4" lg="4">
            <p class="invest-details__key color--gray-2 ma-0 pl-1">
              ㄴ{{ title.countStore }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueDetails.countOverdueStore | commaFormat }}
              </span>
              건
            </p>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="margin-b-5" no-gutters>
          <v-col cols="4" sm="5" md="4" lg="5">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.principal }}
              <Tooltip :text="overdueTooltip" size="16px" alignment="bottom" />
            </p>
          </v-col>
          <v-col cols="8" sm="7" md="8" lg="7" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueDetails.overdueAmount | commaFormat }}
              </span>
              원
            </p>
          </v-col>
        </v-row>

        <v-row class="margin-b-5" no-gutters>
          <v-col cols="4" sm="4" md="4" lg="4">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.rate }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueRate | rateFormat }}
              </span>
              %
            </p>
          </v-col>
        </v-row>

        <v-row no-gutters style="margin-bottom: 2px;">
          <v-col cols="4" sm="4" md="4" lg="4">
            <p class="invest-details__key ma-0 color--gray-2">
              {{ title.countFunding }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ userCnt.cntOverdue | commaFormat }}
              </span>
              건
            </p>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4" lg="4">
            <p class="invest-details__key color--gray-2 ma-0 pl-1">
              ㄴ{{ title.countStore }}
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <p class="invest-details__val ma-0 color--black">
              <span class="font-weight-bold color--black">
                {{ overdueDetails.countOverdueStore | commaFormat }}
              </span>
              건
            </p>
          </v-col>
        </v-row>
      </template>
    </template>
    <p v-else class="color--gray-1 ma-0">
      투자내역 중 연체채권이 존재하지 않습니다.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import MyLoader from '@/components/MyLoader.vue'
import Tooltip from '@/components/common/Tooltip.vue'

export default {
  components: {
    Tooltip,
    MyLoader,
  },
  data() {
    return {
      isLoading: false,
      title: {
        principal: '연체원금',
        rate: '연체채권비율',
        countFunding: '연체 채권수',
        countStore: '연체 상점수',
      },
      overdueTooltip: '상환 예정 원금 중, 연체가 발생한 원금입니다.',
      overdueDetails: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    ...mapGetters('investment', {
      userCnt: InvestmentTypes.getters.GET_USER_CNT,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    overdueRate() {
      return this.userCnt?.cntOverdue / this.userCnt?.cntTotal
    },
  },
  watch: {
    userIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getOverdueDetails()
        }
      },
    },
  },
  methods: {
    async getOverdueDetails() {
      if (this.isLoading === true) {
        return
      }
      this.isLoading = true

      const q = `
        data: getMyOverdueDetails(userIdx: ${this.userIdx}) {
          overdueAmount
          countOverdueFunding
          countOverdueStore
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.overdueDetails = data
      } catch (e) {
        this.overdueDetails = 0
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
