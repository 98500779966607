<template>
  <v-row
    v-if="isMobile"
    class="invest-details__row mobile padding-t-15"
    no-gutters
  >
    <v-col cols="12">
      <MyOverdue />

      <hr class="hr-solid" />
    </v-col>
    <v-col cols="12">
      <MyInsolvent />
    </v-col>
  </v-row>
  <v-row v-else class="invest-details__row" no-gutters>
    <v-col md="3" lg="2">
      <p
        class="invest-details__sub-title ma-0 font-weight-bold font-size--15 color--black"
      >
        연체/부실
      </p>
    </v-col>

    <v-col md="5" lg="5">
      <MyOverdue />
    </v-col>

    <v-col md="4" lg="5">
      <MyInsolvent />
    </v-col>
  </v-row>
</template>

<script>
import MyOverdue from './MyOverdue.vue'
import MyInsolvent from './MyInsolvent.vue'

export default {
  components: {
    MyOverdue,
    MyInsolvent,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
.invest-details__row ::v-deep {
  .invest-details {
    &__key,
    &__val {
      line-height: 1.3;
    }
  }
  @media screen and (min-width: 1200px) {
    > div:nth-child(2) {
      padding-right: 40px;
    }
    > div:nth-child(3) {
      padding-left: 40px;
    }
  }
  &.mobile {
    .invest-details {
      &__key {
        font-size: 15px;
        white-space: nowrap;
      }

      &__val {
        font-size: 17px;
      }
    }
  }

  &:not(.mobile) {
    .invest-details {
      &__key {
        font-size: 14px;
        white-space: nowrap;
      }

      &__val {
        font-size: 16px;
      }
    }
  }
}
</style>
