<template>
  <div></div>
</template>

<script>
export default {
  props: {
    switchName: {
      name: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  created() {
    this.getOffComponent()
  },
  methods: {
    async getOffComponent() {
      let result = false
      if (this.isLoading === true) {
        return result
      }

      this.isLoading = true

      let q = `
        data: getOffSwitchComponent(name: "${this.switchName}") {
          name
          alarmDays
          isHtml
          contents
          offStartTime
          offEndTime
          
          isOff
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data) {
          if (data.isHtml === true) {
            this.$swal.basic.custom({
              confirmButtonText: '확인',
              customClass: {
                container: 'custom-alert__container',
                icon: 'custom-alert__icon',
                popup: 'custom-alert__popup switch-alert__popup',
                content: 'custom-alert__content',
                confirmButton: 'custom-alert__ok-btn',
              },
              html: data.contents,
            })
          } else {
            this.$swal.basic.alert(data.contents)
          }
          result = data
        }
      } finally {
        this.isLoading = false
      }

      this.$emit('update:switching', result)
      return result
    },
  },
}
</script>

<style>
.custom-alert__popup.switch-alert__popup {
  max-width: 350px !important;
  width: 95% !important;
  padding: 20px 5px;
}

.custom-alert__popup.switch-alert__popup ul {
  padding: 0;
  list-style: none;
  text-align: left;
}

.custom-alert__popup.switch-alert__popup ul li {
  margin-bottom: 10px;
}

.custom-alert__popup.switch-alert__popup ul li p {
  font-size: 15px;
}

.custom-alert__popup.switch-alert__popup p {
  line-height: 1.3;
}
</style>
