<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (1) 연체율
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th
                  v-for="h in header"
                  :key="'header_' + h.text"
                  class="text-center"
                >
                  {{ h.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in keys" :key="k.text">
                <td class="text-center">{{ k.text }}</td>
                <td
                  v-for="h in header"
                  :key="k.text + '_' + h.text"
                  class="text-right"
                >
                  <template
                    v-if="h.value === '평균금리' || h.value === '연체율'"
                  >
                    {{ info[k.value][h.value] | rateFormat }} %
                  </template>
                  <template v-else>
                    {{ info[k.value][h.value] | commaFormat }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else class="my-5 font-size--16">
            내역이 없습니다.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5" v-if="info && info.loss">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (2) 손실률
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="info && info.loss">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th
                  v-for="h in lossHeader"
                  :key="'header_' + h.text"
                  class="text-center"
                >
                  {{ h.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in lossKeys" :key="k.text">
                <td class="text-center">{{ k.text }}</td>
                <td
                  v-for="h in lossHeader"
                  :key="k.text + '_' + h.text"
                  class="text-right"
                >
                  <template
                    v-if="['연체율', '평균금리', '손실률'].includes(h.value)"
                  >
                    {{ info.loss[k.value][h.value] | rateFormat }} %
                  </template>
                  <template v-else>
                    {{ info.loss[k.value][h.value] | commaFormat }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else class="my-5 font-size--16">
            내역이 없습니다.
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 연계대출 채권 현황 및 연체율 등 연체에 관한 현황',
    },
    year: {
      required: true,
    },
    month: {
      required: true,
    },
  },
  data() {
    return {
      overdueRateAndDetailsData: {},
      keys: [
        { text: '부동산 PF', value: '부동산PF' },
        { text: '부동산담보', value: '부동산담보' },
        {
          text: '기타담보 (어음, 매출채권 담보 제외)',
          value: '기타담보(어음.매출채권담보제외)',
        },
        { text: '어음, 매출채권 담보', value: '어음.매출채권담보' },
        { text: '개인사업자 신용', value: '개인사업자신용대출' },
        { text: '법인 신용', value: '법인사업자신용대출' },
        { text: '합계', value: '합계' },
      ],
      header: [
        { text: '누적연계 대출금액', value: '누적연계대출금액' },
        { text: '연계대출 잔액', value: '연계대출잔액' },
        { text: '평균금리', value: '평균금리' },
        { text: '연체채권 잔액', value: '연체채권잔액' },
        { text: '연체율', value: '연체율' },
      ],
      info: null,
      lossKeys: [
        { text: '부동산 PF', value: '부동산PF' },
        { text: '부동산담보', value: '부동산담보' },
        {
          text: '기타담보 (어음, 매출채권 담보 제외)',
          value: '기타담보(어음.매출채권담보제외)',
        },
        { text: '어음, 매출채권 담보', value: '어음.매출채권담보' },
        { text: '개인사업자 신용', value: '개인사업자신용대출' },
        { text: '법인 신용', value: '법인사업자신용대출' },
        { text: '합계', value: '합계' },
      ],
      lossHeader: [
        { text: `${this.year}년 대출취급액`, value: '대출취급액' },
        { text: '손실확정금액', value: '손실확정금액' },
        { text: '손실률', value: '손실률' },
      ],
    }
  },
  computed: {
    padMonth() {
      let result = `${this.month}`
      result = result.padStart(2, '0')
      return result
    },
    yearHyphenMonth() {
      return `${this.year}-${this.padMonth}`
    },
  },
  watch: {
    yearHyphenMonth: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    getDefault() {
      const obj = {}
      this.keys.forEach((k) => {
        obj[k.value] = {}
        this.header.forEach((h) => {
          obj[k.value][h.value] = 0
        })
      })

      return obj
    },
    async fetchData() {
      if (!!this.overdueRateAndDetailsData[this.yearHyphenMonth]) {
        this.info = this.overdueRateAndDetailsData[this.yearHyphenMonth]
        return
      }

      if (this.isLoading) {
        return
      }

      const q = `
          data: getMonthlyNotice(category: "OVERDUE_DETAILS", yyyy: "${this.year}", mm: "${this.padMonth}") {
            yyyy
            mm
            details
          }
        `

      this.isLoading = true
      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.overdueRateAndDetailsData[this.yearHyphenMonth] = JSON.parse(
          data.details
        )
      } catch (e) {
        // this.$swal.basic.error(e.message)
        this.overdueRateAndDetailsData[this.yearHyphenMonth] = this.getDefault()
        throw e
      } finally {
        this.info = this.overdueRateAndDetailsData[this.yearHyphenMonth]
        this.isLoading = false
      }
    },
  },
}
</script>
