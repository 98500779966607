<template>
  <div v-if="!!settings && !!rawData">
    <v-row no-gutters>
      <v-col cols="12">
        <StoreSalesStackChart
          :id="id"
          :settings="settings"
          :rawData="rawData"
          :options="options"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <ul class="sales-details">
          <li class="mb-1 font-size--14">
            이 상점의 최근
            <span class="color-funda--basic font-weight-bold">
              {{ rawData.length }}
            </span>
            개월 월 평균매출은
            <span class="color-funda--basic font-weight-bold">
              {{ store.cardSalesMonthlyAverageAmount | commaFormat }}
            </span>
            원 입니다.
          </li>
          <li class="mb-1 font-size--14" v-if="isEarlyPayment === false">
            해당 차주의 대출 이용 총 한도는
            <span class="color-funda--basic font-weight-bold">
              {{ debtor.loanAmount | commaFormat }}
            </span>
            원 입니다.
          </li>
          <li
            v-if="repaymentMethodText"
            v-html="repaymentMethodText"
            class="font-size--14"
          ></li>
        </ul>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <p class="my-5 px-1 color--gray-1" style="line-height: 1.3">
      매출정보를 불러올 수 없습니다. <br />
      잠시후 다시 시도해주세요
    </p>
  </div>
</template>

<script>
import StoreSalesStackChart from '@/components/fundings/StoreSalesStackChart'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    store: {
      type: Object,
      required: true,
      validator(v) {
        return !!v && !!v.currentCardSales
      },
    },
    debtor: {
      type: Object,
      required: true,
    },
    repaymentRate: {
      default: 25,
    },
    repaymentMethod: {
      // 상환방식
      type: String,
      default: '원리금균등',
    },
    fundingType: {
      type: String,
      default: 'FUNDANOW',
    },
    fundingData: {
      type: Object
    }
  },
  data() {
    return {
      settings: null,
      rawData: null,
      options: null,
      averageDailySales: 0
    }
  },
  computed: {
    // 당겨받기
    isFundanowSubType1() {
      if (this.fundingData?.fundanowType === 'FUNDANOW' && this.fundingData?.useDays <= 30) {
        return true
      }
      return false
    },
    // 초단기
    isEarlyPayment() {
      if (this.fundingData?.fundanowType === 'EARLY_PAYMENT') {
        return true
      }
      return false
    },
    countExpectedDay() {
      if (this.averageDailySales > 0) {
        const repayAmount = this.averageDailySales * (this.repaymentRate / 100)
        return this.debtor.loanAmount / repayAmount
      }

      return 0
    },
    isMarketingLoan() {
      return this.fundingType === 'MARKETING_LOAN'
    },
    repaymentMethodText() {
      let text = null
      const repaymentFixedRate = this.repaymentRate.toFixed(2).replace(/(\.\d*[1-9])0+$|\.00$/, '$1')
      if (this.repaymentMethod === '원리금균등') {
        if (this.isMarketingLoan) {
          text = `
            대출모집완료금액의 약 
            <span class="color-funda--basic font-weight-bold">
              0.89%
            </span>씩 
            매일 상환하며, 약 
            <span class="color-funda--basic font-weight-bold">
              112
            </span>일 후 상환이 완료될 예정입니다.
          `
        } else if (this.isEarlyPayment) {
          // text = '일 매출금원에 대해서 카드사 및 배달플랫폼에서 입금되는 금원으로 상환됩니다.'
          text = `
            일 평균매출은
            <span class="color-funda--basic font-weight-bold">
              ${this.$options.filters.commaFormat(this.averageDailySales)}
            </span>
            원 입니다.
          `
        } else {
          text = `
            일 평균매출은
            <span class="color-funda--basic font-weight-bold">
              ${this.$options.filters.commaFormat(this.averageDailySales)}
            </span>
            원으로 일 ${repaymentFixedRate}% 상환 시 약
            <span class="color-funda--basic font-weight-bold">
              ${parseInt(this.countExpectedDay) + 2}
            </span>
            영업일 후 상환이 완료될 것으로 예상됩니다.
          `
        }
      } else {
        text = `
          해당 상품은 ${this.repaymentMethod}상환상품으로, 대출실행금액의
          <span class="color-funda--basic font-weight-bold">
            ${repaymentFixedRate}%
          </span>
          만큼의 원금과 잔여 원금에 대한 이자를 매 영업일 상환합니다.
        `
      }

      return text
    },
  },
  components: {
    StoreSalesStackChart,
  },
  created() {
    this.process()
  },
  methods: {
    addComma(number) {
      return this.$options.filters.commaFormat(number)
    },
    process() {
      const salesData = this.store.currentCardSales
      if ((Array.isArray(salesData) && salesData.length > 0) === false) {
        return
      }
      const newObj = {}
      const settings = [
        'date',
        '일평균 매출액',
        { type: 'string', role: 'tooltip', p: { html: true } },
        { role: 'annotation' },
        { role: 'style' },
      ]

      let minusDays = 0
      for (let index = 0; index < salesData.length; index++) {
        const sales = salesData[index]

        const tempDate = new Date(sales.date)
        // eslint-disable-next-line
        const key = `${tempDate.getFullYear().toString().substr(-2)}년 ${parseInt(tempDate.getMonth())+1}월`
        const amount = parseInt(sales.amount)

        // 첫번째 일을 기준으로 매출이 0인 일의 수는 평균계산에서 뺀다
        if (minusDays === index && amount === 0) {
          minusDays++
        }

        if (!!newObj[key]) {
          newObj[key].sumAmount += amount
          newObj[key].days++
        } else {
          newObj[key] = {
            sumAmount: amount,
            days: 1,
          }
        }
      }

      // let monthCount = Object.keys(newObj).length
      const newArr = []
      let totalSum = 0
      for (const [k, v] of Object.entries(newObj)) {
        totalSum += v.sumAmount
        const arr = [k]
        const averageAmt = v.sumAmount / v.days
        const amt = parseInt(averageAmt / 10000, 10)

        arr.push(amt)
        arr.push('일평균: ' + this.addComma(amt) + '<small>만원</small>')
        arr.push(null)
        arr.push('')

        newArr.push(arr)
      }

      this.options = {
        title: '(단위: 만원)',
        titlePosition: 'in',
        // width: 400,
        height: 300,
        tooltip: { isHtml: true },
        legend: { position: 'top', maxLines: 2, alignment: 'end' },
        bar: { groupWidth: '35%' },
        colors: ['#25AAE1', '#4C83BC', '#69B9CB'],
        isStacked: true,
        annotations: {
          alwaysOutside: true,
          textStyle: {
            fontSize: 14,
            color: '#000',
            auraColor: 'none',
          },
        },
        backgroundColor: { fill: 'transparent' },
      }

      this.settings = settings
      this.rawData = newArr
      this.averageDailySales = parseInt(
        totalSum / (salesData.length - minusDays),
        10
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.sales-details {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px 20px;
}
</style>
