import store from '@/store'
import { AuthTypes } from '@/store/types/auth'
import { sleep } from '@/util'

export default [
  {
    name: 'login',
    path: '/login',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/login/Login.vue').default,
    beforeEnter: async (to, from, next) => {
      let tryCount = 0
      while (store.getters['auth/getProfileLoading']) {
        tryCount++
        await sleep(500)
        if (tryCount > 100) {
          break
        }
      }

      if (store.getters[`auth/${AuthTypes.getters.IS_AUTHENTICATED}`]) {
        await global.$vm.$swal.basic.alert('이미 로그인 상태입니다.')
        next({ path: '/' })
        return
      }

      next()
    },
  },
]
