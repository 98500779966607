<template>
  <div class="password">
    <section id="hero_2">
      <div class="intro_title animated fadeInDown">
        <h1>비밀번호 찾기</h1>
      </div>
    </section>
    <v-container class="fill-height" fluid>
      <v-row
        class="align-center justify-center px-5 py-9"
        style="min-height: 400px;"
      >
        <v-col cols="12" sm="8" md="4">
          <p class="mb-5">
            <a
              class="font-size--14 color-funda--basic"
              @click="$router.push({ name: 'id' })"
            >
              (* 아이디를 잊어버리셨나요? 아이디 찾기 -> )
            </a>
          </p>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-content step="1">
                <h2>비밀번호를 잊어버리셨나요?</h2>
                <h3>
                  아이디를 입력하시면 찾을 수 있는 방법을 알려드립니다.
                </h3>
              </v-stepper-content>

              <v-stepper-content step="2">
                <h2 v-if="checked">{{ user.id }}으로 찾은 아이디입니다</h2>
                <h3>
                  개인정보 보호를 위해 아이디는 일부는 *로 표기됩니다.<br />인증할
                  정보를 선택하세요.
                </h3>
              </v-stepper-content>

              <v-stepper-content step="3">
                <h2>인증번호가 발송되었습니다.</h2>
              </v-stepper-content>

              <v-stepper-content step="4">
                <h2>{{ user.id }} 님, 이제 새로운 비밀번호를 입력해주세요.</h2>
              </v-stepper-content>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form @submit.prevent="step1(user)">
                  <v-card class="stepper">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="user.id"
                          :rules="[rules.requiredId]"
                          label="아이디"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="mb-5">
                        <v-btn
                          :disabled="stepLoading || user.id.length < 1"
                          :loading="stepLoading"
                          tile
                          depressed
                          large
                          color="primary"
                          type="submit"
                        >
                          다음 단계
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form @submit.prevent="step2(user)">
                  <v-card elevation="0">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="checked"
                          :label="
                            `마이펀다에 등록된 휴대폰 인증 (${user.maskedPhone})`
                          "
                          :value="user"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                      <template v-if="checked">
                        <v-col cols="12">
                          <v-text-field
                            v-model="user.phone"
                            :rules="[rules.requiredPhone]"
                            label="휴대폰 번호('-'제외)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-5">
                          <v-btn
                            :disabled="stepLoading || isPhoneValid"
                            :loading="stepLoading"
                            tile
                            depressed
                            large
                            color="primary"
                            type="submit"
                          >
                            다음 단계
                          </v-btn>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form v-if="e1 === 3" @submit.prevent="step3(user)">
                  <v-card>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="user.verifyWord"
                          label="인증번호"
                          :rules="[rules.requiredVerifyWord]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="mb-5">
                        <v-btn
                          :disabled="stepLoading || isVerifyValid"
                          :loading="stepLoading"
                          tile
                          depressed
                          large
                          color="primary"
                          type="submit"
                        >
                          다음 단계
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-form @submit.prevent="step4(user)">
                  <v-card elevation="0">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="user.password"
                          label="비밀번호를 입력해주세요"
                          type="password"
                          :rules="[rules.requiredPassword]"
                          autocomplete="on"
                        ></v-text-field>
                        <v-text-field
                          v-model="user.rePassword"
                          label="비밀번호를 다시 입력해주세요"
                          type="password"
                          autocomplete="on"
                          :rules="[rules.requiredPassword, isPasswordValid]"
                        ></v-text-field>
                      </v-col>
                      <v-card-actions>
                        <v-btn
                          :disabled="stepLoading"
                          :loading="stepLoading"
                          tile
                          depressed
                          large
                          color="primary"
                          type="submit"
                        >
                          완료
                        </v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="5">
                <v-card elevation="0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      {{ user.id }} 님 새로운 비밀번호로 변경 완료됐습니다.<br />
                      새로운 비밀번호로 안전하게 Funda 서비스를 이용해 주세요.
                    </v-col>
                    <v-col cols="12">
                      <v-card-actions class="justify-center">
                        <v-btn
                          :to="{ name: 'main' }"
                          tile
                          depressed
                          large
                          color="primary"
                        >
                          FUNDA 첫화면
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  layout: 'legacy',
  data() {
    return {
      e1: 1,
      checked: false,
      code: '',
      user: {
        id: '',
        phone: '',
        userAuthIdx: null,
        verifyWord: '',
        password: null,
        rePassword: null,
      },
      stepLoading: false,
      rules: {
        requiredId: (value) => !!value || '아이디를 입력해주세요',
        requiredVerifyWord: (value) => !!value || '인증번호를 입력해주세요',
        requiredPassword: (value) => !!value || '비밀번호를 입력해주세요',
        requiredPhone: (value) => !!value || '휴대폰 번호를 입력해주세요',
      },
    }
  },
  computed: {
    isPhoneValid() {
      return this.user.phone.length < 10 || this.user.phone.length > 11
    },
    isVerifyValid() {
      return !this.user.verifyWord || this.user.verifyWord.length > 6
    },
    isPasswordValid() {
      return this.user.password === this.user.rePassword
        ? true
        : '비밀번호가 일치하지 않습니다.'
    },
  },
  methods: {
    errorMessage(e) {
      if (e.status >= 400 && e.status < 500) {
        const text = `${e.errors[0].message}`
        this.$swal.basic.error({ text })
      } else {
        throw e
      }
    },
    async step1(user) {
      try {
        this.stepLoading = true
        let { maskedPhone } = await this.$fundaApi.query(gql`{
          maskedPhone: getUserMaskedPhoneById(id: "${user.id}")
        }`)
        user.maskedPhone = maskedPhone
        this.checked = user
        this.e1 = 2
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
    async step2(user) {
      try {
        this.stepLoading = true

        let { userAuthIdx } = await this.$fundaApi.mutation(gql`{
          userAuthIdx: requestFindPasswordAuth(userId: "${user.id}", phone:"${user.phone}")
        }`)
        user.userAuthIdx = userAuthIdx
        this.e1 = 3
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
    async step3(user) {
      try {
        this.stepLoading = true
        await this.$fundaApi.mutation(gql`{
          userAuthIdx: confirmFindPasswordAuth(userAuthIdx: ${user.userAuthIdx}, userId: "${user.id}", verifyWord: "${user.verifyWord}")
        }`)
        this.e1 = 4
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
    async step4(user) {
      try {
        this.stepLoading = true
        await this.$fundaApi.mutation(gql`{
          userAuthIdx: updatePasswordByUserAuth(userAuthIdx: ${user.userAuthIdx}, userId: "${user.id}", verifyWord: "${user.verifyWord}", password: {
            password: "${user.password}",
            rePassword: "${user.rePassword}"
          })
        }`)
        this.e1 = 5
      } catch (e) {
        this.errorMessage(e)
      } finally {
        this.stepLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#hero_2 {
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;
  position: relative;
  height: 236px;
  background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
    center;
  background-size: cover;

  & ~ .container {
    background-color: #ffffff;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;

    h1 {
      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }
  }
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

h3 {
  font-size: 14px;
  color: #b6b6b6;
  margin-bottom: 4px;
}

.v-stepper {
  box-shadow: none;

  &__header {
    height: auto;
    box-shadow: none;

    .v-stepper__content {
      padding: 0;
    }
  }

  &__items {
    .v-stepper__content {
      border: 1px solid #e7edf6;

      .v-card__actions {
        padding-left: 0;

        .v-btn {
          top: unset;
          left: unset;
        }
      }
    }
  }
}

.v-card {
  &.stepper {
    .v-card__title {
      font-size: 17px;
      font-weight: 500;
      padding: 0;
    }
  }
}

.v-btn {
  height: 40px !important;
  color: #ffffff;
  background-color: #25aae1 !important;
  border-radius: 3px;
  font-size: 13px;
}
</style>
