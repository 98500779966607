<template>
  <div
    v-if="!!fundingData"
    class="mx-auto fundanow-details"
    style="max-width: 768px"
  >
    <v-row
      no-gutters
      class="details__title pa-4 py-8"
      :class="{'bg--light-green': isFundanowSubType1, 'bg--blue': isEarlyPayment, 'early-payment-text-color': isEarlyPayment}"
    >
      <v-col cols="12" class="px-2">
        <template v-if="isFundanowSubType1">
          <p
            class="mb-2 font-size--16"
          >
            초단기 상품 투자로 <strong>위험률은 낮추고, 수익은 빠르게!</strong>
          </p>
        </template>
        <template v-else-if="isEarlyPayment">
          <p
            class="mb-2 font-size--16 early-payment-text-color"
          >
            평균 5일, 최대 15일 내 상환되는 <strong>초단기 투자</strong> 상품
          </p>
        </template>

        <p class="ma-0 font-size--12">
          {{ fNumInt }}호 | {{ fundingData.registerDate | dateFormat }}
        </p>
        <p class="mt-1 mb-6 text-h4 font-weight-bold">
          {{ fundingData.title }}
        </p>
        <div class="d-flex justify-space-between mt-1 mb-2">
          <p class="font-size--17 ma-0">
            모집액: {{ fundingData.totalAmount | commaFormat }}원
          </p>
          <p class="font-size--17 ma-0">
            연이율 {{ fundingData.interestRate | rateFormat }}%
            <span>|</span>
            상환기간 {{ fundingData.useDays }}일
          </p>
        </div>
      </v-col>
    </v-row>

    <!-- TODO: 초단기상품 하단 띠 문구 -->
    <v-row v-if="isFundanowSubType1" no-gutters class="mb-n7 px-4 py-2 line-text">
      <p class="mb-0 white--text">
        대출 만기는 {{fundingData.useDays}}일, 평균 10일에 상환되는 초단기 투자 상품입니다.
      </p>
    </v-row>
    <v-row v-else-if="isEarlyPayment" no-gutters class="mb-n7 px-4 py-2 line-text-early-payment">
      <p class="mb-0 white--text">
        대출 만기는 {{fundingData.useDays}}일이지만, 카드사와 배달플랫폼의 정산에 따라 가중평균하면 평균 5일에 상환됩니다.
      </p>
    </v-row>
    <v-row v-if="isWooriCrowdBcPayback" no-gutters class="mb-n7 px-4 py-2 line-text">
      <p class="mb-0 white--text">
        우리동네크라우드펀딩 - 비씨카드 페이백 상품입니다.
      </p>
    </v-row>
    <v-row v-else-if="isWooriCrowdOnlineSeller" no-gutters class="mb-n7 px-4 py-2 line-text">
      <p class="mb-0 white--text">
        우리동네크라우드펀딩 - 온라인셀러 매출채권 양도 상환 상품입니다.
      </p>
    </v-row>
    <v-row v-else-if="isWooriCrowd" no-gutters class="mb-n7 px-4 py-2 line-text">
      <p class="mb-0 white--text">
        우리동네크라우드펀딩 상품입니다.
      </p>
    </v-row>

    <v-row v-if="hasFNum" class="my-5" no-gutters>
      <v-col cols="12">
        <SameFnStoreInvDetails
          :key="fNumInt"
          :fNum="fNumInt"
          hideZeroBalance
          hideDoneRepay
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5 mb-5" v-if="isWooriCrowd">
      <v-col cols="12" class="px-2">
        <div class="frame22 pa-4 py-8">
          <div class="text-h6 font-weight-bold mb-2">우리동네크라우드펀딩이란?</div>
          <div class="frame20 pa-8 mb-2">
            <v-img
              src="@/assets/images/funding/woori-crowd.png"
              max-width="400"
              max-height="142.33"
              contain
            ></v-img>
          </div>
          <ul class="mt-5 mb-2 font-size--14">
            <li class="mb-2">
              소상공인의 사업을 지역 투자자들이 크라우드펀딩으로 돕고, 펀딩 과정에서 소상공인에게 부담된 이자와 플랫폼수수료 등을 정부가 지원하는 사업입니다.
            </li>
            <li class="mb-2">
              지역 경제 활성화에 기여하면서 투자 수익도 얻을 수 있는 기회를 제공합니다.
            </li>
            <li class="mb-2">
              펀다는 당 정부지원사업의 2023년에 이어 2024년에도 주관기관으로 선정되어 운영 중입니다.
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-10" v-if="isWooriCrowdBcPayback">
      <v-col cols="12" class="px-2">
        <div class="frame22 pa-4 py-8">
          <div class="text-h6 font-weight-bold mb-2">비씨카드 페이백 상품이란?</div>
          <div class="frame20 pa-8 mb-2">
            <v-img
              src="@/assets/images/funding/bc_payback.png"
              max-width="300"
              max-height="142.33"
              contain
            ></v-img>
          </div>
          <ul class="mt-5 mb-2 font-size--14">
            <li class="mb-2">
              이 상점에 투자한 투자자가 해당 상점에 방문하여 비씨카드로 결제를 하면, 향후 비씨카드 결제금액에서 투자금의 1%를 청구 할인 받으실 수 있습니다.
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-10" v-if="isWooriCrowdOnlineSeller">
      <v-col cols="12" class="px-2">
        <div class="frame22 pa-4 py-8">
          <div class="text-h6 font-weight-bold mb-2">온라인셀러 매출채권 양도 상환 상품이란?</div>
          <div class="frame20 pa-8 mb-2">
            <v-img
              src="@/assets/images/funding/woori-crowd-online-seller.png"
              max-width="300"
              max-height="400"
              contain
            ></v-img>
          </div>
          <ul class="mt-5 mb-2 font-size--14">
            <li class="mb-2">
              무신사, 에이블리, 브랜디, 하이버, 지그재그 등 온라인 패션 플랫폼에서 상품 판매 실적이 좋은 셀러들에게 제공하는 6개월 신용대출 상품입니다.
            </li>
            <li class="mb-2">
              셀러가 온라인 플랫폼에 대해 가지는 정산매출채권을 포괄적으로 양도받은 선정산회사가 대출상환금의 집금을 대행하는 구조로서 사실상 셀러의 장래 매출채권을 확보한 것과 유사한 안정성을 가집니다.
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!!details && isMortgageLoan === false" no-gutters>
      <v-col cols="12" class="px-2">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          매출정보
        </h2>
        <FnSalesStackChart
          :id="'chart_' + fNumInt"
          :fundingData="fundingData"
          :store="details.store"
          :debtor="details.debtor"
          :repaymentRate="repaymentRate"
          :repaymentMethod="repaymentMethod"
          :fundingType="fundingData.fundanowType"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="px-2">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          공시정보
        </h2>
        <div v-if="pdfLoading" class="text-center">
          <MyLoader />
        </div>
        <template v-if="!!details">
          <div v-show="pdfLoading === false" class="pdf">
            <MortgageDetails v-if="isMortgageLoan" :details="details" />
            <FnDebtorDetails
              v-else
              :id="'debtor_' + fNumInt"
              :store="details.store"
              :debtor="details.debtor"
            />
          </div>
        </template>

        <template v-else>
          <p class="my-5 px-1 color--gray-1" style="line-height: 1.3">
            공시정보를 불러올 수 없습니다. <br />
            잠시후 다시 시도해주세요
          </p>
        </template>
      </v-col>
    </v-row>
    <v-row class="my-5" no-gutters>
      <v-col cols="12">
        <ul class="invest-notice">
          <li v-for="notice in investNotice" :key="notice.title">
            <p class="mb-2 mt-5">
              {{ notice.title }}
            </p>

            <ul
              v-if="!!notice.notice && notice.notice.length > 0"
              class="invest-notice__children"
            >
              <li
                v-for="(n, index) in notice.notice"
                :key="notice.title + '_' + index"
              >
                {{ n }}
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
  <div v-else-if="notFound">
    <Error404/>
  </div>
  <div v-else class="text-center">
    <MyLoader />
  </div>
</template>

<script>
import Error404 from '@/components/error/Error404.vue'
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import FnSalesStackChart from '@/components/fundings/fn/FnSalesStackChart.vue'
import FnDebtorDetails from '@/components/fundings/fn/FnDebtorDetails.vue'
import MortgageDetails from './MortgageDetails.vue'
import MyLoader from '@/components/MyLoader.vue'
import SameFnStoreInvDetails from '@/components/invest/SameFnStoreInvDetails.vue'

export default {
  components: {
    FnSalesStackChart,
    FnDebtorDetails,
    MortgageDetails,
    MyLoader,
    SameFnStoreInvDetails,
    Error404,
  },
  props: {
    fNum: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      storeBalanceLoading: false,
      storeBalance: null,
      fundingData: null,
      details: null,
      pdfLoading: true,
      pdfHtml: null,
      notFound: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    // 당겨받기
    isFundanowSubType1() {
      return this.fundingData?.fundanowType === 'FUNDANOW' && this.fundingData?.useDays <= 30
    },
    // 초단기
    isEarlyPayment() {
      return this.fundingData?.fundanowType === 'EARLY_PAYMENT'
    },
    isWooriCrowd() {
      const disclosureType = this.details?.disclosureType
      return disclosureType?.includes('woori_crowd')
    },
    isWooriCrowdBcPayback() {
      const disclosureType = this.details?.disclosureType
      return disclosureType === 'woori_crowd_bc_payback'
    },
    isWooriCrowdOnlineSeller() {
      const disclosureType = this.details?.disclosureType
      return disclosureType === 'woori_crowd_online_seller'
    },
    fNumInt() {
      return parseInt(this.fNum)
    },
    hasFNum() {
      return !isNaN(this.fNumInt)
    },
    isMarketingLoan() {
      return this.fundingData?.fundanowType === 'MARKETING_LOAN'
    },
    isMortgageLoan() {
      return this.fundingData?.fundanowType === 'SECOND_MORTGAGE'
    },
    repaymentRate() {
      let result = 25
      if (this.isMarketingLoan === true) {
        if (this.fNumInt < 2976500) {
          result = 0.7
        } else {
          result = 5
        }
      }
      return (this.details?.debtor?.repaymentRatio * 100) || result
    },
    repaymentMethod() {
      let result = '원리금균등'
      if (this.isMarketingLoan === true) {
        if (this.fNumInt < 2976500) {
          result = '원금균등'
        }
      }
      return result
    },
    investNotice() {
      const earningRate = (interestRate) => {
        const taxRate = 0.154
        const platformFeeRate = 0.0165
        return ((interestRate * (1 - taxRate) - platformFeeRate) * 100).toFixed(2)
      }

      const fundingNotice = {
        title: '상품 투자에 관한 사항',
        notice: [
          '플랫폼이용 수수료 : 본 상품의 플랫폼 이용료는 잔여상환금의 1.65%이며, 매 원리금상환 시 플랫폼 이용료를 차감합니다.',
          '이자소득 세율 : 개인 15.4%(소득세 14%, 지방소득세 1.4%), 법인 15.4%(법인세 14%, 지방소득세 1.4%)',
          `본 상품의 예상 수익률은 ${(this.fundingData.interestRate * 100).toFixed(2).replace(/(\.\d*[1-9])0+$|\.00$/, '$1')}%이며, 
            플랫폼이용 수수료, 이자소득에 관한 세금을 제외한 순수익률은 ${
              earningRate(this.fundingData.interestRate)
            }% 
            입니다.`,
          '2022년 8월말 기준 펀다나우 상품의 연체율은 2.88%, 부동산담보 상품의 연체율은 0% 입니다.',
        ],
      }

      const bondNotice = {
        title: '채권 관리 사항',
        notice: [
          '펀다 채권관리팀에서는 매 영업일 오전 자동으로 취합된 현재 상환중 채권의 매출액을 확인합니다. 이를 바탕으로 매출이 급격히 감소하거나, 영업이 발생하지 않은 경우 대출자와의 연락을 통해 정확한 사유를 파악합니다.',
          '매 영업일 파악된 상점의 상세 현황을 바탕으로 필요한 경우, 채권 만기가 도래하지 않더라도 빠르게 채권 회수를 위한 업무를 실행하고 있습니다. 대출자의 매출 외에 자력 상환을 유도하거나, 선제적인 법 조치를 통해 채권 잔액의 회수 가능성을 높이고 있습니다.',
          '대출자의 개인회생, 파산 등의 사유로 채권 회수 장기화가 예상될 경우, 투자자 동의를 얻어 채권 매각이 진행될 수 있습니다.',
        ],
      }

      const investNotice = {
        title: '투자 유의사항',
        notice: [
          '투자 마감 전까지 투자의사를 철회할 수 있으며, 투자금 모집 이후에는 투자 철회가 불가능합니다.',
          '원금 보장형 상품이 아니므로 투자 시 신중한 판단이 필요합니다.',
          '대출자의 자금 상황에 따라 만기 전에 조기 상환될 수 있으며, 상환 다음 영업일에 원리금을 지급합니다.',
          '펀다는 투자 상품에 대한 충분한 정보를 제공할 의무가 있으며, 펀다가 제공하는 정보를 확인 후 투자하시기 바랍니다.',
        ],
      }

      let result = [fundingNotice, bondNotice, investNotice]
      if (this.isMortgageLoan) {
        result = [fundingNotice]
      }
      return result
    },
  },
  created() {
    this.fetchData()
    this.getFnStoreBalance()

    setTimeout(() => {
      this.pdfLoading = false
    }, 1500)
  },
  methods: {
    async fetchData() {
      this.isLoading = true

      try {
        await this.getFundingData()
        await this.getFnDetails()
        // await this.getHtml()
      } catch (e) {
        this.$swal.basic.alert('에러가 발생하였습니다')
      } finally {
        this.isLoading = true
      }
    },
    async getFundingData() {
      const q = `
        data: wwwFundingDetail(fNum: ${this.fNumInt}) {
          title
          subTitle
          visible
          totalAmount
          announcedAmount
          unitInvestAmount
          minInvestAmount
          maxInvestAmount
          interestRate
          loanPeriod
          gracePeriod
          investmentType
          status
          registerDate
          investmentStartDate
          investmentClosureDate
          fundanowType
          useDays
        }
      `

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        // if (['공시전', '공시중', '투자예약중', '투자전', '투자마감', '투자중'].includes(data.status) === false) {
        //   this.notFound = true
        //   return
        // }

        this.fundingData = data
      } catch (e) {
        throw new Error(e)
      }
    },
    async getFnDetails() {
      try {
        const details = await this.$fundaApi.call({
          url: `/funding/${this.fNumInt}/announcement`,
          method: 'GET',
        })
        this.details = details
      } catch (e) {
        throw new Error(e)
      }
    },
    async getHtml() {
      try {
        const pdfHtml = await this.$fundaApi.call({
          url: `/funding/${this.fNumInt}/announcement.html`,
          method: 'GET',
        })
        this.pdfHtml = pdfHtml
      } catch (e) {
        throw new Error(e)
      }
    },
    async getFnStoreBalance() {
      if (this.isAuthenticated === false || this.storeBalanceLoading === true) {
        return
      }

      this.storeBalanceLoading = true

      const q = `data: getInvestmentBalanceByFundingDataIdx(
          userIdx: ${this.userIdx}
          fundingDataIdx: ${this.fNumInt}
        )`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        if (data == null) {
          data = 0
        }
        this.storeBalance = data
      } catch (e) {
        this.storeBalance = 0
      } finally {
        this.storeBalanceLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.details__title {
  background-color: #1baaf1;
  color: white;
  padding-top: 10px;
  padding-bottom: 5px;

  .text-h4 {
    font-size: 1.9rem !important;
  }

  &.bg--light-green {
    background-color: #98BC62;
  }
  &.bg--green {
    background-color: #32B611;
  }
  &.bg--blue {
    background-color: #BFF0FB;
  }
}
.early-payment-text-color{
  color: #061FFF;
}

.line-text {
  background-color: #2A5E2D;
}

.line-text-early-payment{
  background-color: #2AA2F9;
}

.pdf {
  width: 100%;
  padding: 15px;
  border: 1px solid #8a94a2;
  overflow-x: auto;
  min-height: 600px;
}

ul.invest-notice {
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  list-style: none;

  > li {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  .invest-notice__children {
    list-style: initial;

    > li {
      margin-bottom: 5px;
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.frame22 {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.frame20 {
  background-color: #F8F9FA;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
