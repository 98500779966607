<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row class="mb-7" no-gutters>
      <v-col cols="12" class="text-center">
        <img
          style="max-width: 780px; width: 100%"
          src="@/assets/images/notice/official/structuraldrawing1.png"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 거래구조 및 영업방식',
    },
  },
  data() {
    return {
      imgSrc: '',
    }
  },
}
</script>

<style lang="scss" scoped></style>
