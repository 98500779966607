import { localize } from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'
import Regex from '@/const/Regex'

localize(
  'ko',
  Object.assign(ko, {
    names: {
      phone: '핸드폰번호',
      password: '비밀번호',
      repassword: '비밀번호',
      oldpassword: '현재 비밀번호',
      code: '인증 번호',
      leave_agree: '동의',
      leave_reason: '탈퇴 사유',
      address: '주소지',
      service_agree: '투자 및 서비스 이용약관',
      info_agree: '개인정보 취급방침',
      email: '이메일',
    },
    messages: {
      required: fieldName => `${fieldName}을(를) 입력해 주세요.`,
      min: (name, obj) => `${obj.length}글자 이상 입력하세요.`,
      max: (name, obj) => `${obj.length}글자 이하로 입력하세요.`,
      numeric: _ => '숫자만 입력 가능합니다.',
    },
    fields: {
      phone: {
        required: _ => '핸드폰번호를 입력해 주세요.',
        phone: _ => '핸드폰번호를 올바르게 입력해 주세요.',
      },
      password: {
        required: _ => Regex.PASSWORD.MSG,
        min: (name, obj) => `비밀번호는 ${obj.length}자 이상 입력해주세요.`,
        max: (name, obj) => `비밀번호는 ${obj.length}자 이하로 입력해주세요.`,
      },
      repassword: {
        required: _ => '비밀번호를 다시 입력해주세요.',
      },
      code: {
        required: _ => '인증 번호를 입력해주세요.',
        digits: (name, obj) => `인증 번호는 ${obj.length}자로 입력해주세요.`,
      },
      leave_agree: {
        required: _ => '고지사항에 대해 동의해주세요.',
      },
      address: {
        required: _ => '새 주소지를 입력해주세요',
      },
      service_agree: {
        required: fieldName => `${fieldName}에 동의해주세요`,
      },
      info_agree: {
        required: fieldName => `${fieldName}에 동의해주세요`,
      },
      email: {
        required: _ => '이메일을 입력해주세요',
      },
    },
  })
)
