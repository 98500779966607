import client from './fundaApiClient'
import Vue from 'vue'

const replaceBracket = q => {
  let res = q.substring(q.indexOf('{') + 1, q.lastIndexOf('}'))
  return res
}

export default {
  client,
  call(option) {
    return client.call(Vue.util.extend({}, option))
  },
  mutation(query, fn = '', variables) {
    return this.graphql({
      query: `mutation ${fn}{ ${replaceBracket(query.loc.source.body)} }`,
      variables
    })
  },
  query(query, fn = '', variables) {
    return this.graphql({
      query: `query ${fn}{ ${replaceBracket(
        query.loc.source.body
      )} }`,
      variables
    })
  },
  graphql(option) {
    if (option.mutation) {
      option.query = option.mutation.loc.source.body
      if (option.query.startsWith('mutation ') === false) {
        option.query = 'mutation ' + option.query
      }
      delete option.mutation
    }
    if (option.query.loc) {
      option.query = option.query.loc.source.body
    }
    return this.call({
      method: 'POST',
      url: option.url == null ? '/graphql' : option.url,
      data: option
    })
  }
}
