<template>
  <div></div>
</template>

<script>
export default {
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(v) {
        // new Date.getDate() 안가져와도 된다
        // 1000 안곱해도 된다
        // ex) 10 -> 10초
        // const half = 60 * 30
        const recommenderCode = v?.code
        const affiliateCode = v?.promotion_code
        const partnerCode = v?.affiliate_id

        if (recommenderCode) {
          this.$cookies.set('recommender', recommenderCode, 0)
        }

        if (affiliateCode) {
          this.$cookies.set('promotion', affiliateCode, 0)
        }

        if (partnerCode) {
          this.$cookies.set('partner', partnerCode, 0)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
