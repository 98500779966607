export default [{
  name: 'test',
  path: '/test/file',
  meta: {
    layout: 'LegacyLayout'
  },
  component: require('@/pages/test/FileUploadTest.vue').default
},
{
  name: 'test/google/chart',
  path: '/test/google/chart',
  meta: {
    layout: 'LegacyLayout'
  },
  component: require('@/pages/test/GoogleChartTest.vue').default
}]
