<template>
  <div :id="id"></div>
</template>
<script>
import { jsLoad } from '@/util'
// 중복 문제 해결 필요
const scriptId = 'scGoogleChart1'

/**
 * 기존 공시페이지 매출분석 섹션
 *
 * props id
 * 차트 그릴때 사용
 * new window.google.visualization.ColumnChart(document.getElementById(this.id))
 *
 * props rawData
 * {
 *    salesData: {"월별매출":{"19-Jan":{"BC매출":{"매출":"7900200","특이사항":"-"}},
 *      "19-Feb":{"BC매출":{"매출":"6384100","특이사항":"-"}},"19-Mar":{"BC매출":{"매출":"6979600","특이사항":"-"}},
 *      "19-Apr":{"BC매출":{"매출":"6178200","특이사항":"-"}},"19-May":{"BC매출":{"매출":"5429050","특이사항":"-"}},
 *      "19-Jun":{"BC매출":{"매출":"5136360","특이사항":"-"}}},"지점":["BC매출"]}
 * }
 *
 */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    rawData: {
      type: Object,
      required: true,
      validator: function(value) {
        return value != null && value.salesData != null
      },
    },
    isMobile: {
      type: Boolean,
      reqired: true,
    },
  },
  watch: {
    isMobile() {
      this.drawChart()
    },
  },
  async created() {
    await jsLoad({
      id: scriptId,
      src: '//www.gstatic.com/charts/loader.js',
    })
    this.drawChart()
  },
  methods: {
    addComma(number) {
      return this.$options.filters.commaFormat(number)
    },
    drawChart() {
      window.google.charts.load('current', { packages: ['corechart'] })
      window.google.charts.setOnLoadCallback(this.drawStacked)
    },
    drawStacked() {
      // props
      let json = this.rawData.salesData
      let newArr = []
      let tempArr = []
      tempArr = ['date']
      for (let key in json.지점) {
        if (isNaN(key)) {
          json.지점[key] = key
        }
        tempArr.push(json.지점[key])
        tempArr.push({ type: 'string', role: 'tooltip', p: { html: true } })
        tempArr.push({ role: 'annotation' })
        tempArr.push({ role: 'style' })
      }
      newArr.push(tempArr)

      // 기존 코드에서 네이밍 변경
      // 최고매출
      let maxSales = 0
      for (let key in json.월별매출) {
        tempArr = []
        tempArr.push(key) // date
        for (let key1 in json.지점) {
          if (isNaN(key1)) {
            json.지점[key1] = key1
          }

          // 기존 코드에서 네이밍 변경
          // 매출
          let sales
          // 기존 코드에서 네이밍 변경
          // 특이사항
          let specificPoint

          if (typeof json['월별매출'][key][json.지점[key1]] === 'undefined') {
            sales = 0
            specificPoint = null
          } else {
            sales = Math.round(
              parseInt(json['월별매출'][key][json.지점[key1]]['매출']) / 10000
            )
            specificPoint = json['월별매출'][key][json.지점[key1]]['특이사항']
            specificPoint = specificPoint === '-' ? null : specificPoint
          }

          if (maxSales < sales) {
            maxSales = sales
          }

          tempArr.push(sales) // 1번 지점 매출
          tempArr.push(
            json.지점[key1] +
              ': ' +
              this.addComma(sales) +
              '<small>만원</small>'
          ) // 스타일
          tempArr.push(specificPoint) // 어노테이션
          tempArr.push('') // 스타일
        }
        newArr.push(tempArr)
      }
      let data = window.google.visualization.arrayToDataTable(newArr)
      let options = {
        title: '(단위:만원)',
        titlePosition: 'in',
        // width: '100%',
        height: 400,
        tooltip: { isHtml: true },
        legend: { position: 'top', maxLines: 2, alignment: 'end' },
        bar: { groupWidth: '35%' },
        colors: ['#25AAE1', '#4C83BC', '#69B9CB'],
        isStacked: true,
        vAxis: {
          maxValue: parseInt(maxSales + maxSales * 0.1),
        },
        annotations: {
          alwaysOutside: true,
          textStyle: {
            fontSize: 14,
            color: '#000',
            auraColor: 'none',
          },
        },
        backgroundColor: { fill: 'transparent' },
      }

      let chart = new window.google.visualization.ColumnChart(
        document.getElementById(this.id)
      )
      chart.draw(data, options)
    },
  },
}
</script>
