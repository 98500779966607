<template>
  <div class="corp-doc">
    <p class="mb-1 color--black font-size--14">
      &lt;법인투자자 제출 서류 목록(필수)>
    </p>

    <ul class="doc-ul">
      <li v-for="(doc, index) in docList" :key="index">
        <p class="mb-1" v-html="doc"></p>
      </li>
    </ul>

    <p class="mt-5 mb-1 color--black font-size--14">
      &lt;다음의 경우 추가 서류 필요>
    </p>

    <div class="mb-3">
      <p class="mb-1 color--black">
        + 법인 대부사업자
      </p>
      <p class="mb-0">
        - 대부업등록증
      </p>
    </div>

    <div>
      <p class="mb-1 color--black">
        + 대리인 거래시
      </p>
      <p class="mb-1">
        - 대리인 신분증 사본
      </p>
      <p class="mb-0">
        - 대리인 거래 신청서 (<a
          href="//cdn.funda.kr/funding/clip/corp_file_01.pdf"
          target="_blank"
        >
          <LoadingText>신청서 내려받기</LoadingText> </a
        >)
      </p>
    </div>

    <div class="mt-4">
      <hr class="hr-solid" />
      <p class="mb-1 color--black font-size--14">
        &lt;서류 접수처> <span class="font-size--13">택 1</span>
      </p>
      <p class="mb-1 font-size--13">
        (1) 원본 서류 등기 발송 (주소: 서울 서초구 사평대로53길 30 메이크잇 203호 ,204호 (주)펀다 투자팀 앞)
      </p>
      <p class="mb-2 font-size--13">
        (2) 메일 발송: <a href="mailto:contact@funda.kr">contact@funda.kr</a>
      </p>
      <p class="mb-0 font-size--13">
        서류 검토 후 결과를 법인투자자님께 개별 안내드립니다.
      </p>
    </div>
  </div>
</template>

<script>
import LoadingText from '@/components/common/text/LoadingText.vue'
export default {
  components: {
    LoadingText,
  },
  data() {
    return {
      docList: [
        '사업자 등록증',
        '법인 등기부등본 (말소사항 포함, 최근 1개월 내 발급본)',
        '법인인감 증명서 (최근 1개월 내 발급본)',
        '신분증 사본 (기재된 대표자 또는 대리인)',
        '법인 통장 사본 (법인명, 은행명, 계좌번호 식별 가능해야함)',
        '주주(출자자) 명부 <br /> * 주주(출자자) 명부가 없는 경우 사업장의 임대차 계약서',
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  font-size: 13px;
  line-height: 1.4;
}
.corp-doc {
  padding: 15px;
  background-color: #f4faff;
  font-family: AppleSDGothicNeo, sans-serif !important;
  word-break: break-all;
  line-height: 22px;
}

.doc-ul {
  padding-left: 16px;
  list-style: decimal;
}
</style>
