import Vue from 'vue'
import VueGtm from 'vue-gtm'
import router from '../router'

if (process.env.common.mode !== 'local') {
  // if (process.env.common.mode) {
  Vue.use(VueGtm, {
    id: 'GTM-M27WZTV', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    // debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
  })
}
