import client from '../fundaApiClient'
import qs from 'querystring'

// origin
const clientId = 'funda-web'
const pw = 'u%Y+*H5m$zm3u8@5YLYa'
// test
// const clientId = 'funda-web-test'
// const pw = 'u%Y+*H5m$zm3u8@5YLYa'
// console.log(clientId)

const b64 = btoa(`${clientId}:${pw}`)

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Basic ${b64}`,
}

// signin만 별도로 운영
export default {
  signin(params) {
    return client.post('/oauth/token', qs.stringify(Object.assign({
      scope: 'default',
      grant_type: 'password',
    }, params)), {
      headers
    })
  },
  signinSns(platform, token) {
    return client.post(`/oauth/sns/${platform}/token`, qs.stringify({
      token
    }))
  },
  signupNaverProfile(token) {
    return client.get(`/oauth/sns/naver/profile?token=${token}`)
  }
}
