/* eslint-disable */
// import 'es6-promise/auto'
import 'babel-polyfill'

// element remove
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
