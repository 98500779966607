<template>
  <div
    v-if="isLoading"
    class="d-flex justify-center align-center"
    style="height: 165px"
  >
    <MyLoader />
  </div>

  <div v-else>
    <v-row class="text-center" no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" class="px-6 py-6">
        <p id="cnt-total" class="ma-0 color--gray font-size--15">
          총 투자
          <span class="margin-l-10 color--black font-weight-bold font-size--18">
            {{ myCnt.cntTotal | commaFormat }}
          </span>
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" class="position--relative">
        <ul class="dashboard-ul invest-summary-count">
          <li>
            <p class="margin-b-10 color--gray">
              투자중<Tooltip
                :text="
                  `투자금을 모집 중이며 마감 시간이 지나지 않은 상품입니다.
                    투자취소가 가능한 상태입니다.`
                "
              />
            </p>
            <span class="font-weight-bold color--black">
              {{ myCnt.cntWaiting | commaFormat }}
            </span>
          </li>
          <div class="vr-solid align-self-center" style="height: 29px"></div>
          <li>
            <p class="margin-b-10 color--gray">
              투자마감<Tooltip
                :text="
                  `투자금 모집이 완료되었거나 마감 시간이 지난 상품입니다.
                    투자취소가 불가능한 상태입니다.`
                "
              />
            </p>
            <span class="font-weight-bold color--black">
              {{ myCnt.cntDoneInvest | commaFormat }}
            </span>
          </li>
          <div class="vr-solid align-self-center" style="height: 29px"></div>
          <li @click="summaryCountClick('상환중')">
            <p class="margin-b-10 color--gray">상환중</p>
            <span class="font-weight-bold color--black">
              {{ myCnt.cntRepay | commaFormat }}
            </span>
          </li>
          <div class="vr-solid align-self-center" style="height: 29px"></div>
          <hr class="hr-solid width-100 mobile-block" />
          <li @click="summaryCountClick('연체')">
            <p class="margin-b-10 color--gray">연체</p>
            <span class="font-weight-bold color--black">
              {{ myCnt.cntOverdue | commaFormat }}
            </span>
          </li>
          <div class="vr-solid align-self-center" style="height: 29px"></div>
          <li @click="summaryCountClick('부실')">
            <p class="margin-b-10 color--gray">부실</p>
            <span class="font-weight-bold color--black">
              {{ myCnt.totalInsolvent | commaFormat }}
            </span>
          </li>
          <div class="vr-solid align-self-center" style="height: 29px"></div>
          <li @click="summaryCountClick('상환완료')">
            <p class="margin-b-10 color--gray">상환완료</p>
            <span class="font-weight-bold color--black">
              {{ (myCnt.cntDoneRepay + myCnt.cntEarlyRepayment) | commaFormat }}
            </span>
          </li>
        </ul>
        <p v-if="false" class="count-notice color--warning">
          * 2022년 2월 23일부로 채권수 집계 방식이 변경되었습니다
          <a href="https://bit.ly/36Asb4H" target="_blank">(자세히보기)</a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InvestmentTypes } from '@/store/types/investment'
import MyLoader from '@/components/MyLoader.vue'
import Tooltip from '@/components/common/Tooltip.vue'

export default {
  components: {
    MyLoader,
    Tooltip,
  },
  computed: {
    ...mapGetters('investment', {
      isLoading: InvestmentTypes.getters.GET_USER_CNT_LOADING,
      myCnt: InvestmentTypes.getters.GET_USER_CNT,
    }),
  },
  created() {
    this.getMyCnt()
  },
  methods: {
    ...mapActions('investment', {
      getMyCnt: InvestmentTypes.actions.GET_USER_CNT,
    }),
    summaryCountClick(status) {
      this.$router.push({
        name: 'myfunda-invest-list',
        query: { filter: status },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#cnt-total {
  line-height: 1;
}

.invest-summary-count {
  display: flex;
  width: 100%;
  background-color: #f6f8fa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  justify-content: space-around;
  margin: 0;
  padding: 24px;

  li p {
    font-size: 16px;
  }
}

.count-notice {
  position: absolute;
  bottom: 10px;
  margin: 0;
  padding-left: 24px;
  font-size: 12px;
}
</style>
