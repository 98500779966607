import store from '@/store'
import { AuthTypes } from '@/store/types/auth'
import { sleep } from '@/util'

const meta = {
  layout: 'LegacyLayout',
}
export default [
  {
    path: '/myfunda/schedule/detail/:fNum?',
    name: 'myfunda-schedule-detail',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/myfunda/schedule-detail/ScheduleDetail.vue')
      .default,
    props: true,
  },
  {
    path: '/myfunda/schedule/majungmul-detail/:fNum?',
    name: 'myfunda-majungmul-detail',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/myfunda/schedule-detail/MajungmulDetail.vue')
      .default,
    props: true,
  },
  {
    path: '/myfunda/schedule/fundanow-detail/:fNum?',
    name: 'myfunda-fundanow-detail',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/myfunda/schedule-detail/FundaNowDetail.vue')
      .default,
    props: true,
  },
  {
    path: '/myfunda/schedule/disposals',
    name: 'myfunda-disposals',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/myfunda/schedule-detail/DisposalDetail.vue')
      .default,
    props: true,
  },
  {
    path: '/myfunda',
    component: require('@/pages/myfunda/Index.vue').default,
    meta,
    beforeEnter: async (to, from, next) => {
      let tryCount = 0
      while (store.getters['auth/getProfileLoading']) {
        tryCount++
        await sleep(500)
        if (tryCount > 100) {
          break
        }
      }

      const profile = store.getters[`auth/${AuthTypes.getters.GET_PROFILE}`]
      if (profile.newBankAccountFunda != null) {
        next()
      } else {
        const uv =
          store.getters[`auth/${AuthTypes.getters.GET_USER_VALIDATION}`]

        let userCdds = uv.userCdds.length > 0 ? uv.userCdds : []

        const isWaiting = userCdds.some(cdd => cdd.status === 'WAITING')

        if (isWaiting === true && uv.userEdds.length > 0) {
          await global.$vm.$swal.basic.alert(
            '본인 인증 완료 후 마이펀다를 이용하실 수 있습니다.'
          )
        } else {
          await global.$vm.$swal.basic.alert('투자자 정보를 입력해주세요.')
          next({ name: 'signup' })
        }
      }
    },
    children: [
      {
        path: 'home',
        alias: '',
        name: 'myfunda-home',
        component: require('@/pages/myfunda/DashboardHome.vue').default,
        meta,
      },
      {
        path: 'invest-board',
        name: 'myfunda-invest-board',
        component: require('@/pages/myfunda/InvestBoard.vue').default,
        meta,
      },
      {
        path: 'invest-list',
        name: 'myfunda-invest-list',
        component: require('@/pages/myfunda/InvestList.vue').default,
        props: true,
        meta,
      },
      {
        path: 'schedule',
        name: 'myfunda-schedule',
        component: require('@/pages/myfunda/Schedule.vue').default,
        meta,
      },
      {
        path: 'balance-deposit',
        component: require('@/pages/myfunda/BalanceDepositIndex.vue').default,
        meta,
        children: [
          {
            path: '',
            name: 'myfunda-balance-deposit',
            component: require('@/pages/myfunda/balance-deposit/BalanceDepositAccount.vue')
              .default,
            meta,
          },
          {
            path: 'transaction',
            name: 'balance-deposit-transaction',
            component: require('@/pages/myfunda/balance-deposit/BalanceDepositTransaction.vue')
              .default,
            meta,
          },
          {
            path: 'point',
            name: 'balance-deposit-point',
            component: require('@/pages/myfunda/balance-deposit/BalanceDepositPoint.vue')
              .default,
            meta,
          },
        ],
      },
      {
        path: 'setting',
        component: require('@/pages/myfunda/SettingIndex.vue').default,
        meta,
        children: [
          {
            path: '',
            name: 'myfunda-setting',
            component: require('@/pages/myfunda/setting/Normal.vue').default,
            meta,
          },
          {
            path: 'investor',
            name: 'myfunda-setting-investor',
            component: require('@/pages/myfunda/setting/Investor.vue').default,
            meta,
          },
          {
            path: 'login-history',
            name: 'myfunda-setting-login-history',
            component: require('@/pages/myfunda/setting/LoginHistory.vue')
              .default,
            meta,
            props: route => ({ query: route.query }),
          },
        ],
      },
    ],
  },
]
