<template>
  <div>
    <template v-if="isOff === false">
      <v-row class="file-upload" no-gutters>
        <v-col cols="12">
          <SkeletonIdLoader :is-active="!!tempFile" />
        </v-col>
        <v-col cols="12">
          <p
            v-show="!!tempFile"
            class="mt-3 mb-1 error--text font-size--16 line-height--18"
          >
            ※ 개인정보 보호를 위해 미리보기가 제공되지 않습니다
          </p>
        </v-col>
        <v-col
          v-if="!!tempFile"
          cols="12"
          class="d-flex justify-center my-3 font-size--15"
        >
          <label class="mr-2 font-weight-bold" for="id-card">
            파일명:
          </label>

          <input readonly id="id-card" type="text" :value="tempFile.orgName" />
        </v-col>

        <v-col
          cols="12"
          class="file-upload__btn"
          :class="{ float: tempFile === null }"
        >
          <S3FileUploadButton
            ref="fileUploadDriverLicense"
            uploadOnSelect
            :bucketName="bucketName"
            :path="path"
            :tempFile.sync="tempFile"
            :savedFile.sync="savedFile"
            :isCamera="true"
          >
            {{ !!tempFile ? '다른파일 업로드' : '주민등록증 업로드' }}
          </S3FileUploadButton>
        </v-col>
      </v-row>

      <v-row class="mt-3" no-gutters>
        <v-col cols="12">
          <div class="label"><v-icon small>mdi-account</v-icon>이름</div>
          <v-text-field
            outlined
            single-line
            v-model="cddForm.name"
            :rules="rules.name"
            :loading="isLoading"
            label="성명을 입력해주세요"
            hide-details
            disabled
            readonly
            class="mb-3"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="mb-3">
          <div class="label">
            <v-icon small>mdi-account-details-outline</v-icon>주민등록번호
          </div>
          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field
                outlined
                single-line
                v-model="jumin.jumin1"
                name="jumin1"
                maxlength="6"
                :error-messages="jumin.jumin1ErrorMsg"
                :hide-details="!!!jumin.jumin1ErrorMsg"
                :class="{ 'error--text': !!jumin.jumin1ErrorMsg }"
                readonly
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="height: 56px"
            >
              <p class="ma-0">
                ㅡ
              </p>
            </v-col>

            <v-col cols="7">
              <v-text-field
                outlined
                single-line
                v-model="jumin.jumin2"
                :append-icon="jumin.jumin2Show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.jumin2"
                :type="jumin.jumin2Show ? 'text' : 'password'"
                :hide-details="
                  jumin.jumin2 === null ||
                    rules.jumin2[0](jumin.jumin2) === true
                "
                name="jumin2"
                maxlength="7"
                label="뒷자리(7자리)"
                @click:append="jumin.jumin2Show = !jumin.jumin2Show"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div class="label"><v-icon small>mdi-calendar</v-icon>발급일자</div>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="cddForm.issueDate"
            width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="cddForm.issueDate"
                outlined
                single-line
                label="발급일자를 선택해주세요"
                :disabled="isLoading"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="cddForm.issueDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1891-01-01"
              @change="dateSave"
            >
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="width-100 font-size--16"
            height="45px"
            :disabled="isOk !== true || isLoading"
            :loading="isLoading"
            @click="saveCddForm()"
          >
            주민등록증 등록하기
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="d-flex align-center justify-center"
          style="min-height: 300px"
        >
          <div v-html="residentCardSwitch.contents" class="text-center"></div>
        </v-col>
      </v-row>
    </template>

    <SwitchComponent
      switch-name="RESIDENTCARD"
      :switching.sync="residentCardSwitch"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import SkeletonIdLoader from '@/components/common/SkeletonIdLoader.vue'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton.vue'
import SwitchComponent from '@/components/SwitchComponent.vue'

export default {
  components: {
    S3FileUploadButton,
    SkeletonIdLoader,
    SwitchComponent,
  },
  data() {
    return {
      isLoading: false,
      modal: false,
      jumin: {
        jumin1: null,
        jumin1ErrorMsg: null,
        jumin2: null,
        jumin2Show: false,
      },
      cddForm: {
        jumin: null,
        name: null,
        issueDate: null,
        cddFile: null,
      },
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/id-cards/',
      tempFile: null,
      savedFile: null,
      rules: {
        name: [value => !!value || '휴대폰 본인인증 후 자동입력됩니다'],
        jumin2: [
          value => !!value || '주민등록번호를 입력해주세요.',
          value => /[1-4]\d{6}/.test(value) || '주민번호가 유효하지 않습니다',
        ],
        issueDate: [value => !!value || '발급일자를 선택해주세요.'],
      },
      residentCardSwitch: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isOff() {
      return this.residentCardSwitch?.isOff === true
    },
    isValidJumin() {
      return (
        !!this.jumin.jumin1 &&
        !!this.jumin.jumin2 &&
        this.jumin.jumin1ErrorMsg === null &&
        this.jumin.jumin2ErrorMsg === null
      )
    },
    isValidForm() {
      const f = this.cddForm
      const file = f.cddFile
      const fileIsValid = !!file?.orgName && !!file?.tempKey

      return !!f.jumin && !!f.name && !!f.issueDate && fileIsValid
    },
    isOk() {
      if (this.isValidJumin !== true) {
        return false
      }

      return this.isValidForm === true
    },
  },
  watch: {
    'jumin.jumin1': {
      immediate: true,
      handler(v) {
        if (v?.length !== 6) {
          this.jumin.jumin1ErrorMsg = '휴대폰 본인인증 후 자동입력됩니다.'
        } else {
          this.jumin.jumin1ErrorMsg = null
        }
      },
    },
    'jumin.jumin2': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('jumin2', newVal)
        this.jumin.jumin2ErrorMsg = msg
        this.cddForm.jumin = `${this.jumin.jumin1}${this.jumin.jumin2}`
      },
    },
    profile: {
      immediate: true,
      deep: true,
      handler(v) {
        this.cddForm.name = v?.name

        if (!!v && !!v.userBirthdate) {
          let repl = v.userBirthdate.replace(/-/g, '')
          this.jumin.jumin1 = repl.slice(-6)
        }
      },
    },
    tempFile(v) {
      if (!!v) {
        this.cddForm.cddFile = v
      }
    },
    modal(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    ...mapMutations('auth', {
      SET_USER_VALIDATION: AuthTypes.mutations.SET_USER_VALIDATION,
    }),
    ...mapActions('auth', {
      GET_USER_VALIDATION: AuthTypes.actions.GET_USER_VALIDATION,
    }),
    dateSave(date) {
      this.$refs.dialog.save(date)
    },
    getFormErrMsg(type, newVal) {
      const rules = this.rules[type]
      let msg = null
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const isValid = rule(newVal)
        if (typeof isValid !== 'boolean') {
          msg = isValid
          break
        }
      }

      return msg
    },
    async saveCddForm() {
      if (this.isOk !== true) {
        this.$swal.basic.warning('정보를 입력해주세요')
        return
      }

      let alert = await this.$swal.basic.confirm('신분증 등록을 진행합니다')
      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      let q = `
        data: registerResidentCard(userIdx: ${this.userIdx}, residentCard: $input) {
          userIdx
          userCddIdx
        }
      `

      try {
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: ResidentCard!)',
          { input: this.cddForm }
        )

        if (!!data?.userCddIdx) {
          const uv = await this.GET_USER_VALIDATION()
          this.SET_USER_VALIDATION(uv)
        } else {
          this.$swal.basic.error(
            '신분증 등록 중 에러가 발생하였습니다. 다시 시도해주세요.'
          )
        }
      } catch (e) {
        this.$swal.basic.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-upload {
  position: relative;
  margin-bottom: 15px;
  text-align: center;

  input {
    min-width: 200px;
    max-width: 280px;
  }

  &__btn {
    &.float {
      position: absolute;
      display: flex;
      height: 206px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
