<template>
  <section id="section__manager" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row no-gutters style="margin-bottom: 5%">
        <v-col cols="12">
          <p class="section-title">
            <img src="@/assets/images/logo.png" />

            경영진 소개
          </p>

          <p
            class="ma-0 black--text font-size--15 text-center"
            style="line-height: 1.3"
          >
            펀다는 소상공인의 금융 니즈를 기술로 혁신합니다.
          </p>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          v-for="manager in managers"
          :key="manager.nickname"
          cols="12"
          class="manager-card-wrapper"
        >
          <div class="manager-card">
            <div class="manager-card__left">
              <picture class="manager-card__img">
                <img :src="`${cdnPath}${manager.img}`" />
              </picture>
            </div>
            <div class="manager-card__right">
              <v-row no-gutters>
                <v-col cols="12" class="manager-card__name">
                  <p class="nickname">
                    {{ manager.nickname }}
                  </p>
                  <p class="realname">
                    {{ realnameText(manager) }}
                  </p>
                </v-col>

                <v-col cols="12">
                  <p class="manager-card__talk">
                    {{ manager.talk }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  data() {
    return {
      cdnPath: '//cdn.funda.kr/funding/common/image/_members/funda/',
      managers: [
        {
          img: 'sean.png',
          nickname: 'Sean',
          realname: '박성준',
          position: '대표이사',
          birth: '1973-01-01',
          talk: `서울대 전기공학부 학사, 석사, 박사를 수료하고 3개의 기술 스타트업을 연쇄 창업했습니다. 
            기술기반의 훌륭한 팀을 구성하고 운영하는 방법론에 탁월한, 따뜻한 리더십을 가지고 있습니다.`,
        },
        {
          img: 'joseph.png',
          nickname: 'Joseph',
          realname: '조상욱',
          position: '부대표',
          birth: '1968-08-28',
          talk: `부산대 법학과, 연세대 경제대학원, 금융감독원 16년간 재직과 대형 금융기관 포함 29년 경력을
          보유하고 있습니다. 금융 당국과 대형 금융기관에 엄청난 인맥을 보유한 최고의 사람부자입니다.`,
        },
        {
          img: 'julius.png',
          nickname: 'Julius',
          realname: '임성광',
          position: 'CTO',
          birth: '1988-05-08',
          talk: `KAIST 전산학과를 졸업하고 기술 스타트업을 창업, 5년간 Full stack 개발 경험을 치열하게 쌓은
          천재 개발자입니다. 머신러닝 연구와 서비스 개발의 모든 분야에 구석구석 관여하고 있지만 비즈니스에 대해서도
          엄청난 인사이트와 소통력을 겸비하고 있습니다.`,
        },
      ],
    }
  },
  methods: {
    realnameText(manager) {
      // const now = new Date()
      // const birth = new Date(manager.birth)
      // const age = now.getFullYear() - birth.getFullYear()
      return `${manager.realname} ${manager.position}`
    },
  },
}
</script>

<style lang="scss" scoped>
#section__manager {
  background-color: white;

  .section-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    max-width: 290px;
    width: 90%;

    border-bottom: 1px solid black;
    color: #333;
    font-size: 25px;
    line-height: 1;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    padding-bottom: 16px;

    img {
      width: 100px;
      object-fit: contain;
      margin-right: 8px;
    }
  }

  .manager-card-wrapper:not(:last-child) {
    margin-bottom: 28px;
  }

  .manager-card {
    display: flex;
    max-width: 95%;
    width: 100%;

    margin: 0 auto;

    &__left {
      max-width: 145px;
      min-width: 105px;
      width: 40%;
      padding-right: 10px;

      img {
        max-width: 135px;
        max-height: 135px;
        width: 100%;
        background-color: #eaeff4;
        border-radius: 100%;
        object-fit: contain;
      }
    }

    &__right {
      p {
        margin: 0;
      }
    }

    &__name {
      display: flex;
      align-items: baseline;

      .nickname {
        border-right: 1px solid gray;
        color: #1baaf1;
        font-size: 20px;
        font-weight: 900;
        line-height: 1;
        padding-right: 8px;
      }

      .realname {
        font-size: 16px;
        font-weight: bold;
        padding-left: 8px;
      }
    }

    &__talk {
      font-size: 16px;
      line-height: 1.3;
      margin-top: 8px !important;
      word-break: break-all;
    }
  }

  &.mobile {
    .manager-card {
      max-width: 100%;

      &__name {
        .nickname {
          font-size: 18px;
        }

        .realname {
          font-size: 14px;
          font-weight: bold;
        }
      }

      &__talk {
        font-size: 14px;
        margin-top: 8px !important;
      }
    }
  }
}
</style>
