import { numberFormat, removeComma } from '@/util'
import Vue from 'vue'

const getInputTag = (el) => {
  if (el.tagName.toLowerCase() === 'input') {
    return el
  } else {
    return el.querySelector('input')
  }
}

const getMaxValue = (el) => {
  const max = el.getAttribute('max') ?? null
  return max === null ? max : parseInt(max, 10)
}

const getModel = (context, path) => {
  let result = context
  for (const p of path) {
    result = result?.[p]
  }
  return result
}

const setValue = (context, expression, value) => {
  const path = expression.split('.')
  const [lastKey] = path.slice(-1)
  const model = getModel(context, path.slice(0, path.length - 1))
  model[lastKey] = value
}

const inputFunding = {
  bind(el, bind, vnode) {
    const input = getInputTag(el)

    input.addEventListener('input', (e) => {
      e.target.value = removeComma(e.target.value)
      inputFunding.update(e.target, bind, vnode)
    })
    // 최초 로딩시 적용
    inputFunding.update(el, bind, vnode)
  },
  update(el, bind, vnode) {
    const input = getInputTag(el)
    let nv = bind.value ?? null
    if (nv === null && !!input.value) {
      const tmp = parseInt(input.value)
      nv = tmp
    }

    if (nv === 0 || nv === null) {
      setValue(vnode.context, bind.expression, null)
      input.value = null
      return
    }
    const maxValue = getMaxValue(input)
    nv = parseInt(nv)
    nv = nv > maxValue ? maxValue : nv

    const v = numberFormat(nv)
    setValue(vnode.context, bind.expression, nv)
    input.value = v
  },
}

Vue.directive('input-funding', inputFunding)
