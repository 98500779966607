<template>
  <v-card v-if="isAuth === true" class="alarm-board">
    <v-card-title
      class="alarm-board__title pa-3 mx-auto align-center justify-space-between"
      style="max-width: 768px"
    >
      <a>
        <v-icon v-if="isMobile" large @click="closeDialog()">
          mdi-chevron-left
        </v-icon>
      </a>
      <p class="headline ma-0 text-ellipsis" style="line-height: 1">
        알림
      </p>
      <a></a>
    </v-card-title>
    <v-card-text class="pa-3" :class="{ mobile: isMobile }">
      <template v-if="isLoading">
        <div class="d-flex align-center justify-center">
          <MyLoader />
        </div>
      </template>
      <template v-else>
        <template v-if="alarms.length > 0">
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-btn
                class="btn-funda--black"
                rounded
                height="30"
                :loading="isReadLoading"
                :disabled="hasUnread === false || isReadLoading"
                @click="setReadAll()"
              >
                모두읽기
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              v-for="alarm in alarms"
              :key="`alarm_${alarm.idx}`"
              cols="12"
              class="alarm-box"
              @click="clickAlarm(alarm)"
            >
              <p
                class="alarm-box__category"
                :style="getCategoryColor(alarm.alarmCategory)"
              >
                [{{ alarm.alarmCategory.name }}]
              </p>
              <div class="alarm-box__contents">
                <v-badge
                  v-if="!alarm.isRead"
                  class="alarm-box__unread"
                  color="pink"
                  dot
                >
                </v-badge>
                <div v-html="alarm.contents" class="mb-1"></div>
                <p class="alarm-box__create-time">
                  {{ alarm.createTime | dateFormat }}
                </p>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="d-flex align-center justify-center">
            <p class="mt-5 mb-0 font-size--15">
              알림 내역이 없습니다.
            </p>
          </div>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { AlarmTypes } from '@/store/types/alarm'
import { OverlayTypes } from '@/store/types/overlay'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: {
    MyLoader,
  },
  data() {
    return {
      isLoading: false,
      isReadLoading: false,
      alarms: [],
      categoryColor: {
        1: '#1baaf1',
        2: '#f9356a',
        3: '#ff893a',
        4: '#58b147',
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    ...mapGetters('overlay', {
      overlayKey: OverlayTypes.getters.GET_OVERLAY,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    hasUnread() {
      const result = this.alarms.some((a) => a.isRead === false)
      return result
    },
  },
  // watch: {
  //   overlayKey: {
  //     immediate: true,
  //     handler(v) {
  //       if (v === '#alarm') {
  //         this.getAlarms()
  //       }
  //     },
  //   },
  // },
  created() {
    this.getAlarms()
  },
  methods: {
    ...mapActions('alarm', {
      getHasUnread: AlarmTypes.actions.GET_HAS_UNREAD,
    }),
    ...mapActions('overlay', {
      closeDialog: OverlayTypes.actions.CLOSE_OVERLAY,
    }),
    getCategoryColor(category) {
      const color = this.categoryColor[category.idx]
      return `color: ${color}`
    },
    async getAlarms() {
      if (this.isLoading) {
        return
      }

      const q = `
        data: getAllUserAlarms(userIdx: ${this.userIdx}) {
          idx
          isRead
          contents
          link
          createTime
          readTime

          alarmCategory {
            idx
            name
          }
        }
      `

      this.isLoading = true

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.alarms = data
      } finally {
        this.isLoading = false
      }
    },
    async setReadAll() {
      if (this.isReadLoading) {
        return
      }

      const yes = await this.$swal.basic.confirm({
        html:
          '읽지 않은 알람이 <br /> <span style="font-weight: 700">모두 읽음처리</span> 됩니다',
      })

      if (yes.isConfirmed === false) {
        return
      }

      if (this.hasUnread === false) {
        this.$swal.basic.alert('모두 읽음 상태입니다.')
        return
      }

      const q = `data: readAllUserAlarm(userIdx: ${this.userIdx})`

      this.isReadLoading = true

      try {
        await this.$fundaApi.mutation(gql`{${q}}`)
        this.getAlarms()
      } finally {
        this.getHasUnread()
        this.isReadLoading = false
      }
    },
    async setRead(alarm) {
      if (this.isReadLoading) {
        return
      }

      if (alarm.isRead === true) {
        return
      }

      let q = `
        data: readUserAlarm(userAlarm: {
          idx: ${alarm.idx},
          userIdx: ${this.userIdx}
        }) {
          idx
          userIdx
          isRead
        }
      `

      this.isReadLoading = true

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)
        alarm.isRead = data.isRead
      } finally {
        this.getHasUnread()
        this.isReadLoading = false
      }
    },
    async clickAlarm(alarm) {
      await this.setRead(alarm)
      if (alarm.link) {
        if (alarm.link.startsWith('http')) {
          window.open(alarm.link, '_blank')
        } else {
          this.$router.push({ path: alarm.link })
          this.closeDialog()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alarm-board {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &__title {
    a {
      width: 36px;
      height: 36px;
      font-size: 36px;

      &.opacity-0 {
        cursor: default !important;
      }
    }
  }
}

.alarm-box {
  margin-top: 8px;
  margin-bottom: 15px;

  &__category {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
  }

  &__contents {
    position: relative;
    padding: 16px 15px 28px;
    background-color: #ffffff !important;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
    cursor: pointer;
  }

  &__unread {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__create-time {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #727d8c;
    margin: 0;
  }
}

::v-deep .v-card__text {
  min-height: calc(500px - 60px);
  background-color: rgb(238, 238, 238, 0.4);

  &.mobile {
    min-height: calc(100% - 60px);
  }
}
</style>
