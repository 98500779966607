<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center" rowspan="2">구분</th>
                <th class="text-center" colspan="3">수탁자</th>
              </tr>
              <tr>
                <th class="text-center">법무법인등</th>
                <th class="text-center">회계법인</th>
                <th class="text-center">기타</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">원리금 상환・배분업무에 관한 계획</td>
                <td class="text-center">-</td>
                <td class="text-center">{{ accountCorp }}</td>
                <td class="text-center">-</td>
              </tr>

              <tr>
                <td class="text-center">연계대출채권 등의 관리 계획</td>
                <td class="text-center">-</td>
                <td class="text-center">{{ accountCorp }}</td>
                <td class="text-center">-</td>
              </tr>

              <tr>
                <td class="text-center">원리금 상환・배분업무에 관한 계획</td>
                <td class="text-center">-</td>
                <td class="text-center">{{ accountCorp }}</td>
                <td class="text-center">-</td>
              </tr>

              <tr>
                <td class="text-center">
                  연계투자 및 연계대출 계약의 관리 계획
                </td>
                <td class="text-center">-</td>
                <td class="text-center">{{ accountCorp }}</td>
                <td class="text-center">-</td>
              </tr>

              <tr>
                <td class="text-center">그 밖의 사항</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '원리금 상환 배분 업무에 관한 계획 등 위탁 현황',
    },
  },
  data() {
    return {
      accountCorp: '회계법인혜안',
    }
  },
}
</script>

<style lang="scss" scoped></style>
