<template>
  <div>
    <div class="cursor--pointer font-size--13" @click="ON_INCREASE_DIALOG()">
      <slot>
        투자 한도 증액하기
        <v-icon>mdi-chevron-right</v-icon>
      </slot>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  methods: {
    ...mapActions('auth', {
      ON_INCREASE_DIALOG: AuthTypes.actions.ON_INCREASE_DIALOG,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
