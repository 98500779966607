<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-1 font-size--18 font-weight-bold">
          개인 전문투자자
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <table class="width-100">
          <thead>
            <tr>
              <th width="50%" class="font-size--15">분류기준</th>
              <th class="font-size--15">증빙자료</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-1">
                <p class="mb-5 font-size--14">
                  필수 요건 + 선택 요건 1가지를 충족 하는 경우
                </p>
                <p class="mb-1 font-size--14 font-weight-bold">
                  ■ 필수 요건
                </p>
                <p class="mb-5 font-size--13">
                  최근 5년 중 1년 이상 (금융투자상품 인정 잔고) 월 말 평균
                  5천만원 이상
                </p>

                <p class="mb-1 font-size--14 font-weight-bold">
                  ■ 선택 요건 (아래 중 택 1)
                </p>
                <p class="mb-1 font-size--13">(1) 소득 요건</p>
                <p class="mb-3 font-size--13">
                  본인 1억원 또는 부부 합산 1.5억원
                </p>

                <p class="mb-1 font-size--13">(1) 자산 요건</p>
                <p class="mb-3 font-size--13">
                  부부합산 순 재산가액 5억원 (거주 부동산 관련 금액 제외)
                </p>

                <p class="mb-1 font-size--13">(1) 전문가 요건</p>
                <p class="mb-1 font-size--13">
                  - 회계사, 감평사, 변호사, 변리사, 세무사 (1년 이상 종사자)
                </p>
                <p class="mb-1 font-size--13">
                  - 투자자산운용사, 금융투자분석사, (국제) 재무 위험 관리사
                </p>
                <p class="mb-0 font-size--13">
                  - 금융투자업 주요 직무 종사자
                </p>
              </td>
              <td class="px-1">
                <p class="mb-2 font-size--14">
                  증권사로부터 발급받은 '전문 투자자 확인증'
                </p>
                <a
                  class="font-size--13"
                  target="_blank"
                  href="https://blog.naver.com/fundamaster/221758697966"
                >
                  자세히 보기
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>

    <v-row class="mt-3" no-gutters>
      <v-col v-if="isAlreadyLimit4 || isWaitingStatus" cols="12" class="mt-5">
        <p class="color--pink mb-0 font-size--14 text-center">
          {{
            isAlreadyLimit4
              ? `현재 ${curMyType} 입니다.`
              : '서류 접수가 완료되어 심사가 진행중입니다.'
          }}
        </p>
      </v-col>
      <template v-else>
        <v-col cols="12">
          <div class="mb-1">
            <S3FileUploadButton
              ref="fileUploadLimit2_1"
              uploadOnSelect
              :bucketName="bucketName"
              :path="path"
              :tempFile.sync="tempFile"
              :savedFile.sync="savedFile"
              dark
            >
              {{ !!tempFile ? '다른파일 업로드' : '서류1 업로드' }}
            </S3FileUploadButton>
            <p v-if="!!tempFile" class="my-1 font-size--14">
              업로드된 파일: {{ tempFile.orgName }}
            </p>
          </div>
        </v-col>

        <v-col cols="12" class="pt-5 text-center">
          <v-btn
            class="btn-funda--blue common-button"
            height="45px"
            :loading="isLoading"
            :disabled="isDisabled"
            @click="submitRequest()"
          >
            제출하기
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton.vue'

export default {
  components: {
    S3FileUploadButton,
  },
  data() {
    return {
      isLoading: false,
      requestLoading: false,
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/investor_type/',
      requestUser: {
        investorType: 4,
        files: [],
      },
      tempFile: null,
      savedFile: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      myReq: AuthTypes.getters.GET_REQ_LIMIT,
    }),
    curMyType() {
      return this.profile?.investmentLimit.type
    },
    isAlreadyLimit4() {
      return this.profile?.investmentLimit.idx >= 3
    },
    isWaitingStatus() {
      return this.myReq?.status === 'WAITING'
    },
    isDisabled() {
      if (this.isLoading) {
        return true
      }

      if (this.isWaitingStatus) {
        return true
      }

      return !!!this.tempFile
    },
  },
  created() {
    this.getMyReq()
  },
  methods: {
    ...mapActions('auth', {
      getMyReq: AuthTypes.actions.GET_REQ_LIMIT,
    }),
    async submitRequest() {
      if (this.isDisabled) {
        return
      }

      let alert = await this.$swal.basic.confirm(
        `[${this.curMyType}] -> [전문투자자]로 투자한도 증액을 신청합니다`
      )

      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      this.requestUser.files = [this.tempFile]
      const q = `
        updateInvestmentTypeFile(userIdx: ${this.userIdx}, signupUserStep1: $input) {
          idx
          userIdx
          status
        }
      `

      try {
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: SignupUserStep1)',
          { input: this.requestUser }
        )

        if (!!data) {
          this.$swal.basic.success({
            html:
              '증빙서류 접수가 완료되었습니다. <br /> 서류 검토 후 승인이 완료될 예정입니다. <br />(영업일 기준 1일 이내)',
          })
        }
      } catch (e) {
        this.$swal.basic.warning(e.message)
      } finally {
        this.isLoading = false
        this.getMyReq()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
td p {
  line-height: 1.1;
}

.common-button {
  max-width: 150px;
  width: 100%;
  font-size: 15px;
  padding: 15px 0;
}
</style>
