export default {
  callProxyPayment(paymentProxyDto) {
    if (paymentProxyDto.body != null) {
      paymentProxyDto.body = JSON.stringify(paymentProxyDto.body)
    }
    return this.graphql({
      // mutation 고정
      query: gql`mutation call($uri: String!, $method: String!, $body: String){
        data : callProxyPayment(paymentProxyDto:{
          uri: $uri,
          method: $method,
          body: $body
        })
      }`,
      variables: paymentProxyDto
    })
  }
}
