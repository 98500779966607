import { render, staticRenderFns } from "./ChangePasswordSection.vue?vue&type=template&id=6ece1b63&scoped=true&"
import script from "./ChangePasswordSection.vue?vue&type=script&lang=js&"
export * from "./ChangePasswordSection.vue?vue&type=script&lang=js&"
import style0 from "./ChangePasswordSection.vue?vue&type=style&index=0&id=6ece1b63&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ece1b63",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VFadeTransition,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
