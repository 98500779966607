import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Regex from '@/const/Regex'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('phone', value => {
  const regex = /^01\d{8,9}$/
  return regex.test(value)
})

extend('password', {
  validate(value) {
    const regex = Regex.PASSWORD.REGEX
    return regex.test(value)
  },
  message: Regex.PASSWORD.MSG
})

extend('repassword', {
  validate(value, { password }) {
    return value === password
  },
  params: ['password'],
  message: '비밀번호가 일치하지 않습니다.'
})

extend('phone', {
  validate(value) {
    const regex = Regex.PHONE.REGEX
    return regex.test(value)
  },
  message: Regex.PHONE.MSG
})

extend('email', {
  validate(value) {
    const regex = Regex.EMAIL.REGEX
    return regex.test(value)
  },
  message: Regex.EMAIL.MSG
})

extend('id_duplicate', {
  validate(value) {
    return new Promise(async resolve => {
      let { idValid } = await global.$fundaApi.query(gql`{
        idValid : getSignupUserIdValid(id: "${value}")
      }`)
      resolve(idValid === true ? idValid : '이미 등록된 아이디 입니다.')
    })
  }
})

extend('email_duplicate', {
  validate(value) {
    return new Promise(async resolve => {
      let result = false
      let msg = '이미 가입된 이메일 주소입니다.'
      try {
        let { emailValid } = await global.$fundaApi.query(gql`{
          emailValid : getSignupUserEmailValid(id: "${value}")
        }`)
        result = emailValid
      } catch (e) {
        if (e.status === 400 && e.errors[0].message === '이메일 주소가 유효하지 않습니다.') {
          msg = e.errors[0].message
        } else {
          throw e
        }
      }
      resolve(result === true ? result : msg)
    })
  }
})
