<template>
  <v-row class="signup" no-gutters>
    <v-col cols="12">
      <div class="signup-board my-5">
        <v-tabs
          v-model="tab"
          class="d-none"
          background-color="transparent"
          grow
          hide-slider
        >
          <v-tab v-for="(item, index) in signupTabs" :key="index"> </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="(item, index) in signupTabs" :key="index">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex align-center justify-space-between px-0"
              >
                <v-btn
                  v-if="tab > 0"
                  small
                  fab
                  text
                  tile
                  width="24"
                  @click="prevStep()"
                >
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
                <p class="header color-black my-0">
                  {{ item.title }}
                </p>
                <v-btn
                  small
                  fab
                  text
                  tile
                  width="24"
                  :class="{ 'opacity-0 cursor--none': item.isValid !== true }"
                >
                  <v-icon v-if="item.isValid === true" @click="nextStep()">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card elevation="0" class="py-0" min-height="700px">
              <v-card-text class="px-0">
                <keep-alive>
                  <component
                    v-bind:is="item.content"
                    @next-step="nextStep"
                  ></component>
                </keep-alive>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import SignupGuide from './step/SignupGuide'
import SignupMobile from './step/SignupMobile'
import SignupCDD from './step/SignupCDD'
import SignupEDD from './step/SignupEDD'
import SignupAccount from './step/SignupAccount'
import SignupComplete from './step/SignupComplete'

export default {
  components: {
    SignupGuide,
    SignupMobile,
    SignupCDD,
    SignupEDD,
    SignupAccount,
    SignupComplete,
  },
  data() {
    return {
      tab: 0,
      tabItems: [
        { title: '회원등록 가이드', content: 'SignupGuide', isValid: true },
        {
          title: '휴대폰인증',
          name: 'isValidPhone',
          content: 'SignupMobile',
          isValid: false,
        },
        {
          title: '신분증 등록',
          name: 'isValidIdentification',
          content: 'SignupCDD',
          isValid: false,
        },
        {
          title: '출금계좌 등록',
          name: 'isValidAccount',
          content: 'SignupAccount',
          isValid: false,
        },
        {
          title: '추가 정보 등록',
          name: 'isValidEdd',
          content: 'SignupEDD',
          isValid: false,
        },
        {
          title: '인증 정보 등록 완료',
          content: 'SignupComplete',
          isValid: false,
        },
      ],
    }
  },
  created() {
    this.$cookies.remove('recommender')
    this.$cookies.remove('promotion')
  },
  computed: {
    ...mapGetters('auth', {
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isCorplInvestor() {
      return this.profile?.investmentLimit?.idx === 3
    },
    signupTabs() {
      let result = []
      this.tabItems.forEach((t) => {
        if (t.content === 'SignupMobile') {
          if (this.isCorplInvestor === false) {
            result.push(t)
          }
        } else {
          result.push(t)
        }
      })

      return result
    },
    maxTab() {
      return this.signupTabs.length - 1
    },
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!!v) {
          this.tabItems.forEach((i) => {
            if (!!i.name) {
              i.isValid = v[i.name]
            }
          })
        }
      },
    },
    tab() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
  methods: {
    redirect() {
      let redirectUri = this.$route.query.redirectUri
      if (redirectUri == null || redirectUri.indexOf('/signup') > -1) {
        redirectUri = '/'
      }
      this.$router.push({ path: redirectUri })
    },
    prevStep() {
      const prev = this.tab - 1
      // const prevTab = this.signupTabs[prev]

      // if (prevTab.isValid === true) {
      //   prev--
      // }
      this.tab = prev < 0 ? 0 : prev
    },
    nextStep() {
      const max = this.maxTab
      const next = this.tab + 1

      this.tab = next > max ? max : next
    },
  },
}
</script>
