import { render, staticRenderFns } from "./DriverLicense.vue?vue&type=template&id=5bac3cb9&scoped=true&"
import script from "./DriverLicense.vue?vue&type=script&lang=js&"
export * from "./DriverLicense.vue?vue&type=script&lang=js&"
import style0 from "./DriverLicense.vue?vue&type=style&index=0&id=5bac3cb9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bac3cb9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VIcon,VRow,VSubheader,VTextField})
