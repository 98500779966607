export default [
  {
    name: 'terms',
    path: '/terms',
    meta: {
      layout: 'LegacyLayout',
    },
    component: require('@/pages/terms/Terms.vue').default,
  },
]
