<template>
  <Switching v-if="showOther" />
  <div
    v-else-if="popup"
    class="custom-popup"
    :class="{ show: showPopup }"
    @click="showPopup = false"
  >
    <div
      class="custom-popup__contents color--body"
      @click.stop="showPopup = true"
    >
      <div
        class="popup-body"
        :class="{ 'my-4': !!popup.title }"
        style="line-height: 0"
      >
        <h3 v-if="!!popup.title" class="text-header">{{ popup.title }}</h3>
        <div v-html="popup.contents"></div>
      </div>
      <div class="popup-footer">
        <div class="popup-not-showing" @click.stop="closeOneDay()">
          오늘하루 안보기
        </div>
        <div class="popup-close-btn" @click.stop="showPopup = false">
          닫기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switching from './PopupForNewbie.vue'

export default {
  components: {
    Switching,
  },
  data() {
    return {
      showOther: false,
      showPopup: true,
      popup: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.getPopupData()
  },
  methods: {
    getCookie() {
      if (this.popup === null) {
        return
      }

      let v = document.cookie.match(
        '(^|;) ?' + this.popup.name + '=([^;]*)(;|$)'
      )

      return !!v ? v[2] : null
    },
    setCookie() {
      if (this.popup === null) {
        return
      }

      let d = new Date()
      let days = 1
      let value = 1

      d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * days)
      document.cookie =
        this.popup.name + '=' + value + ';path=/;expires=' + d.toGMTString()
    },
    closeOneDay() {
      this.setCookie()
      this.showPopup = false
    },
    async getPopupData() {
      const q = `
        data: wwwPopup {
          name
          title
          contents
        }
      `

      const data = await this.$fundaApi.query(gql`{${q}}`)
      if (data !== null) {
        this.popup = data
      } else {
        this.showOther = true
      }

      const popupCookie = this.getCookie()

      if (!!popupCookie) {
        this.showPopup = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup.show > .custom-popup__contents {
  position: fixed;
  top: 100px;
  animation: moveDown 0.7s;
}

@keyframes moveDown {
  0% {
    top: 30px;
  }
  100% {
    top: 100px;
  }
}

@media screen and (max-height: 800px) {
  .custom-popup.show > .custom-popup__contents {
    top: 50px;
  }

  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 50px;
    }
  }
}
</style>
