<template>
  <div class="width-100 margin-t-5">
    <div class="width-100 balance-deposit width-100 mb-5">
      <div
        class="my-balance-deposit-table__filter d-flex margin-b-10"
        v-if="filters != null"
        :class="{ mobile: isMobile }"
      >
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="text-center cursor--pointer padding-y-10 padding-x-15"
          :class="[
            { active: filter.value == selectedFilter },
            filter.value == selectedFilter
              ? 'color--gray-3 font-weight-black'
              : 'color--gray-2 font-weight-bold',
          ]"
          @click="changeOption(filter.value)"
        >
          {{ filter.name }}
        </div>
        <div
          class="point-balance d-flex justify-end align-center"
          :class="{ 'margin-t-15 margin-b-10': isMobile }"
        >
          <span
            class="d-flex justify-center align-center color--white margin-r-5"
            >P</span
          >
          <span>{{ pointBalance | commaFormat }}</span>
        </div>
      </div>
      <MyBalanceDepositTable
        :data="pagingData"
        :headers="headers"
        :is-loading="tableData.isLoading"
      >
        <template slot="col" slot-scope="slotProps">
          <div
            :style="isMobile ? styles.dateTime.mobile : styles.dateTime.pc"
            class="color--gray-2 font-weight-bold"
          >
            {{ slotProps.row.dateTime | dateFormat2 }}
          </div>
          <div
            :style="
              isMobile
                ? styles.selectedFilterName.mobile
                : styles.selectedFilterName.pc
            "
            class="font-weight-black font-size--13"
          >
            {{ selectedFilterName }}
          </div>
          <div
            :style="isMobile ? styles.memo.mobile : styles.memo.pc"
            class="font-weight-bold my-balance-deposit-table__text"
          >
            <span>{{ slotProps.row.memo }}</span>
          </div>
          <div
            :style="isMobile ? styles.amount.mobile : styles.amount.pc"
            class="justify-end font-weight-black font-size--14"
          >
            {{ slotProps.row.amount | commaFormat }} P
          </div>
          <div
            :style="
              selectedFilter != 'GIVE'
                ? 'display: none;'
                : isMobile
                ? styles.expireTime.mobile
                : styles.expireTime.pc
            "
            class="font-weight-bold"
          >
            <p v-if="isMobile" class="my-0 mr-2 font-weight-black">소멸일</p>
            <p class="ma-0 color--gray-2">
              <span v-if="isMobile">~</span>
              {{ slotProps.row.expireTime | dateFormat2 }}
            </p>
          </div>
        </template>
      </MyBalanceDepositTable>
    </div>
    <PageMaker
      v-if="tableData.transactions"
      :key="selectedFilter"
      :is-reverse="true"
      :page="paging.page"
      :size="paging.size"
      :total-count="paging.totalCount"
      :raw-data="tableData.transactions"
      :data-loading="tableData.isLoading"
      :is-white="false"
      @currentData="setPageData($event)"
      @currentPage="paging.page = $event"
    />
  </div>
</template>

<script>
import MyBalanceDepositTable from '@/components/myfunda/MyBalanceDepositTable'
import PageMaker from '@/components/PageMaker'

export default {
  components: {
    MyBalanceDepositTable,
    PageMaker,
  },
  data() {
    return {
      pagingData: null,
      selectedFilter: null,
      selectedFilterName: null,
      filters: [
        { name: '사용', value: 'USE' },
        { name: '소멸', value: 'EXPIRE' },
        { name: '지급', value: 'GIVE' },
      ],
      styles: {
        dateTime: {
          pc: {
            width: '15%',
            'justify-content': 'center',
          },
          mobile: {
            order: 1,
            width: '10%',
            minWidth: '90px',
          },
        },
        selectedFilterName: {
          pc: {
            width: '10%',
            'justify-content': 'center',
          },
          mobile: {
            order: 3,
            width: '30%',
          },
        },
        memo: {
          pc: {
            width: '53%',
            'justify-content': 'center',
          },
          mobile: {
            order: 2,
            width: '90%',
            maxWidth: 'calc(100% - 90px)',
            marginBottom: '7px',
          },
        },
        amount: {
          pc: {
            width: '22%',
          },
          mobile: {
            order: 4,
            width: '70%',
            marginBottom: '7px',
          },
        },
        expireTime: {
          pc: {
            width: '15%',
            'justify-content': 'center',
          },
          mobile: {
            order: 5,
            width: '100%',
            'justify-content': 'space-between',
          },
        },
      },
      headers: null,
      headersWithExp: [
        { name: '날짜', width: '15%' },
        { name: '구분', width: '10%' },
        { name: '내역', width: '38%' },
        { name: '포인트', width: '22%' },
        { name: '소멸일', width: '15%' },
      ],
      headersDefault: [
        { name: '날짜', width: '15%' },
        { name: '구분', width: '10%' },
        { name: '내역', width: '53%' },
        { name: '포인트', width: '22%' },
      ],
      tableData: {
        transactions: null,
        paging: null,
        isLoading: false,
      },
      pointBalance: 0,
      totalData: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  async created() {
    this.tableData.isLoading = true
    this.getPointBalance()

    this.selectedFilter = 'USE'
    this.selectedFilterName = this.filters.find(
      filter => filter.value === this.selectedFilter
    ).name

    this.setTableHeader()
    this.getPointContents()
  },
  methods: {
    setTableHeader() {
      if (this.selectedFilter === 'GIVE') {
        this.headers = this.headersWithExp
        this.styles.memo.pc.width = '38%'
      } else {
        this.headers = this.headersDefault
        this.styles.memo.pc.width = '53%'
      }
    },
    setCategoryData(datas) {
      let selectListName = null
      if (this.selectedFilter === 'GIVE') {
        selectListName = '지급내역'
      } else if (this.selectedFilter === 'USE') {
        selectListName = '사용내역'
      } else if (this.selectedFilter === 'EXPIRE') {
        selectListName = '소멸내역'
      }

      this.tableData.transactions = datas[selectListName]
      this.paging = {
        size: 20,
        page: 1,
        totalCount: datas[selectListName].length,
      }
    },
    setPageData(datas) {
      let headers = ['dateTime', 'memo', 'amount']
      let dataColumnNames = null
      if (this.selectedFilter === 'GIVE') {
        headers.push('expireTime')
        dataColumnNames = {
          dateTime: '지급일',
          memo: '비고',
          amount: '금액',
          expireTime: '유효기간',
        }
      } else if (this.selectedFilter === 'USE') {
        dataColumnNames = {
          dateTime: '사용일',
          memo: '사용처',
          amount: '금액',
        }
      } else if (this.selectedFilter === 'EXPIRE') {
        dataColumnNames = {
          dateTime: '소멸일',
          memo: '소멸사유',
          amount: '금액',
        }
      }

      let arr = []
      for (let i = 0; i < datas.length; i++) {
        let row = datas[i]

        let obj = {}
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = row[dataColumnNames[headers[j]]]
        }
        arr.push(obj)
      }
      this.pagingData = arr
    },
    async getPointContents() {
      let q = `
            data: wwwPointContents
          `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        let d = JSON.parse(data)
        this.totalData = d
        this.setCategoryData(d)
      } catch (e) {
        // alert('다시 시도해주세요')
      } finally {
        this.tableData.isLoading = false
      }
    },
    async getPointBalance() {
      let q = `
            data: wwwPointDeposit
          `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.pointBalance = data
      } catch (e) {
        this.pointBalance = 0
      }
    },
    changeOption(value) {
      this.selectedFilter = value
      this.selectedFilterName = this.filters.find(
        filter => filter.value === this.selectedFilter
      ).name
      this.setTableHeader()
      if (!!this.totalData) {
        this.setCategoryData(this.totalData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-balance-deposit-table__filter:not(.mobile) .point-balance {
  margin-left: auto;
  width: 160px;
}

.my-balance-deposit-table__filter.mobile .point-balance {
  width: 100%;
}

.my-balance-deposit-table__filter .point-balance {
  font-weight: 900;
  color: #333333;
  padding-left: 0;
  padding-right: 0;
}

.my-balance-deposit-table__filter .point-balance span:first-child {
  font-size: 17px;
  letter-spacing: -0.3px;
  width: 26px;
  height: 26px;
  background-color: #1baaf1;
  border-radius: 50%;
}

.my-balance-deposit-table__filter .point-balance span:last-child {
  font-size: 22px;
}
</style>
