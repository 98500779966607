<template>
  <div class="landing-rubystone-invitation__wrapper font-nanumsquare">
    <Header :elevation="0" :max-width="$vuetify.breakpoint.mobileBreakpoint" />
    <v-main
      class="landing-rubystone-invitation__container color--gray-3"
      :style="{ maxWidth: $vuetify.breakpoint.mobileBreakpoint + 'px' }"
    >
      <!-- 헤더 및 통계 겹침 영역 시작 -->
      <div
        class="section__header-stats__wrapper position--relative background--white"
      >
        <!-- 헤더 시작 -->
        <div
          class="section__header background--ruby color--white font-size--16 text-center pt-6 pb-16 px-3"
        >
          <p class="mb-4 font-size--28 font-weight-black">INVITATION</p>
          <p class="mb-0 pb-16">
            환영합니다!&nbsp;{{ recommenderName }}&nbsp;님의 초대로 오셨군요😊
          </p>
        </div>
        <!-- 헤더 끝 -->

        <!-- 누적 투자금 및 이자수익 통계 시작 -->
        <div
          class="animated fadeInDown section__stats position--absolute background--white px-5 py-7 text-center rounded--4"
        >
          <template
            v-if="
              recommenderName == null ||
                isLoading ||
                stats.totalInvestAmount == 0
            "
          >
            <div
              key="invitationloader"
              class="d-flex align-center justify-center"
              style="height: 356px"
            >
              <MyLoader />
            </div>
          </template>
          <template v-else>
            <p
              class="section__stats__description font-size--16 mb-5 font-weight-bold line-height--23"
            >
              펀다와 부자언니가 함께한지
              <span class="color-funda--basic">{{
                sinceDays | commaFormat
              }}</span
              >일&nbsp;<br class="break--wide-mobile" />
              <span class="color-funda--basic" data-user-count>{{
                stats.userCount | commaFormat
              }}</span>
              명의 언니들이 함께 하고 있어요!
            </p>
            <div class="section__stats__stat">
              <p class="font-size--14 mb-2 font-weight-bold">
                언니들의 누적 투자금
              </p>
              <p class="font-size--20 font-weight-black">
                {{ stats.totalInvestAmount | commaFormat }}&nbsp;원
              </p>
            </div>
            <div class="section__stats__stat">
              <p class="font-size--14 mb-2 font-weight-bold">누적 이자수익</p>
              <p class="font-size--20 font-weight-black">
                {{ stats.totalInterestAmount | commaFormat }}&nbsp;원
              </p>
            </div>
            <div class="mt-10 mb-5">
              <span
                class="d-block width-100 background--ruby color--white font-size--14 py-3 rounded--6 white-space--nowrap"
              >
                언니들은 한 달 간 얼마나 벌었을까요?
              </span>
            </div>
            <div class="font-size--14">
              <div
                v-for="(monthlyInterest, index) in monthlyInterests"
                :key="index"
                class="d-flex justify-space-between mb-2"
              >
                <span>
                  {{ index == 0 ? '🥇' : index == 1 ? '🥈' : '🥉' }}
                  &nbsp;{{ monthlyInterest.goeAmount / 10000 }}만원 이상
                </span>
                <MonthlyInterestsUsersModal
                  :userCount="monthlyInterest.userCount"
                  :usersText="monthlyInterest.usersText"
                />
              </div>
              <p class="font-size--11 text-right mt-1 mb-0">(최근 30일 기준)</p>
            </div>
          </template>
        </div>
        <!-- 누적 투자금 및 이자수익 통계 끝 -->
      </div>
      <!-- 헤더 및 통계 겹침 영역 끝 -->

      <!-- 인터뷰 시작 -->
      <div class="section__interview background--gray-1 pt-8 pb-4 px-6">
        <p
          class="section__interview__title font-size--16 font-weight-black text-center mb-5"
        >
          언니들이 말하는 펀다 투자
        </p>
        <div class="section__interview__carousel-wrapper">
          <v-carousel
            height="250px"
            cycle
            :next-icon="false"
            :prev-icon="false"
          >
            <v-carousel-item
              v-for="(investor, i) in investors"
              :key="i"
              class="pa-5 background--white"
            >
              <div class="interview__profile mb-4 d-flex">
                <!-- first-child -->
                <div>
                  <div class="interview__profile__img-wrapper">
                    <div class="interview__profile__img-container">
                      <img
                        :src="require('@/assets/images/' + investor.imageName)"
                      />
                    </div>
                  </div>
                </div>
                <!-- first-child -->
                <!-- last-child -->
                <div class="color--gray-3 pl-4">
                  <p class="mb-0">{{ investor.name }}</p>
                  <p class="mb-0">{{ investor.status }}</p>
                  <p class="mb-0">{{ investor.type }}</p>
                </div>
                <!-- last-child -->
              </div>
              <div class="interview__content color--gray-3">
                {{ investor.content }}
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
      <!-- 인터뷰 끝 -->
      <!-- 랜딩 영역 푸터 시작 -->
      <!-- <div class="section__landing__footer background--ruby color--white text-center py-8">
          <p class="font-size--22 font-weight-black" :class="{ 'mb-0': isSignin === true }">펀다 투자를 시작해보세요</p>
          <p v-if="isSignin === false" class="font-size--13 mb-2">아래의 버튼을 통해 가입하시면</p>
          <p v-if="isSignin === false" class="font-size--13 mb-6">루비스톤 코드와 추천인 코드가 자동 적용됩니다 </p>
          <v-btn
            v-if="isSignin === false"
            type="button"
            :to="{ name: 'signup', query: { code, promotion_code: promotionCode} }"
            elevation="0"
            class="font-size--13 mb-1 px-8"
            color="white"
            link
            large
          >
            회원가입 바로가기
          </v-btn>
        </div> -->
      <!-- 랜딩 영역 푸터 끝 -->
      <div
        class="section__landing__footer background--ruby color--white text-center py-8"
      >
        <p
          class="font-size--22 font-weight-black"
          :class="{ 'mb-0': isSignin === true }"
        >
          펀다 투자를 시작해보세요
        </p>
        <p v-if="isSignin === false" class="font-size--13 mb-2">
          아래 양식을 통해 가입 시 루비스톤 코드와
        </p>
        <p v-if="isSignin === false" class="font-size--13 mb-1">
          {{ recommenderName }} 님의 추천인 코드가 자동 적용됩니다
        </p>
      </div>
      <!-- 회원가입 폼 시작 -->
      <div
        v-if="isSignin === false && recommenderName != null"
        class="section__signup pt-3"
      >
        <div class="section__signup__container">
          <LandingSignupForm
            :recommenderCode="code"
            :promotionCode="promotionCode"
          />
        </div>
      </div>
      <!-- 회원가입 폼 끝 -->
    </v-main>
  </div>
</template>
<script>
import Header from '../../../components/legacy/Header'
import MyLoader from '@/components/MyLoader'
import { mapGetters } from 'vuex'
import MonthlyInterestsUsersModal from '@/components/landing/MonthlyInterestsUsersModal.vue'
import LandingSignupForm from '@/components/common/form/signup/LandingSignupForm.vue'

export default {
  components: {
    Header,
    MyLoader,
    MonthlyInterestsUsersModal,
    LandingSignupForm,
  },
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.mobileBreakpoint
    },
    sinceDays() {
      return this.$moment().diff(this.$moment('2019-09-05'), 'days')
    },
    ...mapGetters('auth', ['isSignin']),
  },
  data() {
    return {
      isLoading: false,
      promotionCode: 'rubystone',
      recommenderName: null,
      stats: {
        userCount: 0,
        totalInvestAmount: 0,
        totalInterestAmount: 0,
      },
      monthlyInterests: [],
      investors: [
        {
          name: '30대 투자자 김현진',
          status: '펀다 딜리버리+펀다나우 자동투자',
          type: '완전분산형',
          amount: '3,890',
          rateOfReturn: 8.54,
          startDate: '2019.02',
          content: `예적금보다 좋은 유동성과 수익률을 주는 펀다는 제 최애 업체죠. 
          펀다나우는 완전 분산투자와 일상환으로 절세효과를 극대화 한 것이 극장점이죠!`,
          imageName: 'image7-1.jpg',
        },
        {
          name: '40대 투자자 이윤주',
          status: '펀다 딜리버리+펀다나우 자동투자',
          type: '완전분산형',
          amount: '1,301',
          rateOfReturn: 13.29,
          startDate: '2016.02',
          content: `펀다에 만족하는 세가지 이유 
          1. 높은 수익률과 안전성 
          2. 자동분산투자로 편하게 투자 가능 
          3. 펀다나우 리포트를 통한 투명한 투자`,
          imageName: 'IMG_1515.jpg',
        },
        {
          name: '20대 투자자 황정*',
          status: '펀다나우 자동투자',
          type: '금액선택형',
          amount: '2,553',
          rateOfReturn: 11.54,
          startDate: '2019.08',
          content: `매일 원리금이 일부 상환되고 바로 재투자되는 방식이기 때문에 신경쓰지 않아도 되어 편리하고, 
          가만히 두어도 복리 효과를 얻었어요.`,
          imageName: 'IMG_1425.jpg',
        },
      ],
    }
  },
  async created() {
    if (this.code == null) {
      this.$swal.basic.alert('유효한 주소가 아닙니다')
      this.$router.replace({ name: 'main' })
    }
    await this.getRecommender()
    await this.getStats()
    const description = `${this.recommenderName} 언니의 초대장`
    _seo({
      titles: ['FUNDA x 부자언니', `${description}`],
      image: '/images/invite/rubystone_invite_logo_1.png',
      description,
    })
  },
  methods: {
    async getStats() {
      this.isLoading = true
      try {
        const q = gql`
          {
            data: rubystoneLandingStatsData {
              userCount
              totalInvestAmount
              totalInterestAmount
              monthlyInterests {
                goeAmount
                ltAmount
                userCount
                usersText
              }
            }
          }
        `
        const data = await this.$fundaApi.query(q)
        this.stats = data

        const monthlyInterests = data.monthlyInterests
        // 금액 내림차순 정렬
        this.monthlyInterests = monthlyInterests.sort(function(a, b) {
          return a.goeAmount > b.goeAmount
            ? -1
            : a.goeAmount < b.goeAmount
              ? 1
              : 0
        })
      } catch (e) {
        this.$swal.basic.alert(
          '페이지 로드에 실패했습니다. 문제가 지속되면 문의바랍니다.'
        )
        this.$router.replace({ name: 'main' })
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async getRecommender() {
      try {
        const q = `
          data: rubystoneRecommenderName(code: "${this.code}")
        `
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.recommenderName = data

        this.$cookies.set('code', this.code, 0)
        this.$cookies.set('affiliateCode', this.promotionCode, 0)
      } catch (e) {
        console.error(e)
        try {
          this.$swal.basic.alert(e.errors[0].message)
        } catch (e1) {
          this.$swal.basic.alert(
            '페이지 로드에 실패했습니다. 문제가 지속되면 문의바랍니다.'
          )
        } finally {
          this.$router.replace({ name: 'main' })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.background--ruby {
  background-color: #df398a;
}
.background--white {
  background-color: white;
}
.background--gray {
  &-1 {
    background-color: #e8eaec;
    // #f5f5f5;
  }
}
.landing-rubystone-invitation {
  // &__wrapper {
  // }

  &__container {
    margin: 0 auto;

    // 겹치는 영역 전체 길이
    .section__header-stats__wrapper {
      height: 600px;
    }

    .section__stats {
      width: 90%;
      margin: 0 auto;
      left: 0;
      right: 0;
      // 겹치는 영역 상단 여백
      top: 110px;
      // 가장자리 푸른 회색
      // border: 7px solid #d7e1ea;
      border: 7px solid #e8eaec;
      max-width: 400px;
    }

    // 인터뷰
    .section__interview {
      &__carousel-wrapper {
        max-width: 375px;
        margin: 0 auto;
      }

      &::v-deep .v-carousel__controls {
        background-color: #e8eaec;
        height: 35px;

        .v-btn {
          height: 6px;
          width: 6px;
          background-color: white;

          &--active {
            background-color: #1baaf1;
          }
        }

        .v-icon {
          font-size: 6px !important;
          color: #e1e9f3;
        }
      }
    }
    .interview__profile > div:first-child {
      /* 인터뷰 프로필 왼쪽 영역 길이 */
      width: 25%;
    }

    .interview__profile__img-wrapper {
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      &:after {
        /* after로 반응형 정사각형 만들기 */
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      .interview__profile__img-container {
        /* 내부에 컨텐츠가 있을 때 추가 */
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .interview__profile__img-container img {
        width: 100%;
      }
    }

    .interview__profile > div:last-child {
      /* 인터뷰 프로필 오른쪽 영역 길이 */
      width: 75%;
      // padding-left: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }

    .interview__content {
      font-size: 14px;
      line-height: 20px;
    }

    .section__signup {
      background-color: white;

      &__container {
        margin: 0 auto;
        max-width: 400px;
      }
    }
  }
}

.break--wide-mobile {
  display: none;
}
@media screen and (min-width: 370px) {
  .break--wide-mobile {
    display: block;
  }
}
</style>
