<template>
  <div
    class="dashboard-box d-flex flex-column justify-space-between width-100 height-100"
  >
    <div class="mb-5">
      <v-row class="store-intro__title" no-gutters>
        <v-col cols="3">
          <v-icon size="53px" color="#ff0000">
            mdi-youtube
          </v-icon>
        </v-col>

        <v-col cols="9" :class="isMobile ? 'pl-0' : 'pl-2'">
          <p class="ma-0 font-size--20">투자 상점을</p>
          <p class="ma-0 font-size--20">
            소개합니다!
          </p>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row no-gutters>
        <v-col cols="12"> </v-col>
      </v-row>
    </div>
    <div>
      <v-row no-gutters>
        <v-col cols="12">
          <p class="mb-3 font-size--16 line-height--20">
            투자자들이 만나고 싶어한
            <br />
            상점의 진솔한 이야기를
            <br />
            만나보세요!
          </p>
          <p class="mb-0 font-size--16">
            <a target="_blank" href="https://bit.ly/30ZUUNG">
              오늘의 상점 >
            </a>
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
.store-intro__title {
  display: flex;
  align-items: center;

  p {
    line-height: 25px;
    color: #333333;
  }
}
</style>
