// 임시 사용
export default {
  PASSWORD: {
    REGEX: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^*+=-])[A-Za-z\d!@#$%^*+=-]{6,15}$/,
    MSG: '비밀번호는 영문, 숫자, 특수문자(!@#$%^*+=-) 조합 6~15자로 입력해주세요.'
  },
  PHONE: {
    REGEX: /^01\d{8,9}$/,
    MSG: '휴대폰전화번호를 잘 못 입력하셨습니다.'
  },
  EMAIL: {
    REGEX: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    MSG: '이메일 주소가 유효하지 않습니다.'
  }
}
