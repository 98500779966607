import { TimeTypes } from './types/time'

export default {
  name: 'time',
  namespaced: true,
  state: {
    KR_TIME_DIFF: 9 * 60 * 60 * 1000,
    current: Date.now(),
  },
  getters: {
    getKrTimeDiff(state) {
      return state.KR_TIME_DIFF
    },
  },
  mutations: {
    refresh(state) {
      state.current = Date.now()
    },
  },
  actions: {
    refresh({ commit }) {
      commit('refresh')
    },
    getKrDate({ getters }) {
      const curr = new Date()
      const offset = curr.getTimezoneOffset() * 60 * 1000
      const utc = curr.getTime() + offset
      const krDate = new Date(utc + getters.getKrTimeDiff)
      return krDate
    },
    async [TimeTypes.actions.GET_IS_INSPECT_TIME]({ dispatch }) {
      const now = await dispatch('getKrDate')
      const endHour = 1
      const hours = now.getHours()
      const minutes = now.getMinutes()
      // 23시 30분부터 1시 까지
      const is = (hours > 22 && minutes > 28) || hours < endHour
      return is
    },
    async [TimeTypes.actions.GET_CANNOT_INVEST_TIME]({ dispatch }) {
      const now = await dispatch('getKrDate')
      const endHour = 4
      const hours = now.getHours()
      const minutes = now.getMinutes()
      // 23시 20분부터 4시 까지
      const is = (hours > 22 && minutes > 19) || hours < endHour

      return is
    },
  },
}
