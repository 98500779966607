<template>
  <v-card tile elevation="0" :color="color">
    <v-card-title v-if="showMoreBtn === false" class="pb-3 justify-center">
      <span class="text-h5">동조건 재투자 안내</span>
    </v-card-title>
    <v-card-text class="py-2">
      <p
        v-if="showMoreBtn === false"
        class="ma-0 color--gray-3 font-size--15 text-center"
        style="line-height: 1.2"
      >
        동조건 재투자에 동의하시면 매일 상환되는 원리금이 빠르게 재투자되어 투자
        수익과 효율이 증가합니다!
      </p>
      <p
        v-else
        class="ma-0 color--gray-3 font-size--14"
        style="line-height: 1.4"
      >
        동조건 재투자란?<br />투자자가 기투자한 상점에서 재대출 발생시 투자자가
        직접 투자한 시점의 대출 조건에서 변동이 없다면 자동으로 해당 상품에
        재투자하는 서비스입니다.
        <a @click="showDetail = !showDetail">
          {{ showDetail ? '닫기' : '더보기' }}
          <img
            :src="
              showDetail
                ? require('@/assets/images/myfunda/btn-arrow-top-g.svg')
                : require('@/assets/images/myfunda/btn-arrow-down-g.svg')
            "
          />
        </a>
      </p>

      <ul
        v-show="hideDetail === false && (showMoreBtn === false || showDetail)"
        class="margin-t-10 padding-l-15 font-size--14"
      >
        <li>
          동조건 재투자로 상품에 투자되는 금액은 최대 10만원을 초과하지
          않습니다.
        </li>
        <li class="mt-1">
          아래의 조건에 한 가지라도 충족한다면 재투자는 자동 중단됩니다.

          <ul
            class="mt-1 pl-3 font-size--14"
            style="list-style: hangul-consonant;"
          >
            <li v-for="(cond, index) in stopCondition" :key="index">
              {{ cond }}
            </li>
          </ul>
        </li>
        <li class="mt-1">
          동조건 재투자 설정은 마이펀다에서 상시 변경할 수 있습니다.
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    showMoreBtn: {
      type: Boolean,
      default: false,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      showDetail: false,
      stopCondition: [
        '상점의 직전 3개월 월평균 매출 20% 이상 하락시',
        '대출 한도가 직전 대비 20% 이상 하락 시',
        '차주 신용평점 10% 이상 하락 시',
        '사업자 변경, 집금계좌 변경 등 부정행위 확인 시',
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
