<template>
  <div
    v-if="isValid"
    class="d-flex align-center flex-column justify-center"
    style="min-height: 400px"
  >
    <p class="mb-3 font-size--17 font-weight-bold line-height--20 text-center">
      신분증 등록이 완료되었습니다.
      <br />
      승인 후 투자 서비스 이용이 가능합니다.
    </p>
    <v-btn
      color="primary"
      class="font-size--16"
      height="45px"
      @click="nextStep()"
    >
      다음단계
    </v-btn>
  </div>
  <div v-else>
    <v-row no-gutters>
      <v-col cols="12">
        <v-radio-group v-model="cddType" row class="ma-0">
          <v-radio
            v-for="cdd in cddTypes"
            :key="cdd.text"
            :label="cdd.text"
            :value="cdd.value"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-2 primary--text font-size--14 line-height--18 text-center">
          정보 식별이 어렵거나 훼손/유효하지 않은 신분증 제출 시,
          <br />
          심사과정에서 신분증 제출을 재요청할 수 있습니다.
        </p>
      </v-col>

      <v-col cols="12">
        <keep-alive>
          <component v-bind:is="selectedComponent"></component>
        </keep-alive>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import ResidentCard from '@/components/auth/ResidentCard'
import DriverLicense from '@/components/auth/DriverLicense'
import Corporation from '@/components/signup/Corporation'

export default {
  components: {
    ResidentCard,
    DriverLicense,
    Corporation,
  },
  data() {
    return {
      isValid: false,
      cddTypes: [
        {
          text: '주민등록증',
          value: 'residentCard',
          component: 'ResidentCard',
        },
        {
          text: '운전면허증',
          value: 'driverLicense',
          component: 'DriverLicense',
        },
      ],
      cddType: 'residentCard',
      selectedComponent: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      async handler(newVal, oldVal) {
        if (newVal?.userCdds.length === oldVal?.userCdds.length) {
          return
        }

        if (newVal?.userCdds.length > 0) {
          const isWaiting = newVal?.userCdds.some(
            cdd => cdd.status === 'WAITING'
          )

          if (isWaiting) {
            this.isValid = true
            await this.$swal.basic.success({
              title: '신분증 업로드 완료',
              text: '신분증 진위여부를 확인할 예정입니다.',
            })
            return
          }
        }

        if (newVal?.isValidIdentification === true) {
          this.isValid = true
        } else {
          this.isValid = false
        }
      },
    },
    isValid: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.nextStep()
        }
      },
    },
    cddType: {
      immediate: true,
      handler(v) {
        const selected = this.cddTypes.filter(cdd => {
          return cdd.value === v
        })
        this.selectedComponent = selected[0].component
      },
    },
  },
  created() {
    if (this.profile?.investmentLimit?.idx === 3) {
      this.cddTypes = [
        {
          text: '사업자 등록증',
          value: 'corporation',
          component: 'Corporation',
        },
      ]
      this.cddType = 'corporation'
    }
  },
  methods: {
    nextStep() {
      if (this.isValid === false) {
        return
      }
      this.$emit('next-step')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  &::v-deep .v-input--radio-group__input {
    display: flex;
    justify-content: space-around;
  }
}
</style>
