<template>
  <div class="custom-popup" :class="{ show: showPopup }" @click="showToggle()">
    <div class="custom-popup__contents" @click.stop="showPopup = true">
      <div
        class="popup-header d-flex justify-space-between align-center padding-x-25 margin-t-30"
      >
        <h2 class="font-size--20 font-weight-black my-0">연체&#47;부실 안내</h2>
        <a @click.stop="showToggle()" class="cursor--pointer">
          <img src="@/assets/images/myfunda/btn-close.svg" />
        </a>
      </div>
      <div v-html="content" class="padding-x-25 padding-y-25"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPopup: false,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    showToggle() {
      this.showPopup = !this.showPopup
      console.log(this.showPopup ? 'open' : 'close')
    },
  },
}
</script>
