export default [
  {
    name: 'notice',
    path: '/notice',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/notice/Notice.vue').default,
  },

  {
    name: 'officialNotice',
    path: '/official-notice',
    meta: {
      layout: 'LegacyLayout',
    },
    component: require('@/pages/notice/OfficialNotice.vue').default,
  },
]
