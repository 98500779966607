<template>
  <div class="pa-3">
    <S3FileUploadButton
      ref="fileUploadTest"
      uploadOnSelect
      :bucketName="bucketName"
      :path="path"
      :tempFile.sync="tempFile"
      :savedFile.sync="savedFile"
    />
    <v-spacer class="my-5" />
    임시 저장된 파일: {{ tempFile }}
    <v-row class="my-10">
      <v-col cols="6">
        <h3>임시 저장된 파일 지정된 버켓에 저장</h3>
        <form @submit.prevent="$refs.fileUploadTest.saveFile()">
          <v-text-field label="버켓이름" v-model="bucketName" />
          <v-text-field label="경로" v-model="path" />
          <v-btn type="submit" :disabled="tempFile == null" block>
            {{ bucketName }}버켓의 {{ path }} 경로에 저장
          </v-btn>
        </form>
      </v-col>
    </v-row>
    저장된 파일: {{ savedFile }}
  </div>
</template>

<script>
import S3FileUploadButton from '../../components/common/form/S3FileUploadButton.vue'
export default {
  components: { S3FileUploadButton },
  data() {
    return {
      tempFile: null,
      savedFile: null,
      bucketName: 'dev-admin-res',
      path: 'test/b',
    }
  },
  async created() {},
  methods: {},
}
</script>
