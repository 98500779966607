import client from '../fundaApiClient'

export default {
  postS3TempFile(data, onUploadProgress) {
    return client.call({
      url: '/file/s3/temp',
      method: 'POST',
      data,
      onUploadProgress,
    })
  },
  putS3TempFile(data) {
    return client.call({
      url: '/file/s3/temp',
      method: 'PUT',
      data: data,
    })
  },
}
