<template>
  <component :is="layout">
    <slot />
  </component>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'RootLayout',
  components: {
    DefaultLayout: () => import('./DefaultLayout.vue'),
    EmptyLayout: () => import('./EmptyLayout.vue'),
    LegacyLayout: () => import('./LegacyLayout.vue'),
    LegacyLayoutFluid: () => import('./LegacyLayoutFluid.vue'),
    OauthLayout: () => import('./OauthLayout.vue'),
  },
  setup(props, { root }) {
    const layout = computed(() => root.$route.meta.layout || 'DefaultLayout')
    return {
      layout,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/legacy/common.scss';
@import '@/assets/legacy/vuetify-custom.scss';
@import '@/assets/legacy/swal-temp-custom.scss';
</style>
