<template>
  <div>
    <Cookie />
    <OverlayView />
    <PopupManager />
    <v-app-bar
      color="white"
      tile
      app
      :elevation="isShowNotice ? '0' : elevation"
      :max-width="maxWidth"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <OverlayAlarmButton v-if="isMobile" :key="new Date().getTime()" />

      <v-toolbar-title class="pl-0">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="funda logo" />
        </router-link>
        <template v-if="isAuthenticated === false">
          <v-btn
            key="login"
            depressed
            color="primary"
            class="hidden-md-and-up route-to-invest"
            @click="getRedirectUri()"
          >
            로그인
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            v-if="!isMyFunda"
            key="myFunda"
            depressed
            color="primary"
            class="hidden-md-and-up route-to-invest"
            :to="{ name: 'myfunda-home' }"
          >
            마이펀다
          </v-btn>
          <v-btn
            v-else
            depressed
            color="primary"
            class="hidden-md-and-up route-to-invest"
            :to="{ name: 'invest' }"
          >
            투자하기<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </v-toolbar-title>
      <v-btn
        text
        :ripple="false"
        :to="{ name: 'invest' }"
        class="left hidden-sm-and-down"
      >
        투자하기
      </v-btn>

      <v-btn
        text
        :ripple="false"
        href="https://nice-factoring.funda.kr/landing/myfinbiz"
        target="_blank"
        class="left hidden-sm-and-down"
      >
        대출받기
      </v-btn>

      <v-btn
        text
        :ripple="false"
        href="https://nice-factoring.funda.kr/landing/myfinbiz"
        target="_blank"
        class="left hidden-sm-and-down"
      >
        <span class="sub-mark">NEW</span><span>카드매출 선정산</span>
      </v-btn>

      <v-spacer class="hidden-sm-and-down"></v-spacer>

      <v-menu
        open-on-hover
        bottom
        offset-y
        tile
        content-class="company-introduction"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :ripple="false"
            class="right hidden-sm-and-down"
            v-bind="attrs"
            v-on="on"
          >
            회사소개
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in detailCompanyIntrodictionItems"
            :key="index"
          >
            <v-btn :to="{ name: item.link }" text>
              {{ item.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu open-on-hover bottom offset-y tile content-class="notice">
        <template v-slot:activator="{ on, attrs }">
          <template v-if="isAuthenticated === false">
            <v-btn
              text
              :ripple="false"
              class="right hidden-sm-and-down"
              :to="{ name: 'notice' }"
            >
              공지사항
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              text
              :ripple="false"
              class="right hidden-sm-and-down"
              v-bind="attrs"
              v-on="on"
            >
              공지사항
            </v-btn>
          </template>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in detailNoticeItems" :key="index">
            <v-btn :to="{ name: item.link }" text>
              {{ item.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-if="isAuthenticated === false">
        <v-btn
          text
          :ripple="false"
          :to="{ name: 'signup' }"
          class="right hidden-sm-and-down"
        >
          회원가입
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          text
          :ripple="false"
          :to="{ name: 'myfunda-home' }"
          class="right hidden-sm-and-down"
        >
          마이펀다
        </v-btn>
      </template>
      <template v-if="isAuthenticated === false">
        <v-btn
          text
          key="login"
          :ripple="false"
          class="right hidden-sm-and-down"
          @click="getRedirectUri()"
        >
          로그인
        </v-btn>
      </template>
      <template v-else>
        <v-menu
          bottom
          left
          offset-y
          open-on-hover
          tile
          min-width="250"
          max-width="250"
          content-class="investor-summary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              :ripple="false"
              class="right hidden-sm-and-down"
              v-bind="attrs"
              v-on="on"
              @mouseenter="openProfileMenu"
            >
              {{ profile.name ? profile.name : profile.id }}님
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-card class="summary-contents">
            <v-list>
              <template v-if="isValidUser">
                <v-list-item>
                  <v-list-item-title>예치금</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    <BalanceText /> 원
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="border-bottom-dashed">
                  <v-list-item-title>포인트</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    <BalanceText type="point" /> P
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>예치금충전</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="account"
                  v-if="profile.newBankAccountFunda"
                  :to="{ name: 'myfunda-balance-deposit' }"
                  active-class="vuetify-active-none"
                >
                  <v-list-item-content max-width="50">
                    <v-list-item-subtitle>{{
                      profile.newBankAccountFunda.bank
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="text-right">
                    <v-list-item-subtitle>
                      {{ profile.newBankAccountFunda.account }}<br />({{
                        profile.newBankAccountFunda.accountHolder
                      }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item-action v-else class="register">
                  <v-btn depressed tile :to="`/signup`">
                    투자자 인증 하기
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>

                <v-list-item class="d-block border-top-dashed py-2">
                  <InvestmentLimitText :show-btn="true" />
                </v-list-item>

                <v-list-item v-if="profile.codeRecommender">
                  <v-list-item-title>나의 추천인 코드</v-list-item-title>
                </v-list-item>
                <v-list-item class="recommend" v-if="profile.codeRecommender">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ profile.codeRecommender.code }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-btn
                      depressed
                      :ripple="false"
                      @click="copyRecommenderCode(profile.codeRecommender.code)"
                    >
                      코드 복사하기
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="recommend-text">
                  <v-list-item-subtitle>
                    * 친구도 나도 +{{
                      getRecommender.pointAmount | commaFormat
                    }}
                    포인트
                    <a :href="this.getRecommender.blogUrl" target="_blank">
                      더보기
                      <v-icon small>mdi-chevron-right-circle-outline</v-icon>
                    </a>
                  </v-list-item-subtitle>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item-action class="consult">
                  <template v-if="isReviewAuth">
                    <v-btn depressed tile>
                      인증 서류 검토중입니다
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn depressed tile :to="`/signup`">
                      본인 인증 하기
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </template>

              <v-list-item-action>
                <v-btn text :ripple="false" :to="{ name: 'myfunda-setting' }">
                  회원정보 변경
                </v-btn>
                <v-btn :ripple="false" text @click="signout"> 로그아웃 </v-btn>
              </v-list-item-action>
            </v-list>
          </v-card>
        </v-menu>

        <AlarmButtonPC v-if="!isMobile" :key="new Date().getTime()" />
      </template>
    </v-app-bar>
    <NoticeBand />
    <v-navigation-drawer width="90%" v-model="drawer" fixed temporary>
      <template v-if="isAuthenticated === false">
        <v-card tile color="primary" elevation="0">
          <v-card-actions>
            <v-btn
              text
              tile
              key="login"
              color="white"
              width="40%"
              @click="getRedirectUri()"
            >
              <v-icon left>mdi-power</v-icon> 로그인
            </v-btn>
            <div class="vertical-line"></div>
            <v-btn text tile color="white" width="40%" :to="{ name: 'signup' }">
              <v-icon left>mdi-account-circle-outline</v-icon> 회원가입
            </v-btn>
            <v-btn
              text
              tile
              right
              class="close"
              color="white"
              width="20%"
              @click="drawer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-else>
        <v-card tile color="primary" elevation="0" height="50">
          <div
            class="
              signin
              d-flex
              flex-no-wrap
              justify-space-between
              align-center
            "
          >
            <v-card-title>
              {{ profile.name ? profile.name : profile.id }}님
            </v-card-title>
            <v-card-actions>
              <v-btn
                outlined
                rounded
                text
                tile
                right
                color="white"
                :to="{ name: 'myfunda-home' }"
                @click="drawer = false"
                class="myfunda"
              >
                마이펀다
              </v-btn>
              <v-btn
                text
                tile
                right
                color="white"
                width="20%"
                @click="drawer = false"
                class="close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </template>
      <template v-if="isAuthenticated">
        <v-card v-if="isValidUser" class="summary-contents" elevation="0">
          <v-list>
            <v-list-item class="border-bottom-solid">
              <v-list-item-title>예치금</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <BalanceText v-if="drawer" /> 원
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="border-bottom-solid">
              <v-list-item-title>포인트</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                <BalanceText v-if="drawer" type="point" /> P
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>예치금충전</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="account"
              v-if="profile.newBankAccountFunda"
              :to="{ name: 'myfunda-balance-deposit' }"
              active-class="vuetify-active-none"
            >
              <v-list-item-content max-width="50">
                <v-list-item-subtitle>{{
                  profile.newBankAccountFunda.bank
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-subtitle>
                  {{ profile.newBankAccountFunda.account }}<br />({{
                    profile.newBankAccountFunda.accountHolder
                  }})
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-action v-else class="register">
              <v-btn depressed tile :to="`/signup`">
                투자자 인증 하기
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-list-item
              class="d-block border-top-solid border-bottom-solid py-2"
              @click="drawer = false"
            >
              <InvestmentLimitText v-if="drawer" :show-btn="true" />
            </v-list-item>

            <v-list-item
              class="border-top-solid"
              v-if="profile.codeRecommender"
            >
              <v-list-item-title>나의 추천인 코드</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="recommend border-bottom-dashed pb-3"
              v-if="profile.codeRecommender"
            >
              <v-list-item-content class="code">
                <v-list-item-subtitle>
                  {{ profile.codeRecommender.code }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="button">
                <v-btn
                  depressed
                  :ripple="false"
                  @click="copyRecommenderCode(profile.codeRecommender.code)"
                >
                  복사
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="recommend-text">
              <v-list-item-subtitle>
                * 친구도 나도 +{{ getRecommender.pointAmount | commaFormat }}
                포인트
                <a :href="this.getRecommender.blogUrl" target="_blank">
                  더보기
                  <v-icon small>mdi-chevron-right-circle-outline</v-icon>
                </a>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card v-else class="summary-contents">
          <v-list>
            <v-list-item-action class="consult">
              <template v-if="isReviewAuth">
                <v-btn depressed tile>
                  인증 서류 검토중입니다
                </v-btn>
              </template>
              <template v-else>
                <v-btn depressed tile :to="`/signup`">
                  본인 인증 하기
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
            </v-list-item-action>
          </v-list>
        </v-card>
      </template>
      <v-card elevation="0">
        <v-list>
          <v-list-item-group>
            <v-list-item :to="{ name: 'invest' }">
              <v-list-item-title>투자하기</v-list-item-title>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-list-item href="https://bit.ly/2ZpfNx4" target="_blank">
              <v-list-item-title>대출하기</v-list-item-title>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group>
            <v-list-item href="https://bungae-fnb.webflow.io" target="_blank">
              <v-list-item-title>
                <span>카드매출 선정산 (번개입금)</span>
                <span class="ml-1 color-funda--basic font-size--12 font-weight-bold">NEW</span>
              </v-list-item-title>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-list-group
            v-for="item in drawerItems"
            :key="item.title"
            v-model="item.active"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.to }"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <template v-if="isAuthenticated === false">
            <v-list-item-group>
              <v-list-item :to="{ name: 'notice' }">
                <v-list-item-title>공지사항</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </template>
          <template v-if="isAuthenticated">
            <v-list-item-group>
              <v-list-item :to="{ name: 'myfunda-setting' }">
                <v-list-item-title>회원정보 변경</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item class="justify-center">
                <v-list-item-action>
                  <v-btn text @click="signout">
                    <v-icon>mdi-power</v-icon>
                    로그아웃
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import { textCopy } from '@/util'
import { linkShare } from '@/plugins/sns/kakao'
import OverlayView from '@/components/overlay/OverlayView.vue'
import NoticeBand from '@/components/common/NoticeBand.vue'
import OverlayAlarmButton from '@/components/overlay/button/OverlayAlarmButton.vue'
import AlarmButtonPC from '@/components/alarm/AlarmButtonPC.vue'
import BalanceText from '@/components/common/text/BalanceText.vue'
import InvestmentLimitText from '@/components/common/text/InvestmentLimitText.vue'
import Cookie from '@/components/common/Cookie.vue'
import PopupManager from '@/components/popups/PopupManager.vue'

export default {
  props: {
    elevation: {
      type: Number,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: null,
    },
  },
  components: {
    OverlayView,
    NoticeBand,
    OverlayAlarmButton,
    AlarmButtonPC,
    BalanceText,
    InvestmentLimitText,
    Cookie,
    PopupManager,
  },
  data() {
    return {
      drawer: false,
      group: null,
      kakaoImageUrl:
        '//developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png',
      bandImageUrl: '//developers.band.us/img/share/button/ko/30x30.png',
      detailCompanyIntrodictionItems: [
        { title: '펀다소개', link: 'intro' },
        { title: '언론보도', link: 'news' },
        { title: '펀다공시', link: 'officialNotice' },
      ],
      detailNoticeItems: [{ title: '공지사항', link: 'notice' }],
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      profile: AuthTypes.getters.GET_PROFILE,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    ...mapGetters('notice', {
      isShowNotice: 'isShow',
    }),
    ...mapGetters('event', ['getRecommender']),
    isValidUser() {
      return this.userValidation?.isValid === true
    },
    isReviewAuth() {
      const v = this.userValidation
      const isPhone = v?.isValidPhone === true
      const isCddWaiting = v?.userCdds?.some((cdd) => cdd.status === 'WAITING')
      const isAccount = v?.isValidAccount === true
      const isEdd = v?.isValidEdd === false && v?.userEdds?.length > 0

      return isPhone && isCddWaiting && isAccount && isEdd
    },
    drawerItems() {
      let items = [
        {
          items: [
            { title: '펀다소개', to: 'intro' },
            { title: '언론보도', to: 'news' },
            { title: '펀다공시', to: 'officialNotice' },
          ],
          title: '회사소개',
        },
      ]

      if (this.isAuthenticated) {
        items.push({
          items: [{ title: '공지사항', to: 'notice' }],
          title: '공지사항',
        })
      }

      return items
    },
    bandShareHref() {
      const pointAmount = this.$options.filters.commaFormat(
        this.getRecommender.pointAmount
      )
      let msg = `
      * 펀다 친구 추천 이벤트\n ${this.profile.name}님이 밴드 친구분들에게 ${pointAmount}원 쿠폰을 선물하였습니다.
      
      1만원부터 시작하는 연 10% 재테크
      적금을 고민하고 있다면 적금보다 펀다!
      
      ${pointAmount}원을 드리는 고유 가입링크
      ☞ ${this.profile.codeRecommender.shortenUrl}
      추천인코드: ${this.profile.codeRecommender.code}
      
      이벤트 자세히 보기: ${this.getRecommender.blogUrl}`
      msg = encodeURIComponent(msg)
      return `https://band.us/plugin/share?body=${msg}&route=www.funda.kr`
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isMyFunda() {
      return this.$route?.path.indexOf('/myfunda') > -1
    },
  },
  methods: {
    ...mapActions('auth', [
      AuthTypes.actions.SIGN_OUT,
      AuthTypes.actions.GET_BALANCE,
      AuthTypes.actions.ON_LOGIN_DIALOG,
    ]),
    ...mapActions('investment', {
      getUserInvestment: InvestmentTypes.actions.GET_USER_INVESTMENT,
    }),
    copyRecommenderCode(value) {
      textCopy(value)
      this.$swal.basic.alert('추천코드가 복사되었습니다')
    },
    getRedirectUri() {
      this.drawer = false
      this[AuthTypes.actions.ON_LOGIN_DIALOG]()
      // let path
      // if (this.$route.name === 'login') {
      //   path = this.$route.query.redirectUri
      // } else {
      //   path = AuthService.getRedirectUri()
      // }
      // return `/login?redirectUri=${path}`
    },
    async signout() {
      this.SIGN_OUT()
      this.drawer = false

      this.$swal.basic.alert({
        title: '안내',
        text: '로그아웃되었습니다.',
      })
    },
    async openProfileMenu() {
      this.getUserInvestment()
      await this[AuthTypes.actions.GET_BALANCE]()
    },
    kakaoShare() {
      const name = this.profile.name
      const link = this.profile.codeRecommender.shortenUrl
      const msg = {
        objectType: 'feed',
        content: {
          title: `${name}님이 추천하는 재테크 펀다`,
          description:
            '1만원부터 시작하는 연 10% 재테크\n적금을 고민하고 있다면 적금보다 펀다!',
          imageUrl:
            'https://www.funda.kr/v2/abtest/image/171212_recommend_kakaolink.png',
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
        buttons: [
          {
            title: '포인트 받기',
            link: {
              mobileWebUrl: link,
              webUrl: link,
            },
          },
          {
            title: '자세히 보기',
            link: {
              mobileWebUrl: this.getRecommender.blogUrl,
              webUrl: this.getRecommender.blogUrl,
            },
          },
        ],
        installTalk: true,
      }
      linkShare(msg)
    },
    bandShare() {
      const pointAmount = this.$options.filters.commaFormat(
        this.getRecommender.pointAmount
      )
      let msg = `
      * 펀다 친구 추천 이벤트\n ${this.profile.name}님이 밴드 친구분들에게 ${pointAmount}원 쿠폰을 선물하였습니다.
      
      1만원부터 시작하는 연 10% 재테크
      적금을 고민하고 있다면 적금보다 펀다!
      
      ${pointAmount}원을 드리는 고유 가입링크
      ☞ ${this.profile.codeRecommender.shortenUrl}
      추천인코드: ${this.profile.codeRecommender.code}
      
      이벤트 자세히 보기: ${this.getRecommender.blogUrl}`
      /* eslint-disable */
      let r = new ShareBand.makeButton({
        lang: 'ko',
        type: 'c',
        text: msg,
        withUrl: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  font-family: NanumSquare, sans-serif;
  z-index: 99 !important;

  .sub-mark {
    position: absolute;
    left: 0;
    top: -12px;
    font-size: 12px;
    color: #1baaf1;
    font-weight: 900;
  }

  img {
    width: 52%;
    position: relative;
    vertical-align: middle;
  }

  // header에 max-width 주는 경우 가운데 정렬 되도록
  &::v-deep.v-app-bar {
    margin: 0 auto;
  }

  &::v-deep .v-toolbar__content {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px !important;
    padding-right: 12px !important;

    .v-app-bar__nav-icon {
      .v-icon {
        font-size: 33px;
      }
    }

    .left,
    .right {
      color: #666666;
      font-size: 14px;
      letter-spacing: 0px;
      font-weight: 400;

      span {
        &:hover {
          font-weight: 600;
        }
      }
    }

    .left {
      padding: 0 12px;

      .v-btn__content {
        font-size: 1.203rem;
      }
    }

    .right {
      padding: 0 0 0 20px;

      &:last-child[aria-expanded='true'] {
        .mdi-chevron-down::before {
          transition-duration: 0.4s;
          transition-property: transform;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      .v-btn__content {
        font-size: 14px;

        .mdi-chevron-down::before {
          font-size: 15px;
          color: rgb(0, 0, 0, 0.5);
        }
      }
    }
  }

  @media (min-width: 960px) {
    &::v-deep .v-toolbar__content {
      max-width: 900px;
    }
  }
  @media (min-width: 1264px) {
    &::v-deep .v-toolbar__content {
      max-width: 1185px;
    }
  }

  @media (max-width: 960px) {
    &::v-deep .v-toolbar__content > .v-btn.v-btn--icon:first-child {
      position: absolute;
    }

    .v-toolbar__title {
      margin: 0 auto;
      text-align: center;
    }

    .route-to-invest {
      position: absolute;
      max-width: 77px;
      max-height: 28px;
      border-radius: 50px;
      right: 12px;

      &::v-deep .v-btn__content {
        letter-spacing: 0;
        font-weight: 600;
        font-size: 13px;
      }

      .mdi-chevron-right::before {
        font-size: 14px;
      }
    }
  }
}

.v-menu__content {
  font-family: NanumSquare, sans-serif;
  z-index: 9999 !important;

  &.company-introduction {
    width: 100px;

    .v-list {
      padding: 0;

      &-item {
        padding: 0px;
        min-height: 41px;

        a {
          width: 100%;
          color: #666666 !important;

          &:hover {
            font-weight: 600;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ededed;
        }
      }
    }
  }

  &.notice {
    width: 100px;

    .v-list {
      padding: 0;

      &-item {
        padding: 0px;
        min-height: 41px;

        a {
          width: 100%;
          color: #666666 !important;

          &:hover {
            font-weight: 600;
          }
        }

        &:first-child {
          border-bottom: 1px solid #ededed;
        }
      }
    }
  }
}

.summary-contents {
  .v-list {
    padding-top: 6px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;

    &-item {
      padding: 0px;
      margin-left: 15px;
      margin-right: 15px;
      min-height: 34px;

      @media (max-width: 960px) {
        min-height: 44px;
      }

      &__title {
        font-size: 13px;
        font-weight: 400;
        color: #333333;

        @media (max-width: 960px) {
          font-size: 15px;
        }
      }

      &__subtitle {
        font-size: 14px;
        color: #333333;

        @media (max-width: 960px) {
          font-size: 14px;
        }
      }

      &.account {
        .v-list-item {
          &__content {
            padding-top: 0px;

            &:first-child {
              max-width: 51px;
              align-self: flex-start;
            }
          }

          &__subtitle {
            color: #25aae1;
          }
        }
      }

      &.recommend {
        min-height: 24px;

        .v-list-item {
          &__content {
            padding: 0;

            &.code {
              border: 1px solid #ccc;
              height: 30px;
              line-height: 30px;
              padding-left: 7px;
            }

            &.button {
              max-width: 65px;

              ::v-deep.v-btn {
                border-radius: 0;
                border-left: none;
                color: #999999;
                font-weight: 600;
                height: 30px;
                max-width: 50px;
                background-color: #f7f7f7;
              }
            }
          }

          &__subtitle {
            color: #54bbe5;
          }
        }

        .v-btn {
          height: 24px;
          letter-spacing: 0;
          border-radius: 13px;
          border: 1px solid #c7c5c5;
          background-color: transparent;
          color: #6f6c6c;

          &:hover {
            font-weight: 600;
          }
        }

        &-text {
          .v-list-item {
            &__subtitle {
              font-size: 13px;
              color: #f37472;

              a {
                float: right;

                @media (max-width: 960px) {
                  line-height: 18px;
                }
              }

              .mdi-chevron-right-circle-outline::before {
                font-size: 12px;
                margin-bottom: 1px;
              }

              @media (max-width: 960px) {
                font-size: 15px;
              }
            }

            &__content {
              padding: 0px;

              &:first-child {
                max-width: 200px;
              }
            }
          }
        }
      }

      &__action {
        width: 100%;

        &.consult,
        &.register {
          padding-left: 15px;
          padding-right: 15px;

          .v-btn {
            border: 1px solid #d2d3d4;
            color: #666666;
            background-color: #f8f9fb;
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.25px !important;
            height: 34px;

            @media (max-width: 960px) {
              height: 44px;
            }

            &__content {
              .mdi-chevron-right::before {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }
        }

        &:last-of-type:not(:only-child) {
          align-items: flex-start;
          border-top: 1px solid #cccccc;
          background-color: #f7f7f7;
          margin: 0px;
          width: 100%;
          padding-top: 8px;
          padding-bottom: 8px;

          .v-btn {
            height: 24px;
            min-height: 24px;
            font-weight: 500;
            font-size: 13px;
            color: #4d4d4d;
            letter-spacing: -0.05px;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }

      a {
        color: #666666 !important;

        .mdi-help-circle::before {
          color: #25aae1;
          font-size: 14px;
          margin-right: 3px;
          margin-bottom: 2px;
        }

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .share-content {
    display: inline-block;

    div {
      margin-left: 5px;
      margin-bottom: 0;
      float: left;
    }

    .clear {
      clear: both;
    }
  }
}

.close {
  margin-left: 0 !important;
}

.border-top-solid {
  border-top: 1px solid #ededed !important;
}

.border-bottom-solid {
  border-bottom: 1px solid #ededed !important;
}

.border-top-dashed {
  border-top: 1px dashed #d2d3d4;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #d2d3d4;
}

@media (max-width: 960px) {
  .v-navigation-drawer {
    font-family: NanumSquare, sans-serif;
    z-index: 9999;

    .summary-contents {
      border-bottom: 2px solid #25aee1;
      padding-top: 4px;
    }

    .signin {
      .v-card {
        &__title {
          height: 50px;
          font-size: 14px;
          flex-wrap: nowrap;
          color: #ffffff;

          .v-btn__content {
            i {
              bottom: 1px;
            }

            .mdi-cog-outline::before {
              color: #ffffff;
              font-size: 21px;
            }
          }
        }

        &__actions {
          height: 50px;

          .v-btn {
            &.myfunda {
              border: 1px solid #ffffff;
              border-radius: 12px;
              padding: 0;
              margin-right: 5px;
            }

            &.close {
              padding-left: 0;
              padding-right: 0;
              min-width: 20px;
            }
          }
        }
      }
    }

    &__content {
      .v-card__actions {
        .v-btn {
          font-size: 14px;
        }
      }
      .v-list {
        padding: 0;

        .v-item-group {
          height: 48px;
          border-bottom: 1px solid #ededed;

          .v-list-item {
            height: 48px;

            &--active::before {
              opacity: 0;
            }
          }
        }

        &-group {
          border-bottom: 1px solid #ededed;

          .v-list-item:hover::before {
            opacity: 0;
          }
        }
      }
    }

    .v-list-group__items {
      .v-list-item {
        padding-left: 35px;
      }
    }

    .vertical-line {
      top: initial;
      border-left: 1px solid #ffffff;
      height: 20px;
    }
  }
}
</style>
