<template>
  <section id="section__earnings-rate-chart" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row class="erc__main" no-gutters>
        <v-col :cols="isMobile ? 12 : 6" class="erc__text">
          <div class="text-main">
            <p><span class="font-weight-black">연 12.32% 수익률</span>을</p>

            <p>함께 누려요!</p>
          </div>
          <div class="text-sub">
            <p>
              펀다의 자동화된 시스템이
              <br v-if="isMobile" />
              상점의 카드매출을 매일매일 펀다로 적립하여
              <br />
              이를 투자자들에게 원금과 이자로 상환합니다.
            </p>
            <p class="font-size--13 color--body">
              (* 최근 3년간 평균 세전 수익률은 10.67%입니다)
            </p>
          </div>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6">
          <div v-if="charts" class="chart-wrapper">
            <div v-if="false" class="legend">
              <div></div>
              <p>수익률(세전)</p>
            </div>
            <div class="chart-area">
              <div
                v-for="chart in charts"
                :key="'chart_' + chart.year"
                class="chart"
                :style="{ opacity: chart.opacity }"
              >
                <p class="mb-2 text-center">
                  <span
                    class="font-size--18"
                    :class="{
                      'font-size--20 font-weight-black': chart.weight === 1,
                    }"
                  >
                    {{ chart.rate }}%
                  </span>
                </p>
                <div
                  class="stack"
                  :style="{
                    height: chart.height + 'px',
                    'background-color': chart.color,
                  }"
                ></div>
                <p class="mt-2 mb-0 font-size--14 text-center">
                  {{ chart.year }}년
                  <span class="font-size--12">
                    (세전)
                  </span>
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      MAX_HEIGHT: 270,
      charts: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const charts = [
        {
          year: 2019,
          rate: 9.5,
        },
        {
          year: 2020,
          rate: 10.19,
        },
        {
          year: 2021,
          rate: 12.32,
        },
      ]

      const arr = []
      charts.forEach((chart) => {
        arr.push(chart.rate)
      })
      arr.sort((a, b) => b - a)
      const max = arr[0]

      charts.forEach((chart) => {
        const diff = max - chart.rate
        const weight = (chart.rate - diff) / max

        chart.weight = weight
        chart.height = parseInt(this.MAX_HEIGHT * weight)
        chart.color = weight === 1 ? '#1baaf1' : 'lightgray'
        chart.opacity = weight === 1 ? weight : weight * 0.75
      })

      this.charts = charts
    },
  },
}
</script>

<style lang="scss" scoped>
#section__earnings-rate-chart {
  background-color: white;

  .erc__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-main {
      color: rgb(29, 27, 27);
      font-size: 28px;
      letter-spacing: 1px;
      margin: 25px 0 30px;

      p {
        margin: 0;
        line-height: 1.2;
      }
    }

    .text-sub {
      p {
        margin: 0;
        color: #565a5c;
        font-size: 15px;
        line-height: 1.5;
      }
    }
  }

  .chart-wrapper {
    max-width: 768px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;

    .legend {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 32px;
        height: 14px;
      }

      p {
        margin-bottom: 0;
        margin-left: 8px;
        font-size: 14px;
      }
    }

    .chart-area {
      max-width: 400px;
      width: 100%;
      height: 350px;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      margin: 0 auto;
      padding: 0 25px;

      .chart {
        min-width: 70px;

        .stack {
          width: 80px;
          background-color: #1baaf1;
          margin: 0 auto;
        }

        p {
          font-weight: bold;
          line-height: 1.4;
        }
      }
    }
  }

  &.mobile {
    .erc__text {
      display: block;
      text-align: center;

      .text-main {
        margin-top: 0;
        margin-bottom: 30px;
      }

      .text-sub {
        margin-bottom: 30px;
      }
    }

    .chart-wrapper {
      .chart-area {
        justify-content: space-evenly;
        padding: 0;
      }
    }
  }
}
</style>
