<template>
  <div v-if="isAuthenticated === false">
    <v-dialog v-model="onDialog" max-width="370" :persistent="btnSigninLoading">
      <div class="login">
        <v-form @submit.prevent="signin(user)">
          <v-card tile class="pa-7" width="100%" height="380">
            <v-card-title class="justify-center pb-7">
              <router-link to="/">
                <img src="@/assets/images/logo.png" alt="funda logo" />
              </router-link>
            </v-card-title>
            <v-card-actions class="justify-center">
              <div>
                <v-text-field
                  solo
                  single-line
                  hide-details
                  ref="user.username"
                  v-model="user.username"
                  label="아이디를 입력해주세요"
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  solo
                  single-line
                  hide-details
                  ref="user.password"
                  v-model="user.password"
                  type="password"
                  label="비밀번호를 입력해주세요"
                  prepend-inner-icon="mdi-key-variant"
                  autocomplete="on"
                ></v-text-field>
                <v-btn
                  depressed
                  class="btn__login"
                  type="submit"
                  :disabled="btnSigninLoading"
                  :loading="btnSigninLoading"
                >
                  로그인
                </v-btn>
                <button
                  v-if="!!!signupSocial"
                  class="btn__social btn__social--kakao"
                  :disabled="btnSigninLoading"
                  @click="clickKakao()"
                >
                  카카오 로그인
                </button>

                <div class="mt-3 text-center">
                  <v-btn
                    text
                    :to="{ name: 'signup' }"
                    class="pass-info mr-2"
                    @click="ON_LOGIN_DIALOG(false)"
                  >
                    회원가입
                  </v-btn>
                  <v-btn
                    text
                    :to="{ name: 'id' }"
                    class="pass-info"
                    @click="ON_LOGIN_DIALOG(false)"
                  >
                    ID/PW 찾기
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { AuthTypes } from '@/store/types/auth'
import AuthService from '@/service/auth/AuthService'
import { mapGetters, mapActions } from 'vuex'
import { SignUpFailedManyTimesException } from '@/error'

export default {
  data() {
    return {
      onDialog: false,
      user: {
        username: '',
        password: '',
        checkbox: false,
      },
      btnSigninLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      signupSocial: AuthTypes.getters.GET_SIGN_UP_SOCIAL,
      loginDialog: AuthTypes.getters.GET_LOGIN_DIALOG,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler() {
        this.user.username = ''
        this.user.password = ''
      },
    },
    loginDialog: {
      immediate: true,
      handler(v) {
        this.onDialog = v
      },
    },
    onDialog(v) {
      if (v === false) {
        this.ON_LOGIN_DIALOG(v)
      }
    },
  },
  methods: {
    ...mapActions('auth', ['signinKakao', 'signinSns', 'ON_LOGIN_DIALOG']),
    async signin(user) {
      if (user.username.length < 1) {
        await this.$swal.basic.alert('아이디를 입력해주세요.')
        // this.$refs['user.username'].focus()
        return
      } else if (user.password.length < 1) {
        await this.$swal.basic.alert('패스워드를 입력해주세요.')
        // this.$refs['user.password'].focus()
        return
      }
      this.btnSigninLoading = true
      try {
        await AuthService.signin(user)
        if (this.signupSocial) {
          this.signinSns(this.signupSocial)
        }
      } catch (e) {
        // 400번대의 에러라면
        if (e.status >= 400 && e.status < 500) {
          this.user.password = null
          this.$swal.basic.error(
            {
              title: '',
              html: `(${e.signUpFailedCount}/${e.maximumAllowedCount})<br/>아이디가 없거나 비밀번호가 틀렸습니다.<br/>(${e.maximumAllowedCount}회 실패시 ${e.failedTime}분간 제한)`,
            },
            this.isMobile
          )
        } else if (e instanceof SignUpFailedManyTimesException) {
          this.user.password = null
          this.$swal.basic.error(
            {
              title: '',
              text: e.message,
            },
            this.isMobile
          )
        } else {
          throw new Error(e)
        }
      } finally {
        this.btnSigninLoading = false
      }
    },
    async clickKakao() {
      try {
        this.btnSigninLoading = true
        await this.signinKakao()
      } catch (e) {
        if (e.status === 404) {
          let alert = await this.$swal.basic.custom({
            showCancelButton: true,
            confirmButtonText: '로그인',
            cancelButtonText: '회원가입',
            html:
              '연동된 회원정보가 없습니다<br />펀다 회원가입을 진행해주세요<br />기회원은 로그인 후 자동 연동됩니다.',
            customClass: {
              container: 'custom-alert__container pa-0',
              popup: 'custom-alert__popup',
              content: 'custom-alert__content pa-0',
              confirmButton: 'custom-alert__confirm-btn',
              cancelButton: 'custom-alert__cancel-btn',
            },
          })
          if (alert.isConfirmed === false) {
            this.ON_LOGIN_DIALOG()
            this.$router.push({ name: 'signup' })
          }
        }
      } finally {
        this.btnSigninLoading = false
      }
    },
    openNewTab(routerName) {
      let r = this.$router.resolve({
        name: routerName,
      })

      window.open(r.href, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: white;
  font-family: 'Noto Sans', sans-serif;

  .v-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .v-card {
    .v-toolbar__title {
      font-size: 22px;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        font-size: 19px;
      }
    }

    &__actions {
      padding: 0;

      & > div {
        width: 100%;
      }

      ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      .v-input {
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 10px;
        padding-top: 0;

        &::v-deep input {
          font-size: 12px;
          padding-left: 12px;
        }

        &::v-deep .v-input__control {
          min-height: 34px;

          .v-input__slot {
            padding: 0;
            box-shadow: none;
          }
        }

        &::v-deep .v-input__prepend-inner {
          background-color: #eee;
          height: 34px;
          width: 42px;
          margin-top: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right: 1px solid #ccc;

          .v-icon {
            left: 4px;
            top: 4px;
            color: #555;
          }

          .mdi-account::before {
            font-size: 20px;
          }

          .mdi-key-variant::before {
            font-size: 18px;
          }
        }

        & > .v-input__control > .v-input__slot:before {
          border-style: none;
        }

        &:first-child {
          margin-bottom: 10px;
        }

        &::v-deep .v-label {
          font-size: 12px;
          left: 12px !important;
        }
      }
    }

    .btn__login {
      color: #ffffff;
      font-size: 13px;
      font-weight: 600;
      height: 40px;
      width: 100%;
      border-radius: 3px;
      background-color: #1baaf1 !important;
      margin-top: 20px;
      margin-bottom: 10px;
      letter-spacing: 0;
    }

    .sign-up {
      color: #25aae1;
      font-size: 13px;
      font-weight: 600;
      height: 44px;
      width: 100%;
      border-radius: 3px;
      border: 2px solid #1baaf1;
      background-color: #ffffff;
      letter-spacing: 0;
    }

    .pass-info {
      color: #1baaf1;
      font-size: 13px;
      text-align: center;
    }
  }
}
</style>
