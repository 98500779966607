<template>
  <div id="dashboard-left" :class="{ fold: isMobile && isDetailFold }">
    <div
      v-if="myDashboardData == null"
      class="dashboard-box mx-md-auto px-6 py-6 d-flex justify-center align-center"
      :style="isMobile ? 'height: 280px;' : 'height: 617px;'"
    >
      <MyLoader />
    </div>
    <div v-else class="dashboard-box mx-md-auto px-6 py-6 overflow--hidden">
      <v-row class="pb-5 hr-b-dotted" no-gutters>
        <v-col cols="6" sm="6" md="6">
          <EarningsRateText />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-flex justify-end align-center text-right"
        >
          <span v-for="fc in myFundaClasses" :key="fc.fundaClass.className">
            <img
              v-if="!!fc.fundaClass.image"
              :src="
                '//' +
                  fc.fundaClass.image.bucketName +
                  '/' +
                  fc.fundaClass.image.fileKey
              "
              class="image__funda-class"
            />
          </span>
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-1" no-gutters>
        <v-col cols="5" xs="6" sm="5" md="5">
          <p class="left-key">투자 가능 금액</p>
        </v-col>
        <v-col cols="7" xs="6" sm="7" md="7" class="text-right">
          <span class="right-value color-funda--basic">
            <LoadingText :is-loading="amountLoading">
              {{ totalBalance | commaFormat }}
            </LoadingText>
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <v-row class="mb-1" no-gutters>
        <v-col cols="4" sm="4" md="4">
          <p class="left-key">총 자산</p>
        </v-col>
        <v-col cols="8" sm="8" md="8" class="text-right">
          <span class="right-value color--black">
            <LoadingText :is-loading="amountLoading || userInvestmentLoading">
              {{ totalAsset | commaFormat }}
            </LoadingText>
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <v-row :class="{ 'pb-5 hr-b-dotted': isDetailFold === false }" no-gutters>
        <v-col cols="6" sm="4" md="4">
          <p class="left-key">누적 수익금</p>
        </v-col>
        <v-col cols="6" sm="8" md="8" class="text-right">
          <span class="right-value color--black">
            {{ totalEarning | commaFormat }}
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <template v-if="isMobile == false || isDetailFold == false">
        <div class="mt-5 mb-5">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
                투자 가능
              </p>
            </v-col>
          </v-row>

          <v-row class="mb-1" no-gutters>
            <v-col cols="4" sm="4" md="4">
              <p class="left-key">예치금</p>
            </v-col>
            <v-col cols="8" sm="8" md="8" class="text-right">
              <span class="right-value color--gray"> <BalanceText />원 </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="4" sm="4" md="4">
              <p class="left-key">포인트</p>
            </v-col>
            <v-col cols="8" sm="8" md="8" class="text-right">
              <span class="right-value color--gray">
                {{ myDashboardData.sumPointBalance | commaFormat }} 원
              </span>
            </v-col>
          </v-row>
        </div>

        <div class="mb-5">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
                총 자산
              </p>
            </v-col>
          </v-row>

          <v-row class="mb-1" no-gutters>
            <v-col cols="4" sm="4" md="4">
              <p class="left-key">예치금</p>
            </v-col>
            <v-col cols="8" sm="8" md="8" class="text-right">
              <span class="right-value color--gray"> <BalanceText />원 </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5" sm="5" md="5">
              <p class="left-key">투자 중 원금</p>
            </v-col>
            <v-col cols="7" sm="7" md="7" class="text-right">
              <span class="right-value color--gray">
                <LoadingText :is-loading="userInvestmentLoading">
                  {{ !!userInvestment ? userInvestment.pure : 0 | commaFormat }}
                </LoadingText>
                원
              </span>
            </v-col>
          </v-row>
        </div>

        <div
          :style="
            isMobile == false ? 'margin-bottom: 37px;' : 'margin-bottom: 20px;'
          "
        >
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
                누적 수익
              </p>
            </v-col>
          </v-row>

          <v-row class="mb-1" no-gutters>
            <v-col cols="4" sm="4" md="4">
              <p class="left-key">
                이자
                <span class="font-size--12" style="margin-left: -2px">
                  (세전)
                </span>
              </p>
            </v-col>
            <v-col cols="8" sm="8" md="8" class="text-right">
              <span class="right-value color--gray">
                <LoadingText :is-loading="userEarningLoading">
                  {{
                    userEarning ? userEarning.totalInterest : 0 | commaFormat
                  }}
                </LoadingText>
                원
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="4" sm="4" md="4">
              <p class="left-key">포인트 등</p>
            </v-col>
            <v-col cols="8" sm="8" md="8" class="text-right">
              <span class="right-value color--gray">
                {{ totalPoint | commaFormat }}
                원
              </span>
            </v-col>
          </v-row>
        </div>

        <div class="my-limit padding-x-10 padding-y-10 color--gray">
          <InvestmentLimitText />
        </div>
      </template>

      <div v-if="isMobile == true">
        <v-row class="d-flex justify-center align-center mt-5" no-gutters>
          <button
            type="button"
            class="show-detail-btn font-size--16 font-weight-bold border--none outline--none"
            @click="isDetailFold = !isDetailFold"
          >
            상세 {{ isDetailFold == true ? '보기' : '접기' }}
            <span class="d-inline-flex">
              <img
                src="@/assets/images/common/icons/btn-btn-arrow-my-down-gray.svg"
              />
            </span>
          </button>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import MyLoader from '@/components/MyLoader.vue'
import EarningsRateText from '@/components/common/text/EarningsRateText.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import BalanceText from '@/components/common/text/BalanceText.vue'
import InvestmentLimitText from '@/components/common/text/InvestmentLimitText.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  components: {
    EarningsRateText,
    MyLoader,
    LoadingText,
    BalanceText,
    InvestmentLimitText,
  },
  data: function() {
    return {
      totalBalance: 0,
      isDetailFold: this.isMobile,
      myFundaClasses: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      getBalanceLoading: AuthTypes.getters.GET_BALANCE_LOADING,
      withdrawBalance: AuthTypes.getters.GET_BALANCE,
    }),
    ...mapGetters('investment', {
      userInvestmentLoading:
        InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userInvestment: InvestmentTypes.getters.GET_USER_INVESTMENT,
      userEarningLoading: InvestmentTypes.getters.GET_PROFIT_LOSS_LOADING,
      userEarning: InvestmentTypes.getters.GET_PROFIT_LOSS,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    amountLoading() {
      return this.getBalanceLoading === true || !!!this.withdrawBalance
    },
    totalAsset() {
      let withdraw = !!this.withdrawBalance ? this.withdrawBalance.withdraw : 0
      let pure = !!this.userInvestment ? this.userInvestment.pure : 0
      return withdraw + pure
    },
    totalPoint() {
      let v = 0
      if (!!this.myDashboardData) {
        v =
          this.myDashboardData.sumAllPoint -
          this.myDashboardData.sumExpiredPoint
      }

      return v
    },
    totalEarning() {
      const sumInterest = this.userEarning?.totalInterest
      return sumInterest + this.totalPoint
    },
  },
  watch: {
    myDashboardData(v) {
      if (v != null) {
        this.setByMyDashBoardData()

        if (this.isMobile === true && this.isDetailFold === true) {
          this.GET_BALANCE()
          this.getUserInvestment()
        }
      }
    },
    withdrawBalance: {
      deep: true,
      handler(v) {
        if (this.myDashboardData == null) {
          return
        }
        let withdraw = !!v ? v.withdraw : 0
        this.totalBalance = withdraw + this.myDashboardData.sumPointBalance
      },
    },
  },
  created() {
    this.setByMyDashBoardData()
    this.isDetailFold = this.isMobile
  },
  methods: {
    ...mapActions('auth', [AuthTypes.actions.GET_BALANCE]),
    ...mapActions('investment', {
      getUserInvestment: InvestmentTypes.actions.GET_USER_INVESTMENT,
    }),
    setByMyDashBoardData() {
      if (this.myDashboardData == null) {
        return
      }

      this.myFundaClasses = this.myDashboardData.user.userFundaClasses
    },
  },
}
</script>

<style lang="scss" scoped>
.show-detail-btn {
  background-color: #f6f8fa;
  color: #727d8c;
  width: 160px;
  height: 38px;
  border-radius: 20px;

  span img {
    transition: all 0.5s;
  }
}

#dashboard-left:not(.fold) {
  .show-detail-btn span img {
    transform: rotate(180deg);
  }
}

.image__funda-class {
  width: 55px;
}
</style>
