<template>
  <div style="min-width: 40px;">
    <transition name="fade1" mode="out-in">
      <p
        v-if="isLoading == false && status != null && textColor != null"
        key="text"
        class="list-card__status font-size--15 font-weight-black ma-0"
        :class="textColor"
        :style="myInvestment.isSafeplan == true ? 'letter-spacing: -1px;' : ''"
      >
        {{ status }}
      </p>
      <p
        v-else
        key="loader"
        class="list-card__status font-size--15 font-weight-black ma-0"
      >
        <MyLoader class="small" />
      </p>
    </transition>
  </div>
</template>

<script>
import MyLoader from '../MyLoader.vue'
export default {
  props: {
    myInvestment: Object,
    filter: String,
    isLoading: Boolean,
  },
  components: {
    MyLoader,
  },
  data() {
    return {
      status: null,
      isTester: false,
    }
  },
  async created() {
    let v = this.myInvestment
    if (this.isTester === true && v.fundingDataIdx < 9630) {
      this.status = '상세확인'
      return
    }

    if (v.fInvestmentType === 13) {
      if (this.filter !== '전체' && this.filter !== '부실') {
        this.status = this.filter
        return
      } else if (
        v.fundingDataIdx < 2900000 &&
        v.fStatus !== '중도상환마감' &&
        v.fRepaymentStatus !== '연체'
      ) {
        let q = `data: wwwFundanowInvestmentStatus(fNum: ${v.fundingDataIdx})`
        try {
          let data = await this.$fundaApi.query(gql`{${q}}`)
          v.fStatus = data
        } catch (e) {
          console.error(e)
        }
      }
    }

    if (v.fRepaymentStatus === '연체' || v.fStatus === '연체') {
      this.status = '연체'
    } else if (
      v.fRepaymentStatus === '채권매각' ||
      v.fRepaymentStatus === '기한의이익상실' ||
      v.fRepaymentStatus === '부도' ||
      v.fStatus === '부도'
    ) {
      if (v.isSafeplan === true) {
        this.status = '세이프플랜'
      } else if (v.fRepaymentStatus === '채권매각') {
        this.status = '채권매각'
      } else {
        this.status = '부실'
      }
    } else if (v.fStatus === '상품상환중') {
      this.status = '상환중'
    } else if (v.fStatus === '정상마감') {
      this.status = '상환완료'
    } else {
      this.status = v.fStatus.substring(0, 4)
    }
  },
  computed: {
    textColor() {
      if (this.status == null) {
        return null
      }

      if (this.status === '연체') {
        return 'color-funda--warning'
      } else if (this.status === '부실' || this.status === '채권매각') {
        return 'color-funda--warning'
      } else if (this.status === '상환중') {
        return 'color-funda--basic'
      } else if (this.status === '상환완료') {
        return 'color--gray-3'
      } else if (this.status === '중도상환마감') {
        return 'color--gray-3'
      } else if (this.status === '세이프플랜') {
        return 'color-funda--basic font-size--14'
      } else if (this.status === '상세확인') {
        return 'color--gray-2'
      } else {
        return 'color-funda--basic'
      }
    },
  },
}
</script>
