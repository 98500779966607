<template>
  <section id="section__affiliate" :class="{ mobile: isMobile }">
    <div class="section-container container">
      <v-row no-gutters class="section-main-row">
        <v-col cols="12">
          <p class="section-title">
            펀다와 함께 합니다
          </p>
        </v-col>

        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              v-for="list in mainList"
              :key="list.text"
              :cols="isMobile ? 12 : 4"
              class="affiliate-card"
            >
              <picture class="affiliate-card__logo">
                <img :src="`${cdnPath}${list.img}`" />
              </picture>

              <p class="affiliate-card__text">
                {{ list.text }}
              </p>
            </v-col>
          </v-row>

          <v-row no-gutters class="margin-t-25">
            <v-col cols="12">
              <p class="font-size--19 text-center my-7">
                &lt; 주요 주주사 >
              </p>
            </v-col>

            <v-col
              v-for="(list, index) in anotherList"
              :key="index"
              :cols="isMobile ? 4 : 2"
              class="affiliate-card list"
            >
              <picture class="affiliate-card__logo">
                <img :src="`${cdnPath}${list.img}`" />
              </picture>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      cdnPath: '//cdn.funda.kr/funding/common/image/_logo/with-funda/',
      mainList: [
        {
          img: 'ibk.jpg',
          text: '소상공인 대출 심사 위탁, 지분투자',
        },
        {
          img: 'fsc.gif',
          text: '소상공인 중금리대출 지정대리인 선정',
        },
        {
          img: 'nice.png',
          text: '소상공인 유동성 지원 "오늘입금" 공동사업',
        },
      ],
      anotherList: [
        {
          img: 'utc.png',
          name: '유티씨인베스트먼트',
          text: '지분투자',
        },
        {
          img: 'korea_inv.png',
          name: '한국투자파트너스',
          text: '지분투자',
        },
        {
          img: 'posco_inv.png',
          name: '포스코기술투자',
          text: '지분투자',
        },
        {
          img: 'kakao_inv.png',
          name: '카카오인베스트먼트',
          text: '지분투자',
        },
        {
          img: 'sj_inv.png',
          name: '에스제이인베스트먼트',
          text: '지분투자',
        },
        {
          img: 'inter.png',
          name: '인터베스트',
          text: '지분투자',
        },
        {
          img: 'hyundai_inv.png',
          name: '현대기술투자',
          text: '지분투자',
        },
        {
          img: 'tyel.png',
          name: '티엘자산운용',
          text: '지분투자',
        },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.anotherList.sort((a, b) => a.name.localeCompare(b.name))
  },
}
</script>

<style lang="scss" scoped>
#section__affiliate {
  background-color: white;

  .affiliate {
    &-card {
      text-align: center;
      padding: 0 15px;
      margin-bottom: 25px;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 200px;
          width: 100%;
          height: 55px;
          object-fit: contain;
        }
      }

      &__text {
        color: #333;
        font-size: 15px;
        line-height: 1.4;
        margin-top: 10px;
        margin-bottom: 0;
        white-space: nowrap;
      }

      &.list {
        margin-bottom: 35px;

        img {
          max-width: 170px;
          width: 100%;
          height: 40px;
          object-fit: contain;
        }

        &__text {
          font-size: 14px;
        }
      }
    }
  }

  &.mobile {
    .affiliate {
      &-card {
        padding: 0 10px;

        &__text {
          font-size: 13px;
          white-space: normal;
        }
      }
    }
  }
}
</style>
