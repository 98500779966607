import AuthService from '@/service/auth/AuthService'
const paths = ['/myfunda']

const isCheck = to => {
  for (let i = 0; i < paths.length; i++) {
    if (to.path.startsWith(paths[i])) {
      return true
    }
  }
  return false
}

export default async (to, from, next) => {
  if (isCheck(to) && AuthService.isSignin() === false) {
    next({ path: `/login?redirectUri=${AuthService.getRedirectUri(to)}` })
    return
  }
  next()
}
