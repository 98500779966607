// 레거시 v2를 리다이렉트 하기위한 코드
export default [{
  name: 'v2all',
  path: '/v2**',
  redirect(to) {
    let path = to.path.substr(3)
    if (path.length === 0) {
      path = '/'
    }
    return {
      path,
      query: to.query
    }
  }
}]
