<template>
  <div class="form-wrapper px-6 py-8">
    <button
      class="btn__social btn__social--kakao rounded--4 mb-3 font-size--16"
      style="height: 44px;"
      :disabled="isSignupLoading || isSocial"
      @click="clickKakao()"
    >
      {{
        isSocial == true
          ? '카카오 간편 회원가입 진행 중'
          : '카카오 간편 회원가입'
      }}
    </button>
    <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(signup)">
        <SignupInput
          name="email"
          ref="email"
          rules="required|min:5|email|id_duplicate|email_duplicate|max:30"
          icon="mdi-account"
          label="아이디/이메일"
          placeholder="아이디를 입력해주세요(이메일 형식)"
          :inputValue.sync="form.id"
          :disabled="isSignupLoading"
        />
        <template v-if="isSocial === false">
          <SignupInput
            name="password"
            rules="required|password"
            icon="mdi-key-variant"
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요(6~15자)"
            type="password"
            :inputValue.sync="form.password"
            :disabled="isSignupLoading"
            @focus="passwordFocus"
          />
          <SignupInput
            name="repassword"
            :rules="`required|repassword:${form.password}`"
            icon="mdi-key-variant"
            label="비밀번호 재입력"
            placeholder="비밀번호를 다시 입력해주세요"
            type="password"
            :inputValue.sync="form.rePassword"
            :disabled="isSignupLoading"
          />
        </template>
        <SignupPhoneGroup
          :inputValue.sync="form.phone"
          :verifyWord.sync="form.phoneVerifyWord"
          :authIdx.sync="form.phoneUserAuthIdx"
          :phoneValid.sync="phoneValid"
        />
        <v-divider />
        <SignupCheckbox
          label="투자알림 및 약관에 모두 동의합니다."
          labelClass="color--pink"
          :disabled="isSignupLoading"
          @update:inputValue="changeAllAgree"
        />
        <SignupCheckbox
          label="투자 및 서비스 이용약관에 동의합니다."
          link="/terms?mode=service"
          :inputValue.sync="checkOptions.serviceAgree"
          :rules="{ required: { allowFalse: false } }"
          :disabled="isSignupLoading"
          name="service_agree"
        />
        <SignupCheckbox
          label="개인정보 취급방침에 동의합니다."
          link="/terms?mode=info"
          :inputValue.sync="checkOptions.infoAgree"
          :rules="{ required: { allowFalse: false } }"
          :disabled="isSignupLoading"
          name="info_agree"
        />
        <SignupCheckbox
          label="투자 상품 및 이벤트 소식 받기"
          description="투자할 상품의 정보를 문자로 알려드립니다"
          :inputValue.sync="checkOptions.noticeAgree"
          :disabled="isSignupLoading"
        />
        <div class="mt-8 d-flex justify-center">
          <v-btn
            type="submit"
            color="#df398a"
            class="color--white font-size--16"
            style="width: 70%;"
            large
            :loading="isSignupLoading"
            :disabled="isSignupLoading || invalid || phoneValid == false"
          >
            가입하기
          </v-btn>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import SignupCheckbox from './form/SignupCheckbox.vue'
import SignupInput from './form/SignupInput.vue'
import SignupPhoneGroup from './form/SignupPhoneGroup.vue'
import AuthService from '@/service/auth/AuthService'
import Regex from '../../../../const/Regex'
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: { SignupInput, SignupCheckbox, SignupPhoneGroup },
  props: {
    recommenderCode: {
      type: String,
      default: '',
    },
    promotionCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        id: '',
        password: '',
        rePassword: '',
        phone: '',
        email: '',
        referral: '재테크 관련 커뮤니티 (블로그, 카페 등)',
        phoneVerifyWord: '',
        phoneUserAuthIdx: null,
        code: this.recommenderCode || '',
        affiliateCode: this.promotionCode || '',
      },
      checkOptions: {
        noticeAgree: true,
        serviceAgree: false,
        infoAgree: false,
      },
      phoneValid: false,
      isSignupLoading: false,
      isSocial: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      signupSocial: AuthTypes.getters.GET_SIGN_UP_SOCIAL,
    }),
  },
  watch: {
    signupSocial: {
      immediate: true,
      handler() {
        if (!!this.signupSocial) {
          this.isSocial = true
          this.form.id = this.signupSocial.profile.email
          this.form.email = this.signupSocial.profile.email
          if (this.signupSocial.profile.phone) {
            this.form.phone = this.signupSocial.profile.phone.replace(/-/g, '')
          }
          const password =
            uuidv4()
              .replace(/-/g, '')
              .substring(0, 13) + '!'
          this.form.password = password
          this.form.rePassword = password

          this.$nextTick(_ => {
            this.$refs.email.$refs.validationProvider.validate()
          })
        }
      },
    },
  },
  async created() {
    // 추천인 코드 및 프로모션 코드 검사
    if ((await this.confirmCodeValid(this.recommenderCode)) !== true) {
      this.$swal.basic.alert('추천인 코드가 유효하지 않습니다.')
      this.$router.replace({ name: 'main' })
    } else if (
      (await this.confirmAffiliateCodeValid(this.promotionCode)) !== true
    ) {
      this.$swal.basic.alert('프로모션 코드가 유효하지 않습니다.')
      this.$router.replace({ name: 'main' })
    }
  },
  methods: {
    ...mapActions('auth', ['signinKakao', 'signinSns']),
    async clickKakao() {
      try {
        this.isSignupLoading = true
        const isSigninSuccess = await this.signinKakao()
        if (isSigninSuccess === true) {
          this.$router.replace({ name: 'main' })
        }
      } catch (e) {
      } finally {
        this.isSignupLoading = false
      }
    },
    passwordFocus() {
      if (this.$refs.validForm.fields.password.invalid === true) {
        this.$refs.validForm.setErrors({
          password: [Regex.PASSWORD.MSG],
        })
      }
    },
    async confirmCodeValid(code) {
      if (!code || code === '') {
        return false
      }

      try {
        let isValid = await this.$fundaApi.query(gql`{
          data: searchRecommenderCode(
            code: "${code}"
            ) {
              code,
              shortenUrl
            }
        }`)
        return !!isValid.code
      } catch (e) {
        return false
      }
    },
    async confirmAffiliateCodeValid(affiliateCode) {
      if (!affiliateCode || affiliateCode === '') {
        return false
      }

      try {
        let isValid = await this.$fundaApi.query(gql`{
          data: getCouponByCode(
            couponCode: "${affiliateCode}"
            status: "사용중"
            ) {
              idx,
              title
            }
        }`)
        return !!isValid.idx
      } catch (e) {
        return false
      }
    },
    async signup() {
      if (this.phoneValid !== true) {
        return
      }
      if (this.isSignupLoading === true) {
        return
      }

      this.isSignupLoading = true
      try {
        // 이메일을 아이디로 사용
        this.form.email = this.form.id
        this.form.newsletterPhone =
          this.checkOptions.noticeAgree === true ? 'checked' : ''

        this.form.agree = []
        this.form.agree[0] = this.checkOptions.serviceAgree === true ? 'y' : 'n'
        this.form.agree[1] = this.checkOptions.infoAgree === true ? 'y' : 'n'

        const { user } = await this.$fundaApi.mutation(
          gql`
            {
              user: signupUser(signupUser: $signupUser) {
                id
                idx
              }
            }
          `,
          'signupUser($signupUser: SignupUser!)',
          {
            signupUser: this.form,
          }
        )

        await AuthService.signin({
          username: user.id,
          password: this.form.password,
        })
        if (this.signupSocial) {
          this.signinSns(this.signupSocial)
        }
        this.$router.push({ name: 'register' })
      } catch (e) {
        if (e.code) {
          this.$swal.basic.error(e.errors[0].message)
        } else {
          throw e
        }
      } finally {
        this.isSignupLoading = false
      }
    },
    changeAllAgree(value) {
      // checkOptions 아래 모든 항목 값 통일
      const checkOptionNames = Object.getOwnPropertyNames(this.checkOptions)
      checkOptionNames.forEach(checkOptionName => {
        this.checkOptions[checkOptionName] = value
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.form-wrapper {
  background-color: white;

  .input-wrapper {
    margin-bottom: 12px;
  }
}
</style>
