<template>
  <div :style="block ? 'width: 100%;' : ''">
    <v-btn
      large
      :loading="isLoading || isSelecting"
      :color="!dark ? 'primary' : ''"
      class="font-size--14"
      :class="dark ? 'btn-funda--black' : ''"
      :disabled="isLoading"
      @click="clickUploadButton"
      :block="block"
    >
      <template v-if="isLoading !== true">
        <slot>
          파일 업로드
        </slot>
        <v-icon right dark> mdi-cloud-upload</v-icon>
      </template>
      <slot v-else name="loader">
        <template v-if="loadingPercent && loadingPercent != 100">
          업로드 중
          <v-progress-linear
            absolute
            bottom
            :buffer-value="100 - loadingPercent"
            :value="loadingPercent"
            stream
            color="primary"
          />
        </template>
        <template v-else>
          <v-progress-circular indeterminate size="20" />
        </template>
      </slot>
    </v-btn>
    <input
      v-if="isCamera === true"
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      capture="camera"
      @change="uploadFile($event.target.files)"
    />
    <input
      v-else
      ref="uploader"
      class="d-none"
      type="file"
      @change="uploadFile($event.target.files)"
    />
  </div>
</template>
<script>
export default {
  props: {
    singleFile: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingPercent: {
      type: Number,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    isCamera: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelecting: false,
    }
  },
  methods: {
    clickUploadButton() {
      this.isSelecting = true

      try {
        window.addEventListener(
          'focus',
          () => {
            this.isSelecting = false
          },
          {
            once: true,
          }
        )
      } catch (e) {
        this.isSelecting = false
      }

      this.$refs.uploader.click()
    },
    uploadFile(files) {
      this.$emit('update:files', this.singleFile === true ? files[0] : files)
      this.$refs.uploader.value = null
    },
  },
}
</script>
