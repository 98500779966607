<template>
  <div class="intro">
    <section id="hero_2" class="invest-pased-wrap main-300">
      <div class="intro_title animated fadeInDown">
        <h1>펀다 소개</h1>
      </div>
    </section>
    <div class="intro-content">
      <div class="col-md-12 structure">
        <h2 class="margin-bottom-30">
          '건실한 상점에 안전한 투자를 연결합니다'<br /><strong
            class="text-blue"
            >국내 유일의 자영업 전문 P2P 금융기업 FUNDA</strong
          >
        </h2>
        <div class="text-center picture_area">
          <picture>
            <img src="@/assets/images/intro/intro_pc_img01_2.png" />
          </picture>
        </div>
        <p>
          펀다는 자금이 필요한 건실한 상점에 다수의 투자자들을 연결하는<br
            class="hidden-xs"
          />
          P2P 금융(대출형 크라우드펀딩) 서비스를 선도하며,<br
            class="hidden-xs"
          />
          자영업자와 투자자, 그리고 펀다가 함께 성장하는 상생의 금융 서비스를
          만들어갑니다.
        </p>
      </div>
      <div class="uvp">
        <div class="col-md-12 margin-top-50 why-funda">
          <h2><strong class="text-blue">왜 펀다인가요?</strong></h2>
        </div>
        <div class="col-md-12 uvp01">
          <div class="title-box">
            <h3>
              기존 금융권과 차별화된<br class="visible-xs" />
              자영업자 맞춤형 대출 서비스
            </h3>
          </div>
          <div class="text-center picture_area">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="@/assets/images/intro/intro_mo_img02.png"
              />
              <img src="@/assets/images/intro/intro_pc_img02.png" />
            </picture>
          </div>
          <p>
            펀다는 상점의 매출을 분석하여 대출자의 상환 능력을 평가합니다.<br
              class="hidden-xs"
            />
            펀다는 매출이 우수해도 신용등급이 낮아 은행 문턱을 넘기 어려웠던
            자영업자/소상공인들에게<br class="hidden-xs" />
            연 10%대의 중금리 대출 서비스를 제공하고 있습니다.
          </p>
        </div>
        <div class="col-md-12 uvp02">
          <div class="title-box">
            <h3>
              건실한 상점에 투자하는<br class="visible-xs" />
              안전한 연 8% 재테크
            </h3>
          </div>
          <div class="text-center">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="@/assets/images/intro/safe_8_percent.png"
              />
              <img
                class="width-100 margin-all-0"
                src="@/assets/images/intro/safe_8_percent.png"
              />
            </picture>
          </div>
          <p>
            펀다는 상점의 카드매출을 선수취하여 상환 재원으로 확보하고,<br
              class="hidden-xs"
            />
            다수의 상점에 분산투자하여 안전한 연 8% 수익을 추구합니다.
          </p>
        </div>
        <div class="col-md-12 uvp03">
          <div class="title-box">
            <h3>
              유수의 금융기관이 인정한<br class="visible-xs" />
              펀다의 자영업 심사 전문성
            </h3>
          </div>
          <div class="text-center picture_area">
            <picture>
              <source
                media="(max-width: 767px)"
                srcset="@/assets/images/intro/intro_pc_img05-01.png"
              />
              <img src="@/assets/images/intro/intro_pc_img05-01.png" />
            </picture>
          </div>
          <p>
            펀다는 매출분석 기반의 자영업 전문 심사력을 인정받아<br
              class="hidden-xs"
            />
            신한은행, IBK기업은행, KCB 등 다양한 파트너들과 협업하고
            있습니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    _seo({
      titles: ['건실한 상점에 안전한 투자를 연결합니다'],
    })
  },
}
</script>
<style lang="scss" scoped>
#hero_2 {
  position: relative;
  color: #fff;
  width: 100%;
  font-size: 16px;
  display: table;
  text-align: center;

  &.main-300 {
    height: 300px;

    @media (max-width: 768px) {
      height: 150px;
    }

    h1 {
      font-size: 38px;
      color: #fff;
      font-weight: bold;

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }

      &:before,
      &:after {
        display: inline-block;
        width: 60px;
        height: 1px;
        content: '';
        vertical-align: middle;
        background: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  &.invest-pased-wrap {
    background: #4d536d url('~@/assets/images/company_bg.jpg') no-repeat center
      center;
    background-size: cover;
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;
  }
}

.intro-content {
  background-color: #ffffff;

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 70px;

    @media (max-width: 480px) {
      font-size: 1.02em;
    }
  }
}

.uvp {
  margin: 0 auto;
  padding-bottom: 70px;
  width: 970px;

  .uvp02 {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .uvp03 {
    p {
      margin-bottom: 0;
    }
  }
}

.why-funda {
  margin-bottom: 30px;

  h2 {
    font-size: 31px;

    strong {
      font-weight: 600;
      color: #25aae1;
    }

    @media screen and (max-width: 970px) {
      font-size: 26px;
    }
  }
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
}

.uvp .title-box {
  background-color: #53bde7;
}

.uvp .title-box h3 {
  font-size: 22px;
  text-align: center;
  padding: 12px 0;
  color: #ffffff;
}

.picture_area picture img {
  width: auto;
  margin: 55px auto;
}

.margin-top-50 {
  margin-top: 50px;

  @media (max-width: 480px) {
    margin-top: 25px;
  }
}

.structure {
  background-color: #fbfbfb;
  border-bottom: 1px dashed #aaaaaa;
  padding-right: 10px;
  padding-left: 10px;

  h2 {
    line-height: 35px;
    font-size: 25px;
    margin-top: 2rem;

    @media (max-width: 480px) {
      font-size: 17px !important;
      font-weight: 600;

      .margin-bottom-30 {
        margin-bottom: 15px;
      }
    }
  }

  strong {
    font-size: 1.15em;
    color: #25aae1;
  }
}

.margin-all-0 {
  margin: 0 !important;
}

.width-100 {
  width: 100%;
}

.structure h2 {
  line-height: 35px;
  font-size: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

@media screen and (max-width: 970px) {
  .uvp {
    width: 100%;
  }

  .uvp .why-funda h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .picture_area picture img {
    width: 90%;
    margin: 40px 0;
  }

  .uvp03 .picture_area picture img {
    margin: 15px auto;
  }
}
</style>
