<template>
  <section id="section__media" :class="{ mobile: isMobile }">
    <div class="section-container container">
      <v-row class="media__main" no-gutters>
        <v-col cols="12">
          <p class="section-title white--text">
            언론속의 펀다
          </p>
        </v-col>
        <v-row no-gutters class="media__wrapper">
          <v-col
            :cols="isMobile ? 12 : 4"
            v-for="article in articleList"
            :key="article.title"
          >
            <div class="media-card">
              <a :href="article.link" target="_blank">
                <v-row no-gutters>
                  <v-col cols="12" class="media-card__head">
                    <picture class="media-card__logo">
                      <img :src="`${cdnPath}${article.img}`" />
                    </picture>
                    <p class="media-card__date">
                      {{ article.date }}
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters class="media-card__body">
                  <v-col cols="12" class="media-card__title">
                    <p class="title__main" v-html="article.title"></p>

                    <p class="title__sub">" {{ article.subTitle }} "</p>
                  </v-col>

                  <v-col cols="12" class="media-card__contents">
                    <p v-html="article.contents"></p>
                  </v-col>
                </v-row>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cdnPath: '//cdn.funda.kr/funding/common/image/_media/',
      articleList: [
        {
          title: 'AI·머신러닝 기반 취약 소상공인 품을 것',
          subTitle: '자생가능한 소상공인 솎아 적기 지원, 연체율 2% 불과',
          img: 'logo_mediapen.png',
          contents: `
            펀다는 독보적인 신용평가모형(CSS)을 보유하고 있으며, 
            인공지능(AI) 머신러닝 소상공인 매출데이터 분석 기술을 기반으로 상점의 미래 매출 및 폐업률 예측, 
            대출금 상환여력을 자동으로 평가한다. 
          `,
          link: 'http://m.mediapen.com/news/view/730338',
          date: '2022년 6월 10일',
          press: '미디어펜',
        },
        {
          title:
            '<span class="color-funda--basic">펀다</span>, 온투업 최종 등록',
          subTitle: '소상공인 최적의 금융 서비스 제공',
          img: 'logo_korea_text.png',
          contents: `
            펀다가 2021년 8월 26일자로 금융위원회에 <span style="color: #333;">온라인투자연계금융업</span>
            (온투업) 등록을 완료했다고 27일 밝혔다.
          `,
          link:
            'https://cnews.fntimes.com/html/view.php?ud=202108271544514056ee0209bd21_18',
          date: '2021년 8월 27일',
          press: '한국금융신문',
        },
        {
          title: '펀다, <b>66억원 규모</b>, 시리즈B 투자 유치',
          subTitle: 'IBK 기업은행 등 6개사',
          img: 'logo_maekyung_text.jpg',
          contents: `
            이번 투자를 리딩한 현대기술투자는 소상공인이라는 한 영역에 집중하여 다양한 금융 서비스를 개발해나가는 
            펀다만의 금융 전문성과 앞으로의 성장가능성을 높이 평가한다"고 말했다.
          `,
          link: 'https://www.mk.co.kr/news/economy/view/2020/01/56613/',
          date: '2020년 1월 7일',
          press: '매일경제',
        },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
#section__media {
  // background-color: #1baaf1;
  background-color: rgb(0, 173, 229);

  .section-title {
    margin-bottom: 5%;
  }

  .media__main {
    min-height: 400px;
  }

  .media-card {
    position: relative;
    max-width: 90%;
    width: 100%;
    height: 260px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 8px 32px 0 rgba(23, 25, 26, 0.5);
    padding: 15px;
    margin-left: auto;
    margin-right: auto;

    &__head {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      border-bottom: 3px solid rgb(145, 157, 218);
      padding-bottom: 4px !important;
    }

    &__logo {
      display: flex;

      img {
        width: 70px;
        height: 28px;
        object-fit: contain;
        object-position: center;
      }
    }

    &__date {
      color: #727d8c;
      font-size: 11px;

      margin: 0;
    }

    &__body {
      margin-top: 5px;
      // border-top: 1px solid rgb(145, 157, 218);
      // border-bottom: 1px solid rgb(145, 157, 218);
    }

    &__title {
      height: 70px !important;
      text-align: center;

      margin: 15px 0 10px;

      .title__main {
        color: #333;
        font-size: 19px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 4px;
      }

      .title__sub {
        color: #333;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.2;
        margin: 0;
      }
    }

    &__contents {
      p {
        color: #8a94a2;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.3;
        word-break: break-all;
      }
    }
  }

  &.mobile {
    .media__wrapper {
      > .col {
        margin-bottom: 7%;
      }
    }

    .media-card {
      max-width: 100%;

      &__title {
        .title__main {
          font-size: 18px;
        }

        .title__sub {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
