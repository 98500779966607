import { jsLoad } from '@/util'

let loaded = false
const init = async () => {
  if (loaded === true) {
    return true
  }
  await jsLoad({
    id: 'sc-kakao',
    src: '//developers.kakao.com/sdk/js/kakao.min.js',
  })
  Kakao.init('6d94baaffd4628183dc8710336cdfbbd')
  loaded = true
}

/**
 * https://developers.kakao.com/docs/latest/ko/message/js
 * 카카오 링크 공유
 * @param msg
 */
export const linkShare = async msg => {
  await init()
  Kakao.Link.sendDefault(msg)
}

export const signin = async () => {
  await init()
  return new Promise((resolve, reject) => {
    obj.signinKakaoResolve = resolve
    obj.signinKakaoReject = reject
    Kakao.Auth.login({
      success(authObj) {
        obj.signinKakaoResolve(authObj)
      },
      fail(err) {
        obj.signinKakaoReject(err)
      },
    })
  })
}

const obj = {
  linkShare,
  signin,
  signinKakaoResolve: null,
  signinKakaoReject: null,
}

export default obj
