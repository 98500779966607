<template>
  <PageLoading v-if="pageLoading" />
  <div
    v-else-if="isValid"
    class="d-flex align-center flex-column justify-center"
    style="min-height: 400px"
  >
    <p class="mb-3 font-size--17 font-weight-bold line-height--20 text-center">
      출금계좌 등록이 완료되었습니다.
    </p>
    <v-btn
      color="primary"
      class="font-size--16"
      height="45px"
      @click="nextStep()"
    >
      다음단계
    </v-btn>
  </div>
  <div v-else style="min-height: 500px">
    <v-alert text color="info" class="text-center">
      <div>
        출금 계좌 등록이란?<br />
        <br />
        현재 등록하시는 출금계좌를 통해서 투자실행을 위한 예치금 입금 및 예치금
        출금이 가능합니다.

        <br />
        <br />
        <p class="mb-0 error--text font-size--14 font-weight-bold">
          ※ 등록된 계좌 외에는 예치금 입,출금이 제한됩니다.
        </p>
      </div>
    </v-alert>

    <AuthWithdrawAccount />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import PageLoading from '@/components/common/PageLoading'
import AuthWithdrawAccount from '@/components/auth/AuthWithdrawAccount'

export default {
  components: {
    PageLoading,
    AuthWithdrawAccount,
  },
  data() {
    return {
      pageLoading: true,
      isValid: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal?.isValidAccount === newVal?.isValidAccount) {
          return
        }

        if (newVal?.isValidAccount === true) {
          this.isValid = true
        } else {
          this.isValid = false
        }
      },
    },
    isValid: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.nextStep()
        }
      },
    },
  },
  created() {
    setTimeout(() => {
      this.pageLoading = false
    }, 800)
  },
  methods: {
    nextStep() {
      if (this.isValid === false) {
        return
      }
      this.$emit('next-step')
    },
  },
}
</script>
