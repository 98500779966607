<template>
  <div
    v-if="myDashboardData == null"
    class="dashboard-box--pa-0 my-1 d-flex justify-center align-center"
    style="height: 528px"
  >
    <MyLoader />
  </div>
  <div v-else>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <div class="dashboard-box--pa-0 my-1" v-show="!isMobile">
          <InvestCountBoard />
        </div>
      </v-col>
    </v-row>

    <v-row
      class="d-flex"
      :class="isMobile ? 'flex-column' : ''"
      style="min-height: 400px"
    >
      <v-col cols="12" sm="12" md="4" lg="4" class="d-flex flex-column">
        <MyLinkedSocial />
        <MyAccount />
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" class="d-flex flex-column">
        <StoreIntro />
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" class="d-flex">
        <MyRecommender />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyLoader from '@/components/MyLoader.vue'
import InvestCountBoard from '@/components/myfunda/invest/InvestCountBoard.vue'
import MyLinkedSocial from '@/components/myfunda/home/MyLinkedSocial.vue'
import MyAccount from '@/components/myfunda/home/MyAccount.vue'
import StoreIntro from '@/components/myfunda/home/StoreIntro.vue'
import MyRecommender from '@/components/myfunda/home/MyRecommender.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  components: {
    MyLoader,
    InvestCountBoard,
    MyLinkedSocial,
    MyAccount,
    StoreIntro,
    MyRecommender,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss" scoped></style>
