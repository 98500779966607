<template>
  <div>
    <Login />
    <LimitTypeFileUpload />
    <ValidationAlarm />

    <OverlayRouterDialog />
  </div>
</template>

<script>
import Login from '@/components/Login.vue'
import LimitTypeFileUpload from '@/components/popups/LimitTypeFileUpload.vue'
import ValidationAlarm from '@/components/alarm/ValidationAlarm.vue'
import OverlayRouterDialog from './OverlayRouterDialog.vue'

export default {
  components: {
    Login,
    LimitTypeFileUpload,
    ValidationAlarm,
    OverlayRouterDialog,
  },
}
</script>
