<template>
  <div>
    <p class="earnings-rate-title color--gray">
      연 수익률(세전)
      <v-btn
        icon
        color="green"
        small
        class="btn__refresh"
        :class="{ turn: userEarningLoading || isLoading }"
        :disabled="isDisabled"
        @click="refreshRate()"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </p>
    <p
      class="mb-1 color-funda--basic font-weight-black"
      :class="isMobile ? 'font-size--30' : 'font-size--28'"
    >
      <LoadingText :is-loading="userEarningLoading || isLoading">
        {{ userEarning ? userEarning.earningsRate : 0 }}%
      </LoadingText>
    </p>
    <p class="color--gray-2 rate-update-time">
      <template v-if="isLoading === false && updateTime">
        ({{ updateTime }} 기준)
      </template>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import LoadingText from './LoadingText.vue'

export default {
  components: {
    LoadingText,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    ...mapGetters('investment', {
      userEarningLoading: InvestmentTypes.getters.GET_PROFIT_LOSS_LOADING,
      userEarning: InvestmentTypes.getters.GET_PROFIT_LOSS,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    updateTime() {
      if (this.userEarning) {
        const d = this.userEarning.showUpdateTime.split('T')
        const ymd = d[0]
        const hm = d[1].slice(0, 5)
        return `${ymd} ${hm}`
      }
      return null
    },
    afterMinutes() {
      if (this.userEarning) {
        const now = new Date()
        const updateTime = new Date(this.userEarning.showUpdateTime)
        updateTime.setHours(updateTime.getHours() + 1)

        const diff = updateTime - now
        if (diff > 0) {
          const minutes = Math.ceil(diff / (1000 * 60))
          return Math.min(59, minutes)
        }
      }
      return 0
    },
    isDisabled() {
      return this.afterMinutes > 0 || this.userEarningLoading || this.isLoading
    },
  },
  watch: {
    userIdx() {
      this.removeUserEarning()
    },
    userEarning: {
      immediate: true,
      deep: true,
      handler(nv, oldv) {
        if (!!this.userIdx && nv === null) {
          const isRealtime = oldv !== undefined
          this.getUserEarning(isRealtime)
        }
      },
    },
  },
  methods: {
    ...mapActions('investment', {
      removeUserEarning: InvestmentTypes.actions.REMOVE_PROFIT_LOSS,
      getUserEarning: InvestmentTypes.actions.GET_PROFIT_LOSS,
    }),
    refreshRate() {
      if (this.isDisabled) {
        return
      }

      this.removeUserEarning()
    },
  },
}
</script>

<style lang="scss" scoped>
.earnings-rate-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px !important;
  font-size: 14px;
  line-height: 1;
}

.btn__refresh {
  width: 36px;
  height: 24px;
}

.turn {
  animation: turnLoop 1s infinite;
}

@keyframes turnLoop {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rate-update-time {
  margin: 0;
  font-size: 13px;
  font-style: italic;
  white-space: nowrap;
}
</style>
