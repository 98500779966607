<template>
  <v-card>
    <v-card-actions v-if="totalPage > 1" class="mx-2">
      <strong
        >페이지: <span id="page_num">{{ currentPage }}</span> /
        <span id="page_count">{{ totalPage }}</span></strong
      >
      <v-spacer></v-spacer>
      <v-btn @click="onPrevPage" elevation="2">이전</v-btn>
      <v-btn @click="onNextPage" elevation="2">다음</v-btn>
    </v-card-actions>

    <div class="d-flex justify-center">
      <canvas id="the-canvas" :style="{ width: width }"></canvas>
    </div>
  </v-card>
</template>
<style scoped>
#the-canvas {
  height: 100%;
  border-top: 1px solid #f5f5f5;
}
</style>
<script>
import * as pdfjs from 'pdfjs-dist/es5/build/pdf'
import { pdfjsworker } from 'pdfjs-dist/es5/build/pdf.worker.entry'

const scale = 2

export default {
  name: 'pdfViewer',
  props: {
    url: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: String,
      default: '80%',
    },
  },
  data() {
    return {
      pdfDoc: null,
      currentPage: 0,
      totalPage: 0,
      pageNum: 1,
      pageNumPending: null,
      pageRendering: false,
    }
  },
  async mounted() {
    await this.loadPdf()
  },
  methods: {
    async loadPdf() {
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker

      const pdfDoc_ = await pdfjs.getDocument(this.url).promise
      this.pdfDoc = pdfDoc_

      this.totalPage = this.pdfDoc.numPages

      await this.renderPage(this.pageNum)
    },
    async renderPage(num) {
      this.pageRendering = true

      // Using promise to fetch the page
      const page = await this.pdfDoc.getPage(num)
      const viewport = page.getViewport({ scale: scale })
      const canvas = document.getElementById('the-canvas')
      const context = canvas.getContext('2d')

      canvas.height = viewport.height
      canvas.width = viewport.width

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }
      const renderTask = page.render(renderContext)

      // Wait for rendering to finish
      await renderTask.promise
      this.pageRendering = false
      if (this.pageNumPending !== null) {
        // New page rendering is pending
        await this.renderPage(this.pageNumPending)
        this.pageNumPending = null
      }

      this.currentPage = num
    },
    async queueRenderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num
      } else {
        await this.renderPage(num)
      }
    },
    async onPrevPage() {
      if (this.pageNum <= 1) {
        return
      }
      this.pageNum--
      await this.queueRenderPage(this.pageNum)
    },
    async onNextPage() {
      if (this.pageNum >= this.pdfDoc.numPages) {
        return
      }
      this.pageNum++
      await this.queueRenderPage(this.pageNum)
    },
  },
}
</script>
