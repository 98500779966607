<template>
  <div class="input-wrapper">
    <div v-if="label != null" class="font-size--12 mb-1">
      <v-icon small>{{ icon }}</v-icon>
      {{ label }}
    </div>
    <ValidationProvider
      v-slot="{ errors, invalid }"
      :name="name"
      :rules="rules"
      ref="validationProvider"
      slim
    >
      <v-text-field
        solo
        dense
        single-line
        autocomplete="off"
        ref="input"
        :placeholder="placeholder"
        :type="type"
        :class="{ addon }"
        :hide-details="errors.length == 0"
        :error-messages="errors"
        :value="inputValue"
        :disabled="disabled"
        @input="$emit('update:inputValue', $event.trim())"
        @focus="$emit('focus')"
      >
        <template v-if="addon == true" v-slot:append-outer>
          <v-btn
            depressed
            type="button"
            :loading="addonLoading"
            :disabled="
              invalid || errors.length > 0 || addonLoading || addonDisabled
            "
            color="primary"
            @click="$emit('click')"
          >
            {{ addonBtnText }}
          </v-btn>
        </template>
      </v-text-field>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    inputValue: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    addon: {
      type: Boolean,
      default: false,
    },
    addonBtnText: {
      type: String,
      default: null,
    },
    addonDisabled: {
      type: Boolean,
      default: false,
    },
    addonLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.input-wrapper {
  &::v-deep .v-text-field.v-input {
    font-size: 12px;

    .v-input__control {
      & > .v-input__slot {
        height: 34px;
        min-height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: none;

        &:before {
          border-style: none;
        }
      }

      & > .v-text-field__details {
        margin-bottom: 0;
      }

      & input {
        &::placeholder,
        &::-webkit-input-placeholder {
          font-size: 12px;
        }
      }
    }

    &.addon .v-input__control > .v-input__slot {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & .v-input__append-outer {
      margin-top: 0px !important;
      margin-left: 0px;

      .v-btn {
        height: 34px;
        min-width: 54.5px;
        font-weight: 600;
        letter-spacing: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
