<template>
  <div>
    <v-alert text color="info" class="text-center">
      <div>
        고객확인 제도에 따른 마지막 추가 정보 입력
        <br /><br />
        투자자의 안전한 투자 환경 제공을 위해 투자자의 직업 및 투자 목적을
        파악합니다.
      </div>
    </v-alert>
    <v-row no-gutters>
      <div class="label" @click="registerAddr()">
        <v-icon small>mdi-map-marker-outline</v-icon>주소입력
      </div>
      <v-col cols="12">
        <v-btn
          color="#333333"
          class="width-50 font-size--15 white--text my-3"
          @click="daumAddrOpen = !daumAddrOpen"
        >
          주소 검색
        </v-btn>
        <div v-show="daumAddrOpen" class="mb-3">
          <hr class="width-100 mt-0 mb-3" />
          <DaumAddr :isOpen.sync="daumAddrOpen" :addr.sync="daumAddr" />
        </div>
        <v-text-field
          outlined
          single-line
          v-model="addrForm.addr1"
          hide-details
          name="address"
          label="주소 검색을 이용하세요"
          readonly
          disabled
        ></v-text-field>
        <v-text-field
          outlined
          single-line
          v-model="addrForm.addr2"
          :rules="rules.address"
          name="address"
          autocomplete="off"
          ref="address2"
          label="상세주소를 입력해주세요"
        ></v-text-field>
      </v-col>

      <v-col v-for="qna in qnaList" :key="qna.name" cols="12">
        <div class="label">
          <v-icon small>mdi-playlist-check</v-icon>
          {{ qna.text }}
        </div>
        <v-select
          outlined
          :name="qna.name"
          :items="qna.options"
          :rules="rules[qna.name]"
          v-model="eddForm[qna.name]"
          placeholder="선택해주세요"
          :hide-details="qna.name === 'purpose' && showPurposeField"
        >
        </v-select>
        <v-text-field
          outlined
          v-if="qna.name === 'purpose'"
          v-show="showPurposeField"
          v-model="selfPurposeField"
          :label="qna.text"
          :rules="rules[qna.name]"
          class="mt-3"
          placeholder="직접 입력해주세요"
          :error="!!!selfPurposeField"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          class="btn-funda-basic"
          color="primary"
          :disabled="isLoading || isValidForm !== true"
          :loading="isLoading"
          @click="saveForm()"
          elevation="0"
        >
          추가 정보 저장
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import DaumAddr from '@/components/DaumAddr'

export default {
  components: {
    DaumAddr,
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      daumAddrOpen: false,
      daumAddr: null,
      rules: {
        address: [(value) => !!value || '주소를 입력해주세요.'],
        job: [(value) => !!value || '직업을 선택해주세요.'],
        purpose: [(value) => !!value || '거래목적을 선택해주세요.'],
        sourceOfFund: [(value) => !!value || '자금의 원천을 선택해주세요.'],
      },
      qnaList: [
        {
          name: 'job',
          text: '고위험 직업확인',
          icon: 'mdi-playlist-check',
          options: [
            '해당사항 없음',
            '대부업',
            '귀금속 판매업',
            '카지노 사업',
            '환전업',
            '가상자산 취급업',
          ],
        },
        {
          name: 'purpose',
          text: '거래 목적',
          icon: 'mdi-playlist-check',
          options: ['노후준비', '상속', '저축', '투자/재테크'],
        },
        {
          name: 'sourceOfFund',
          text: '자금 원천',
          icon: 'mdi-playlist-check',
          options: [
            '근로소득',
            '사업소득',
            '금융소득',
            '부동산소득',
            '상속 및 증여',
            '연금/퇴직소득',
          ],
        },
      ],
      showPurposeField: false,
      selfPurposeField: null,
      addrForm: {
        addr1: null,
        addr2: null,
      },
      eddForm: {
        job: null,
        purpose: null,
        sourceOfFund: null,
      },
      userEdds: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    jonQna() {
      let result = null
      const array = this.qnaList
      for (let index = 0; index < array.length; index++) {
        const qna = array[index]
        if (qna?.name === 'job') {
          result = qna
          break
        }
      }

      return result
    },
    isValidAddr() {
      return !!this.addrForm.addr1 && !!this.addrForm.addr2
    },
    isValidForm() {
      if (this.isValidAddr !== true) {
        return false
      }

      let allValid = this.qnaList.every((qna) => {
        return !!this.eddForm[qna.name]
      })

      return allValid
    },
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v?.isValidEdd === true) {
          this.isValid = true
        } else {
          this.isValid = false
        }
      },
    },
    isValid: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.nextStep()
        }
      },
    },
    daumAddr: function(value) {
      this.addrForm.addr1 = value.address
      this.$refs.address2.focus()
    },
    'eddForm.job'(v) {
      if (v !== this.jonQna?.options[0]) {
        this.$swal.basic.custom({
          icon: 'warning',
          confirmButtonText: '확인',
          customClass: {
            container: 'custom-alert__container icon-container',
            icon: 'custom-alert__icon',
            popup: 'custom-alert__popup edd-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__ok-btn',
          },
          html: `
            <p style="font-size: 17px; font-weight: 900; margin-bottom: 15px;">
              유선심사 추가 진행
            </p>

            <p style="line-height: 1.4; margin-bottom: 10px;">
              고객님께서는 자금세탁방지법에 의거,
              자금세탁행위 위험 직업에 분류되는 [${v}] 을(를) 선택하셨으며,
            </p>

            <p>
              금융업무규정에 따라 추가 유선심사가 진행될 예정입니다. 
            </p>
          `,
        })
      }
    },
    'eddForm.purpose'(v) {
      if (v === '기타') {
        this.showPurposeField = true
      } else {
        this.selfPurposeField = null
        this.showPurposeField = false
      }
    },
    userEdds: {
      immediate: true,
      async handler(v) {
        if (!!v && v.length > 0) {
          this.isValid = true
        }
      },
    },
  },
  created() {
    this.getEdds()
  },
  methods: {
    ...mapActions('auth', {
      reloadProfile: AuthTypes.actions.GET_PROFILE,
    }),
    nextStep() {
      if (this.isValid === false) {
        return
      }
      this.$emit('next-step')
    },
    async getEdds() {
      let q = `data: getUserEdds(userIdx: ${this.userIdx}) {
        userIdx
        question
        answer
        createTime
      }`

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.userEdds = data
      } finally {
        this.isLoading = false
      }
    },
    async registerAddr() {
      if (this.isValidAddr !== true) {
        return
      }

      const addr = [this.addrForm.addr1, this.addrForm.addr2].join(',')

      let q = `result: wwwUpdateUserAddress(address: "${addr}") {
        idx
        address
      }`
      await this.$fundaApi.mutation(gql`{${q}}`)
    },
    async registerEdds() {
      if (this.isValidForm !== true) {
        return
      }

      const params = this.qnaList.map((qna) => {
        let obj = {}

        obj.question = qna.text
        obj.answer = this.eddForm[qna.name]

        if (qna.name === 'purpose' && !!this.selfPurposeField) {
          obj.answer = this.selfPurposeField
        }

        return obj
      })

      let q = `
        data: registerEDD(userIdx: ${this.userIdx}, inputUserEdd: $input) {
          idx
          userIdx
          question
          answer
          createTime
        }
      `

      let data = await this.$fundaApi.mutation(
        gql`{${q}}`,
        'call($input: [InputUserEdd])',
        { input: params }
      )

      return data
    },
    async saveForm() {
      let alert = await this.$swal.basic.confirm('추가 정보 등록을 진행합니다')
      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      try {
        await this.registerAddr()
        const edds = await this.registerEdds()

        if (!!edds && edds.length > 0) {
          await this.reloadProfile()
          await this.$swal.basic.success(
            '추가 정보가 정상적으로 등록되었습니다.'
          )
          this.userEdds = edds
        } else {
          this.$swal.basic.error(
            '추가 정보 등록 중 에러가 발생하였습니다. 다시 시도해주세요.'
          )
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>
.custom-alert__popup.edd-alert__popup {
  max-width: 400px !important;
  width: 95% !important;
}
</style>

<style lang="scss" scoped>
div.label {
  font-weight: 600;
}
</style>
