<template>
  <LoadingText
    :is-loading="getBalanceLoading === true || !!!balance"
    :loading-size="loadingSize"
    :loading-width="loadingWidth"
  >
    {{ slotText | commaFormat }}
  </LoadingText>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import LoadingText from './LoadingText'

export default {
  props: {
    type: {
      type: String,
      default: 'withdraw',
    },
    loadingSize: {
      default: 16,
    },
    loadingWidth: {
      default: 2,
    },
  },
  components: {
    LoadingText,
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      balance: AuthTypes.getters.GET_BALANCE,
      getBalanceLoading: AuthTypes.getters.GET_BALANCE_LOADING,
    }),
    slotText() {
      if (this.isAuthenticated === true) {
        return this.type === 'point'
          ? this.balance?.point
          : this.balance?.withdraw
      }
      return 0
    },
  },
  created() {
    this.GET_BALANCE()
  },
  methods: {
    ...mapActions('auth', [AuthTypes.actions.GET_BALANCE]),
  },
}
</script>
