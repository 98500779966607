<template>
  <v-row class="login" align="center" justify="center" no-gutters>
    <v-col cols="12" sm="8" md="4">
      <div v-if="agree === false">
        <v-card tile class="pt-2 pr-7 pb-5 pl-7" max-width="370" height="380">
          <v-card-title class="justify-center">
            제3자 정보 제공동의
          </v-card-title>
          <v-card-text>
            <p class="font-size--15">
              펀다의 투자 정보를 {{ $route.query.clientName }} 서비스에 제공하는
              것에 동의합니다.
            </p>
            <p class="font-size--15">
              정보 제공 비동의 시 추가 리워드 포인트 제공이 불가능 합니다.
            </p>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn class="btn-agree" @click="agree = true">동의하기</v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <template v-else>
        <v-row class="login" align="center" justify="center" no-gutters>
          <v-col cols="12" class="text-center">
            <button class="btn-funda-basic reverse" @click="ON_LOGIN_DIALOG()">
              FUNDA 로그인
            </button>
          </v-col>
        </v-row>
        <Login />
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { AuthTypes } from '@/store/types/auth'
import { mapGetters, mapActions } from 'vuex'
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
        checkbox: false,
      },
      btnSigninLoading: false,
      agree: false,
    }
  },
  created() {
    // // 강제로 끄기
    // window.ChannelIO('shutdown')
    _seo({
      titles: ['로그인'],
    })
  },
  watch: {
    agree(value) {
      if (value === true) {
        if (this.isAuthenticated === true) {
          this.redirect()
        } else {
          this.ON_LOGIN_DIALOG()
        }
      }
    },
    isAuthenticated: {
      immediate: true,
      handler(v) {
        if (v === true && this.agree === true) {
          this.redirect()
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
    }),
  },
  methods: {
    ...mapActions('auth', [AuthTypes.actions.ON_LOGIN_DIALOG]),
    redirect() {
      let redirectUri = this.$route.query.redirectUri
      // 임시적으로 해당하는 host에 대해서만 redirect를 허용한다.
      const tempHosts = [
        'localhost',
        'dev-www.shopporters.com',
        'www.shopporters.com',
      ]
      let closed = true
      for (let i = 0; i < tempHosts.length; i++) {
        const tempHost = tempHosts[i]
        if (redirectUri?.indexOf(tempHost) > -1) {
          closed = false
        }
      }
      if (closed) {
        self.close()
      }

      if (!!redirectUri) {
        location.href = `${redirectUri}${
          redirectUri.indexOf('?') > -1 ? '&' : '?'
        }token=${btoa(localStorage[this.$store.state.env.token.name])}`
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.login {
  min-height: 100vh;
  background: url('~@/assets/images/login_background.jpg') no-repeat center;
  background-size: cover;
}

#funda-main {
  font-family: 'Noto Sans', sans-serif;

  .v-form {
    margin-left: 10px;
    margin-right: 10px;
  }

  .v-card {
    margin: 105px auto 155px;

    .btn-agree {
      color: #ffffff;
      font-size: 13px;
      font-weight: 600;
      height: 40px;
      width: 100%;
      border-radius: 3px;
      background-color: #25aae1;
      margin-top: 20px;
      margin-bottom: 10px;
      letter-spacing: 0;
    }
  }
}

button.btn-funda-basic {
  width: 200px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}
</style>
