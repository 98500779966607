<template>
  <div style="background-color: white">
    <div v-if="fundingData === null" class="text-center">
      <MyLoader />
    </div>
    <div v-else>
      <section class="parallax-window">
        <div class="parallax-content-2 product-contents">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="8">
              <p class="funding__num ma-0 color--white font-italic">
                {{ fNum }}호
              </p>
              <p class="funding__title ma-0 mb-1 color--white font-weight-bold">
                {{ fundingData.title }}
              </p>
              <p class="funding__sub-title ma-0 color--white">
                {{ fundingData.subTitle }}
              </p>
            </v-col>

            <v-col
              class="d-flex align-end justify-end"
              cols="12"
              sm="12"
              md="4"
            >
              <p class="funding__amt ma-0 color--white">
                <span>
                  {{ (fundingData.investmentAmount / 10000) | commaFormat }}
                </span>
                /
                {{ (fundingData.totalAmount / 10000) | commaFormat }} 만원
              </p>
            </v-col>
          </v-row>
        </div>
      </section>

      <section class="section__detail">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="8">
            <template v-if="!!fundingData.majungmulType">
              <Majungmul :f-num="fNum" />
            </template>

            <template v-else>
              <General :f-num="fNum" :funding-data="fundingData" />
            </template>

            <hr class="my-10" />

            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <p class="mt-0 mb-4 font-size--22 font-weight-bold">
                  투자 위험 고지
                </p>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <p class="ma-0 line-height--20">
                  펀다는 투자 원금과 수익을 보장하지 않으며, 투자 손실에 대한
                  책임은 투자자에게 있습니다. 펀다의 상품 투자는 투자자가 펀다
                  플랫폼을 통해 대출 채권의 원금과 이자를 수취할 수 있는
                  권리(원리금 수취권)를 매입하는 것으로, 대출 채권의 상태 변경에
                  따라 수익률이 직접적인 영향을 받을 수 있습니다.
                </p>
                <p
                  v-if="fundingData.provisionFundIdx < 1"
                  class="ma-0 color--red line-height--20"
                >
                  본 상품은 세이프플랜 미적용 상품입니다. 본 상품의 수익률에는
                  세이프플랜이 적용된 상품 대비 투자에 따른 리스크가 반영되어
                  있으며, 채권의 연체 및 부도 발생 시 원금 손실 가능성을 가지고
                  있습니다.
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="4" class="remote-wrapper"> </v-col>
        </v-row>
      </section>
    </div>
  </div>
</template>

<script>
import MyLoader from '@/components/MyLoader'
import General from './types/General'
import Majungmul from './types/Majungmul'

export default {
  props: {
    fNum: {
      type: String,
      default: null,
    },
  },
  components: {
    MyLoader,
    General,
    Majungmul,
  },
  data() {
    return {
      loading: false,
      fundingData: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true

      let q = `
        data: wwwFundingDetail(fNum: ${this.fNum}) {
          title
          subTitle
          visible
          isEasyLoan
          totalAmount
          minInvestAmount
          monthlyRepayment
          interestRate
          repaymentMethod
          loanPeriod
          gracePeriod
          score
          fundaGrade
          kcbGrade
          investmentStartDate
          investmentType
          fundingRepresentativeFeature
          provisionFundIdx
          status
          purpose1
          purpose2
          purpose3
          registerDate
          investmentStartDate
          investmentClosureDate

          investmentAmount

          additionalInfoJson

          store {
            name
            address
            address2
            type
            operatingHour
            employeeNumber
            area
            openDate
          }

          loanBreakdowns {
            bankAndInsuranceAmount
            cardAndSavingBankAmount
          }
          
          majungmulType {
            name
          }
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.fundingData = data
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.parallax-window {
  position: relative;
  background-color: #25aae1;
  background-size: cover;
  opacity: 1;
  overflow: visible;

  .product-contents {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding: 53px 12px;
  }
}

.section__detail {
  max-width: 1185px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 12px;
}

.funding {
  &__num {
    font-size: 18px;
    line-height: 1.3;
  }

  &__title {
    font-size: 49px;
    line-height: 1.3;

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  &__sub-title {
    font-size: 20px;
    line-height: 1.3;

    @media screen and (max-width: 768px) {
      font-size: 17px;
      margin-bottom: 15px !important;
    }
  }

  &__amt {
    font-size: 22px;

    @media screen and (max-width: 768px) {
      font-size: 17px;
    }

    span {
      font-size: 42px;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}
</style>
