<template>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-spinner {
  display: inline-block;
  position: relative;

  div {
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      border-radius: 25%;
      background: #727d8c;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }

  &.small {
    width: 15px;
    height: 15px;
    margin-left: -10px;

    div {
      transform-origin: 26px 11px;

      &:after {
        top: 17px;
        left: 26px;
        width: 1px;
        height: 5px;
      }
    }
  }

  &:not(.small) {
    width: 50px;
    height: 50px;

    div {
      transform-origin: 27px 27px;

      &:after {
        top: 9px;
        left: 25px;
        width: 3px;
        height: 10px;
      }
    }
  }
}
</style>
