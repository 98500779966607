import store from '@/store'
import Vue from 'vue'

// window.onerror = function (msg, src, linenum, colnum, error) {
//   console.log(msg, src, linenum, colnum, error)
// }

Vue.config.errorHandler = (err, vm, info) => {
  if (err.name === 'NavigationDuplicated') {
    store.dispatch('time/refresh')
  } else {
    // store.dispatch('errors/add', { title: '정의되지 않은 에러 발생', message: err })
    // console.error(err)
  }
}
