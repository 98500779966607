<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (1) 누적 연계대출 금액
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th
                  v-for="year in years"
                  :key="year + '년'"
                  class="text-center"
                >
                  {{ year }} 년
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="aKey in accumulationLinkKeys" :key="'누적_' + aKey">
                <td class="text-center">{{ aKey }}</td>
                <td
                  v-for="val in info"
                  :key="'누적_' + val.year"
                  class="text-right"
                >
                  {{ val.누적연계대출[aKey] | commaFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-black">
          (2) 연계대출 잔액
        </h3>
      </v-col>

      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th
                  v-for="year in years"
                  :key="year + '년'"
                  class="text-center"
                >
                  {{ year }} 년
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="nKey in normalLinkKeys" :key="'일반_' + nKey">
                <td class="text-center">{{ nKey }}</td>
                <td
                  v-for="val in info"
                  :key="'누적_' + val.year"
                  class="text-right"
                >
                  {{ val.연계대출잔액[nKey] | commaFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '누적 연계대출 금액 및 연계대출 잔액',
    },
  },
  data() {
    return {
      info: null,
      years: [],
      accumulationLinkKeys: [],
      normalLinkKeys: [],
    }
  },
  watch: {
    info: {
      deep: true,
      handler(v) {
        if (!!v) {
          let years = []
          this.info.forEach((val) => {
            years.push(val.year)
            this.accumulationLinkKeys = this.keyList(val.누적연계대출)
            this.normalLinkKeys = this.keyList(val.연계대출잔액)
          })
          this.years = years
        }
      },
    },
  },
  created() {
    this.info = [
      {
        year: 2023,
        누적연계대출: {
          '부동산 PF': 0,
          부동산담보: 715000000,
          '기타담보 (어음, 매출채권 담보 제외)': 12974700000,
          '어음, 매출채권 담보': 745000000,
          '개인사업자 신용': 343610169990,
          '법인 신용': 45388235114,
          합계: 403433105104,
        },
        연계대출잔액: {
          '부동산 PF': 0,
          부동산담보: 0,
          '기타담보 (어음, 매출채권 담보 제외)': 2063430794,
          '어음, 매출채권 담보': 0,
          '개인사업자 신용': 3303585844,
          '법인 신용': 0,
          합계: 5367016638,
        },
      },
      {
        year: 2022,
        누적연계대출: {
          '부동산 PF': 0,
          부동산담보: 715000000,
          '기타담보 (어음, 매출채권 담보 제외)': 12974700000,
          '어음, 매출채권 담보': 745000000,
          '개인사업자 신용': 270557689778,
          '법인 신용': 45388235114,
          합계: 330380624892,
        },
        연계대출잔액: {
          '부동산 PF': 0,
          부동산담보: 20000000,
          '기타담보 (어음, 매출채권 담보 제외)': 2150230794,
          '어음, 매출채권 담보': 0,
          '개인사업자 신용': 3841851605,
          '법인 신용': 0,
          합계: 6012082399,
        },
      },
      {
        year: 2021,
        누적연계대출: {
          '부동산 PF': 0,
          부동산담보: 695000000,
          '기타담보 (어음, 매출채권 담보 제외)': 12974700000,
          '어음, 매출채권 담보': 745000000,
          '개인사업자 신용': 237945714127,
          '법인 신용': 45388235114,
          합계: 297748649241,
        },
        연계대출잔액: {
          '부동산 PF': 0,
          부동산담보: 0,
          '기타담보 (어음, 매출채권 담보 제외)': 2269910794,
          '어음, 매출채권 담보': 0,
          '개인사업자 신용': 4101808945,
          '법인 신용': 16923644,
          합계: 6388643383,
        },
      },
      {
        year: 2020,
        누적연계대출: {
          '부동산 PF': 0,
          부동산담보: 695000000,
          '기타담보 (어음, 매출채권 담보 제외)': 12974700000,
          '어음, 매출채권 담보': 745000000,
          '개인사업자 신용': 158580531745,
          '법인 신용': 45388235114,
          합계: 218383466859,
        },
        연계대출잔액: {
          '부동산 PF': 0,
          부동산담보: 0,
          '기타담보 (어음, 매출채권 담보 제외)': 2779493979,
          '어음, 매출채권 담보': 0,
          '개인사업자 신용': 5374103622,
          '법인 신용': 347532388,
          합계: 8501129989,
        },
      },
      {
        year: 2019,
        누적연계대출: {
          '부동산 PF': 0,
          부동산담보: 695000000,
          '기타담보 (어음, 매출채권 담보 제외)': 12372700000,
          '어음, 매출채권 담보': 745000000,
          '개인사업자 신용': 76040133699,
          '법인 신용': 43917675114,
          합계: 133770508813,
        },
        연계대출잔액: {
          '부동산 PF': 0,
          부동산담보: 150000000,
          '기타담보 (어음, 매출채권 담보 제외)': 5521696732,
          '어음, 매출채권 담보': 22008163,
          '개인사업자 신용': 17096707364,
          '법인 신용': 1688995828,
          합계: 24479408087,
        },
      },
      // {
      //   year: 2018,
      //   누적연계대출: {
      //     '부동산 PF': 0,
      //     부동산담보: 695000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 2098700000,
      //     '어음, 매출채권 담보': 745000000,
      //     '개인사업자 신용': 33575037663,
      //     '법인 신용': 37025725114,
      //     합계: 74139462777,
      //   },
      //   연계대출잔액: {
      //     '부동산 PF': 0,
      //     부동산담보: 150000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 523219285,
      //     '어음, 매출채권 담보': 22008163,
      //     '개인사업자 신용': 9060798663,
      //     '법인 신용': 3959403840,
      //     합계: 13715429951,
      //   },
      // },
      // {
      //   year: 2017,
      //   누적연계대출: {
      //     '부동산 PF': 0,
      //     부동산담보: 545000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 1528700000,
      //     '어음, 매출채권 담보': 745000000,
      //     '개인사업자 신용': 13505509848,
      //     '법인 신용': 11540685114,
      //     합계: 27864894962,
      //   },
      //   연계대출잔액: {
      //     '부동산 PF': 0,
      //     부동산담보: 210000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 167400987,
      //     '어음, 매출채권 담보': 290507716,
      //     '개인사업자 신용': 5134306351,
      //     '법인 신용': 3968822897,
      //     합계: 9771037951,
      //   },
      // },
      // {
      //   year: 2016,
      //   누적연계대출: {
      //     '부동산 PF': 0,
      //     부동산담보: 335000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 1278700000,
      //     '어음, 매출채권 담보': 595000000,
      //     '개인사업자 신용': 5975200000,
      //     '법인 신용': 4779800000,
      //     합계: 12963700000,
      //   },
      //   연계대출잔액: {
      //     '부동산 PF': 0,
      //     부동산담보: 125000000,
      //     '기타담보 (어음, 매출채권 담보 제외)': 0,
      //     '어음, 매출채권 담보': 475828744,
      //     '개인사업자 신용': 3480641949,
      //     '법인 신용': 2978197290,
      //     합계: 7059667983,
      //   },
      // },
    ]
  },
  methods: {
    keyList(info) {
      if (!!info) {
        return Object.keys(info)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped></style>
