<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="(item, index) in items" :key="index">
        <span class="font-size--15">
          {{ item.tab }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <v-card elevation="0">
          <v-card-text class="px-0 py-3">
            <component v-bind:is="item.content"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Limit2Type from './limit-types/Limit2Type.vue'
import Limit4Type from './limit-types/Limit4Type.vue'

export default {
  components: {
    Limit2Type,
    Limit4Type,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: '적격 투자자', content: 'Limit2Type' },
        { tab: '전문 투자자', content: 'Limit4Type' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab--active {
  background-color: rgb(37, 174, 225, 0.1);
}

::v-deep table {
  border-collapse: collapse;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #8a94a2;

  .total-amt {
    background-color: rgb(238, 238, 238, 0.3);
  }

  thead {
    background-color: #f9fcff;

    &.rpy {
      background-color: white;
      border-bottom: 1px solid #e7edf6;

      th {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
      }
    }

    th {
      margin: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
  }

  tbody {
    tr {
      &.active {
        background-color: #f5f5f5;
      }
    }

    td {
      padding-top: 12px;
      padding-bottom: 12px;
      color: #565a5c;
    }
  }
}
</style>
