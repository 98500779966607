<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th colspan="2">
                  구 분
                </th>
                <th>
                  내용
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">
                  상호
                </td>
                <td>
                  한글
                </td>
                <td>
                  펀다
                </td>
              </tr>
              <tr>
                <td>
                  영문
                </td>
                <td>
                  Funda
                </td>
              </tr>
              <tr v-for="inf in Object.keys(info)" :key="inf">
                <td colspan="2">
                  {{ inf }}
                </td>
                <td>
                  {{ info[inf] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 온라인투자연계금융업 등록 내역',
    },
  },
  data() {
    return {
      info: {
        대표자: '박성준',
        '소재지(본점)': '서울 서초구 사평대로53길 30 메이크잇 203호 ,204호',
        '대표 전화번호': '02-6925-1788',
        '홈페이지 주소': 'www.funda.kr',
        '회사 설립일': '2015년 6월 15일',
        법인등록번호: '110111-5746130',
        '온라인투자연계금융업 등록번호': '2022-18',
        '온라인투자연계금융업 등록일': '2021년 8월 26일',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
th,
td {
  text-align: center;
}
</style>
