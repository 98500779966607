export const XaiTypes = {
  getters: {
    GET_MSG: 'GET_MSG',
  },
  mutations: {
    SET_MSG: 'SET_MSG',
  },
  actions: {
    GET_MSG: 'GET_MSG',
  },
}
