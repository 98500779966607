<template>
  <div class="my-balance-deposit-table width-100" :class="{ mobile: isMobile }">
    <div class="flexible-table width-100">
      <div class="header-row">
        <div
          class="header font-weight-black justify-center"
          v-for="(header, index) in headers"
          :key="index"
          :style="{ width: header.width }"
        >
          {{ header.name }}
        </div>
      </div>
      <div
        v-if="data == null || data.length < 1"
        class="d-flex justify-center align-center"
        style="height: 226px"
      >
        <transition name="fade1" mode="out-in">
          <MyLoader v-if="isLoading == true" key="loader" />
          <div
            v-else
            key="empty"
            class="text-center font-weight-bold mx-auto width-100"
          >
            <img src="@/assets/images/myfunda/ic-ic-my-list-empty.svg" />
            <p class="color--gray-2 margin-t-10 font-size--16">
              거래내역이 아직 없습니다.
            </p>
          </div>
        </transition>
      </div>
      <div
        v-else
        class="body-row d-flex"
        v-for="(row, index) in data"
        :key="index"
        ref="bodyrow"
      >
        <slot name="col" :row="row"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import MyLoader from '@/components/MyLoader'

export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MyLoader,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>

<style lang="scss">
/* start:my-balance-deposit-table */
.my-balance-deposit-table .flexible-table {
  height: auto;
}

.my-balance-deposit-table.mobile .header-row {
  display: none;
}

.my-balance-deposit-table .body-row {
  display: flex;
  width: 100%;
}

.my-balance-deposit-table.mobile .body-row {
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 8px 0;
}

.my-balance-deposit-table .header-row,
.my-balance-deposit-table.mobile .body-row:nth-child(2) {
  border-top: solid 1px #333333;
}

.my-balance-deposit-table:not(.mobile) .body-row,
.my-balance-deposit-table.mobile .body-row:not(:last-child) {
  border-bottom: solid 1px #d7e1ea;
}

.my-balance-deposit-table .body-row:last-child {
  padding-bottom: 0;
}

.my-balance-deposit-table .header-row div {
  background-color: #f9fcff;
  font-size: 13px;
}

.my-balance-deposit-table .header-row div,
.my-balance-deposit-table .body-row > div {
  height: 46px;
  display: inline-flex;
  align-items: center;
}

.my-balance-deposit-table .body-row > div {
  padding: 0 7px;
}

.my-balance-deposit-table.mobile .body-row > div {
  height: 20px;
  padding: 0;
}

.my-balance-deposit-table__filter div {
  width: 75px;
  border-radius: 20px;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  background-color: #ffffff;
}

.my-balance-deposit-table__filter.mobile {
  flex-wrap: wrap;
}

.my-balance-deposit-table__filter.mobile div {
  width: 25%;
  justify-content: center;
  display: flex;
}

.my-balance-deposit-table__filter div.active {
  background-color: #f5f5f5;
}
/* end:my-balance-deposit-table */
</style>
