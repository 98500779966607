<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="!show" key="hide">
        <v-row class="d-flex align-center" no-gutters>
          <v-col cols="12" sm="12" md="2">
            <p class="setting__name font-size--14">휴대전화</p>
          </v-col>

          <v-col cols="9" sm="9" md="6" lg="7">
            <input
              class="input-setting"
              type="tel"
              readonly
              disabled
              :value="phone"
            />
          </v-col>

          <v-col cols="3" sm="3" md="4" lg="3" class="pl-2">
            <button
              v-if="false"
              class="btn-funda--black rounded common-button"
              style="max-width: 100%;"
            >
              변경
            </button>
          </v-col>
        </v-row>
      </div>
      <div v-else key="show">
        <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(requestChangePhone)">
            <ChangeFormInput
              label="변경 휴대전화"
              name="phone"
              rules="required|numeric|phone"
              autofocus
              :inputValue.sync="phoneNumber"
              :disabled="isLoading || userAuthIdx != null"
            />
            <v-row
              v-if="userAuthIdx != null"
              class="d-flex align-center mt-3 mb-2"
              no-gutters
            >
              <v-col cols="12" sm="12" md="2"></v-col>

              <v-col cols="12" lg="10" md="10" sm="9">
                <MyVerifyForm
                  :isMobile="isMobile"
                  :expireTime="expireTime"
                  :isLoading="isLoading"
                  :errorMsg="confirmErrorMessage"
                  @request-confirm="confirmChangePhone"
                  @request-resend="requestChangePhone"
                />
              </v-col>

              <v-col cols="3" sm="3" md="4"></v-col>
            </v-row>
            <!-- 폼 액션 시작 -->
            <v-row class="d-flex align-center mb-5" no-gutters>
              <v-col cols="12" sm="12" md="2"> </v-col>

              <v-col cols="12" sm="9" md="10" lg="10">
                <v-row no-gutters>
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                    class="mb-0 mb-md-2 mb-lg-0 pr-1"
                  >
                    <button
                      type="submit"
                      class="btn-funda--black rounded common-button passwd"
                      :disabled="invalid || isLoading || userAuthIdx != null"
                      v-ripple
                    >
                      변경
                    </button>
                  </v-col>

                  <v-col cols="6" lg="6" md="6" sm="6" class="pl-1">
                    <button
                      type="button"
                      class="btn-funda--black rounded common-button passwd"
                      style="background-color: #f9356a"
                      @click="close"
                      :disabled="isLoading"
                      v-ripple
                    >
                      취소
                    </button>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="3" md="12"> </v-col>
            </v-row>
            <!-- 폼 액션 끝 -->
          </form>
        </ValidationObserver>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import MyVerifyForm from '../../MyVerifyForm.vue'
import ChangeFormInput from './ChangeFormInput.vue'
import { AuthTypes } from '@/store/types/auth'
import { mapActions } from 'vuex'

export default {
  components: { ChangeFormInput, MyVerifyForm },
  props: {
    phone: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      reqired: true,
    },
  },
  data() {
    return {
      show: false,
      phoneNumber: null,
      userAuthIdx: null,
      expireTime: null,
      confirmErrorMessage: null,
      isLoading: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    ...mapActions('auth', {
      reloadProfile: AuthTypes.actions.GET_PROFILE,
    }),
    async requestChangePhone() {
      if (this.isLoading === true) {
        return
      }

      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '휴대전화 변경',
          text:
            '변경할 번호로 인증문자가 발송됩니다.\n 휴대전화 번호를 변경하시겠습니까?',
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        const {
          result: { idx, expireDatetime },
        } = await this.$fundaApi.mutation(gql`{
          result: wwwRequestUpdateUserPhone(phone: "${this.phoneNumber}") {
            idx
            expireDatetime
          }
        }`)

        this.userAuthIdx = idx
        this.expireTime = new Date(expireDatetime)
      } catch (e) {
        this.$refs.validForm.setErrors({
          phone: [e.errors[0].message],
        })
      } finally {
        this.isLoading = false
      }
    },
    async confirmChangePhone(code) {
      if (this.isLoading === true) {
        return
      }

      try {
        this.isLoading = true

        const { result } = await this.$fundaApi.mutation(gql`{
          result: wwwConfirmUpdateUserPhone(userAuthIdx: ${this.userAuthIdx}, phone: "${this.phoneNumber}", verifyCode: "${code}")
        }`)

        if (result === true) {
          await this.reloadProfile()

          this.$swal.basic.alert('휴대전화 변경이 완료되었습니다.')
          this.close()
        }
      } catch (e) {
        if (e.errors[0].message) {
          this.confirmErrorMessage = e.errors[0].message
        } else {
          this.$swal.basic.alert(
            '휴대폰 번호 변경에 실패했습니다. 문제가 지속되면 문의바랍니다.'
          )
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped>
button {
  transition: all 0.3s;
}
.my-setting .common-button.passwd {
  max-width: 100%;
}
</style>
