import Vue from 'vue'
import Vuex from 'vuex'

const modules = [
  require('./alarm'),
  require('./auth'),
  require('./env'),
  require('./funding'),
  require('./time'),
  require('./errors'),
  require('./event'),
  require('./code'),
  require('./system'),
  require('./seo'),
  require('./notice'),
  require('./overlay'),
  require('./investment'),
  require('./xai'),
].reduce((map, module) => {
  const d = module.default
  map[d.name] = d
  map[d.name].defaultState = module.defaultState
  return map
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  state: {
    globalLoading: false,
  },
  getters: {
    isGlobalLoading(state) {
      return state.globalLoading
    },
  },
  mutations: {
    clearState(state) {
      console.log('FUNDA 로그아웃')
      for (const key in modules) {
        const module = modules[key]
        if (typeof module.defaultState === 'function') {
          state[key] = module.defaultState()
        }
      }
    },
  },
})
