<template>
  <main class="main not-found my-10">
    <Error404></error404>
  </main>
</template>
<script>
import Error404 from '@/components/error/Error404.vue'
export default {
  components: {
    Error404,
  }
}
</script>
<style lang="scss" scoped>
.image img {
  max-width: 350px;
}
</style>
