import { numberFormat, removeComma } from '@/util'
import Vue from 'vue'

const nObj = {
  common: {
    setToValue(obj, value, path) {
      var i
      path = path.split('.')
      for (i = 0; i < path.length - 1; i++) {
        obj = obj[path[i]]
      }
      obj[path[i]] = value
    },
    getToValue(obj, arr) {
      var res = obj
      arr.forEach((v, i) => {
        if (Object.prototype.hasOwnProperty.call(obj, v)) {
          res = nObj.common.getToValue(obj[v], arr.slice(i + 1))
        }
      })
      return res
    },
    getMaxValue(el) {
      return parseInt(el.getAttribute('max'), 10)
    },
  },
  bind(el, bind, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = removeComma(e.target.value)
      nObj.inputHandler(el, bind, vnode, e.target.value)
    })
  },
  update(el, bind, vnode) {
    let maxValue = nObj.common.getMaxValue(el)
    let v = nObj.common.getToValue(vnode.context, bind.expression.split('.'))

    if (!isNaN(maxValue) && maxValue < v) {
      nObj.common.setToValue(vnode.context, parseInt(maxValue), bind.expression)
      nObj.update(el, bind, vnode)
      return
    }

    v = v === null ? NaN : v
    el.value = isNaN(v) ? null : numberFormat(v)
  },
  inputHandler(el, bind, vnode, eventValue) {
    // eslint-disable-next-line
    if (nObj.common.getToValue(vnode.context, bind.expression.split('.')) === eventValue) {
      nObj.update(el, bind, vnode)
      return
    }

    nObj.common.setToValue(vnode.context, eventValue, bind.expression)
  },
}

Vue.directive('input-currency', nObj)
