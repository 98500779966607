<template>
  <v-card>
    <v-card-title>
      {{ notice.title }}
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            :href="downloadUrl"
            download
            target="_blank"
          >
            <v-icon dark>
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </template>
        <span>다운로드</span>
      </v-tooltip>
    </v-card-title>
    <PdfViewer v-if="downloadUrl" :url="downloadUrl" :width="'100%'" />
  </v-card>
</template>
<script>
// import * as firebase from '@/plugins/firebase'
import PdfViewer from '@/components/PdfViewer'

export default {
  name: 'shareholdersNotice',
  components: {
    PdfViewer,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    notice: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      cdnPath: 'https://cdn.funda.kr/shareholders-notice/',
      title: '',
      downloadUrl: null,
    }
  },
  async created() {
    await this.setNotice()
  },
  methods: {
    formatCreated(time) {
      return new Date(time.toDate()).toISOString().split('T')[0]
    },
    async setNotice() {
      // const collection = 'notices'
      this.getNoticeLoading = true
      try {
        // const querySnapshot = await firebase.db
        //   .collection(`${collection}`)
        //   .doc(this.id)
        //   .get()
        // this.title = querySnapshot.data().title
        // this.downloadUrl = querySnapshot.data().downloadUrl
        const fullUrl = `${this.cdnPath}${encodeURIComponent(this.id)}`
        this.downloadUrl = fullUrl
      } catch (error) {
        if (error) {
          this.$swal.fire('실패', '공지사항을 가져오는데 실패했습니다', 'error')
        }
      } finally {
        this.getNoticeLoading = false
      }
    },
  },
}
</script>
