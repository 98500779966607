import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyAL5-uWUEwa3tkLGG7LZBHlLm8BGQSTxek',
  authDomain: 'stockholder-notices.firebaseapp.com',
  projectId: 'stockholder-notices',
  storageBucket: 'stockholder-notices.appspot.com',
  messagingSenderId: '766809836349',
  appId: '1:766809836349:web:f43c993b50891209f1d354'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// export utils/refs
export { db, auth }
