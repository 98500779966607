import './api'
import './auth'
import './components'
import './compositionApi'
import './eventBus'
import './gtm'
import './moment'
import './sentry'
import './sweetalert2'
import './vueCookies'
import './vueMeta'
import vuetify from './vuetify'

export default {
  vuetify,
}
