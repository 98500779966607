export const OverlayTypes = {
  getters: {
    IS_SHOW_OVERLAY: 'IS_SHOW_OVERLAY',
    GET_OVERLAY: 'GET_OVERLAY',
    GET_PROPS: 'GET_PROPS',
  },
  mutations: {
    SET_OVERLAY: 'SET_OVERLAY',
    SET_PROPS: 'SET_PROPS',
  },
  actions: {
    CLOSE_OVERLAY: 'CLOSE_OVERLAY',
    OPEN_OVERLAY: 'OPEN_OVERLAY',
    PASS_PROPS: 'PASS_PROPS',
  },
}
