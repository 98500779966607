<template>
  <FileUploadButton
    singleFile
    :files.sync="file"
    :isLoading="isLoading"
    :loadingPercent="uploadCompletedPercent"
    :isCamera="isCamera"
    :dark="dark"
    @update:files="updateFile"
  >
    <slot></slot>
  </FileUploadButton>
</template>
<script>
/**
 * <S3FileUploadButton
 *  ref="refName"
 *  uploadOnSelect
 *  bucketName="dev-bucket-name"
 *  path="test/b"
 * />
 *
 * < 목적 >
 * 파일을 임시버켓에 저장한 후(this.$refs.{ref}.uploadTempFile())
 * 해당 파일을 계속 보관해야하는 경우 지정한 버켓에 저장하는 기능 제공(this.$refs.{ref}.saveFile())
 *
 * < 기능1 >
 * this.$refs.{ref}.uploadTempFile() : 파일을 s3 temp버켓에 저장
 * (uploadOnSelect props가 있으면 파일 선택시 자동 임시 저장됨)
 *
 * 성공시 'update:tempFile' 이벤트 발생(tempFile.sync modifier 사용 가능)
 * data example
 * {
 *      "orgName": "orgName.exe",
 *      "tempKey": "temp/tempKey.exe"
 *  }
 *
 *
 * < 기능2 >
 * this.$refs.{ref}.saveFile() : 임시 저장된 파일을 지정된 s3 버켓에 저장
 * (uploadTempFile()이 선행되어있어야 실행된다
 * {bucketName props} 버켓의 {path props} 경로에 저장됨)
 *
 * 성공시 'update:savedFile' 이벤트 발생(savedFile.sync modifier 사용 가능)
 * data example
 * {
 *   "idx": idx,
 *   "bucketName": "bucketName",
 *   "fileKey": "test/b/tempKey.exe",
 *   "orgName": "orgName.exe",
 *   "created": "2020-05-26T10:55:31.442"
 * }
 *
 */
import FileUploadButton from './FileUploadButton.vue'

export default {
  components: { FileUploadButton },
  props: {
    uploadOnSelect: {
      type: Boolean,
      default: false,
    },
    bucketName: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    tempFile: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    savedFile: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    isCamera: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      uploadCompletedPercent: 0,
      isLoading: false,
    }
  },
  methods: {
    async updateFile() {
      if (this.uploadOnSelect === true) {
        await this.uploadTempFile(this.file)
      }
    },
    async uploadTempFile(file) {
      try {
        this.isLoading = true

        let formData = new FormData()
        formData.append('file', file)

        const data = await this.$fundaApi.postS3TempFile(
          formData,
          (progressEvent) => {
            this.uploadCompletedPercent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          }
        )
        this.$emit('update:tempFile', data[0])
        this.$swal.basic.alert('파일이 업로드 되었습니다')
      } finally {
        this.isLoading = false
        this.uploadCompletedPercent = 0
      }
    },
    async saveFile() {
      if (this.tempFile == null) {
        return
      }
      const data = await this.$fundaApi.putS3TempFile({
        bucketName: this.bucketName,
        movePath: this.path,
        orgName: this.tempFile.orgName,
        tempKey: this.tempFile.tempKey,
      })
      this.$emit('update:savedFile', data)
    },
  },
}
</script>
