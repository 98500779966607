export default {
  name: 'notice',
  namespaced: true,
  state: {
    show: true,
    notice: null,
  },
  getters: {
    isShow(state) {
      return state.show && !!state.notice
    },
    getNotice(state) {
      return state.notice
    },
  },
  mutations: {
    setShowNotice(state, flag) {
      if (typeof flag !== 'boolean') {
        flag = !state.show
      }
      state.show = flag
    },
    setNotice(state, data) {
      state.notice = data
    },
  },
  actions: {
    switchNotice({ commit }, flag) {
      commit('setShowNotice', flag)
    },
    getNotice({ commit, getters }) {
      if (!!getters.getNotice) {
        return
      }

      // let notice = {
      //   category: '[이벤트]',
      //   content: '8월 한정 3종 이벤트 진행중!',
      //   url: 'https://bit.ly/3m7ksAX',
      // }

      let notice = null

      commit('setNotice', notice)
    },
  },
}
