<template>
  <div class="funding__detail">
    <v-row class="funding__detail__feat" no-gutters>
      <v-col cols="12">
        <ul class="feat__icons">
          <li class="mb-5">
            <p class="ma-0">
              <i class="d-block text-center icon-calendar-7"></i>
              상환기간
              <br v-if="isMobile" />
              <span class="color-funda--basic font-size--18">
                {{ fundingData.loanPeriod }}개월
              </span>
            </p>
          </li>

          <li class="mb-5">
            <p class="ma-0">
              <i class="icon-money-2"></i>
              연 수익률
              <br v-if="isMobile" />
              <span class="color-funda--basic font-size--18">
                {{ (fundingData.interestRate * 100).toFixed(2) }}%
                <a>!</a>
              </span>
            </p>
          </li>

          <li v-if="fundingData.provisionFundIdx > 0">
            <p class="ma-0 font-size--15 text-center">
              <template
                v-if="fundingData.fundingRepresentativeFeature === '부분보호'"
              >
                <img
                  class="feat__img"
                  src="@/assets/images/safeplan/pc_safe5000_icon.png"
                />
              </template>
              <template v-else>
                <img
                  class="feat__img"
                  src="@/assets/images/safeplan/pc_safe_icon.png"
                />
              </template>
              <br />
              <span>
                {{ fundingData.fundingRepresentativeFeature }}
              </span>
              <a>?</a>
            </p>
          </li>

          <li v-if="fundingData.investmentType === 11">
            <p class="ma-0 font-size--15 text-center">
              <img
                class="feat__img"
                src="//cdn.funda.kr/funding/custom/card_assure/card_assure_icon.png"
                style="width: 50px"
              />
              <br />
              <span> 카드매출 담보 </span>
              <a>?</a>
            </p>
          </li>
        </ul>

        <hr class="my-10" style="border-top: 1px dashed #ddd" />

        <ul>
          <li class="text-center">
            <p class="mb-2 font-size--14">상품종류</p>
            <p class="ma-0 font-size--16">
              {{ fundingData.store.type }}
            </p>
          </li>

          <li class="text-center">
            <p class="mb-2 font-size--14">월 상환금</p>
            <p class="ma-0 font-size--16">
              {{ parseInt(fundingData.monthlyRepayment / 10000) | commaFormat }}
              만원
            </p>
          </li>

          <li class="text-center">
            <p class="mb-2 font-size--14">상환방식</p>
            <p class="ma-0 font-size--16">
              {{ fundingData.repaymentMethod }}
            </p>
          </li>
        </ul>
      </v-col>
    </v-row>

    <hr class="my-10" />

    <template v-if="!!customHTML">
      <v-row no-gutters>
        <v-col cols="12" sm="12">
          <div class="custom-wrapper" v-html="customHTML"></div>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
          <p class="mt-0 mb-4 font-size--22 font-weight-bold">대출목적</p>
        </v-col>

        <v-col cols="12" sm="12" md="8">
          <ul class="analysis-point-list">
            <li>{{ fundingData.purpose1 }}</li>
          </ul>
        </v-col>
      </v-row>

      <hr class="my-10" />

      <template v-if="!!salesData">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="4">
            <p class="mt-0 mb-4 font-size--22 font-weight-bold">매출분석</p>
          </v-col>

          <v-col cols="12" sm="12" md="8">
            <SalesGoogleStackChart
              :id="'stackChart'"
              :raw-data="salesData"
              :is-mobile="isMobile"
            />
            <ul v-if="!!repaymentData" class="analysis-point-list">
              <li>
                최근 {{ repaymentData.period }}개월 평균 월 BC카드 매출은
                {{ repaymentData.averageSale }}
                만원 수준입니다.
              </li>
            </ul>
          </v-col>
        </v-row>
        <hr class="my-10" />
      </template>

      <template v-if="!!repaymentData">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="4">
            <p class="mt-0 mb-4 font-size--22 font-weight-bold">일상환 분석</p>
          </v-col>

          <v-col cols="12" sm="12" md="8">
            <RepaymentGoogleBarChart
              :id="'barChart'"
              :raw-data="repaymentData"
              :is-mobile="isMobile"
            />
            <ul v-if="!!repaymentData" class="analysis-point-list bar-chart-ul">
              <li>
                상점의 일환산 상환금(25 영업일 기준)은
                {{ repaymentData.repaymentPerDay | commaFormat }}원으로, 이는
                상점의 일평균
                <span class="color-funda--basic">
                  BC카드 매출의
                  {{ repaymentData.repaymentRatio }}% </span
                >에 해당합니다.
              </li>
              <li>
                펀다는 안정적인 상환을 위해 해당 상점의 일평균
                <span class="color-funda--basic">
                  BC카드 매출의 약 100%인
                  {{ repaymentData.bcSalesPerDay | commaFormat }}원 </span
                >을 매일 적립합니다.
              </li>
              <li>
                이 경우 본 상점은
                <span class="color-funda--basic">
                  약 {{ repaymentData.repaymentDay }} 영업일
                </span>
                경 해당 월의 상환 원리금 적립을 완료할 것으로 예상합니다.
              </li>
            </ul>
          </v-col>
        </v-row>
        <hr class="my-10" />
      </template>

      <template v-if="false">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="4">
            <p class="mt-0 mb-4 font-size--22 font-weight-bold">투자자 보호</p>
          </v-col>

          <v-col cols="12" sm="12" md="8">
            <ul class="d-flex">
              <i class="icon-lock-2"></i>
              <li>운영 자금</li>
            </ul>
          </v-col>
        </v-row>

        <hr class="my-10" />
      </template>

      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
          <p class="mt-0 mb-4 font-size--22 font-weight-bold">신용평가</p>
        </v-col>

        <v-col cols="12" sm="12" md="8">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="5"
              class="mb-4"
              :class="{ 'pr-3': isMobile === false }"
            >
              <div class="c-rate__box">
                <div class="c-rate__header">
                  <p class="ma-0 font-size--17 text-center">KCB 신용정보</p>
                </div>
                <div class="c-rate__rate">
                  <p class="ma-0">
                    <span>
                      {{ fundingData.kcbGrade }}
                    </span>
                    등급
                  </p>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="7"
              class="mb-4"
              :class="{ 'pl-3': isMobile === false }"
            >
              <div class="l-breakdown">
                <div class="l-breakdown__header">
                  <p class="ma-0 font-size--17 text-center">
                    기존 신용대출정보
                  </p>
                </div>
                <div class="l-breakdown__detail">
                  <table class="width-100">
                    <thead>
                      <tr>
                        <th width="45%">금융기관</th>
                        <th width="55%">합계금액(만원)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="color: #c0c0c0">은행, 보험</td>
                        <td class="color--black">
                          {{ baiAmount | commaFormat }} 만원
                        </td>
                      </tr>
                      <tr>
                        <td style="color: #c0c0c0">카드, 저축은행</td>
                        <td class="color--black">
                          {{ casAmount | commaFormat }} 만원
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <p class="mb-5">출처: KCB(코리아크레딧뷰로)</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hr class="my-10" />

      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
          <p class="mt-0 mb-4 font-size--22 font-weight-bold">상점소개</p>
        </v-col>

        <v-col cols="12" sm="12" md="8">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <ul class="store-detail">
                <li>운영기간: {{ operationPeriod }}</li>
                <li>직원수: {{ fundingData.store.employeeNumber }}</li>
                <li>운영시간: {{ fundingData.store.operatingHour }}</li>
              </ul>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <ul class="store-detail">
                <li>매장규모: {{ fundingData.store.area }}</li>
                <li>주소: {{ fundingData.store.address }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import SalesGoogleStackChart from '@/components/legacy/funding/SalesGoogleStackChart'
import RepaymentGoogleBarChart from '@/components/legacy/funding/RepaymentGoogleBarChart'

export default {
  props: {
    fNum: {
      default: null,
    },
    fundingData: {
      type: Object,
      default: null,
    },
  },
  components: {
    SalesGoogleStackChart,
    RepaymentGoogleBarChart,
  },
  data() {
    return {
      loading: false,
      salesData: null,
      repaymentData: null,
      customHTML: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    baiAmount() {
      if (!!this.fundingData) {
        let amount = 0
        this.fundingData.loanBreakdowns.forEach((v) => {
          amount += v.bankAndInsuranceAmount
        })
        return amount
      }

      return 0
    },
    casAmount() {
      if (!!this.fundingData) {
        let amount = 0
        this.fundingData.loanBreakdowns.forEach((v) => {
          amount += v.cardAndSavingBankAmount
        })
        return amount
      }

      return 0
    },
    operationPeriod() {
      if (!!this.fundingData && !!this.fundingData.store.openDate) {
        let registerDate = this.fundingData.registerDate
        let openDate = this.fundingData.store.openDate

        let rd = new Date(registerDate.split('T')[0])
        let od = new Date(openDate)

        let diff = Math.abs(rd.getTime() - od.getTime())
        diff = Math.ceil(diff / (1000 * 60 * 60 * 24))

        let y = parseInt(diff / 365)
        let m = parseInt((diff - y * 365) / 30)

        return `${y}년 ${m}개월`
      }

      return 0
    },
    customDetails() {
      return `https://s3.ap-northeast-2.amazonaws.com/cdn.funda.kr/funding/custom/${this.fNum}/index.html`
    },
  },
  created() {
    if (!!this.fundingData.additionalInfoJson) {
      let addData = JSON.parse(this.fundingData.additionalInfoJson)

      this.salesData = {
        salesData: addData['매출자료'],
      }

      this.processData()
    }
    this.getSerializeHTML()
  },
  methods: {
    async getSerializeHTML() {
      if (parseInt(this.fundingData.investmentType) !== 11) {
        const { data } = await axios.get(this.customDetails)
        const result = data?.replace(/#page#/g, this.fNum)
        this.customHTML = result
      }
    },
    processData() {
      let salesData = this.salesData.salesData
      let repaymentAmount = this.fundingData.monthlyRepayment
      let sumSale = 0
      let sumBcSale = 0
      let operationPeriod = 0

      let d = {
        repaymentPerDay: 0,
        period: 0,
        averageSale: 0,
        bcSalesPerDay: 0,
        repaymentRatio: 0,
        repaymentDay: 0,
      }

      Object.entries(salesData.월별매출).forEach((v) => {
        let k = v[0]
        salesData.지점.forEach((k2) => {
          if (salesData.월별매출[k][k2]['매출'] === '') {
            return
          }

          let sales = parseInt(salesData.월별매출[k][k2]['매출'])

          sumSale += Math.round(sales / 10000)
          sumBcSale += sales
        })
        operationPeriod++
      })

      d.repaymentPerDay = Math.round(repaymentAmount / 25)
      d.period = operationPeriod
      d.averageSale = Math.round(sumSale / operationPeriod)
      d.bcSalesPerDay = Math.round(sumBcSale / operationPeriod / 25)
      d.repaymentRatio = ((d.repaymentPerDay / d.bcSalesPerDay) * 100).toFixed(
        1
      )
      d.repaymentDay = Math.round(repaymentAmount / d.bcSalesPerDay)

      this.repaymentData = d
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;

  &.analysis-point-list {
    li {
      &::before {
        font-style: normal;
        font-weight: bold;
        font-family: 'icon_set_1';
        font-size: 14px;
        padding-right: 10px;
        content: '\6c';
        color: #1baaf1;
        left: 0;
        top: 0;
      }
    }
  }

  &.store-detail {
    li {
      &::before {
        font-style: normal;
        font-weight: bold;
        font-family: 'icon_set_1';
        font-size: 14px;
        padding-right: 10px;
        content: '\2a';
        color: #1baaf1;
        left: 0;
        top: 0;
      }
      margin-bottom: 5px;
    }
  }

  &.bar-chart-ul {
    margin-top: 20px;
    li {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }
}

.funding {
  &__detail {
    &__feat {
      ul {
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 768px) {
          &.feat__icons {
            display: inline-block;

            li {
              display: inline-block !important;
              width: 50%;
              text-align: center;

              p > span {
                line-height: 1.8;
              }
            }
          }
        }

        i {
          display: block;
          margin-bottom: 20px;
          font-size: 36px;
          text-align: center;
        }
      }
    }
  }
}

.feat__img {
  width: 30px;
  height: 36px;
  margin-bottom: 25px;
}

.c-rate {
  &__box {
    background-color: #fff;
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.41);
    color: white;
  }

  &__header {
    background-color: #25aae1;
    color: #fff;
    padding: 10px 0;
    font-weight: bold;
  }

  &__rate {
    display: flex;
    height: 140px;
    align-items: center;
    color: #25aae1;
    font-size: 28px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
  }
}

.l-breakdown {
  background-color: #fff;
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.41);
  color: white;

  &__header {
    background-color: #25aae1;
    color: #fff;
    padding: 10px 0;
    font-weight: bold;
  }

  &__detail {
    height: 140px;

    table {
      border-collapse: collapse;
      thead {
        color: black;

        th {
          font-size: 14px;
          padding: 10px 0;
        }
      }

      tbody {
        td {
          border-top: 1px solid #ddd;
          padding: 13px 0;
          text-align: center;
        }
      }
    }
  }
}

.custom-wrapper {
  width: 100%;

  ::v-deep {
    table {
      width: 100%;

      td {
        padding: 10px;
      }
    }
  }
}
</style>
