export default {
  name: 'seo',
  namespaced: true,
  state: {
    seo: null
  },
  getters: {
    get(state) {
      return state.seo
    }
  },
  mutations: {
    set(state, m) {
      state.seo = m
    }
  },
  actions: {
    set({ commit }, m) {
      commit('set', m)
    },
  }
}
