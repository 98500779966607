<template>
  <div>
    <a v-if="false" class="mb-2" target="_blank" :href="googleDocs">
      새창으로 보기(google docs)
    </a>

    <PdfViewer v-if="pdfUrl" :url="pdfUrl" :width="'100%'" />
  </div>
</template>

<script>
import PdfViewer from '@/components/PdfViewer'

export default {
  props: {
    details: {
      type: Object,
      default: null,
    },
  },
  components: {
    PdfViewer,
  },
  data() {
    return {
      realUrl: null,
    }
  },
  computed: {
    pdfUrl() {
      // const url = encodeURIComponent(this.details?.pdfUrl)
      const url = this.details?.pdfUrl
      return `${url}`
    },
    googleDocs() {
      const docs = 'http://docs.google.com/gview?embedded=true&url='
      return `${docs}${this.pdfUrl}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
