<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table>
            <thead>
              <tr>
                <th class="text-center" rowspan="2">구분</th>
                <th class="text-center" colspan="4">예치기관</th>
              </tr>
              <tr>
                <th class="text-center">은행</th>
                <th class="text-center">저축은행</th>
                <th class="text-center">증권금융</th>
                <th class="text-center">기타</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">연계투자예치금</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">㈜웰컴페이먼츠</td>
              </tr>

              <tr>
                <td class="text-center">연계투자신탁금</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
                <td class="text-center">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '투자금 등의 예치기관에 관한 사항',
    },
  },
}
</script>

<style lang="scss" scoped></style>
