<template>
  <v-card
    v-if="isAuth && isValidUser && !!balance"
    class="invest-popup font-nanumsquare"
  >
    <v-card-title class="invest-popup__title">
      <template v-if="beforeInvest">
        <h2 class="font-size--20 font-weight-black my-0">
          투자 신청 확인
        </h2>
        <a @click="closeDialog()">
          <v-icon large>
            mdi-close
          </v-icon>
        </a>
      </template>
    </v-card-title>
    <v-card-text class="invest-popup__contents">
      <template v-if="beforeInvest">
        <div class="invest-popup__terms">
          <div class="invest-popup__summary">
            <p class="mb-2 font-size--15 font-weight-bold color--black">
              투자요약
            </p>
            <p class="mb-2 color--black font-size--14">
              선택한 상품: {{ checkedFundings.length }} 개
            </p>
            <p class="mb-2 color--black font-size--14 font-weight-bold">
              총 투자금액: {{ checkedAmount | commaFormat }} 원
            </p>
            <p class="ma-0 pl-1 color--black font-size--14">
              - 예치금:

              <LoadingText :is-loading="estimatedPoint < 0">
                {{
                  Math.min(
                    balance.withdraw,
                    this.checkedAmount - this.estimatedPoint
                  ) | commaFormat
                }}
                원
              </LoadingText>
            </p>
            <p
              v-if="estimatedPoint > 0"
              class="mt-1 mb-0 pl-1 color--warning font-size--14"
            >
              - 포인트:

              {{ estimatedPoint | commaFormat }} P
              <Tooltip
                size="16px"
                alignment="bottom"
                text="총 투자금액의 최대 50%까지 자동 적용됩니다"
              />
            </p>
          </div>

          <div
            v-if="checkedFundings.length > 0"
            class="invest-ing invest-ing__agree"
          >
            <v-row
              v-for="checked in checkedFundings"
              :key="checked.fundingDataIdx"
              class="pt-2 pb-1"
              no-gutters
            >
              <v-col cols="6">
                <p class="ma-0 font-size--14 text-ellipsis">
                  <a>{{ checked.title }}</a>
                  <v-icon
                    v-show="checked.alreadyInvested === true"
                    class="icon__already"
                    small
                  >
                    mdi-flag-variant
                  </v-icon>
                </p>
              </v-col>

              <v-col cols="6">
                <p class="ma-0 color--black font-size--14 text-right">
                  {{ checked.investAmount | commaFormat }} 원
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
        <h2
          class="mt-4 margin-b-5 pl-5 color--black font-size--18 font-weight-black"
        >
          약관 동의
        </h2>
        <div class="invest-popup__agreement">
          <div>
            <div class="agreement px-0">
              <button class="agreement__check-btn" @click="agreeAll()">
                <p
                  class="icon__check"
                  :class="{ active: isAgreeAll === true }"
                ></p>
                <span class="font-size--15"> 모두 동의합니다 </span>
              </button>
            </div>

            <div
              v-for="req in agreement.required"
              :key="req.name"
              class="agreement"
            >
              <button
                class="agreement__check-btn"
                @click="req.isAgree = !req.isAgree"
              >
                <p
                  class="icon__check sub"
                  :class="{ active: req.isAgree === true }"
                ></p>
                <span> [필수] {{ req.title }}동의 </span>
              </button>
              <router-link
                v-if="!!req.mode"
                class="icon__arrow--gray"
                :to="{ name: 'terms', query: { mode: req.mode } }"
                target="_blank"
              />
            </div>

            <div v-for="opt in agreement.optional" :key="opt.name">
              <div class="agreement">
                <button
                  class="agreement__check-btn"
                  @click="opt.isAgree = !opt.isAgree"
                >
                  <p
                    class="icon__check sub"
                    :class="{ active: opt.isAgree === true }"
                  ></p>
                  <span> [선택] {{ opt.title }}동의 </span>
                </button>
                <router-link
                  v-if="!!opt.mode"
                  class="icon__arrow--gray"
                  :to="{ name: 'terms', query: { mode: opt.mode } }"
                  target="_blank"
                />
              </div>
              <div
                class="agreement__terms pl-5"
                v-if="opt.name === 'sameStoreAutoAgree'"
              >
                <SameStoreAutoAgreement color="#f4faff" show-more-btn />
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="showSameStoreAutoAgreeAlert" max-width="700px">
          <SameStoreAutoAgreement />
          <v-spacer></v-spacer>
          <div class="py-3 text-center" style="background: white">
            <v-btn
              class="btn__same-store-auto mr-2"
              @click="showSameStoreAutoAgreeAlert = false"
            >
              미동의
            </v-btn>
            <v-btn
              class="btn__same-store-auto ml-2 agree"
              @click="checkSameStore()"
            >
              동의하기
            </v-btn>
          </div>
        </v-dialog>
        <div class="invest-popup__agree">
          <div class="margin-r-10 font-size--14">
            나
            <b class="color--black font-weight-black">{{ profile.name }}</b
            >은 상기 내용을 확인하였으며, 그 내용에
          </div>
          <div class="position--relative">
            <input
              type="text"
              class="
                  width-100
                  rounded--4
                  text-center
                  font-size--16
                  color--gray-3
                  font-weight-black
                  outline-color--funda-blue
                "
              placeholder="동의함"
              v-model="iAgree"
              @input="inputIAgree"
              @value="iAgree"
            />
          </div>
        </div>
        <p
          class="margin-t-5 mb-5 font-size--13 text-center color--gray"
          style="line-height: 1.5"
        >
          &#42; 빈칸에 &apos;동의함&apos;을 직접 입력해 주세요.
        </p>
      </template>

      <template v-else>
        <div class="text-center mb-4">
          <div
            v-if="investLoading === false"
            class="swal2-icon swal2-success swal2-icon-show"
            style="display: flex; margin-top: 30px"
          >
            <div
              class="swal2-success-circular-line-left"
              style="background-color: rgb(255, 255, 255)"
            ></div>
            <span class="swal2-success-line-tip"></span>
            <span class="swal2-success-line-long"></span>
            <div class="swal2-success-ring"></div>
            <div
              class="swal2-success-fix"
              style="background-color: rgb(255, 255, 255)"
            ></div>
            <div
              class="swal2-success-circular-line-right"
              style="background-color: rgb(255, 255, 255)"
            ></div>
          </div>
          <div v-else class="invest-ing__header-loading">
            <div class="dot-pulse my-5"></div>
          </div>

          <h2 class="font-size--20 color--black font-weight-black margin-b-10">
            {{ investingText }}
          </h2>

          <v-row class="invest-popup__result-summary" no-gutters>
            <v-col :cols="investResult.fail > 0 ? 6 : 12">
              <div
                class="cnt__success pr-1"
                :class="
                  investResult.fail > 0 ? 'justify-end' : 'justify-center'
                "
              >
                <div
                  class="
                      swal2-icon swal2-success swal2-icon-show
                      icon--smaill
                    "
                  style="display: flex"
                >
                  <span class="swal2-success-line-tip"></span>
                  <span class="swal2-success-line-long"></span>
                </div>
                <span class="cnt__text">
                  성공 {{ investResult.success }} 개
                </span>
              </div>
            </v-col>
            <v-col v-if="investResult.fail > 0" cols="6">
              <div class="cnt__fail pl-1">
                <div
                  class="
                      swal2-icon swal2-warning swal2-icon-show
                      icon--smaill
                    "
                  style="display: flex"
                >
                  <div class="swal2-icon-content">!</div>
                </div>
                <span class="cnt__text"> 실패 {{ investResult.fail }} 개 </span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="invest-popup__terms">
          <div class="invest-ing">
            <v-row
              v-for="inv in failList"
              :key="inv.fundingDataIdx"
              class="pt-1 pb-1"
              no-gutters
            >
              <v-col cols="7">
                <div class="invest-ing__title">
                  <div
                    class="
                        swal2-icon swal2-warning swal2-icon-show
                        icon--smaill
                      "
                    style="display: flex"
                  >
                    <div class="swal2-icon-content">!</div>
                  </div>
                  <p class="text-ellipsis">
                    {{ inv.title }}
                  </p>
                </div>
              </v-col>

              <v-col cols="5" class="d-flex align-center justify-end">
                <p class="ma-0 font-size--15 font-weight-bold">
                  {{ inv.investAmount | commaFormat }} 원
                </p>
              </v-col>

              <v-col v-if="!!inv.error" cols="12" class="invest-ing__error">
                <p class="mt-1 mb-0 color--pink">
                  {{ inv.error }}
                </p>
              </v-col>
            </v-row>

            <v-row
              v-for="inv in successList"
              :key="inv.fundingDataIdx"
              class="pt-1 pb-1"
              no-gutters
            >
              <v-col cols="7">
                <div class="invest-ing__title">
                  <template v-if="inv.investmentState === 'ing'">
                    <div style="max-width: 20px">
                      <v-progress-circular :size="20" :width="2" indeterminate>
                      </v-progress-circular>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="
                          swal2-icon swal2-success swal2-icon-show
                          icon--smaill
                        "
                      style="display: flex"
                    >
                      <span class="swal2-success-line-tip"></span>
                      <span class="swal2-success-line-long"></span>
                    </div>
                  </template>
                  <p class="text-ellipsis">
                    {{ inv.title }}
                  </p>
                </div>
              </v-col>

              <v-col cols="5" class="d-flex align-center justify-end">
                <p class="ma-0 font-size--15 font-weight-bold">
                  {{ inv.investAmount | commaFormat }} 원
                </p>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-row class="result-tip mt-4" no-gutters>
          <v-col cols="12" class="text-center">
            <p class="mb-1 color-funda--basic font-size--15">펀다 TIP</p>
            <p class="mb-3 font-size--14" style="line-height: 1.3">
              예치금을 남김없이 투자할 때 <br />
              복리효과를 통한 수익률 상승을 기대할 수 있어요
            </p>
            <p v-if="investLoading === false" class="mb-5 font-size--14">
              고객님의 잔여 예치금:
              <template v-if="refreshBalanceLoading === false">
                <BalanceText />원
              </template>
              <template v-else>
                <v-progress-circular
                  class="ml-3"
                  :size="16"
                  :width="2"
                  indeterminate
                >
                </v-progress-circular>
              </template>
            </p>
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <v-card-actions class="popup-submit-footer">
      <button
        v-if="beforeInvest"
        type="button"
        class="outline--none btn-funda--blue"
        :disabled="cannotSubmit"
        @click="doInvest()"
      >
        투자하기
      </button>

      <button
        v-else
        type="button"
        class="btn-funda--blue btn__invest-complete"
        :disabled="investLoading"
        @click="completeInvest()"
      >
        {{ investLoading ? '투자 진행중입니다' : '확인' }}
      </button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { OverlayTypes } from '@/store/types/overlay'
import { TimeTypes } from '@/store/types/time'
import LoadingText from '@/components/common/text/LoadingText.vue'
import BalanceText from '@/components/common/text/BalanceText.vue'
import Tooltip from '@/components/common/Tooltip.vue'
import SameStoreAutoAgreement from '@/components/common/SameStoreAutoAgreement.vue'

export default {
  props: {
    checkedFundings: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    LoadingText,
    BalanceText,
    Tooltip,
    SameStoreAutoAgreement,
  },
  data() {
    return {
      investLoading: false,
      showSameStoreAutoAgreeAlert: false,
      iAgree: '',
      investList: [],
      investingText: '투자가 진행중입니다',
      investResult: {
        success: 0,
        successAmt: 0,
        fail: 0,
        failAmt: 0,
      },
      agreement: {
        required: [
          {
            title: '투자이용약관',
            name: 'investAgree',
            isAgree: true,
            mode: 'online_investment',
          },
          {
            title: '서비스이용약관',
            name: 'serviceAgree',
            isAgree: true,
            mode: 'service',
          },
          {
            title: '개인정보취급방침',
            name: 'infoAgree',
            isAgree: true,
            mode: 'info',
          },
        ],
        optional: [
          {
            title: '동조건 재투자',
            name: 'sameStoreAutoAgree',
            isAgree: true,
          },
        ],
      },
      showAccount: false,
      refreshBalanceLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      balance: AuthTypes.getters.GET_BALANCE,
      balanceLoading: AuthTypes.getters.GET_BALANCE_LOADING,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    ...mapGetters('overlay', {
      overlayKey: OverlayTypes.getters.GET_OVERLAY,
    }),
    checkedAmount() {
      let amt = 0
      this.checkedFundings.forEach((f) => {
        amt += parseInt(f.investAmount, 10)
      })
      return amt
    },
    checkedSameStore() {
      let result = false

      this.agreement.optional.forEach((agree) => {
        if (agree.name === 'sameStoreAutoAgree') {
          result = agree.isAgree
        }
      })

      return result
    },
    isAgreeRequired() {
      const required = this.agreement.required.every((a) => {
        return a.isAgree === true
      })
      return required
    },
    isAgreeAll() {
      const optional = this.agreement.optional.every((a) => {
        return a.isAgree === true
      })
      return this.isAgreeRequired && optional
    },
    invalidAmount() {
      return this.notEnoughDeposit !== 0
    },
    isValidUser() {
      return this.userValidation?.isValid === true
    },
    estimatedPoint() {
      let p = 0
      if (this.balanceLoading === false && !!this.balance) {
        const myPoint = this.balance.point
        p = this.checkedAmount * 0.5
        p = Math.min(myPoint, p)
      } else {
        p = -1
      }
      return p
    },
    notEnoughDeposit() {
      if (this.estimatedPoint >= 0) {
        const d =
          this.checkedAmount - this.estimatedPoint - this.balance?.withdraw
        return Math.max(d, 0)
      }
      return -1
    },
    cannotSubmit() {
      return (
        this.isAuth === false ||
        this.invalidAmount ||
        this.investLoading ||
        this.isAgreeRequired === false ||
        this.iAgree !== '동의함'
      )
    },
    beforeInvest() {
      return this.investList.length < 1
    },
    failList() {
      return this.investList.filter((f) => {
        return f.investmentState !== 'ing' && f.investmentState !== '투자완료'
      })
    },
    successList() {
      return this.investList.filter((f) => {
        return f.investmentState === 'ing' || f.investmentState === '투자완료'
      })
    },
  },
  watch: {
    async investLoading(v) {
      if (v === true) {
        this.investingText = '투자가 진행중입니다'
      } else {
        this.investingText = '투자가 완료되었습니다'
        await this.refreshBalance()
        this.getProfile()
      }
    },
    checkedSameStore(v) {
      if (v === false) {
        this.showSameStoreAutoAgreeAlert = true
      } else {
        this.showSameStoreAutoAgreeAlert = false
      }
    },
  },
  methods: {
    ...mapActions('auth', {
      getBalance: AuthTypes.actions.GET_BALANCE,
      getProfile: AuthTypes.actions.GET_PROFILE,
    }),
    ...mapActions('overlay', {
      closeDialog: OverlayTypes.actions.CLOSE_OVERLAY,
    }),
    ...mapActions('time', {
      getIsInspectTime: TimeTypes.actions.GET_CANNOT_INVEST_TIME,
    }),
    refreshBalance() {
      this.refreshBalanceLoading = true
      setTimeout(() => {
        this.getBalance()
        this.refreshBalanceLoading = false
      }, 3000)
    },
    checkSameStore() {
      this.agreement.optional.forEach((agree) => {
        if (agree.name === 'sameStoreAutoAgree') {
          agree.isAgree = true
        }
      })
    },
    inputIAgree(e) {
      this.iAgree = e.target.value
    },
    agreeAll() {
      const v = !this.isAgreeAll

      this.agreement.required.forEach((r) => {
        r.isAgree = v
      })

      this.agreement.optional.forEach((o) => {
        o.isAgree = v
      })
    },
    calcPoint() {
      let useTotalP = this.estimatedPoint
      this.investList.sort((a, b) => a.investAmount - b.investAmount)

      for (let i = 0; i < this.investList.length; i++) {
        if (useTotalP < 1) {
          break
        }
        const f = this.investList[i]
        let maxP = f.investAmount / 2
        let useP = Math.min(maxP, useTotalP)
        f.point = useP
        useTotalP -= useP
      }
    },
    async invest(funding) {
      const q = `
          investResult: addInvest(inputAddInvestment: {
            userIdx: ${this.userIdx}
            fundingDataIdx: ${funding.fundingDataIdx}
            amount: ${funding.investAmount}
            point: ${funding.point}
            isAutoInvest: ${this.checkedSameStore}
            preferenceAddress: "${funding?.disclosure?.store?.address ? funding?.disclosure?.store?.address : ''}"
          }) {
            userIdx
            fundingDataIdx
            investmentAmount
            pointAmount
            investmentState
            datetime
          }
        `
      const data = await this.$fundaApi.mutation(gql`{${q}}`)

      return data
    },
    async callInvest() {
      if (await this.getIsInspectTime()) {
        this.$swal.basic.warning('투자 가능한 시간이 아닙니다.')
        return
      }

      if (this.cannotSubmit) {
        this.$swal.basic.warning('에러가 발생했습니다.')
        return
      }

      this.investLoading = true
      this.iAgree = ''
      this.investingText = '투자가 진행중입니다'

      this.checkedFundings.forEach((f) => {
        let t = Object.assign({}, f)
        t.point = 0
        t.investmentState = 'ing'
        this.investList.push(t)
      })
      this.calcPoint()
      let complete = 0
      this.investResult.success = 0
      this.investResult.successAmt = 0
      this.investResult.fail = 0
      this.investResult.failAmt = 0

      for (let i = 0; i < this.investList.length; i++) {
        const f = this.investList[i]
        const invstAmt = parseInt(f.investAmount, 10)

        try {
          const resp = await this.invest(f)

          if (!!resp.investResult) {
            const result = resp.investResult

            f.investmentState = result.investmentState

            if (result.investmentState === '투자완료') {
              this.investResult.success++
              this.investResult.successAmt += invstAmt
            } else {
              throw new Error({
                message: '투자실패',
              })
            }
          } else {
            throw new Error(resp.error)
          }
        } catch (e) {
          console.error(e)
          f.investmentState = '투자실패'
          f.error = !!e.message
            ? e.message
            : '해당 에러가 발생할 경우 고객센터로 문의부탁드립니다.'

          this.investResult.fail++
          this.investResult.failAmt += invstAmt
        } finally {
          complete++

          if (this.checkedFundings.length === complete) {
            this.investLoading = false
          }
        }
      }

      // for (let i = 0; i < this.investList.length; i++) {
      //   let f = this.investList[i]
      //   let invstAmt = parseInt(f.investAmount, 10)

      //   try {
      //     let resp = await this.invest(f)

      //     if (!!resp.investResult) {
      //       let result = resp.investResult

      //       f.investmentState = result.investmentState

      //       if (result.investmentState === '투자완료') {
      //         this.investResult.success++
      //         this.investResult.successAmt += invstAmt
      //       } else {
      //         throw new Error({
      //           message: '투자실패',
      //         })
      //       }
      //     } else {
      //       throw new Error(resp.error)
      //     }
      //   } catch (e) {
      //     console.error(e)
      //     f.investmentState = '투자실패'
      //     f.error = !!e.message ? e.message : '해당 에러가 발생할 경우 고객센터로 문의부탁드립니다.'

      //     this.investResult.fail++
      //     this.investResult.failAmt += invstAmt
      //   } finally {
      //     complete++

      //     if (this.checkedFundings.length === complete) {
      //       this.investLoading = false
      //     }
      //   }
      // }
    },
    async doInvest() {
      const yes = await this.$swal.basic.confirm({
        text: '투자를 진행하시겠습니까?',
        customClass: {
          container: 'custom-alert__container',
          popup: 'custom-alert__popup',
          content: 'custom-alert__content',
          confirmButton: 'custom-alert__confirm-btn btn__invest-ok',
          cancelButton: 'custom-alert__cancel-btn',
        },
      })
      if (yes.isConfirmed === false) {
        return
      }

      this.callInvest()
    },
    async completeInvest() {
      this.checkSameStore()
      this.investList = []
      this.closeDialog()
      this.$eventBus.$emit('complete-invest')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__my-account {
  background: rgb(0, 0, 0, 0.8);
}

.agreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.agreement__check-btn {
  display: flex;
  align-items: center;
}

.agreement__check-btn .icon__check.sub {
  width: 22px;
  height: 22px;
}

.agreement__check-btn span {
  padding-left: 8px;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
}

.btn__same-store-auto {
  max-width: 110px;
  width: 110px;
  box-shadow: unset;
  padding: 25px 0px !important;
  font-size: 15px;
  font-weight: bold;

  &.agree {
    color: white;
    background-color: #1baaf1 !important;
  }
}

.invest-popup {
  max-width: 768px;
  height: 100%;
  overflow: hidden;
}

.invest-popup .invest-popup__title {
  padding: 12px 20px !important;
  align-items: center;
  justify-content: space-between;
}

.invest-popup .invest-popup__contents {
  max-width: 768px !important;
  width: 100%;
  max-height: 100% !important;
  border-radius: 0;
  overflow-y: auto !important;
  padding: 0;

  @media screen and (max-height: 736px) {
    height: 100%;
    padding-bottom: 120px;
  }
}

.invest-popup .invest-popup__terms {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 15px;
}

.invest-popup .invest-popup__terms .invest-popup__summary,
.invest-popup .invest-popup__terms .invest-ing {
  padding: 15px;
  background-color: #f4faff;
  font-family: AppleSDGothicNeo, sans-serif !important;
  word-break: break-all;
  line-height: 22px;
  letter-spacing: -0.2px;
}

.invest-popup .invest-popup__terms .invest-popup__summary {
  padding-bottom: 0;

  > p:last-child {
    padding-bottom: 10px;
    border-bottom: 1px solid #d7e1ea;
  }
}

.detail-arrow {
  left: unset;
  margin-top: 3px;
}

.invest-popup__result-summary {
  .cnt__success,
  .cnt__fail {
    display: flex;
    align-items: center;
    color: black;
  }

  .cnt__text {
    margin-left: 4px;
    color: black;
    font-size: 14px;
  }
}

.invest-popup .invest-popup__terms > div.invest-ing {
  max-height: 200px;
  overflow-y: auto;

  &.invest-ing__agree {
    max-height: 150px;
    padding-top: 2px;
    padding-bottom: 7px;
  }
}

.invest-ing__header-loading {
  display: flex;
  height: 130px;
  align-items: center;
  justify-content: center;
}

.invest-ing__title {
  display: flex;
  align-items: center;

  > p {
    margin-left: 4px;
    margin-bottom: 0px;
    padding-top: 3px;
    font-size: 15px;
  }
}

.invest-popup .invest-popup__agreement {
  padding: 0 20px;
  color: black;
  font-weight: 900;
}

.invest-popup .invest-popup__agreement > div {
  border: 1px solid #eee;
  padding: 5px 10px;
}

.invest-popup .invest-popup__agree {
  display: flex;
  margin-top: 15px;
  align-items: center;
  font-weight: bold;
  padding: 0 20px;
}

.invest-popup .invest-popup__agree > div {
  word-break: break-all;
}

.invest-popup .invest-popup__agree input {
  height: 46px;
  border: 1px solid #d7e1ea;
}

.invest-popup .invest-popup__agree input::placeholder {
  color: #8a94a2;
  font-size: 15px;
  letter-spacing: -0.3px;
  text-align: center;
}

.invest-popup .popup-submit-footer {
  position: fixed;
  bottom: 0;
  max-width: 768px !important;
  width: 100%;
  padding: 0;
}

.invest-popup .popup-submit-footer button {
  width: 100%;
  border: none;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 900;
  outline: none;
  line-height: 1.2;
  transition: all 0.3s;
}

@media screen and (max-width: 359px) {
  .invest-popup .invest-popup__terms > div {
    line-height: 18px;
    font-size: 12px !important;
  }
  .invest-popup .invest-popup__agree {
    font-size: 12px;
  }
}

.swal2-icon.icon--smaill {
  min-width: 17px;
  width: 17px;
  height: 17px;
  margin: 0 !important;
  border-width: 2px;

  &.swal2-success {
    .swal2-success-line-tip {
      width: 5px !important;
      height: 2px !important;
      left: 3px !important;
      top: 9px !important;
      transform: rotate(38deg);
    }

    .swal2-success-line-long {
      top: 8px !important;
      left: 5px !important;
      height: 2px !important;
      width: 10px !important;
    }
  }

  &.swal2-warning {
    border-color: #f9356a !important;
    color: #f9356a !important;

    .swal2-icon-content {
      font-size: 14px !important;
    }
  }
}

.result-tip {
  p {
    color: black;
  }
}
</style>
