import { CodeTypes } from './types/code'

export default {
  name: 'code',
  namespaced: true,
  state: {
    loading: false,
    banks: null
  },
  getters: {
    [CodeTypes.getters.GET_BANKS](state) {
      return state.banks
    }
  },
  mutations: {
    [CodeTypes.mutations.SET_LOADING](state, v) {
      state.loading = v
    },
    [CodeTypes.mutations.SET_BANKS](state, banks) {
      state.banks = banks
    }
  },
  actions: {
    async [CodeTypes.actions.GET_BANKS]({ commit, state }) {
      if (state.loading) {
        return null
      }

      if (!!state.banks && state.banks.length > 0) {
        return state.banks
      }

      commit(CodeTypes.mutations.SET_LOADING, true)

      let data = await global.$fundaApi.query(gql`
        {
          data: getPaymentUserWithdrawAccountAllowBanks {
            code
            name
          }
        }
      `)

      commit(CodeTypes.mutations.SET_BANKS, data)
      commit(CodeTypes.mutations.SET_LOADING, false)

      return state.banks
    }
  }
}
