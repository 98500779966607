<template>
  <div id="dashboard" class="container__inner">
    <v-row class="short-text" no-gutters>
      <div class="short-text__background"></div>
      <v-col cols="12" sm="12" md="12" class="short-text__contents">
        <p v-if="!!myUser" class="mx-0 mb-2">
          <span class="font-weight-bold">{{ myUser.name }}</span>
          ({{ myUser.investmentLimit.type }})님,
        </p>
        <p class="my-0">
          <span class="font-weight-bold">펀다</span>에 오신 것을 환영합니다!
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="dashboard-left-wrapper px-2"
      >
        <DashboardLeft :my-dashboard-data="myDashboardData"></DashboardLeft>
      </v-col>

      <v-col cols="12" sm="12" md="8" class="dashboard-main-wrapper px-2">
        <v-row v-if="!isMobile" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div
              id="dashboard-top"
              class="dashboard-box"
              :class="{ 'pa-5 mb-2': isMobile }"
            >
              <div
                class="dashboard-menu d-flex justify-space-between width-100 ma-0 pa-0"
              >
                <router-link
                  v-for="menu in menuList"
                  :key="menu.value"
                  :to="{ name: menu.value }"
                >
                  {{ menu.text }}
                </router-link>
              </div>
              <div
                v-if="false"
                class="mobile d-flex align-center position--relative"
              >
                <select
                  v-model="activeMenu"
                  class="width-100 color--gray border--none outline--none font-weight-bold"
                  @change="changeMenu($event)"
                >
                  <option value="/" disabled selected hidden>
                    메뉴를 선택해주세요
                  </option>
                  <option
                    v-for="menu in menuList"
                    :key="menu.value"
                    :value="menu.value"
                    :selected="selectedMenu(menu.value)"
                  >
                    {{ menu.text }}
                  </option>
                </select>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-else no-gutters>
          <v-col
            v-for="(menu, index) in menuList"
            :key="menu.value"
            cols="6"
            :class="index % 2 === 0 ? 'pr-2' : 'pl-2'"
          >
            <v-btn
              class="dashboard-box width-100 font-size--14 margin-t-5 margin-b-5"
              style="color: #8a94a2; padding: 30px 25px"
              :class="{ 'menu--active': menu.value === activeMenu }"
              :to="{ name: menu.value }"
            >
              {{ menu.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row ref="myfundaContent" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12" class="position--relative">
            <router-view
              :key="$route.name"
              :my-dashboard-data="myDashboardData"
            >
            </router-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import DashboardLeft from '@/components/myfunda/DashboardLeft'

export default {
  components: {
    DashboardLeft,
  },
  data() {
    return {
      activeMenu: this.$route.name,
      myPageMenuList: [
        {
          value: 'myfunda-home',
          text: '마이홈',
        },
        {
          value: 'myfunda-invest-board',
          text: '투자현황',
        },
        {
          value: 'myfunda-invest-list',
          text: '투자내역',
        },
        {
          value: 'myfunda-schedule',
          text: '상환내역',
        },
        {
          value: 'myfunda-balance-deposit',
          text: '예치금 입출금',
        },
      ],
      myDashboardData: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      myProfile: AuthTypes.getters.GET_PROFILE,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    myUser() {
      if (!!this.myProfile) {
        this.fetchData()
      }

      return this.myProfile
    },
    menuList() {
      let menuList = this.myPageMenuList

      if (this.isMobile === true) {
        menuList = this.myPageMenuList.filter((menu) => {
          return menu.value !== 'myfunda-home'
        })
      }

      return menuList
    },
    selectedMenu() {
      return (name) => {
        let include = name.split('myfunda-')[1]
        let isSelected = this.$route.name.indexOf(include) > -1
        if (isSelected) {
          this.activeMenu = name
        }
        return isSelected
      }
    },
  },
  created() {
    _seo({
      titles: ['마이펀다'],
    })
  },
  methods: {
    changeMenu(event) {
      let val = event.target.value
      this.activeMenu = val
      this.$router.push({ name: val })
    },
    async fetchData() {
      const q1 = `
        data: wwwDashboardData {
          sumAllPoint
          sumExpiredPoint
          sumPointBalance
        }
      `

      let data = await this.$fundaApi.query(gql`{${q1}}`)

      data.user = {
        ...this.myUser,
      }

      this.myDashboardData = data
    },
  },
}
</script>

<style lang="scss">
.v-application {
  background-color: #f6f8fa !important;
}

.short-text {
  color: #333333;
  font-size: 18px;

  @media screen and (max-width: 767px) {
    color: white;
    font-size: 20px;
  }

  &__contents {
    z-index: 5;
  }

  &__background {
    display: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    top: 0%;
    left: 0%;
    width: 100%;
    height: 210px;
    background-color: #1baaf1;
    z-index: 0;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.left {
  &-key {
    margin: 0 !important;
    color: #8a94a2;
    font-size: 15px;
    line-height: 1.4;
  }

  &-subtitle {
    font-size: 16px;
  }
}
.right-value {
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
}

.my-limit {
  border-radius: 4px;
  background-color: #f6f8fa;
  .left-key {
    padding: 0;
    font-size: 14px;
    color: #727d8c;
  }

  .right-value {
    font-size: 15px;
  }
}

/* 대시보드 박스 기본 스타일 */
.dashboard-box {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 25px;
  background-color: #ffffff !important;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);

  @media screen and (max-width: 767px) {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }

  &--ma-0 {
    padding: 25px;
    background-color: #ffffff !important;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);

    @media screen and (max-width: 767px) {
      margin-top: 10px !important;
      margin-bottom: 5px !important;
    }
  }

  &--pa-0 {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #ffffff !important;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  }

  &.menu--active {
    background-color: #1baaf1 !important;
    color: white !important;
    font-weight: bold;
  }
}
/* 대시보드 박스 기본 스타일 */

#dashboard {
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (max-width: 767px) {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    .mobile-hide {
      display: none !important;
    }
  }

  .mobile-block {
    display: none;

    @media screen and (max-width: 767px) {
      display: block !important;
    }
  }

  .dashboard-left-wrapper {
    z-index: 5;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      float: unset;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 768px) {
      max-width: 330px;
      width: 100%;
    }
  }

  .dashboard-main-wrapper {
    @media (min-width: 1264px) {
      max-width: calc(100% - 330px);
      width: 100%;
      flex: 0 0 100%;
    }
  }
}

#dashboard-top {
  select {
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
  }

  > div.mobile::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 10px;
    background: no-repeat center;
    background-image: url('~@/assets/images/common/icons/btn-btn-arrow-my-down-gray.svg');
    background-color: white;
    right: 0;
  }

  .dashboard-menu {
    flex-wrap: wrap;
    list-style: none;

    > a {
      color: #8a94a2;
      font-size: 15px;

      &:hover,
      &.active,
      &.router-link-active,
      &.router-link-exact-active {
        color: #333333;
        font-weight: bold;
      }
    }
  }
}

.dashboard-ul {
  flex-wrap: wrap;
  list-style: none;

  > li {
    color: #8a94a2;
    cursor: pointer;
    font-size: 15px;
    text-align: center;

    @media screen and (max-width: 767px) {
      flex-basis: 33.33333333%;
    }

    a {
      &:link,
      &:visited,
      :active {
        color: #8a94a2;
      }
    }

    > span {
      font-size: 18px;
    }
  }
}

.fade5-enter-active,
.fade5-leave-active {
  transition: opacity 0.5s;
}

.fade1-enter-active,
.fade1-leave-active {
  transition: opacity 0.1s;
}

.fade1-enter,
.fade1-leave-to,
.fade5-enter,
.fade5-leave-to {
  opacity: 0;
}

.list-card {
  &__title {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.my-funda-input {
  height: 46px;
  border-radius: 4px;
  border: solid 1px #d7e1ea;
  background-color: #ffffff;
  width: 100%;
  outline: none;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 900;
  padding-top: 2px;
  box-shadow: none;

  &:disabled {
    background-color: #f6faff;
    cursor: not-allowed;
  }

  &:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &-addon {
    position: relative;
    display: flex;
    align-items: center;

    span {
      position: absolute;
      right: 10px;
      letter-spacing: -0.2px;
      font-weight: 700;
    }
  }
}

.sub-menu {
  button {
    outline: none;
    width: 100%;
    max-width: 180px;
    font-size: 16px;
    font-weight: 900;
    border-radius: 22.5px;
    border: none;
    padding: 10px;

    @media screen and (min-width: 960px) and (max-width: 1264px) {
      max-width: 160px;
    }

    &:not(:last-child) {
      margin-right: 20px;

      @media screen and (min-width: 992px) and (max-width: 1200px) {
        margin-right: 15px;
      }
    }

    &:not(.router-link-exact-active) {
      background-color: #ffffff;
      color: #8a94a2;
    }

    &.router-link-exact-active {
      background-color: #f2f9ff;
      color: #1baaf1;
    }
  }

  &.mobile {
    button {
      font-size: 16px;
      max-width: 130px;
      width: 33%;
      margin-right: 0 !important;
      padding: 10px 0;

      @media screen and (max-width: 359px) {
        font-size: 14px;
      }
    }
  }
}
</style>
