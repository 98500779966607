<template>
  <v-row v-if="access" class="signup" no-gutters>
    <v-col cols="12">
      <div class="signup-board my-5">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="header color-black my-0">
              회원등록
            </p>
          </v-col>
        </v-row>
        <v-card elevation="0" class="py-0" min-height="700px">
          <v-card-text v-if="isAuth === false" class="px-0">
            <UserRegister
              v-if="channel"
              is-channel
              :recommenderCode="recommender"
              :promotionCode="promotion"
              @invalid-affiliate="invalidPromotion"
              @clear-optional="clearOptional"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import UserRegister from '@/components/signup/UserRegister.vue'

export default {
  components: {
    UserRegister,
  },
  data() {
    return {
      channels: {
        OK시럽: {
          name: 'syrup',
          title: 'Syrup',
          component: 'Syrup',
        },
      },
      access: null,
      recommender: null,
      isInvalid: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    channel() {
      if (this.access) {
        return this.channels[this.access]
      }
      return null
    },
  },
  watch: {
    userValidation: {
      immediate: true,
      handler(v) {
        if (v?.isValid === true) {
          this.$router.push({ name: 'invest' })
        } else if (v?.isValid === false) {
          this.$router.push({ name: 'signupSteps' })
        }
      },
    },
    access: {
      immediate: true,
      handler(v) {
        if (v) {
          this.validatePage()
        }
      },
    },
    isInvalid(v) {
      if (v === true) {
        this.invalidPromotion()
      }
    },
  },
  async created() {
    const recommender = this.$cookies.get('recommender') ?? null
    const promotion = this.$cookies.get('promotion') ?? null
    this.recommender = recommender
    this.promotion = promotion
    this.access = promotion

    if (this.access === null) {
      this.isInvalid = true
    }
  },
  methods: {
    async invalidPromotion() {
      await this.$swal.basic.warning(
        '프로모션을 찾을 수 없습니다. 다시 접속해 주세요.'
      )
      this.$router.push({ name: 'main' })
    },
    validatePage() {
      if (!this.channel) {
        this.isInvalid = true
      }
    },
    clearOptional() {
      this.$swal.basic.warning(
        '[마케팅 정보 선택적 수집 및 이용 약관]에 동의하지 않을 경우, 추후 프로모션 보상이 정상적으로 지급되지 않습니다'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.signup {
  min-height: unset;
}
</style>
