export default [
  {
    name: 'callback/sns/naver',
    path: '/callback/sns/naver',
    component: require('@/pages/callback/sns/Naver.vue').default,
  },
  {
    name: 'authPhonePayment',
    path: '/aa',
    meta: {
      layout: 'OauthLayout',
    },
    component: require('@/pages/callback/auth/PhonePayment.vue').default,
  },
]
