<template>
  <label @click="updateNewsletter" class="funda-switch funda-switch--big">
    <input type="checkbox" :checked="newsletter" disabled />
    <span class="slider round"></span>
  </label>
</template>
<script>
import { AuthTypes } from '@/store/types/auth'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      loading: false,
    }
  },
  props: {
    newsletter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('auth', {
      reloadProfile: AuthTypes.actions.GET_PROFILE,
    }),
    async updateNewsletter() {
      if (this.loading === true) {
        return
      }

      try {
        this.loading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '알림 설정 변경',
          text: '알림 설정을 변경하시겠습니까?',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        await this.$fundaApi.mutation(gql`{
          wwwUpdatePromotionSms(isValid: ${!this.newsletter})
        }`)

        await this.reloadProfile()

        this.$swal.basic.alert('알림 설정 변경이 완료되었습니다.')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
