<template>
  <div>
    <v-row :class="{ 'margin-t-15': isMobile }" no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <p class="ma-0 text-right">
          <span class="color--gray font-size--14" style="line-height: 1.4">
            * 상환일이 주말 혹은 공휴일인 경우에는<br v-show="isMobile" />
            다음 영업일에 상환이 진행됩니다.
          </span>
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <div class="dashboard-box--pa-0 margin-t-5">
          <template v-if="isLoading">
            <div
              class="d-flex align-center justify-center"
              style="height: 368px"
            >
              <v-row class="font-weight-bold font-size--16" no-gutters>
                <transition name="fade1" mode="out-in">
                  <MyLoader v-if="isLoading" key="loader" class="mx-auto" />
                </transition>
              </v-row>
            </div>
          </template>

          <template v-else>
            <ul
              v-if="!!myRepayments && showData.length > 0"
              class="my-schedule-list ma-0 pa-0"
              style="list-style: none"
            >
              <div v-for="(sc, index) in showData" :key="sc.fidx">
                <li
                  @click="openDetailPage(sc.isFundaNow, sc.fNum)"
                  class="px-5 py-5 position--relative cursor--pointer"
                >
                  <v-row class="font-weight-bold font-size--15" no-gutters>
                    <v-col
                      cols="3"
                      sm="3"
                      md="1"
                      lg="1"
                      :class="isMobile ? 'margin-b-10' : ''"
                    >
                      <p class="ma-0 color-funda--basic">
                        {{
                          isRange
                            ? '상환완료'
                            : sc.scheduleRepaymentStatus.substr(0, 4)
                        }}
                      </p>
                    </v-col>

                    <v-col
                      cols="9"
                      sm="9"
                      md="3"
                      lg="2"
                      :class="isMobile ? 'margin-b-10' : ''"
                    >
                      <template v-if="isMobile">
                        <p
                          v-if="isRange === false"
                          class="list-card__title ma-0 color--gray"
                        >
                          {{ sc.fNum }}호 {{ sc.fTitle }}
                        </p>
                      </template>

                      <template v-else>
                        <p class="ma-0 color--black">
                          {{ repaymentDateText(sc) }}
                        </p>
                      </template>
                    </v-col>

                    <v-col cols="6" sm="6" md="6" lg="7">
                      <template v-if="isMobile">
                        <p class="ma-0 color--black">
                          {{ repaymentDateText(sc) }}
                        </p>
                      </template>

                      <template v-else>
                        <p
                          v-if="isRange === false"
                          class="list-card__title ma-0 color--gray"
                        >
                          {{ sc.fNum }}호 {{ sc.fTitle }}
                        </p>
                      </template>
                    </v-col>

                    <v-col cols="6" sm="6" md="2" lg="2">
                      <p class="ma-0 color--pink text-right">
                        + {{ sc.totalRepaymentAmount | commaFormat }}
                        원
                      </p>
                    </v-col>
                  </v-row>
                </li>

                <hr
                  v-if="index < myRepayments.length - 1"
                  class="hr-solid margin-x-25 my-0"
                />
              </div>
            </ul>

            <div
              v-else
              class="d-flex align-center justify-center"
              style="height: 368px"
            >
              <v-row class="font-weight-bold font-size--16" no-gutters>
                <transition name="fade1" mode="out-in">
                  <div key="empty" class="text-center font-weight-bold mx-auto">
                    <img
                      src="@/assets/images/myfunda/ic-ic-my-list-empty.svg"
                    />
                    <p class="color--gray-2 margin-t-10 font-size--16">
                      상환내역이 없습니다.
                    </p>
                  </div>
                </transition>
              </v-row>
            </div>
          </template>
        </div>

        <PageMaker
          :key="`${startDate}_${endDate}:${totalPageCount}`"
          :size="20"
          :total-count="totalPageCount"
          :raw-data="myRepayments"
          @currentData="pageData = $event"
          @currentPage="page = $event"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import MyLoader from '@/components/MyLoader.vue'
import PageMaker from '@/components/PageMaker.vue'

export default {
  props: {
    dateRange: {
      type: Array,
      default() {
        return []
      },
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MyLoader,
    PageMaker,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      myRepayments: [],
      pageData: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    startDate() {
      return this.dateRange[0]
    },
    endDate() {
      return this.dateRange[1]
    },
    isRange() {
      return !!this.endDate
    },
    totalPageCount() {
      return this.isRange ? this.myRepayments.length : this.totalCount
    },
    showData() {
      return this.isRange ? this.pageData : this.myRepayments
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v?.length < 1) {
          return
        }

        this.page = 1
        this.getMyRepayments()
      },
    },
    page() {
      const content = this.$parent.$parent.$refs.myfundaContent
      if (!!content) {
        window.scrollTo({ top: content.offsetTop, behavior: 'smooth' })
      }

      if (this.isRange) {
        return
      }

      setTimeout(() => {
        this.getMyRepayments()
      }, 250)
    },
  },
  methods: {
    ...mapMutations('investment', {
      setRepaymentLoading: InvestmentTypes.mutations.SET_REPAYMENT_LOADING,
    }),
    async getMyRepayments() {
      if (this.isLoading) {
        return
      }

      this.setRepaymentLoading(true)
      this.isLoading = true

      let q = `data: getMyRepaymentInfo(req: {
          userIdx: ${this.userIdx}
          startDate: "${this.startDate}"
          ${!!this.endDate ? `endDate: "${this.endDate}"` : ''}
          isSummary: false
          page: ${this.page}
          size: 20
        }) {
          myRepayments {
            fNum
            fTitle
            isActual
            loanPeriod
            gracePeriod
            fStatus
            fRepaymentStatus
            bankruptcyNotice
            paybackSeq
            paybackTotal
            scheduleRepaymentStatus
            scheduleOverdueDetail
            repaymentDate
            repaymentPrincipal
            repaymentTax
            repaymentFee
            repaymentPressFee
            pressFeeRate
            totalPI
            totalInterest
            totalCost
            totalRepaymentAmount

            isSafeplan
            isFundaNow
            isNewFundaNow
            isOldFundaNow
            
          }
        }`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        if (!!data.myRepayments) {
          this.myRepayments = data.myRepayments
        } else {
          this.myRepayments = []
        }
      } catch (e) {
        this.myRepayments = []
      } finally {
        this.setRepaymentLoading(false)
        this.isLoading = false
      }
    },
    repaymentDateText(detail) {
      const dateText = this.$options.filters.dateKorean(detail.repaymentDate)

      if (this.isRange) {
        return dateText
      }

      let payBack = ''
      if (detail.isFundaNow === false) {
        payBack = `(${detail.paybackSeq}/${detail.paybackTotal}회)`
      }

      return `${dateText} ${payBack}`
    },
    openDetailPage(isFundanow, fundingDataIdx) {
      if (this.isRange) {
        return
      }

      let r = this.$router.resolve({
        name: isFundanow
          ? 'myfunda-fundanow-detail'
          : 'myfunda-schedule-detail',
        params: { fNum: fundingDataIdx },
      })

      window.open(r.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped></style>
