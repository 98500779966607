<template>
  <div
    class="dashboard-box recommender-box d-flex flex-column width-100 justify-space-between"
  >
    <div class="recommender-box__top">
      <v-row class="recommender-box__title">
        <v-col cols="3">
          <picture>
            <img
              style="width: 50px"
              src="@/assets/images/myfunda/img-img-mype-p-coin@3x.png"
            />
          </picture>
        </v-col>

        <v-col cols="9" :class="{ 'pl-0': isMobile }">
          <p class="ma-0 font-size--20">펀다 소개하고</p>
          <p class="ma-0 font-weight-black font-size--20">
            포인트 받으세요!
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-4 mb-5 recommender-box__sub-title">
        <v-col cols="12" sm="12" md="12" lg="12">
          <p class="ma-0">
            초대된 친구는 가입 즉시
            {{ getRecommender.pointAmount | commaFormat }}P
          </p>
          <p class="ma-0">
            친구가 첫 투자하면 나도
            {{ getRecommender.pointAmount | commaFormat }}P!
          </p>

          <p class="ma-0">
            <a
              class="color-funda--basic"
              style="font-size: 14px"
              :href="getRecommender.blogUrl"
              target="_blank"
            >
              이벤트 보기 >
            </a>
          </p>
        </v-col>
      </v-row>
    </div>

    <div v-if="!!profile" class="recommender-box__bottom">
      <input id="my-clipboard-txt" class="opacity-0" type="hidden" />
      <v-row
        class="d-flex align-center justify-space-between flex-wrap margin-b-15"
      >
        <v-col cols="6" sm="6" md="6" lg="8">
          <p
            class="ma-0 mb-1 font-weight-black font-size--16 color--default-gray"
          >
            추천코드
          </p>
          <p class="ma-0" style="color: #565a5c">
            {{ profile.codeRecommender.code }}
          </p>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="12"
          lg="4"
          class="text-right text-md-left text-lg-right"
        >
          <button
            class="recommender-box__button btn-funda rounded"
            @click="copyText(profile.codeRecommender.code)"
          >
            <span>복사</span>
          </button>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center justify-space-between flex-wrap">
        <v-col cols="8" sm="6" md="6" lg="8">
          <p
            class="ma-0 mb-1 font-weight-black font-size--16 color--default-gray"
          >
            가입링크
          </p>
          <p class="ma-0" style="color: #565a5c">
            {{ profile.codeRecommender.shortenUrl }}
          </p>
        </v-col>
        <v-col
          cols="4"
          sm="6"
          md="12"
          lg="4"
          class="text-right text-md-left text-lg-right"
        >
          <button
            class="recommender-box__button btn-funda rounded"
            @click="copyText(profile.codeRecommender.shortenUrl)"
          >
            <span>복사</span>
          </button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { textCopy } from '@/util'

export default {
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    ...mapGetters('event', ['getRecommender']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    copyText(txt) {
      try {
        textCopy(txt)
        this.$swal.basic.alert('복사되었습니다.')
      } catch (err) {
        this.$swal.basic.error('다시 시도해주세요.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recommender-box {
  &__title {
    p {
      line-height: 25px;
      color: #333333;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      > div.col-9 {
        padding-left: 30px;
      }
    }
  }

  &__bottom {
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      > div.row > div.row {
        flex-wrap: wrap;
      }
      > div.row > div.row > div.col-6:first-child {
        width: 100%;
      }
      > div.row > div.col-md-12:last-child {
        text-align: left;
      }
    }
  }

  &__sub-title p {
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: -0.21px;
    color: #333333;
  }

  &__button {
    padding: 7px 13px;
    background-color: #333333;
    color: #ffffff;

    @media screen and (min-width: 768px) and (max-width: 992px) {
      > div.row > div.col-sm-12:last-child {
        text-align: left;
      }
    }
  }
}
</style>
