<template>
  <section id="section__video" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row class="section-main-row video__main" no-gutters>
        <v-col cols="12" class="video-wrapper">
          <div
            v-for="youtube in youtubeCards"
            :key="youtube.code"
            class="video-card"
          >
            <div class="video__youtube">
              <iframe
                :src="`https://www.youtube.com/embed/${youtube.code}`"
                :title="youtube.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="video__text" @click="openYoutube(youtube.code)">
              <p>
                {{ youtube.title }}
                <v-icon color="#333333" size="22px">
                  mdi-open-in-new
                </v-icon>
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="mb-2 text-center">
          <v-btn
            class="btn-more"
            height="55px"
            href="https://bit.ly/3PyLQUr"
            target="_blank"
          >
            <v-icon size="23px" class="mr-2">
              mdi-youtube
            </v-icon>
            더 많은 팁 얻기!
          </v-btn>
        </v-col>

        <v-col cols="12" class="mt-10">
          <v-row
            no-gutters
            :class="{ 'd-flex align-center': isMobile === false }"
          >
            <v-col
              :cols="isMobile ? 12 : 8"
              :class="{ 'text-center mb-3': isMobile }"
            >
              <p class="supporter">
                투자 길라잡이 '오웬'과
                <br v-if="isMobile" />
                <span class="color-funda--basic">
                  지금 상담하세요!
                </span>
              </p>
            </v-col>
            <v-col
              :cols="isMobile ? 12 : 4"
              :class="isMobile ? 'text-center' : 'text-right'"
            >
              <v-btn
                id="link-to-talk"
                class="btn-funda--blue"
                height="55px"
                @click="openTalk()"
              >
                오웬 비서 연결하기!
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      youtubeCards: [
        {
          title: '소상공인 투자란 무엇인가?',
          code: 'P9cSM5VN6PU',
        },
        {
          title: '이자소득세 0원의 비밀',
          code: 'JNDRvNMZbfc',
        },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    openYoutube(code) {
      const link = `https://youtu.be/${code}`

      window.open(link, '_blank')
    },
    openTalk() {
      window.ChannelIO('show')
    },
  },
}
</script>

<style lang="scss" scoped>
#section__video {
  position: relative;
  // background-color: #eaeff4;
  background-color: white;

  .video {
    &-wrapper {
      // position: absolute;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &-card {
      position: relative;
      max-width: 480px;
      width: 100%;
      margin-bottom: 15px;
      padding: 20px 0;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.2);
      font-weight: bold;
    }

    &__youtube {
      min-height: 250px;
      iframe {
        width: 100%;
        height: 250px;
      }
    }

    &__text {
      p {
        color: #333;
        cursor: pointer;
        font-size: 18px;
        font-weight: 900;
        text-align: center;

        margin-top: 22px;
        margin-bottom: 0;
      }
    }
  }

  .btn-more {
    width: 185px;
    background-color: #ff0000 !important;
    color: white !important;
    font-size: 14px;
    font-weight: bold;
  }

  .supporter {
    font-size: 25px;
    line-height: 1.3;

    margin: 0;

    span {
      font-weight: 900;
    }
  }

  #link-to-talk {
    width: 185px;
    font-size: 14px;
    font-weight: bold;
  }

  &.mobile {
    .video-wrapper {
      display: block;
    }

    .video-card {
      max-width: 100%;

      iframe {
        height: 280px;
      }
    }

    .btn-more {
      width: 100%;
    }

    .supporter {
      font-size: 22px;
    }
  }
}
</style>
