export default [
  {
    name: 'fundingDetail',
    path: '/funding/:fundingDataIdx',
    meta: {
      layout: 'LegacyLayoutFluid',
    },
    component: require('@/pages/funding/FundingDetail.vue').default,
  },
]
