import { fundaApi } from '@/plugins/api/fundaApi'
import * as firebase from '@/plugins/firebase'
import kakao from '@/plugins/sns/kakao'
import naver from '@/plugins/sns/naver'
import router from '@/router'
import store from '@/store'
import jwtDecode from 'jwt-decode'
import env from './env'
import { AuthTypes } from './types/auth'

const TOKEN_NAME = env.state.token.name
const REFRESH_TOKEN_NAME = env.state.token.refreshTokenName

// function kakaoInit() {
//   try {
//     kakao.init('6d94baaffd4628183dc8710336cdfbbd')
//   } catch (e) {

//   }
// }

export const defaultState = () => {
  return {
    loginDialog: false,
    increaseDialog: false,
    profileLoading: false,
    isBalanceLoading: false,
    isUserValidationLoading: false,
    isReqLimitLoading: false,
    isFNTester: false,
    info: null,
    signupSocial: null,
    refresh: null,
    profile: null,
    balance: {
      point: 0,
      withdraw: 0,
    },
    userValidation: null,
    reqLimitWaitingStatus: null,
  }
}

const st = {
  name: 'auth',
  namespaced: true,
  state: defaultState(),
  getters: {
    getRedirectUri() {
      return location.pathname + location.search
    },
    isSignin(state) {
      return state.info != null
    },
    getSession(state) {
      return state
    },
    hasAnyRole: (state) => (roles) => {
      if (state.info == null) {
        return false
      }
      if (state.info.authorities == null) {
        return false
      }
      return !!state.info.authorities.filter((obj) => {
        return roles.indexOf(obj) >= 0
      }).length
    },
    getTokenExpireTime(state) {
      if (state.info == null) {
        return null
      }
      return state.info.exp
    },
    getRefreshTokenExpireTime(state) {
      return state.refresh.exp
    },
    isTokenExpire(state) {
      return state.info.exp < Date.now()
    },
    isRefreshTokenExpire(state) {
      return state.refresh.exp < Date.now()
    },
    [AuthTypes.getters.IS_AUTHENTICATED](state) {
      return state.profile != null
    },
    [AuthTypes.getters.GET_LOGIN_DIALOG](state) {
      if (state.profile !== null) {
        return false
      }
      return state.loginDialog
    },
    [AuthTypes.getters.GET_INCREASE_DIALOG](state) {
      return !!state.profile && state.increaseDialog
    },
    [AuthTypes.getters.GET_USER_IDX](state) {
      return !!state.info ? state.info.idx : null
    },
    [AuthTypes.getters.GET_PROFILE](state) {
      return state.profile
    },
    [AuthTypes.getters.GET_BALANCE](state) {
      return state.balance
    },
    [AuthTypes.getters.IS_FN_TESTER](state) {
      return state.isFNTester
    },
    [AuthTypes.getters.IS_RUBY](state) {
      let rubyRegexp = /(ruby|루비)/gi
      let isRuby = rubyRegexp.test(state.profile.affiliateCode)
      return isRuby
    },
    [AuthTypes.getters.GET_BALANCE_LOADING](state) {
      return state.isBalanceLoading
    },
    [AuthTypes.getters.GET_SIGN_UP_SOCIAL](state) {
      return state.signupSocial
    },
    getProfileLoading(state) {
      return state.profileLoading
    },
    [AuthTypes.getters.GET_USER_VALIDATION](state) {
      return state.userValidation
    },
    [AuthTypes.getters.GET_REQ_LIMIT](state) {
      return state.reqLimitWaitingStatus
    },
  },
  mutations: {
    [AuthTypes.mutations.SIGN_IN](state) {
      state.info = jwtDecode(localStorage[TOKEN_NAME])
      state.info.exp *= 1000
      state.refresh = jwtDecode(localStorage[REFRESH_TOKEN_NAME])
      state.refresh.exp *= 1000
    },
    [AuthTypes.mutations.SIGN_OUT](state) {
      localStorage.removeItem(TOKEN_NAME)
      localStorage.removeItem(REFRESH_TOKEN_NAME)
    },
    [AuthTypes.mutations.SET_PROFILE](state, profile) {
      state.profile = profile
    },
    [AuthTypes.mutations.SET_IS_FN_TESTER](state, isFNTester) {
      state.isFNTester = isFNTester
    },
    [AuthTypes.mutations.SET_BALANCE](state, balance) {
      state.balance = balance
    },
    [AuthTypes.mutations.SET_PAYMENT_USER](state, paymentUser) {
      if (!!state.profile) {
        state.profile.paymentUser = paymentUser

        state.profile.vaccount = paymentUser.virtualAccounts.find(
          (vaccount) => {
            return vaccount.type === 'USER' && vaccount.isClosed === false
          }
        )
      }
    },
    setProfileLoading(state, flag) {
      state.profileLoading = flag
    },
    setSignupSocial(state, obj) {
      state.signupSocial = obj
    },
    setLoginDialog(state, flag) {
      if (typeof flag !== 'boolean') {
        flag = !state.loginDialog
      }
      state.loginDialog = flag
    },
    [AuthTypes.mutations.SET_INCREASE_DIALOG](state, flag) {
      if (typeof flag !== 'boolean') {
        flag = !state.increaseDialog
      }
      state.increaseDialog = flag
    },
    [AuthTypes.mutations.SET_USER_VALIDATION](state, obj) {
      state.userValidation = obj
    },
    [AuthTypes.mutations.SET_REQ_LIMIT](state, obj) {
      state.reqLimitWaitingStatus = obj
    },
  },
  actions: {
    getToken() {
      if (localStorage.getItem(TOKEN_NAME) === 'undefined') {
        localStorage.clear()
      }

      return {
        access_token: localStorage.getItem(TOKEN_NAME),
        refresh_token: localStorage.getItem(REFRESH_TOKEN_NAME),
      }
    },
    async [AuthTypes.actions.SIGN_IN]({ commit }, token) {
      localStorage.setItem(TOKEN_NAME, token.access_token)
      localStorage.setItem(REFRESH_TOKEN_NAME, token.refresh_token)
      commit(AuthTypes.mutations.SIGN_IN)
      const profile = await st.actions[AuthTypes.actions.GET_PROFILE]({
        commit,
      })
      return profile
    },
    [AuthTypes.actions.SIGN_OUT]({ commit }) {
      commit(AuthTypes.mutations.SIGN_OUT)
      commit('clearState', null, { root: true })
      router.push({ name: 'main' })
    },
    async [AuthTypes.actions.GET_PROFILE]({ commit }) {
      if (st.state.profileLoading) {
        return st.state.profile
      }

      try {
        commit('setProfileLoading', true)

        if (!!!localStorage.getItem(TOKEN_NAME)) {
          return null
        }

        const { profile } = await fundaApi.query(gql`
          {
            profile: wwwProfile {
              id
              name
              gender
              email
              userPhone
              promotionSms
              userBirthdate
              isAdmin
              registerDate
              address
              affiliateCode

              codeRecommender {
                code
                shortenUrl
              }

              userFundaClasses {
                createdAt
                fundaClass {
                  classCode
                  className

                  image {
                    bucketName
                    fileKey
                  }
                }
              }

              bankAccount {
                accountHolder
                bank
                accountWoHyphen
              }

              newBankAccountFunda {
                accountHolder
                bank
                account
              }

              investmentLimit {
                idx
                type
                totalAmountLimit
                sameStoreLimit
              }

              socials {
                idx
                platform
                createTime
              }

              userForcedWithdraw {
                idx
                createTime
              }
            }
          }
        `)

        let userValidation = await store.dispatch(
          'auth/' + AuthTypes.actions.GET_USER_VALIDATION
        )
        commit(AuthTypes.mutations.SET_USER_VALIDATION, userValidation)
        commit(AuthTypes.mutations.SET_PROFILE, profile)

        await store.dispatch('auth/' + AuthTypes.actions.GET_IS_FN_TESTER)

        return profile
      } finally {
        commit('setProfileLoading', false)
      }
    },
    async [AuthTypes.actions.GET_IS_FN_TESTER]({ commit }) {
      // 체험단인지 체크 (출금 불가능)
      let q = 'data: wwwIsEventUser(categoryIdx: 8)'
      let data = await fundaApi.query(gql`{${q}}`)
      commit(AuthTypes.mutations.SET_IS_FN_TESTER, data)
    },
    async [AuthTypes.actions.GET_BALANCE]({ commit }) {
      if (st.state.isBalanceLoading) {
        return
      }
      st.state.isBalanceLoading = true
      try {
        const data = await fundaApi.query(gql`
          {
            withdraw: wwwWithdrawBalance
            point: wwwPointDeposit
          }
        `)
        commit(AuthTypes.mutations.SET_BALANCE, data)
        return data
      } finally {
        st.state.isBalanceLoading = false
      }
    },
    async [AuthTypes.actions.GET_PAYMENT_USER]({ commit }) {
      let q = `
        data: wwwPaymentUser {
          virtualAccounts {
            type
            bankCode
            accountNo
            accountName
            isAllDepositable
            isClosed
          }

          withdrawAccount {
            paymentUserWithdrawAccountIdx
            bankCode
            accountNo
            accountName
          }
        }
      `

      let data = await fundaApi.query(gql`{${q}}`)
      commit(AuthTypes.mutations.SET_PAYMENT_USER, data)

      return data
    },
    firebaseSignin({ commit }, user) {
      return firebase.auth.signInWithEmailAndPassword(user.email, user.password)
    },
    async signinSns({ commit, dispatch }, obj) {
      let data = await fundaApi.signinSns(obj.platform, obj.token)
      let orgToken = await dispatch('getToken')
      await dispatch(AuthTypes.actions.SIGN_IN, Object.assign(orgToken, data))
      return data
    },
    async signinKakao({ commit, dispatch }) {
      let resp = false
      let tokenObj = await kakao.signin()
      let socialObj = {
        platform: 'KAKAO',
        token: tokenObj.access_token,
      }
      try {
        await dispatch('signinSns', socialObj)
        resp = true
      } catch (e) {
        if (e.status === 404) {
          const res = await Kakao.API.request({
            url: '/v2/user/me',
          })
          const kakaoAccount = res.kakao_account

          socialObj.profile = {
            email: kakaoAccount.email,
          }

          if (kakaoAccount.has_phone_number === true) {
            const phone = kakaoAccount.phone_number
            const start = '+82 '
            // +82 국가 번호일때
            if (phone.startsWith(start)) {
              socialObj.profile.phone = phone.replace(start, '0')
            }
          }
          commit('setSignupSocial', socialObj)
          throw e
        } else {
          await global.$vm.$swal.basic.alert(
            '에러가 발생했습니다. 고객센터에 문의하여 주세요.'
          )
        }
      }

      return resp
    },
    async signinNaver({ commit, dispatch }, token) {
      let resp = false
      let socialObj = {
        platform: 'NAVER',
        token: token.token,
      }
      try {
        // tokenobj를 사용해야 한다면 사용
        await dispatch('signinSns', socialObj)
      } catch (e) {
        resp = true
        if (e.status === 404) {
          let alert = await global.$vm.$swal.basic.custom({
            showCancelButton: true,
            confirmButtonText: '로그인',
            cancelButtonText: '회원가입',
            html:
              '연동된 회원정보가 없습니다<br />펀다 회원가입을 진행해주세요<br />회원은 마이펀다 > 카카오 계정 연동',
            customClass: {
              container: 'custom-alert__container pa-0',
              popup: 'custom-alert__popup',
              content: 'custom-alert__content pa-0',
              confirmButton: 'custom-alert__confirm-btn',
              cancelButton: 'custom-alert__cancel-btn',
            },
          })

          if (alert.isConfirmed === false) {
            const res = await fundaApi.signupNaverProfile(socialObj.token)
            socialObj.profile = {
              email: res.email,
            }
            commit('setSignupSocial', socialObj)

            router.push({ name: 'signup' })
          }
        } else {
          await global.$vm.$swal.basic.alert(
            '에러가 발생했습니다. 고객센터에 문의하여 주세요.'
          )
        }
      }
      return resp
    },
    getNaverToken() {
      naver.getToken()
    },
    [AuthTypes.actions.ON_LOGIN_DIALOG]({ commit }, flag) {
      commit('setLoginDialog', flag)
    },
    [AuthTypes.actions.ON_INCREASE_DIALOG]({ commit }, flag) {
      commit(AuthTypes.mutations.SET_INCREASE_DIALOG, flag)
    },
    async [AuthTypes.actions.GET_USER_VALIDATION]({ getters }) {
      if (st.state.isUserValidationLoading) {
        return
      }
      const userIdx = getters[AuthTypes.getters.GET_USER_IDX]

      if (!!!userIdx) {
        return
      }

      st.state.isUserValidationLoading = true

      let q = `
        data: getUserValidation(userIdx: ${userIdx}) {
          isValid
          
          isValidPhone
          phoneValidTime
          
          isValidIdentification
          identificationValidTime
          
          isValidAccount
          accountValidTime
          
          isValidEdd
          eddValidTime
          
          createTime

          userCdds {
            type
            status
            infoJson
          }
          
          userEdds {
            question
            answer
            createTime
          }
        }
      `

      try {
        let data = await fundaApi.query(gql`{${q}}`)

        return data
      } finally {
        st.state.isUserValidationLoading = false
      }
    },
    async [AuthTypes.actions.GET_REQ_LIMIT]({ commit, getters }) {
      if (st.state.isReqLimitLoading) {
        return
      }
      const userIdx = getters[AuthTypes.getters.GET_USER_IDX]

      if (!!!userIdx) {
        return
      }

      st.state.isReqLimitLoading = true

      const q = `
        data: getReqLimitWaitingStatus(userIdx: ${userIdx}) {
          requestInvestor
          status 
          regDate
          
          userDocs {
            awsS3File {
              orgName
              created
            }
          }
        }
      `

      try {
        let data = await fundaApi.query(gql`{${q}}`)
        commit(AuthTypes.mutations.SET_REQ_LIMIT, data)
      } finally {
        st.state.isReqLimitLoading = false
      }
    },
  },
}

export default st
