<template>
  <section id="section__interview" :class="{ mobile: isMobile }">
    <div class="section-container container">
      <v-row class="interview__main" no-gutters>
        <v-col :cols="isMobile ? 12 : 6" :class="{ 'mb-7': isMobile }">
          <div class="interview-card">
            <div class="interview-card__profile">
              <picture class="interview-card__profile-img">
                <img :src="`${cdnPath}sung-hun_chung.png`" />
              </picture>

              <p class="interview-card__profile-name">
                정성훈 교수 (조지 워싱턴 대학교)
              </p>
            </div>

            <div class="interview-card__desc">
              <p>
                펀다는 AI 기반의 혁신적인 채권상환 시스템을 활용하여 투자자에게
                안정적이고 간편한 투자 기회를 제공하고 있습니다.
              </p>
            </div>
          </div>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6">
          <div class="interview-card">
            <div class="interview-card__profile">
              <picture class="interview-card__profile-img">
                <img :src="`${cdnPath}chul-ho_lee.png`" />
              </picture>

              <p class="interview-card__profile-name">
                이철호 교수 (카이스트)
              </p>
            </div>

            <div class="interview-card__desc">
              <p>
                펀다를 통해 몇 번의 클릭과 소액 투자로 소상공인과 상생할 수 있는
                기회를 얻을 수 있었고, 주식투자보다 큰 수익은 덤으로 가지게
                되었습니다.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  data() {
    return {
      cdnPath: '//cdn.funda.kr/funding/common/image/_members/interview/',
    }
  },
}
</script>

<style lang="scss" scoped>
#section__interview {
  background-color: rgb(0, 173, 229);

  .interview-card {
    position: relative;
    max-width: 95%;
    min-height: 300px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.4);
    font-weight: bold;
    text-align: center;

    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 15px;

    &__profile {
      &-img {
        img {
          width: 120px;
          height: 120px;
          background-color: #eaeff4;
          border-radius: 100%;
          object-fit: contain;
        }
      }

      &-name {
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }

    &__desc {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 350px;
      width: 90%;
      height: 135px;

      margin: 0 auto;

      p {
        color: #333;
        font-size: 16px;
        line-height: 1.4;
        margin: 0;
      }
    }
  }

  &.mobile {
    .interview-card {
      max-width: 100%;
    }
  }
}
</style>
