<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" slim>
    <v-row
      class="d-flex align-center"
      no-gutters
      :class="errors.length > 0 ? 'mb-1' : 'mb-2'"
    >
      <v-col cols="12" sm="12" md="2">
        <p class="ma-0 font-weight-bold font-size--14">{{ label }}</p>
      </v-col>

      <v-col cols="12" lg="10" md="10" sm="9">
        <input
          @focus="$emit('focus')"
          :value="inputValue"
          :class="{ passwd: type == 'password' }"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled"
          class="input-setting--input outline-color--funda-blue"
          @input="$emit('update:inputValue', $event.target.value)"
          ref="input"
        />
      </v-col>

      <v-col cols="3" lg="12" md="12" sm="3"></v-col>
    </v-row>
    <v-slide-y-transition hide-on-leave>
      <v-row
        v-if="errors && errors.length > 0"
        class="d-flex align-center mb-2"
        no-gutters
      >
        <v-col cols="12" sm="12" md="2" lg="2"></v-col>
        <v-col cols="12" sm="12" md="10" lg="10">
          <p class="color--error font-size--13 mb-0">
            {{ errors[0] }}
          </p>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autofocus === true) {
      this.$refs.input.focus()
    }
  },
}
</script>
