<template>
  <div>
    구글맵 테스트
    <hr />
    <SalesGoogleStackChart
      id="column_chart"
      :rawData="stackData"
      :isMobile="isMobile"
    />
    <RepaymentGoogleBarChart
      id="bar_stacked_chart"
      :rawData="barData"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import RepaymentGoogleBarChart from '../../components/legacy/funding/RepaymentGoogleBarChart.vue'
import SalesGoogleStackChart from '../../components/legacy/funding/SalesGoogleStackChart.vue'

export default {
  components: { SalesGoogleStackChart, RepaymentGoogleBarChart },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  data() {
    return {
      stackData: {
        salesData: {
          월별매출: {
            '19-Jan': { BC매출: { 매출: '7900200', 특이사항: '-' } },
            '19-Feb': { BC매출: { 매출: '6384100', 특이사항: '-' } },
            '19-Mar': { BC매출: { 매출: '6979600', 특이사항: '-' } },
            '19-Apr': { BC매출: { 매출: '6178200', 특이사항: '-' } },
            '19-May': { BC매출: { 매출: '5429050', 특이사항: '-' } },
            '19-Jun': { BC매출: { 매출: '5136360', 특이사항: '-' } },
          },
          지점: ['BC매출'],
        },
      },
      barData: {
        repaymentPerDay: 150902,
        bcSalesPerDay: 253383,
        repaymentRatio: 59.6,
        cardSales: 'BC',
      },
    }
  },
}
</script>
