<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2
          class="mt-5 mb-2 pl-1 color--gray-3 font-size--22 font-weight-black text-center"
        >
          수시공시
        </h2>
      </v-col>
    </v-row>

    <v-row class="mb-7" no-gutters>
      <v-col v-if="false" cols="12">
        <iframe
          :id="Date.now()"
          type="application/pdf"
          class="mb-3"
          style="height: 800px; width:100%;"
          :src="docsUrl"
        ></iframe>
      </v-col>

      <v-col cols="12">
        <p v-for="file in fileList" :key="file">
          <a
            :href="pdfPath(file)"
            target="_blank"
            class="font-size--16 text-decoration--underline"
          >
            {{ linkName(file) }}
          </a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cdnPath: 'https://cdn.funda.kr/funding/clip',
      fileList: [
        '20211015_frequently',
        '20211029_frequently-1',
        '20211029_frequently-2',
        '20211130_frequently',
        '20220415_frequently',
        '20220701_frequently-채권매각_투표결과',
        '20220816_frequently',
        '20230629_frequently-채권매각 투표결과',
        '20230629_frequently',
      ],
      // fileList: [
      //   '202107_official_notice',
      //   '202108_official_notice',
      //   '202109_official_notice',
      // ],
    }
  },
  computed: {
    pdfPath() {
      return (fileName) => {
        return `${this.cdnPath}/${fileName}.pdf`
      }
    },
    linkName() {
      return (fileName) => {
        let temp = fileName.split('_frequently')
        return `${temp[0]} 수시공시 ${temp[1]}`
      }
    },
    docsUrl() {
      let last = this.pdfPath(this.fileList[this.fileList.length - 1])
      return `https://docs.google.com/viewer?embedded=true&url=${last}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
