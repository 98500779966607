<template>
  <div class="balance-deposit" :class="{ mobile: isMobile }">
    <template v-if="isPageRequiredDataLoading == true">
      <div class="d-flex align-center justify-center" style="height: 325px;">
        <p v-if="isInvalidUser" class="mt-3 font-size--16 text-center">
          본인 인증 완료 및 승인 후 입/출금 서비스 이용이 가능합니다
        </p>

        <MyLoader v-else />
      </div>
    </template>
    <template v-else>
      <div class="balance-deposit__content d-flex">
        <div :class="isMobile ? 'width-100' : ['width-50', 'pr-10']">
          <div
            class="balance-deposit__content-waccount-wrapper"
            :class="isMobile ? 'padding-b-25' : 'pb-5'"
          >
            <div class="balance-deposit__content-waccount">
              <div
                class="margin-b-10 d-flex justify-space-between align-center"
              >
                <p
                  class="
                    color-funda--basic
                    position--relative
                    cursor--pointer
                    font-size--20
                    font-weight-black
                    mb-5
                  "
                  style="height: 20px"
                >
                  내 등록 계좌
                </p>
              </div>
              <div
                v-if="!!paymentUser"
                class="d-flex justify-space-between fadein flex-wrap"
              >
                <div>
                  <p class="margin-b-5 font-weight-bold font-size--14">
                    {{ paymentUser.withdrawAccount.bankCode | bankName }}
                  </p>
                  <p
                    class="mb-0 font-weight-black"
                    :class="isMobile ? 'font-size--22' : 'font-size--18'"
                  >
                    {{ paymentUser.withdrawAccount.accountNo }}
                  </p>
                </div>
                <div
                  v-if="isUserForcedWithdraw === false"
                  :class="{ 'width-100 margin-t-10': isMobile }"
                >
                  <button
                    type="button"
                    @click="updateAccountReq.show = true"
                    class="btn-funda--black common-button padding-x-15"
                    :class="{ 'width-100 font-size--18': isMobile }"
                    :disabled="updateAccountReq.show"
                  >
                    {{
                      updateAccountReq.show ? '계좌 변경 중' : '계좌 변경하기'
                    }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="updateAccountReq.show" class="margin-t-25 fadein">
              <div>
                <p class="margin-b-10 font-weight-black">내 등록 계좌</p>
                <ValidAccount
                  :is-disabled="!!updateAccountConfirm.tid"
                  @valid-account-form="updateAccountForm"
                />
              </div>
              <div class="balance-deposit__content-waccount-confirm">
                <MyVerifyForm
                  v-if="!!updateAccountConfirm.tid"
                  :expire-time="updateAccountConfirm.expireTime"
                  :error-msg="updateAccountConfirm.errorMsg"
                  :is-loading="
                    updateAccountReq.loading || updateAccountConfirm.loading
                  "
                  :is-register-account="true"
                  @request-confirm="confirmModifyAccount"
                  @request-resend="requestModifyAccount"
                />
                <div
                  class="margin-t-10 d-flex align-center justify-space-between"
                >
                  <button
                    type="button"
                    @click="closeUpdateAccountReqForm"
                    class="btn-funda--black margin-r-10 common-button"
                    :disabled="
                      updateAccountReq.loading || updateAccountConfirm.loading
                    "
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    class="
                      btn-funda--blue
                      position--relative
                      d-flex
                      align-center
                      justify-center
                      common-button
                    "
                    :disabled="!isUpdateAccountRequestable"
                    @click="requestModifyAccount"
                  >
                    <template v-if="updateAccountReq.loading">
                      <span class="loading-spinner d-inline-block"></span>
                    </template>
                    <template v-else> 계좌 변경하기 </template>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div :class="isMobile ? 'padding-t-25' : 'pt-5'">
            <MyVirtualAccount v-if="!!myDashboardData" :vaccount="vaccount" />
          </div>

          <div
            v-if="isMobile"
            class="width-100 margin-b-25"
            style="border-bottom: 1px solid #d7e1ea"
          ></div>
        </div>
        <div :class="isMobile ? 'width-100' : 'width-50'">
          <div
            class="
              balance-deposit__content-balance
              rounded--4
              width-100
              text-center
              padding-y-15
            "
          >
            <p class="margin-b-7 font-weight-bold">출금 가능 예치금</p>
            <p v-if="withdrawableBalance != null" class="ma-0">
              <b
                class="font-weight-black"
                :class="isMobile ? 'font-size--22' : 'font-size--20'"
              >
                {{ withdrawableBalance | commaFormat }} </b
              >원
            </p>
          </div>
          <p
            class="
              balance-deposit__content-bank-maintenance
              color--gray
              padding-l-10
              margin-t-5
              mb-5
              position--relative
            "
          >
            은행점검 시간(23:00~01:00)에는 입&#47;출금 서비스 이용이 제한될 수
            있습니다.
          </p>

          <div
            class="balance-deposit__content-withdraw"
            :class="isMobile ? 'margin-t-25' : 'mt-5'"
          >
            <p class="font-weight-black margin-b-5 font-size--18">
              출금하기
            </p>
            <p v-if="isUserForcedWithdraw" class="margin-b-10 font-size--15">
              (
              <span class="color-funda--basic">
                {{ profile.name }}
              </span>
              님은 무인증 출금 대상입니다 )
            </p>
            <template v-if="isCannotWithdrawal">
              <span class="color--red">
                {{ cannotWithdrawalText }}
              </span>
            </template>
            <template v-else-if="isFNTester">
              <span class="color--red"> 출금 불가 계정입니다. </span>
            </template>
            <template v-else>
              <div>
                <input
                  class="my-funda-input text-right"
                  :disabled="withdrawReq.loading || !!withdrawConfirm.idx"
                  v-input-currency="withdrawReq.withdrawalAmount"
                  type="tel"
                />
              </div>
              <div
                class="
                  d-flex
                  margin-t-10
                  balance-deposit__content-withdraw-amounts
                "
              >
                <template v-if="withdrawConfirm.idx == null">
                  <button type="button" @click="addWithdrawAmount(10000)">
                    1만원
                  </button>
                  <button type="button" @click="addWithdrawAmount(100000)">
                    10만원
                  </button>
                  <button
                    type="button"
                    @click="addWithdrawAmount('all')"
                    :class="{ 'mr-0': isMobile }"
                  >
                    전액
                  </button>
                </template>
                <button
                  type="button"
                  @click="requestWithdrawal"
                  style="transition: all 0.3s"
                  :style="isMobile ? 'height: 54px;' : ''"
                  :disabled="
                    withdrawReq.loading ||
                      withdrawTimeoutLoading ||
                      !!withdrawConfirm.idx ||
                      forcedWithdrawLoading
                  "
                  class="
                    btn-funda--black
                    position--relative
                    d-flex
                    align-center
                    justify-center
                    common-button
                  "
                  :class="{ 'margin-t-10 width-100': isMobile }"
                >
                  <template
                    v-if="
                      withdrawReq.loading ||
                        withdrawTimeoutLoading ||
                        forcedWithdrawLoading
                    "
                  >
                    <span class="loading-spinner d-inline-block"></span>
                  </template>
                  <template v-else>
                    {{
                      !!withdrawConfirm.idx ? '출금 진행 중' : '출금 신청하기'
                    }}
                  </template>
                </button>
              </div>
            </template>
          </div>
          <div class="balance-deposit__content-withdraw-confirm">
            <MyVerifyForm
              v-if="!!withdrawConfirm.idx"
              :expire-time="withdrawConfirm.expireTime"
              :error-msg="withdrawConfirm.errorMsg"
              :is-loading="withdrawReq.loading || withdrawConfirm.loading"
              @request-confirm="confirmWithdrawal"
              @request-resend="requestWithdrawal"
              class="margin-t-25"
            />
          </div>
        </div>
      </div>
      <SwitchComponent
        switch-name="WITHDRAW"
        :switching.sync="withdrawSwitch"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { TimeTypes } from '@/store/types/time'
import MyLoader from '@/components/MyLoader.vue'
import ValidAccount from '@/components/ValidAccount.vue'
import MyVirtualAccount from '@/components/myfunda/account/MyVirtualAccount.vue'
import MyVerifyForm from '@/components/myfunda/MyVerifyForm.vue'
import SwitchComponent from '@/components/SwitchComponent.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
    banks: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    MyLoader,
    ValidAccount,
    MyVirtualAccount,
    MyVerifyForm,
    SwitchComponent,
  },
  data() {
    return {
      updateAccountReq: {
        show: false,
        accountForm: null,
        loading: false,
      },
      updateAccountConfirm: {
        tid: null,
        verifyWord: '',
        loading: false,
        expireTime: null,
        errorMsg: null,
      },
      withdrawReq: {
        withdrawalAmount: 0,
        remark: '홈페이지에서 출금 신청',
        loading: false,
      },
      withdrawConfirm: {
        idx: null,
        loading: false,
        expireTime: null,
        errorMsg: null,
      },
      forcedWithdrawLoading: false,
      withdrawTimeoutLoading: false,
      paymentUser: null,
      vaccount: null,
      isCannotWithdrawal: true,
      cannotWithdrawalText: '현재 은행 점검시간으로 출금이 불가합니다.',
      withdrawSwitch: false,
      withdrawableBalance: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      isFNTester: AuthTypes.getters.IS_FN_TESTER,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isUpdateAccountRequestable: function() {
      return (
        !!this.updateAccountReq.accountForm &&
        !!this.updateAccountReq.accountForm.accountName &&
        this.updateAccountReq.loading === false &&
        !!!this.updateAccountConfirm.tid
      )
    },
    isUserForcedWithdraw() {
      return !!this.profile?.userForcedWithdraw
    },
    isInvalidUser() {
      return this.userValidation?.isValid !== true
    },
    isPageRequiredDataLoading() {
      if (this.isUserForcedWithdraw === true) {
        return false
      }

      if (this.isInvalidUser) {
        return true
      }

      return (
        this.withdrawableBalance == null ||
        !!!this.myDashboardData ||
        !!!this.paymentUser ||
        !!!this.vaccount
      )
    },
  },
  watch: {
    withdrawSwitch(v) {
      if (!!v) {
        this.isCannotWithdrawal = v.isOff
        this.cannotWithdrawalText = '현재 출금 서비스가 일시 중단 상태입니다.'
      }
    },
  },
  async created() {
    this.setCannotWithdrawal()
    // // 웰컴페이먼츠 사용자 조회
    await this.getPaymentUser()
    // // 출금 가능 잔액 조회
    this.getBalance()
    // // 출금 계좌 및 출금 form 기본 양식..
    this.updateAccountReqReset = Object.assign({}, this.updateAccountReq)
    this.updateAccountConfirmReset = Object.assign(
      {},
      this.updateAccountConfirm
    )
    this.withdrawReqReset = Object.assign({}, this.withdrawReq)
    this.withdrawConfirmReset = Object.assign({}, this.withdrawConfirm)
  },
  methods: {
    ...mapActions('auth', {
      GET_PAYMENT_USER: AuthTypes.actions.GET_PAYMENT_USER,
    }),
    ...mapActions('time', {
      GET_IS_INSPECT_TIME: TimeTypes.actions.GET_IS_INSPECT_TIME,
    }),
    async getPaymentUser() {
      let data = await this.GET_PAYMENT_USER()
      this.paymentUser = data
      if (!!data) {
        this.vaccount = data.virtualAccounts.find((vaccount) => {
          return vaccount.type === 'USER' && vaccount.isClosed === false
        })
      }
    },
    getBalance() {
      return new Promise(async (resolve, reject) => {
        try {
          let q = 'data: wwwWithdrawBalance'

          let data = await this.$fundaApi.query(gql`{${q}}`)
          this.withdrawableBalance = data
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    },
    updateAccountForm(accountForm) {
      this.updateAccountReq.accountForm = accountForm
    },
    async requestModifyAccount() {
      if (
        this.updateAccountReq.loading === true ||
        this.isUpdateAccountRequestable === false
      ) {
        return
      }
      const btnResult = await this.$swal.basic.confirm(
        '변경할 계좌로 1원이 입금됩니다.'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.updateAccountReq.loading = true

      let form = this.updateAccountReq.accountForm

      let q = `
          data: wwwUpdateWithdrawAccount(bankCode: "${form.bankCode}", accountNo: "${form.accountNo}", accountName: "${form.accountName}"){
              tid
              updateTime
          }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '1원 입금 인증을 실패하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          this.updateAccountConfirm.tid = data.tid
          this.updateAccountConfirm.expireTime = this.$moment()
            .add(5, 'minutes')
            .toDate()
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
      } finally {
        this.updateAccountReq.loading = false
      }
    },
    async confirmModifyAccount(code) {
      if (this.updateAccountConfirm.loading === true) {
        return
      }
      const btnResult = await this.$swal.basic.confirm('계좌가 변경됩니다.')
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.updateAccountConfirm.loading = true

      let q = `
          data: wwwConfirmWithdrawAccount(tid: "${this.updateAccountConfirm.tid}", verifyWord: "${code}"){
              tid
              bankCode
              accountNo
              accountName
              isVerify
              updateTime
            }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '계좌 변경을 실패하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          await this.getPaymentUser()
          this.clearForm('updateAccountReq')
          this.clearForm('updateAccountConfirm')
          this.$swal.basic.alert('출금계좌가 정상적으로 변경되었습니다.')
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
        this.updateAccountConfirm.errorMsg = e.message
      } finally {
        this.updateAccountConfirm.loading = false
      }
    },
    clearForm(dataName) {
      const resetDataName = dataName + 'Reset'
      const dataKeys = Object.getOwnPropertyNames(this[dataName])
      for (let i = 0; i < dataKeys.length; i++) {
        this[dataName][dataKeys[i]] = this[resetDataName][dataKeys[i]]
      }
    },
    closeUpdateAccountReqForm() {
      if (this.withdrawReq.loading || this.withdrawConfirm.loading) {
        return
      }

      this.clearForm('updateAccountReq')
      this.clearForm('updateAccountConfirm')
    },
    addWithdrawAmount(amount) {
      if (this.withdrawReq.loading || this.withdrawConfirm.idx != null) {
        return
      }

      let withdrawalAmount
      if (amount === 'all') {
        withdrawalAmount =
          this.withdrawableBalance == null ? 0 : this.withdrawableBalance
      } else {
        withdrawalAmount = Number(this.withdrawReq.withdrawalAmount) + amount
      }

      // ie
      this.$nextTick((_) => {
        this.withdrawReq.withdrawalAmount = withdrawalAmount
      })
    },
    async setCannotWithdrawal() {
      this.isCannotWithdrawal = await this.GET_IS_INSPECT_TIME()
      this.cannotWithdrawalText = '현재 은행 점검시간으로 출금이 불가합니다.'

      return this.isCannotWithdrawal
    },
    async requestWithdrawal() {
      // eslint-disable-next-line
      if (this.isFNTester || this.withdrawReq.loading) {
        return
      }

      if ((await this.setCannotWithdrawal()) === true) {
        this.$swal.basic.alert(this.cannotWithdrawalText)
        return
      }
      if (
        this.withdrawReq.withdrawalAmount < 1 ||
        isNaN(this.withdrawReq.withdrawalAmount)
      ) {
        this.$swal.basic.alert('출금액을 1원이상 입력해주세요.')
        return
      }

      if (this.withdrawReq.withdrawalAmount > this.withdrawableBalance) {
        this.withdrawReq.withdrawalAmount = 0
        this.$swal.basic.alert('출금가능금액을 초과하였습니다.')
        return
      }

      let confirmText = '출금을 위한 인증번호가 발송됩니다.'
      if (this.isUserForcedWithdraw === true) {
        confirmText = `<span style="font-weight: bold;">${this.profile?.name}</span> 님은 <br /> 
        무인증 출금 대상자로, 인증번호 없이 바로 출금이 진행됩니다.`
      }

      const btnResult = await this.$swal.basic.confirm({ html: confirmText })
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.withdrawReq.loading = true

      const reqAmount = parseInt(this.withdrawReq.withdrawalAmount, 10)
      let q = `
        data: wwwReqWithdraw(amount: ${reqAmount}, remark: "${this.withdrawReq.remark}"){
            idx
            amount
            remark
            status
            createTime
          }
        `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '출금 요청중 에러가 발생하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          if (this.isUserForcedWithdraw === true) {
            this.forcedWithdrawLoading = true
            setTimeout(async (_) => {
              await this.$swal.basic.alert('출금이 완료되었습니다.')
              location.reload()
            }, 2500)
          } else {
            this.withdrawConfirm.idx = data.idx
            this.withdrawConfirm.expireTime = this.$moment()
              .add(5, 'minutes')
              .toDate()
          }
        }
      } catch (e) {
        this.$swal.basic.warning({ html: e.message })
      } finally {
        this.withdrawReq.loading = false
      }
    },
    async confirmWithdrawal(code) {
      if (this.isFNTester || this.withdrawConfirm.loading === true) {
        return
      }
      if ((await this.setCannotWithdrawal()) === true) {
        this.$swal.basic.alert(this.cannotWithdrawalText)
        return
      }
      const btnResult = await this.$swal.basic.confirm(
        '출금을 진행하시겠습니까?'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }

      this.withdrawConfirm.loading = true

      let q = `
          data: wwwConfirmWithdraw(paymentUserWithdrawIdx: ${this.withdrawConfirm.idx}, verifyWord: "${code}") {
              remark
              status
              createTime
            }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '출금이 제대로 처리되지 않았습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          // 잔액 업데이트 시점 ?
          // await new Promise(resolve => setTimeout(resolve, 2500))
          // await this.getBalance()
          this.clearForm('withdrawReq')
          this.clearForm('withdrawConfirm')
          // alert('출금이 완료되었습니다.')
          this.withdrawTimeoutLoading = true
          setTimeout(async (_) => {
            await this.$swal.basic.alert('출금이 완료되었습니다.')
            location.reload()
          }, 2500)
        }
      } catch (e) {
        this.$swal.basic.warning({ html: e.message })
        this.withdrawConfirm.errorMsg = e.message
      } finally {
        this.withdrawConfirm.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  line-height: 1.3;
}
/* balance-deposit__content start */
.balance-deposit {
  &.mobile {
    .balance-deposit__content-withdraw .d-flex {
      flex-wrap: wrap;
    }

    .balance-deposit__content-withdraw-amounts {
      button:last-child {
        font-size: 18px;
      }
    }
  }

  &:not(.mobile) {
    .balance-deposit__content-withdraw-amounts {
      button:last-child {
        flex: 1 1 115px;
        font-size: 15px;
      }
    }
  }
}

.balance-deposit__content-withdraw p {
  font-size: 16px;
}

.balance-deposit__content {
  flex-wrap: wrap;
}

.balance-deposit__content-balance {
  border: solid 1px #e7edf6;
  background-color: #f6faff;
}

.balance-deposit__content-balance .spinner-wrapper {
  width: 20px;
}

.balance-deposit__content-balance .spinner-wrapper .loading-spinner {
  position: absolute;
  left: 0;
  bottom: -4px;
}

.balance-deposit__content-waccount > p:nth-child(1) {
  line-height: 18px;
}

.balance-deposit__content-balance p:nth-child(1) {
  line-height: 16px;
}

.balance-deposit__content-balance p:nth-child(2) {
  font-size: 20px;
}

.balance-deposit__content-bank-maintenance {
  font-size: 13px;
  line-height: 18px;
}

.balance-deposit__content-bank-maintenance::before {
  content: '*';
  position: absolute;
  left: 0;
}

.balance-deposit__content-waccount > div:nth-child(1) a::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #333333;
  position: absolute;
  left: 0;
  bottom: -3px;
}

.balance-deposit__content-waccount > div:nth-of-type(1) {
  flex-wrap: wrap;
}

.balance-deposit__content-waccount-confirm p,
.balance-deposit__content-withdraw-confirm p {
  letter-spacing: -0.2px;
}

.balance-deposit__content-waccount-confirm-from {
  background-color: #f2f9ff;
}

.balance-deposit__content-waccount-confirm-from .d-flex:nth-child(2) div {
  flex: 1 1 90%;
}

.balance-deposit__content-waccount-confirm-from .d-flex:nth-child(2) button {
  flex: 1 1 10%;
  min-width: 115px;
}

.balance-deposit__content-waccount-confirm
  .d-flex:last-child
  button:first-child {
  flex: 1 1 40%;
}

.balance-deposit__content-waccount-confirm
  .d-flex:last-child
  button:last-child {
  flex: 1 1 60%;
}

.balance-deposit__content-waccount-wrapper {
  border-bottom: 1px solid #d7e1ea;
}

.balance-deposit__content-withdraw div:nth-child(2) {
  position: relative;
}

.balance-deposit__content-withdraw div:nth-child(2) input {
  padding-right: 40px;
}

.balance-deposit__content-withdraw div:nth-child(2)::before {
  position: absolute;
  content: '원';
  right: 20px;
  font-size: 16px;
  font-weight: 700;
  padding-top: 14px;
}

.balance-deposit__content-withdraw-amounts button {
  outline: none;
  border: none;
  line-height: 16px;
  font-weight: 900;
  padding: 14px 5px;
  border-radius: 4px;
}

.balance-deposit__content-withdraw-amounts button:not(:last-child) {
  margin-right: 10px;
  flex: 1 1 50px;
  border: 1px solid #d7e1ea;
  background-color: #ffffff;
  font-size: 16px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .balance-deposit__content-withdraw-amounts button:not(:last-child) {
    margin-right: 5px;
  }
}
/* balance-deposit__content end */
</style>
