<template>
  <PageLoading v-if="pageLoading" />
  <AuthPhone v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import PageLoading from '@/components/common/PageLoading'
import AuthPhone from '@/components/auth/AuthPhone'

export default {
  components: {
    PageLoading,
    AuthPhone,
  },
  data() {
    return {
      pageLoading: true,
      isValid: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
  },
  watch: {
    userValidation: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v?.isValidPhone === true) {
          this.isValid = true
        } else {
          this.isValid = false
        }
      },
    },
    isValid: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.nextStep()
        }
      },
    },
  },
  created() {
    setTimeout(() => {
      this.pageLoading = false
    }, 800)
  },
  methods: {
    nextStep() {
      if (this.isValid === false) {
        return
      }
      this.$emit('next-step')
    },
  },
}
</script>
