<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="register-box">
          <div class="label"><v-icon small>mdi-account</v-icon>이름</div>
          <v-text-field
            outlined
            single-line
            v-model="form.name"
            label="성명을 입력해주세요"
            :disabled="isAlreadyInvestor || isLoading"
            :loading="isLoading"
            hide-details
            class="mb-5"
          ></v-text-field>

          <div class="label"><v-icon small>mdi-cellphone</v-icon>휴대폰</div>
          <v-text-field
            outlined
            single-line
            name="mobileNo"
            label="휴대폰번호를 입력해주세요('-' 제외)"
            v-model="form.mobileNo"
            :disabled="isAlreadyInvestor || isLoading"
            :error-messages="mobileErrorMessage"
            :hide-details="!!!mobileErrorMessage"
            class="mb-5"
            @input="formatMobileNo($event)"
          ></v-text-field>

          <div class="label"><v-icon small>mdi-calendar</v-icon>생년월일</div>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="form.birthday"
            width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.birthday"
                outlined
                single-line
                label="생년월일을 선택해주세요"
                :disabled="isAlreadyInvestor || isLoading"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                class="mb-5"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="form.birthday"
              :max="new Date().toISOString().substr(0, 10)"
              min="1891-01-01"
              @change="dateSave"
            >
            </v-date-picker>
          </v-dialog>

          <div id="policy" class="pb-5">
            <v-checkbox
              hide-details
              v-model="promotionSms.isAgree"
              :label="promotionSms.title"
              class="my-0"
            ></v-checkbox>
            <small
              class="text-plain"
              style="margin-left: 34px; font-size: 11px; display: block"
              >투자할 상품의 정보를 문자로 알려드립니다</small
            >
          </div>
        </div>
        <div class="pa-0">
          <v-btn
            class="btn-funda-basic"
            color="primary"
            :loading="isLoading"
            :disabled="isValid === false || isLoading"
            @click="getAuthMobileUrl()"
            elevation="0"
          >
            인증 요청하기
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  data() {
    return {
      pageLoading: true,
      isLoading: false,
      isAlreadyInvestor: false,
      rules: {
        mobileNo: [
          value => !!value || '휴대전화번호를 입력해주세요',
          value =>
            /^01\d{8,9}$/.test(value) || '휴대폰전화번호가 올바르지 않습니다',
        ],
      },
      modal: false,
      form: {
        name: '',
        mobileNo: '',
        birthday: '',
        redirectPage: location.origin + '/aa',
      },
      mobileErrorMessage: null,
      mobileIsValid: false,
      promotionSms: {
        title: '휴대폰으로 투자알림 받기',
        name: 'promotionSms',
        isAgree: true,
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    isValid() {
      let valid = true
      Object.keys(this.form).forEach(k => {
        if (!!!this.form[k]) {
          valid = false
        }
      })
      return valid === true && this.mobileIsValid
    },
  },
  watch: {
    'form.mobileNo': {
      immediate: false,
      handler(newVal) {
        let msg = this.getFormErrMsg('mobileNo', newVal)

        if (msg === null) {
          this.mobileDuplicate()
        } else {
          this.mobileErrorMessage = msg
        }
      },
    },
    userValidation: {
      deep: true,
      async handler(newVal, oldVal) {
        if (!!!newVal || newVal?.isValidPhone === oldVal?.isValidPhone) {
          return
        }

        if (newVal.isValidPhone === false) {
          this.$swal.basic.alert(
            '본인인증이 제대로 진행되지 않았습니다. 다시 시도해주세요'
          )
          return
        }

        const profile = await this.GET_PROFILE()

        if (
          profile?.name === this.form.name &&
          profile?.userPhone === this.form.mobileNo
        ) {
          if (this.promotionSms.isAgree === true) {
            await this.$fundaApi.mutation(gql`
              {
                wwwUpdatePromotionSms(isValid: true)
              }
            `)
          }

          await this.$swal.basic.alert('휴대폰 본인인증이 완료되었습니다')
        } else {
          this.$swal.basic.alert(
            '본인인증이 제대로 진행되지 않았습니다. 다시 시도해주세요'
          )
        }
      },
    },
    profile: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!!!v || !!!v?.name || !!!v.userPhone) {
          return
        }
        this.isAlreadyInvestor = true
        this.form.name = v.name
        this.form.mobileNo = v.userPhone
        this.form.birthday = v.userBirthdate
      },
    },
    modal(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    ...mapMutations('auth', {
      SET_USER_VALIDATION: AuthTypes.mutations.SET_USER_VALIDATION,
    }),
    ...mapActions('auth', {
      GET_PROFILE: AuthTypes.actions.GET_PROFILE,
    }),
    formatMobileNo($event) {
      this.form.mobileNo = $event.replace('-', '')
    },
    getFormErrMsg(type, newVal) {
      const rules = this.rules[type]
      let msg = null
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index]
        const isValid = rule(newVal)
        if (typeof isValid !== 'boolean') {
          msg = isValid
          break
        }
      }

      return msg
    },
    dateSave(date) {
      this.$refs.dialog.save(date)
    },
    async mobileDuplicate() {
      this.mobileErrorMessage = null
      this.mobileIsValid = true
    },
    async getAuthMobileUrl() {
      let alert = await this.$swal.basic.confirm('휴대폰 인증을 진행합니다')
      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      let q = `
        data: getAuthMobileUrl(userIdx: ${this.userIdx}, phoneReq: $input) {
          rsltCd
          authUrl
        }
      `

      try {
        let data = await this.$fundaApi.query(
          gql`{${q}}`,
          'call($input: PhoneReq!)',
          { input: this.form }
        )

        if (data.rsltCd === 'B000') {
          window.open(data.authUrl, 'phoneWelcomeLink')
        } else {
          this.$swal.basic.error('휴대폰인증 과정에서 에러가 발생하였습니다')
        }
      } catch (e) {
        this.$swal.basic.error(e.message)
      } finally {
        this.SET_USER_VALIDATION(null)
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
