import axios from 'axios'
import store from '@/store'
// 확인할 주기(초단위)
let versionCheckTime = 60 * 10
let nowVersion
const versionCheck = async () => {
  // 버전확인 연속호출
  setTimeout(versionCheck, versionCheckTime * 1000)

  let { data } = await axios.get(`/assets.json?t=${Date.now()}`)
  // string으로 변환해서 저장
  let serverVersion = JSON.stringify(data)
  if (!nowVersion) {
    nowVersion = serverVersion
    return
  }

  if (Object.is(nowVersion, serverVersion) === false) {
    location.reload()
  }
}

// local이 아닐때만 확인 시작
if (store.state.env.mode !== 'local') {
  // 버전확인 최초호출 (비동기)
  setTimeout(versionCheck, 100)
}
