<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="3">
        <p
          class="setting__category font-weight-black font-size--15 mb-5 mb-md-0"
        >
          회원정보 수정
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="9" lg="6">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" class="mb-5">
            <v-row no-gutters>
              <v-col cols="4" sm="3" md="4" lg="4">
                <p class="ma-0 font-weight-bold font-size--14">아이디</p>
              </v-col>

              <v-col cols="8" sm="9" md="8" lg="8">
                <p class="ma-0 font-size--15 word-break--break-all">
                  {{ myProfile.id }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="6" class="mb-5">
            <v-row no-gutters>
              <v-col cols="4" sm="3" md="4" lg="4">
                <p class="ma-0 font-weight-bold font-size--14">가입일</p>
              </v-col>

              <v-col cols="8" sm="9" md="8" lg="8">
                <p class="ma-0 font-size--15">
                  {{ myProfile.registerDate | dateFormat }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" class="mb-5">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="4" sm="3" md="4" lg="4">
                <p class="ma-0 font-weight-bold font-size--14">
                  프로모션(파트너사) 코드
                </p>
              </v-col>

              <v-col cols="8" sm="9" md="8" lg="8">
                <p
                  v-if="
                    myProfile.affiliateCode != null &&
                      myProfile.affiliateCode.length > 0
                  "
                  class="ma-0 font-size--15"
                >
                  {{ myProfile.affiliateCode }}
                </p>
                <p v-else class="ma-0 font-size--15 pl-8 pl-md-4">(-)</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <ChangePasswordSection
          class="mb-5"
          :key="passwordFormKey"
          @close="passwordFormKey = 'passwordFormKey' + new Date().getTime()"
        />

        <ChangePhoneSection
          :key="phoneFormKey"
          :isMobile="isMobile"
          :phone="myProfile ? myProfile.userPhone : null"
          @close="phoneFormKey = 'phoneFormKey' + new Date().getTime()"
        />
      </v-col>
    </v-row>

    <hr class="hr-solid" style="border-top: 2px solid #d7e1ea" />

    <v-row class="d-flex" no-gutters>
      <v-col cols="12" sm="12" md="3">
        <p class="setting__category font-weight-black font-size--15">
          간편 로그인 설정
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="9">
        <template v-if="myProfile.socials.length < 1">
          <button
            class="btn-funda--black rounded common-button"
            style="max-width: 150px"
            depressed
            :disabled="isLoading"
            @click="clickKakao()"
          >
            <template v-if="isLoading">
              <span class="loading-spinner"></span>
            </template>
            <template v-else> 카카오톡 연동하기 </template>
          </button>
        </template>

        <template v-else>
          <v-row class="mb-2" no-gutters>
            <v-col cols="12">
              <div class="d-inline-block" style="width: 70px">
                <p class="ma-0 font-weight-bold font-size--14">연동채널</p>
              </div>

              <div class="d-inline-block">
                <p class="ma-0 font-weight-bold font-size--14">
                  {{ myProfile.socials[0].platform }}
                </p>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <div class="d-inline-block" style="width: 70px">
                <p class="ma-0 font-weight-bold font-size--14">설정일</p>
              </div>

              <div class="d-inline-block">
                <p class="ma-0 font-weight-bold font-size--14">
                  {{ myProfile.socials[0].createTime | dateFormat }}
                </p>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-2" no-gutters>
            <v-col cols="12">
              <button
                class="btn-funda--black rounded common-button"
                style="max-width: 150px"
                :disabled="isLoading"
                @click="disconnectSocial()"
              >
                <template v-if="isLoading">
                  <span class="loading-spinner"></span>
                </template>
                <template v-else> 연동 해제 </template>
              </button>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <hr class="hr-solid" style="border-top: 2px solid #d7e1ea" />

    <v-row class="d-flex align-center" no-gutters>
      <v-col cols="12" sm="12" md="3">
        <p class="setting__category font-weight-black font-size--15">
          알림 설정
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="9" lg="6">
        <v-row class="d-flex align-center" no-gutters>
          <v-col cols="12" sm="12" md="6" class="pr-0 pr-md-3 mb-3 mb-md-0">
            <v-row class="d-flex align-center" no-gutters>
              <v-col cols="8" sm="8" md="8">
                <p class="ma-0 font-weight-bold font-size--14">
                  투자신청 및 상환정보(필수)
                </p>
              </v-col>

              <v-col cols="4" sm="4" md="4" class="d-flex justify-end">
                <label class="funda-switch funda-switch--big">
                  <input type="checkbox" disabled="disabled" checked />
                  <span class="slider round" style="cursor: not-allowed"></span>
                </label>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="6" class="pl-0 pl-md-3">
            <v-row class="d-flex align-center" no-gutters>
              <v-col cols="8" sm="8" md="8">
                <p class="ma-0 font-weight-bold font-size--14">
                  펀다 광고 수신 동의
                </p>
              </v-col>

              <v-col cols="4" sm="4" md="4" class="d-flex justify-end">
                <p class="ma-0 font-weight-bold">
                  <NewsletterSwitch
                    :newsletter="myProfile ? myProfile.promotionSms : false"
                  />
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <hr class="hr-solid" style="border-top: 2px solid #d7e1ea" />

    <v-row class="d-flex align-center" no-gutters>
      <v-col cols="12" sm="12" md="3">
        <p class="setting__category font-weight-black font-size--15">
          회원 탈퇴
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="9">
        <LeaveDialogButton
          :key="leaveFormKey"
          :name="myProfile.name"
          @close="leaveFormKey = 'leaveFormKey' + new Date().getTime()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import ChangePhoneSection from '../../../components/myfunda/setting/normal/ChangePhoneSection.vue'
import ChangePasswordSection from '../../../components/myfunda/setting/normal/ChangePasswordSection.vue'
import NewsletterSwitch from '../../../components/myfunda/setting/normal/NewsletterSwitch.vue'
import LeaveDialogButton from '../../../components/myfunda/setting/normal/LeaveDialogButton.vue'
export default {
  components: {
    ChangePasswordSection,
    ChangePhoneSection,
    NewsletterSwitch,
    LeaveDialogButton,
  },
  props: {
    myProfile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      passwordFormKey: 'passwordFormKey',
      phoneFormKey: 'phoneFormKey',
      leaveFormKey: 'leaveFormKey',
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    // this.passwordForm.phoneNumber = this.myProfile.userPhone
  },
  methods: {
    ...mapActions('auth', {
      signinKakao: 'signinKakao',
      reloadProfile: AuthTypes.actions.GET_PROFILE,
    }),
    async clickKakao() {
      this.isLoading = true
      let resp = await this.signinKakao()
      this.isLoading = false
      if (resp === true) {
        await this.reloadProfile()
        this.$swal.basic.alert('카카오 계정이 연동되었습니다.')
      } else {
        this.$swal.basic.alert(
          '연동에 실패하였습니다. 문제가 지속되면 문의바랍니다.'
        )
      }
    },
    async disconnectSocial() {
      let alert = await this.$swal.basic.confirm('연동 해제하시겠습니까?')

      if (!alert.isConfirmed) {
        return
      }

      this.isLoading = true

      try {
        const data = await this.$fundaApi.mutation(
          gql`
            {
              data: wwwDeleteUserSocialPlatform(platform: KAKAO)
            }
          `
        )

        if (data === true) {
          await this.reloadProfile()
          this.$swal.basic.alert('연동 해제되었습니다.')
        } else {
          this.$swal.basic.alert(
            '에러가 발생하였습니다. 문제가 지속되면 문의바랍니다.'
          )
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
