<template>
  <div>
    <component v-if="shopPopup !== null" :is="shopPopup"></component>
  </div>
</template>

<script>
import Popup from './Popup.vue'
import PopupForTutorial from './PopupForTutorial.vue'

export default {
  components: {
    Popup,
    PopupForTutorial,
  },
  data() {
    return {
      popups: [
        {
          name: 'Popup',
          values: ['main', 'myfunda'],
        },
        {
          name: 'PopupForTutorial',
          values: ['invest'],
        },
      ],
      shopPopup: null,
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        for (const popup of this.popups) {
          const values = popup.values
          const has = values.some((value) => this.isRouteMatched(value))
          if (has === true) {
            this.shopPopup = popup.name
            break
          }
        }
      },
    },
  },
  methods: {
    isRouteMatched(name) {
      const routeName = this.$route?.name
      const parent = this.$route?.matched[0]
      return name === routeName || `/${name}` === parent.path
    },
  },
}
</script>

<style lang="scss" scoped></style>
