<template>
  <div>
    <v-row class="mb-7" no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>

      <v-col cols="12">
        <h3 class="my-2 pl-1 color--gray-3 font-weight-regular">
          당사는 온라인투자연계금융업 영위와 관련하여 발생할 수 있는 이해상충
          방지를 위해 이해상충 방지지침을 제정 및 운영하고 있으며
          이해상충방지체계 관련 세부내용은 다음과 같습니다.
          <br /><br />

          가.이해상충 발생 가능성 파악 평가 및 관계체계
          <br /><br />
          (1) 임직원과 이용자 간의 이해상충 방지
          <br />
          -특정 임직원의 인적 관계가 연계대출 및 투자 의사결정에 영향을 미칠
          가능성이 합리적으로 인지되는 시점부터 해당 임직원 관련 업무 배제
          <br /><br />
          (2) 차입자 간의 이해상충 방지
          <br />
          -신용평가 대상의 평가기준과 방법을 기술한 평가방법을 갖춤
          <br />
          -신용평가 전문인력 이외의 임직원이 신용평가 및 신용평가 과정에
          참여하지 않음
          <br /><br />
          (3) 투자자 간의 이해상충 방지
          <br />
          -투자자 신용정보의 관리 보호를 위한 정책 및 시스템 구축
          <br />
          -투자자 신용정보의 오 남용을 방지하기 위하여 임직원이 정보관리 절차 및
          정책을 준수하도록 관리
          <br /><br />
          (4) 회사 자기의 계산으로 하는 연계투자 관련 이해상충 방지
          <br />
          -자기의 계산으로 연계투자를 하는 경우에는 관계법령에 따라 해당 자금을
          예치기관에 예치 또는 신탁하여야 하며 회사의 운영자금과 분리
          <br />
          -자기의 계산으로 하는 연계투자와 그렇지 않은 연계투자 간에 차별이
          없도록 채권관리 및 추심업무를 선량한 관리자의 주의로 관리

          <br /><br />
          나.기타 이해상충 방지 관련 실시내용
          <br />
          -당사는 이해상충방지의 실효성 제고를 위하여 내부자신고제도
          명령휴가제도를 운영하고 있으며 임직원 윤리강령 제정 임직원 대상
          내부통제교육을 주기적으로 실시하고 있음
          <br />
          -또한 준법감시체제를 통해 임직원의 내부통제기준 준수 여부를 업무
          중요도 및 위험도 등을 감안하여 주기적으로 점검을 실시하는 한편
          관계법령 및 내부통제기준에서 정하는 금지행위 등의 효율적 점검을 위한
          모니터링 체계를 구축하고 있음

          <br /><br />
          다.정보유출 유인을 제거할 수 있는 절차 및 내용
          <br />
          (1) 내부정보 외부유출 차단(DLP) 시스템 구비
          <br />
          (2) 인터넷망 및 업무망 내부정보의 외부유출 차단
          <br />
          (3) 외부유출 방지(DLP) 개인정보(암호) 악성코드(랜섬웨어) 등 통합정보
          보호
          <br />
          (4) 허용되지 않은 프로그램의 네트워크 사용 차단 등
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '이해상충방지체계',
    },
  },
}
</script>

<style lang="scss" scoped></style>
