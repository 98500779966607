<template>
  <div>
    <ManagerIntroduction />
    <InterviewSection />
    <MediaSection />
    <AffiliateSection />
    <CatchphraseSection
      sectionId="intro-D"
      @go-register="$emit('go-register')"
    />
  </div>
</template>

<script>
import CatchphraseSection from './section/CatchphraseSection.vue'
import MediaSection from '@/pages/main/section/MediaSection.vue'
import AffiliateSection from '@/pages/main/section/AffiliateSection.vue'
import ManagerIntroduction from '@/pages/main/section/ManagerIntroduction.vue'
import InterviewSection from '@/pages/main/section/InterviewSection.vue'

export default {
  components: {
    CatchphraseSection,
    MediaSection,
    AffiliateSection,
    ManagerIntroduction,
    InterviewSection,
  },
}
</script>
