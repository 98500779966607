<template>
  <div class="official-notice">
    <v-tabs v-model="tab" background-color="#f6f8fa" centered>
      <v-tab>
        <p class="ma-0 font-size--16 font-weight-black">
          결산공시
        </p>
      </v-tab>
      <v-tab>
        <p class="ma-0 font-size--16 font-weight-black">
          월간공시
        </p>
      </v-tab>
      <v-tab>
        <p class="ma-0 font-size--16 font-weight-black">
          수시공시
        </p>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Closing />
      </v-tab-item>
      <v-tab-item>
        <Monthly />
      </v-tab-item>
      <v-tab-item>
        <Frequently />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Closing from './official/closing/ClosingIndex.vue'
import Monthly from './official/monthly/MonthlyIndex.vue'
import Frequently from './official/frequently/FrequentlyIndex.vue'

export default {
  components: {
    Closing,
    Monthly,
    Frequently,
  },
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style lang="scss">
.official-notice {
  * {
    line-height: 1.3 !important;
  }

  .v-tabs-bar {
    margin-bottom: 20px;
  }

  .v-tabs-items {
    background-color: #f6f8fa !important;
  }

  .data-table-wrapper {
    overflow: scroll;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #333;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    thead {
      th {
        background-color: #eee !important;
      }
    }
  }
}
</style>
