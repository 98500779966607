<template>
  <div class="custom-popup" :class="{ show: showPopup }" @click="showToggle()">
    <div class="custom-popup__contents" @click.stop="showPopup = true">
      <div class="popup-body">
        <div class="text-header d-flex justify-space-between align-center pt-3">
          <h3 class="ma-0 color--black font-size--20 font-weight-black">
            <span v-if="!!content">
              {{ content.fundingData.idx }}호 [
              {{ content.fnStore.storeName }} ]
            </span>
            연체 공지
          </h3>
          <a class="popup-close-btn cursor--pointer">
            <img
              class="vertical-align--middle"
              style="min-width: 15px"
              src="@/assets/images/myfunda/btn-close.svg"
              @click.stop="showToggle()"
            />
          </a>
        </div>
        <template v-if="isLoading === false">
          <div class="text-popup">
            <template
              v-if="!!content && content.fundingDataOverdueStatuses.length > 0"
            >
              <div class="on-load__overdue-status my-5">
                <label class="d-inline-block mr-1 font-size--17">
                  연체 상태:
                </label>
                <div
                  v-for="(overdueStatus,
                  index) in content.fundingDataOverdueStatuses"
                  :key="index"
                  class="d-inline-block"
                >
                  <template v-if="index > 0">
                    <span class="margin-x-5"> > </span>
                  </template>

                  <span
                    class="font-size--17"
                    :class="
                      content.fundingDataOverdueStatuses.length === index + 1
                        ? 'font-weight-bold overdue-status__' +
                          (overdueStatus.overdueStatusIdx - 1)
                        : ''
                    "
                  >
                    {{ overdueStatus.overdueStatus.name }}
                  </span>
                </div>
                <a
                  class="q-circle"
                  href="//cdn.funda.kr/funding/clip/overdue_cycle_ver2.png"
                  target="_blank"
                >
                  ?
                </a>
              </div>
              <div class="on-load__notice">
                <div class="mb-5">
                  <p class="notice__sub-header font-size--17 margin-b-10">
                    개요
                  </p>
                  <ul class="padding-l-25">
                    <li class="font-size--15 margin-b-5">
                      대출일:
                      {{ content.fundingData.registerDate | dateFormat }}
                    </li>
                    <li class="font-size--15 margin-b-5">
                      연체발생일:
                      {{
                        content.fundingDataOverdueStatuses[0].createdTime
                          | dateFormat
                      }}
                    </li>
                    <li class="font-size--15 margin-b-5">
                      연체사유:
                      {{ content.fundingDataOverdueStatuses[0].reason }}
                    </li>
                  </ul>
                </div>

                <div
                  v-if="content.fundingDataOverdueRepaymentPlans.length > 0"
                  class="notice__repayment-plan mb-5"
                >
                  <p class="notice__sub-header font-size--17 margin-b-10">
                    상환 계획
                  </p>
                  <ul class="padding-l-25">
                    <li
                      v-for="(plan,
                      index) in content.fundingDataOverdueRepaymentPlans"
                      :key="index"
                      class="font-size--15 margin-b-5"
                    >
                      {{ plan.createdTime | dateFormat }}
                      <br />
                      {{ plan.plan }}
                    </li>
                  </ul>
                </div>

                <div
                  v-if="content.fundingDataCollectDetails.length > 0"
                  class="notice__collect-detail mb-5"
                >
                  <p class="notice__sub-header font-size--17 margin-b-10">
                    추심 현황
                  </p>
                  <ul class="padding-l-25">
                    <li
                      v-for="(detail,
                      index) in content.fundingDataCollectDetails"
                      :key="index"
                      class="font-size--15 margin-b-5"
                    >
                      {{ detail.entrustmentTime | dateFormat }}
                      <br />
                      {{ detail.description }}
                    </li>
                  </ul>
                </div>

                <div
                  v-if="
                    !!content.fundingDataOverdueStatuses[0].memoCreditor &&
                      content.fundingDataOverdueStatuses[0].memoCreditor != ''
                  "
                  class="on-load__reference mb-5"
                >
                  <label class="notice__sub-header font-size--17 margin-b-10">
                    참고 사항
                  </label>
                  <p
                    class="font-size--15 ma-0 margin-t-5"
                    v-html="content.fundingDataOverdueStatuses[0].memoCreditor"
                  ></p>
                </div>
              </div>
            </template>
            <div v-else>
              <p class="ma-0 font-size--15">공지가 없습니다.</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <MyLoader />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// FundaNow OverdueNotice
import MyLoader from '@/components/MyLoader'

export default {
  props: {
    fnNum: {
      default: null,
    },
  },
  components: {
    MyLoader,
  },
  data() {
    return {
      isLoading: true,
      showPopup: false,
      content: null,
    }
  },
  watch: {
    fnNum: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getNoticeData()
        }
      },
    },
  },
  methods: {
    showToggle() {
      this.showPopup = !this.showPopup
    },
    setContent(content) {
      this.content = content
    },
    async getNoticeData() {
      this.isLoading = true

      try {
        let q = `
            data: wwwFnStoreFundingData(fnNum: ${this.fnNum}) {
              fundingData {
                idx
                title
                totalAmount
                registerDate
              }
              
              fnStore {
                storeName
              }
              
              fundingDataOverdueStatuses {
                overdueStatusIdx
                reason
                memoCreditor
                createdTime

                overdueStatus {
                  name
                }
              }

              fundingDataOverdueRepaymentPlans {
                plan
                createdTime
              }

              fundingDataCollectDetails {
                entrustmentTime
                description
              }
            }
          `
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data.fundingDataOverdueStatuses.length > 0) {
          data.fundingDataOverdueStatuses[0].memoCreditor = data.fundingDataOverdueStatuses[0].memoCreditor.replace(
            /(?:\r\n|\r|\n)/g,
            '<br />'
          )
        }

        this.setContent(data)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup .text-header {
  border-bottom: 1px solid #8a94a2;
  margin-bottom: 35px;
  padding-bottom: 10px;
}

.q-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 3px;
  background-color: #1baaf1;
  border-radius: 100%;
  color: white;
  font-size: 13px;
  vertical-align: text-bottom;
}

.notice__sub-header {
  @media screen and (max-width: 768px) {
    font-size: 15px !important;
  }
}

.on-load__notice ul li,
.on-load__reference p {
  line-height: 22px;

  @media screen and (max-width: 768px) {
    font-size: 13px !important;
  }
}
</style>
