<template>
  <section id="section__secret" :class="{ mobile: isMobile }">
    <div class="section-container--narrow container">
      <v-row class="section-main-row secret__main" no-gutters>
        <v-col cols="12" class="mb-5">
          <p class="section-title secret-title">
            안전하게 연 10.67% 수익률을
            <br />
            제공하고 있습니다
          </p>
        </v-col>

        <v-col :cols="cols" :class="{ 'mb-2': isMobile }">
          <div class="secret-card">
            <div>
              <p class="secret-card__icon">
                <picture>
                  <img src="@/assets/images/main/everyday-repay.svg" />
                </picture>
              </p>

              <p class="secret-card__title">
                카드매출 일상환
              </p>
            </div>

            <div class="secret-card__desc">
              <p>
                대출자의 카드매출로 매일 원리금을 자동 상환하여 안전성 UP,
                복리효과 UP!
              </p>
            </div>
          </div>
        </v-col>

        <v-col :cols="cols" :class="{ 'mb-2': isMobile }">
          <div class="secret-card">
            <div>
              <p class="secret-card__icon">
                <picture>
                  <img src="@/assets/images/main/time-plan.svg" />
                </picture>
              </p>

              <p class="secret-card__title">
                4,000개 이상 분산투자
              </p>
            </div>

            <div class="secret-card__desc">
              <p>
                전국에 흩어져 있는 4,000개 이상의 사업자에게 리스크를 분산하여
                투자
                <br />
                <span class="font-size--14">(평균대출이자 연 15%)</span>
              </p>
            </div>
          </div>
        </v-col>

        <v-col :cols="cols">
          <div class="secret-card">
            <div>
              <p class="secret-card__icon">
                <picture>
                  <img src="@/assets/images/main/earnings-up.svg" />
                </picture>
              </p>

              <p class="secret-card__title">
                세전 수익률 연 10.67%
              </p>
            </div>

            <div class="secret-card__desc">
              <p>
                최근 3년간 연 10% 이상의 수익률, 저금리 시대 최고의 대안투자
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            id="intro-B"
            class="cp__btn btn-funda--blue"
            height="45px"
            @click="$emit('go-register')"
          >
            회원가입 하러가기
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    cols() {
      return this.isMobile ? 12 : 4
    },
  },
}
</script>

<style lang="scss" scoped>
#section__secret {
  background-color: #eaeff4;

  .secret-title {
    color: #333;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  .secret-card {
    position: relative;
    max-width: 95%;
    min-height: 220px;
    width: 100%;
    background-color: transparent;
    // box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.4);
    font-weight: bold;
    text-align: center;

    margin: 0 auto;
    padding: 25px 12px 15px;

    &__icon {
      img {
        width: 60px;
        height: 60px;
      }
    }

    &__title {
      color: #333;
      font-size: 21px;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 0;
    }

    &__desc {
      display: flex;
      max-width: 350px;
      max-height: 140px;
      width: 90%;

      margin: 12px auto 0;

      p {
        color: #333;
        font-size: 17px;
        font-weight: 300;
        line-height: 1.4;
        margin: 0;
      }
    }
  }

  .cp__btn {
    max-width: 350px;
    width: 100%;
    border-radius: 25px;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
    padding: 0 28px;
  }

  &.mobile {
    .secret-card {
      max-width: 100%;
    }
  }
}
</style>
