/**
 * 텍스트 복사
 * @param value
 */
export const textCopy = (value) => {
  var textarea = document.createElement('textarea')
  textarea.value = value
  document.body.appendChild(textarea)

  var selection = document.getSelection()
  var range = document.createRange()
  range.selectNode(textarea)
  selection.removeAllRanges()
  selection.addRange(range)
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export const jsLoad = async (attr) => {
  return new Promise((resolve, reject) => {
    const org = document.getElementById(attr.id)
    if (org) {
      resolve(org)
      return
    }
    const sc = document.createElement('script')
    for (let key in attr) {
      sc[key] = attr[key]
    }

    sc.onload = () => {
      resolve(sc)
    }
    sc.onerror = () => {
      reject(new Error('script load error'))
    }

    document.head.appendChild(sc)
  })
}

export const cssLoad = (attr) => {
  return new Promise((resolve, reject) => {
    const org = document.getElementById(attr.id)
    if (org) {
      resolve(org)
      return
    }
    const sc = document.createElement('link')
    sc.rel = 'stylesheet'
    sc.type = 'text/css'

    for (let key in attr) {
      sc[key] = attr[key]
    }

    sc.onload = () => {
      resolve(sc)
    }
    sc.onerror = () => {
      reject(new Error('script load error'))
    }

    document.head.appendChild(sc)
  })
}

export const simpleClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const sleep = (m) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(m)
    }, m)
  })
}

export const uuid = () => {
  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export const shuffle = (array) => {
  let currentIndex = array.length
  let randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // eslint-disable-next-line
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex],]
  }

  return array
}

// 랜덤 정수
export const getRandomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const numberFormat = (value) => {
  if (!value) return '0'
  const strNumber = value.toString().replace(/[^\d\\-]/g, '')
  const plainNumber = Number(strNumber).toString()
  return plainNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const floatFormat = (value, digits = 1) => {
  const v = parseFloat(value) * 100
  const vLen = parseInt(v).toString().length
  // length = 정수부분 길이
  // + d = 소수점 이하
  return v.toPrecision(vLen + digits)
}

export const removeComma = (value) => {
  return value.replace(/[^\d]/g, '')
}

export const truncate = (amount, unit = 100) => {
  return Math.floor(amount / unit)
}

export default {
  textCopy,
  simpleClone,
  cssLoad,
  jsLoad,
  sleep,
  uuid,
  shuffle,
  getRandomNum,
  numberFormat,
  removeComma,
  floatFormat,
  truncate,
}
