import Vue from 'vue'
import VueMeta from 'vue-meta'
import store from '@/store'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

function seoToOg(seo) {
  const item = {
    type: 'website',
    title: seo.title,
    description: seo.description,
    url: seo.url,
    image: seo.image,
    site_name: seo.author
  }
  let result = []
  for (let key in item) {
    result.push({
      property: `og:${key}`,
      content: item[key]
    })
  }
  return result
}

function seoToTwitter(seo) {
  const item = {
    card: 'summary',
    title: seo.title,
    description: seo.description,
    image: seo.image,
    domain: location.origin
  }
  let result = []
  for (let key in item) {
    result.push({
      name: `twitter:${key}`,
      content: item[key]
    })
  }

  return result
}

function seoToDefaultMeta(seo) {
  const item = {
    description: seo.description,
    keywords: seo.keywords,
    author: seo.author
  }
  let result = []
  for (let key in item) {
    result.push({
      name: key,
      content: item[key]
    })
  }

  return result
}

function othersMeta(seo) {
  const item = {
    'apple-mobile-web-app-title': seo.author,
    'apple-mobile-web-app-capable': 'yes',
    'mobile-web-app-capable': 'yes',
    'theme-color': '#0CAEFF',
    'msapplication-TileColor': '#00A9FF',
    'msapplication-TileImage': '',
  }

  let result = []
  for (let key in item) {
    result.push({
      name: key,
      content: item[key]
    })
  }

  return result
}

function othersLink(seo) {
  return [
    {
      rel: 'canonical',
      href: location.origin
    },
    // {
    //   rel: 'apple-touch-icon-precomposed',
    //   href: null
    // }, {
    //   rel: 'apple-touch-icon',
    //   href: null
    // }, {
    //   rel: 'icon',
    //   type: 'image/png',
    //   href: null,
    //   sizes: '500x500'
    // }
  ]
}

/**
 *
 * @param {*} vm 현재페이지 created의 this
 * @param {*} obj defaultSeo에 있는 항목과 비슷한 형식의 값
 */
global._seo = async (obj) => {
  let seo = Object.assign({
    author: 'Funda',
    title: '펀다 - 건실한 상점에 안전한 투자를 연결합니다.',
    titlesDefault: '펀다',
    // titles: [],
    description: '자영업자 P2P금융 플랫폼',
    keywords: '소액재테크,bccard,bc카드,세이프플랜,자동분산투자,중금리대출,자영업자대출,소상공인대출,직장인재테크,사회초년생재테크,노후자금,크라우드펀딩,주식,가상화폐,저축은행,적금,금리비교,P2P대출,P2P투자,P2P금융,펀다,funda',
    type: null,
    url: null,
    image: 'https://www.funda.kr/images/logo/funda_ci_01.png',
    meta: [],
    link: []
  }, { url: location.href }, obj)

  if (seo.titles) {
    let title = ''
    seo.titles.unshift(seo.titlesDefault)
    seo.titles.reverse()

    for (var i = 0; i < seo.titles.length; i++) {
      title += seo.titles[i]
      if (i !== seo.titles.length - 1) {
        title += ' | '
      }
    }
    delete seo.titles
    seo.title = title
  }

  if (seo.image.startsWith('http') === false) {
    seo.image = `${location.protocol}//${location.host}${seo.image}`
  }
  // og
  seo.meta = seo.meta
    .concat(seoToOg(seo)) // og
    .concat(seoToTwitter(seo))// twitter
    .concat(seoToDefaultMeta(seo)) // default
    .concat(othersMeta(seo)) // others

  seo.link = seo.link.concat(othersLink(seo))

  store.dispatch('seo/set', seo)

  return seo
}
