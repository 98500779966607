<template>
  <div class="terms">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <pre v-if="terms">
            <div v-html="terms.content"></div>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'legacy',
  data() {
    return {
      terms: null,
    }
  },
  async created() {
    const { terms } = await this.$fundaApi.query(gql`{
      terms: getTermsByCategoryAndVisible(category:${this.$route.query.mode.toUpperCase()}){
        content
      }
    }`)
    this.terms = terms[0]
  },
}
</script>
<style lang="scss" scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: pre-line;
}

::v-deep {
  pre {
    p {
      margin: 0 !important;
      line-height: 1.4 !important;
    }

    table {
      width: 90%;
      // border: solid #333;
      // border-width: 1px 0 0 1px;
      margin: 0 auto;

      td {
        height: 25px !important;
        // border: solid #333;
        // border-width: 0 1px 1px 0;
        font-size: 16px;
        padding: 5px 10px;
        line-height: 1.1;
      }
    }
  }

  .se-main-container {
    .se-component {
      line-height: 0;
    }

    p.se-text-paragraph {
      margin-bottom: 16px !important;

      span {
        display: block;
        line-height: 1.5;
      }
    }
  }
}
</style>
