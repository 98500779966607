<template>
  <div v-if="isAuth">
    <v-dialog v-model="onDialog" max-width="1024" width="95%">
      <v-card>
        <v-card-title class="justify-end" :class="isMobile ? 'px-2' : 'px-4'">
          <a @click.stop="showToggle()" class="cursor--pointer">
            <img src="@/assets/images/myfunda/btn-close.svg" />
          </a>
        </v-card-title>
        <v-card-text :class="isMobile ? 'px-2' : 'px-4'">
          <LimitTypeFileUpload />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import LimitTypeFileUpload from '@/components/investor/LimitTypeFileUpload.vue'

export default {
  components: {
    LimitTypeFileUpload,
  },
  data() {
    return {
      onDialog: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAuth: AuthTypes.getters.IS_AUTHENTICATED,
      increaseDialog: AuthTypes.getters.GET_INCREASE_DIALOG,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    increaseDialog: {
      immediate: true,
      handler() {
        this.onDialog = this.increaseDialog
      },
    },
    onDialog(v) {
      if (v === false) {
        this.ON_INCREASE_DIALOG(v)
      }
    },
  },
  methods: {
    ...mapActions('auth', {
      ON_INCREASE_DIALOG: AuthTypes.actions.ON_INCREASE_DIALOG,
    }),
    showToggle() {
      this.onDialog = !this.onDialog
    },
  },
}
</script>

<style lang="scss" scoped></style>
